import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Fabutton = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6">Standard</Typography>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Primary
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Extended</Typography>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Primary
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add"
                  size="medium"
                >
                  <AddIcon sx={{ mr: 1 }} /> Extended
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Secondary
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab color="secondary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Secondary
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab
                  variant="extended"
                  color="secondary"
                  aria-label="add"
                  size="medium"
                >
                  <AddIcon sx={{ mr: 1 }} /> Extended
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Default
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab color="default" aria-label="add">
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Default
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab
                  variant="extended"
                  color="default"
                  aria-label="add"
                  size="medium"
                >
                  <AddIcon sx={{ mr: 1 }} /> Extended
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Disabled
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab disabled aria-label="add">
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" align="center">
                  Disabled
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Fab variant="extended" disabled aria-label="add" size="medium">
                  <AddIcon sx={{ mr: 1 }} /> Extended
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Fabutton;
