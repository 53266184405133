import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import classes from './Information.module.scss';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { AWSPath } from '../../../../../../utilities/awsUploadImage';
import { format } from 'date-fns';
import {
  getDayLabelAbb,
  getDayLabel,
} from '../../../../../../utilities/constantUtils';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import paths from '../../../../../../routing/Constants';
import { useTranslation } from 'react-i18next';
import { datePickerLanguage } from '../../../../../../i18n/i18nUtil';

const { ORGANIZATION_PATHS } = paths;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}));

const Information = () => {
  const { t } = useTranslation();
  const { selectedOrg, organization: orgContext } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [editBtn, seteditBtn] = useState(false);
  const [organization, setOrganization] = useState(orgContext);

  const navigateToManageInformation = () => {
    const [, _, orgPath, orgId, infoPath] = location.pathname.split('/');
    const pathToManageInfo = `/${_}/${orgPath}/${orgId}/${infoPath}/${ORGANIZATION_PATHS.INFORMATION_PATHS.EDIT_INFORMATION}`;
    navigate(pathToManageInfo);
  };
  useEffect(() => {
    if (selectedOrg?.userAuth) {
      var role = selectedOrg.userAuth.filter((f) => f === 'Administrator');
      if (role.length > 0) {
        seteditBtn(true);
      }
    }
  }, [selectedOrg?.userAuth]);
  useEffect(() => {
    orgContext && setOrganization(orgContext);
  }, [orgContext]);

  const getWorkDays = () => {
    var result = '';
    organization?.workDays.forEach((f) => {
      result = result + getDayLabelAbb(f, t);
    });
    return result.substring(0, result.trim().length - 1);
  };

  const viewOwners = () => {
    return listName(organization.owner);
  };
  const viewAdmins = () => {
    return listName(organization.admins);
  };
  const listName = (owner) => {
    var result = [];
    owner.forEach((f) => {
      var item = (
        <Grid item xs={3}>
          <div className={classes.flex}>
            <div className="padR5">
              <div className="padR10">
                {' '}
                <Avatar
                  className={classes.avatarName}
                  alt={f.name}
                  src="/broken-image.jpg"
                />
              </div>
            </div>
            <div className="padT7">
              <Typography className={classes.dataValue}>{f.name}</Typography>
            </div>
          </div>
        </Grid>
      );
      result.push(item);
    });

    return result;
  };

  const [bImange, setBImage] = useState(true);
  const onHandleImg = () => {
    setBImage(false);
  };
  return (
    <>
      <div container className={classes.invite_card}>
        <div className={`${classes.flexContainer} padB20`}>
          <div className="padR10">
            {' '}
            {bImange ? (
              <img
                alt="org-logo"
                onError={onHandleImg}
                src={AWSPath(organization?.handle)}
                className={classes.img}
              />
            ) : (
              <Avatar
                className={classes.pictureAvatar}
                alt={organization?.name}
                src="/broken-image.jpg"
              />
            )}
          </div>
          <div>
            <Typography className={classes.name}>
              {organization?.name}
            </Typography>
            <Typography className={classes.desc}>
              {organization?.url}
            </Typography>
          </div>
          {editBtn && (
            <div className={classes._button}>
              <div className="padR10">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={() => navigateToManageInformation()}
                  startIcon={<EditIcon />}
                >
                  {t('organization.edit-details')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.titleLabel}>
              {t('organization.information')}
            </Typography>
          </div>
        </div>
        <div className="padB20">
          <Divider />
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.organization-name')}
            </Typography>
          </div>
          <div className={` ${classes.infoValue}`}>
            <Typography className={classes.dataValue}>
              {organization?.name}
            </Typography>
          </div>
          <div className={`padT3 ${classes.titleWidth} `}>
            <Typography className={classes.orgTitle}>
              {t('organization.registration-no')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.dataValue}>
              {organization?.regNo}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>Handle</Typography>
          </div>
          <div className={`${classes.flex} ${classes.infoValue}`}>
            <div className="padR10">
              <Typography className={` ${classes.dataValue}  `}>
                {organization?.handle}
              </Typography>
            </div>
            <div className="padT5">
              <LightTooltip
                placement="top"
                title={
                  <div className={classes.alignCenter}>
                    {t(
                      'organization.your-handle-will-be-your-public-username-this-will-be-your-unique-identifier-to-represent-your-organization-on-meewee',
                    )}
                  </div>
                }
                arrow
              >
                <InfoIcon
                  sx={{
                    fontSize: 14,
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                  }}
                />
              </LightTooltip>
            </div>
          </div>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.vat-no')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.dataValue}>
              {organization?.vatNo}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB50`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.website')}
            </Typography>
          </div>
          <div>
            <Typography
              className={`${classes.infoValue} ${classes.dataValue}  `}
            >
              {organization?.url}
            </Typography>
          </div>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.country')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.dataValue}>
              {organization?.country?.name}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.titleLabel}>
              {t('organization.calendar-settings')}
            </Typography>
          </div>
        </div>
        <div className="padB20">
          <Divider />
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.work-days')}
            </Typography>
          </div>
          <div>
            <Typography
              className={`${classes.infoValue} ${classes.dataValue}  `}
            >
              {organization && getWorkDays()}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.vacation-year-start')}
            </Typography>
          </div>
          <div>
            <Typography
              className={`${classes.infoValue} ${classes.dataValue}  `}
            >
              {t('organization.every')}{' '}
              {organization?.vacationYearStart &&
                format(new Date(organization?.vacationYearStart), 'MMM dd', {
                  locale: datePickerLanguage(),
                })}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.work-week-start')}
            </Typography>
          </div>
          <div>
            <Typography
              className={`${classes.infoValue} ${classes.dataValue}  `}
            >
              {getDayLabel(organization?.workWeekStart, t)}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.work-hours')}
            </Typography>
          </div>
          <div>
            <Typography
              className={`${classes.infoValue} ${classes.dataValue}  `}
            >
              {organization?.workHours} {t('organization.hrs')}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB50`}>
          <div className={`padT3 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.gdpr-privacy')}
            </Typography>
          </div>
          <div className="padR10">
            <Typography className={` ${classes.dataValue}  `}>
              {organization?.registrationNotificationDetails
                ? organization?.registrationNotificationDetails
                  ? 'Enabled'
                  : 'Disabled'
                : 'Disabled'}
            </Typography>
          </div>
          <div className="padT5">
            <LightTooltip
              placement="top"
              title={
                <div className={classes.alignCenter}>
                  {t(
                    'organization.personal-details-are-included-in-notifications-such-as-emails-push-notifications-etc',
                  )}
                </div>
              }
              arrow
            >
              <InfoIcon
                sx={{
                  fontSize: 14,
                  color: 'white',
                  backgroundColor: 'black',
                  borderRadius: '50%',
                }}
              />
            </LightTooltip>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.titleLabel}>
              {t('organization.owner-and-Admins')}
            </Typography>
          </div>
        </div>
        <div className="padB20">
          <Divider />
        </div>

        <div className={`${classes.flexContainer} padB20`}>
          <div className={`padT12 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.owner')}
            </Typography>
          </div>

          <Grid container spacing={1}>
            {organization && viewOwners()}
          </Grid>
        </div>
        <div className={`${classes.flexContainer} padB90`}>
          <div className={`padT12 ${classes.titleWidth}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.admins')}
            </Typography>
          </div>
          <Grid container spacing={1}>
            {organization && viewAdmins()}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Information;
