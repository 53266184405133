import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import paths from './Constants';
import i18n from '../i18n/i18n';

const ProtectedRoute = ({ children }) => {
  const lang = `/${i18n.language}/`;
  const isAuthenticated = useSelector(
    (state) => state.authContext.isAuthenticated,
  );
  //const location = useLocation();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`${lang}${paths.LOGIN}`} replace />
  );
};

export default ProtectedRoute;
