import React from 'react';
import Typography from '@mui/material/Typography';
import classes from './InfoTab.module.scss';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import { projectStatus } from '../../../../../../../utilities/constantUtils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const InfoTab = (props) => {
  const { t } = useTranslation();
  return (
    <div className={classes.flexContainer}>
      <div className={`${classes.row1} padB30`}>
        <div className="padB30">
          <TextField
            id="standard-basic"
            className={classes.fieldWidth}
            label={t('organization.project-title')}
            variant="standard"
            value={props.projectTitle}
            onChange={props.onProjectTitle}
          />
        </div>

        <div className="padB30">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('organization.start-date')}
              value={dayjs(props.startDate)}
              onChange={(newValue) => {
                props.onChangeDate(newValue, 0);
              }}
              renderInput={(params) => <TextField {...params} />}
              className={classes.date_picker}
              inputFormat={dateFormats.datepicker}
            />
          </LocalizationProvider>
        </div>
        <div className="padB20">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('organization.end-date')}
              value={props.endDate ? dayjs(props.endDate) : null}
              onChange={(newValue) => {
                props.onChangeDate(newValue, 1);
              }}
              renderInput={(params) => <TextField {...params} />}
              className={classes.date_picker}
              inputFormat={dateFormats.datepicker}
            />
            <FormHelperText>{t('organization.optional')}</FormHelperText>
          </LocalizationProvider>
        </div>
      </div>

      <div className="padT10">
        <div className={`${classes.row2}`}>
          <Typography className={classes.task}>
            {t('organization.tasks')}
          </Typography>
        </div>
        <div className={`${classes.row2} padB20`}>
          <Typography className={classes.taskResult}>
            0 {t('organization.tasks').toLowerCase()}
          </Typography>
        </div>

        <div className={`${classes.row2} padB30`}>
          <FormControl variant="standard" sx={{ minWidth: 280 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={props.status}
              label={t('organization.status')}
              onChange={props.onChangeStatus}
            >
              {projectStatus(t).map((f) => {
                return (
                  <MenuItem key={f.id} value={f.id}>
                    {f.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{props.statusLabel}</FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;
