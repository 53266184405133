import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import classes from './InfoTab.module.scss';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import { projectStatus } from '../../../../../../../utilities/constantUtils';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const InfoTab = (props) => {
  const { t } = useTranslation();
  const [approver, setApprover] = useState('');
  useEffect(() => {
    setApprover(props.approver);
  }, [props.approver]);

  return (
    <>
      <div className={classes.flexContainer}>
        <div>
          <div className="padB30">
            <TextField
              id="standard-basic"
              className={classes.fieldWidth}
              label={t('organization.project-title')}
              variant="standard"
              value={props.projectTitle}
              onChange={props.onProjectTitle}
            />
          </div>

          <div className={`${classes.row1} padB30`}>
            <FormControl variant="standard" sx={{ minWidth: 250 }}>
              <Autocomplete
                id="disable-close-on-select"
                className={classes.fieldWidth}
                value={props?.selectedTeam}
                clearOnEscape
                onChange={(e, value) => props.onChangeTeams(e, value)}
                options={props.teamList?.map((f) => f?.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('organization.select-team')}
                    variant="standard"
                  />
                )}
              />
              <FormHelperText>{props.orgWideText}</FormHelperText>
            </FormControl>
          </div>
          <div className={`${classes.row1} padB30`}>
            <FormControl variant="standard" sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {t('organization.select-approver')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={approver}
                defaultValue={approver}
                label={t('organization.select-approver')}
                onChange={props.onChangeApprover}
              >
                {props.usersApprover.map((f, index) => {
                  return (
                    <MenuItem key={f.id} value={f.id}>
                      {f.firstName} {f.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div>
            <Typography className={classes.task}>
              {' '}
              {t('organization.tasks')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.taskResult}>
              {props.selectedItem ? props.selectedItem?.task.length : '0'}{' '}
              {t('organization.tasks').toLowerCase()}
            </Typography>
          </div>
        </div>
        <div className="padT10">
          <div className={`${classes.row2} padB30`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('organization.start-date')}
                value={props.startDate ? dayjs(props.startDate) : null}
                onChange={(newValue) => {
                  props.onChangeDate(newValue, 0);
                }}
                renderInput={(params) => <TextField {...params} />}
                className={classes.date_picker}
                inputFormat={dateFormats.datepicker}
              />
            </LocalizationProvider>
          </div>
          <div className={`${classes.row2} padB20`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => props.onChangeDate(null, 1),
                  },
                }}
                label={t('organization.end-date')}
                value={props.endDate ? dayjs(props.endDate) : null}
                onChange={(newValue) => {
                  props.onChangeDate(newValue, 1);
                }}
                renderInput={(params) => <TextField {...params} />}
                className={classes.date_picker}
                inputFormat={dateFormats.datepicker}
              />
              <FormHelperText>{t('organization.optional')}</FormHelperText>
            </LocalizationProvider>
          </div>

          <div className={`${classes.row2} padB30`}>
            <FormControl variant="standard" sx={{ minWidth: 250 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={props.status}
                label={t('organization.status')}
                onChange={props.onChangeStatus}
              >
                {projectStatus(t).map((f) => {
                  return (
                    <MenuItem key={f.id} value={f.id}>
                      {f.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{props.statusLabel}</FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoTab;
