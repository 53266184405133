import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import paths from '../../../../routing/Constants';
import { decodeBase64toUtf8 } from '../../../../utilities/base64Utils';
import { acceptInvitationService } from '../../service/teaminvitationService';
import ContentLayout from '../../../ui/layout/contentlayout/ContentLayout';
import ErrorAlert from '../../../ui/alerts/errorAlert/ErrorAlert';
import Loader from '../../../ui/loader/Loader';
import classes from './AcceptInvitation.module.scss';

const welcomePagePath = paths.ROOT.concat(paths.WELECOME_TEAM);
const homePagePath = paths.ROOT;

const AcceptInvitation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authContext.isAuthenticated,
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const acceptInviteRequest = async (decodedEmail, decodedInvitationId) => {
      const acceptInvitationResponse = await acceptInvitationService({
        email: decodedEmail,
        id: decodedInvitationId,
      });

      setIsLoading(false);

      if (acceptInvitationResponse.result === 1) {
        if (isAuthenticated) {
          navigate(homePagePath, {
            state: { teamName: acceptInvitationResponse.data.teamName },
          });
        } else {
          navigate(welcomePagePath, {
            state: { teamName: acceptInvitationResponse.data.teamName },
          });
        }
      } else {
        setError(acceptInvitationResponse.errorMessage);
      }
    };
    const code = searchParams.get('code');
    const invitationId = searchParams.get('invitation');

    if (code && invitationId) {
      let decodedEmail = decodeBase64toUtf8(code);
      let decodedInvitationId = Number(decodeBase64toUtf8(invitationId));

      acceptInviteRequest(decodedEmail, decodedInvitationId);
    } else {
      setIsLoading(false);
      setError('Invalid credentials');
    }
  }, []);

  return (
    <ContentLayout>
      {isLoading && <Loader title="Team Invitation | Meewee" />}
      {error && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={5}
          className={classes.error_alert}
        >
          <Grid item>
            <ErrorAlert id="sign-in-error" message={error} />
          </Grid>
        </Grid>
      )}
    </ContentLayout>
  );
};

export default AcceptInvitation;
