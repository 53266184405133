import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teamList: [],
};

const orgTeamContextSlice = createSlice({
  name: 'orgTeamContext',
  initialState: initialState,
  reducers: {
    setTeamList: (state, action) => {
      state.teamList = action.payload;
    },
  },
});

export const { setTeamList } = orgTeamContextSlice.actions;

export default orgTeamContextSlice.reducer;
