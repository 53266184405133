import {
  authTypes,
  organizationComponentPermissions,
  userRoles,
} from '../utilities/roleUtils';

const useAuthorization = () => {
  const checkAuthHandler = (
    authType,
    authInfo,
    roles,
    teamRoles,
    typeOfCheck,
  ) => {
    if (authType === authTypes.ACTION) {
      if (authInfo === organizationComponentPermissions.ADD_MEMBERS) {
        const verifyUserRoles =
          roles?.includes(userRoles.orgAdmin) ||
          roles?.includes(userRoles.orgOwner) ||
          roles?.includes(userRoles.teamManager);
        const verifyTeamRoles =
          teamRoles?.filter(
            (team) =>
              team.teamRole === userRoles.teamApprover ||
              team.teamRole === userRoles.teamManager,
          ).length > 0;
        return typeOfCheck === 'org'
          ? verifyUserRoles
          : verifyUserRoles || verifyTeamRoles;
      }
    }

    if (authType === authTypes.PAGE) {
      if (authInfo === organizationComponentPermissions.VIEW_EMPLOYEE_PAGES) {
        return (
          roles?.includes(userRoles.employee) ||
          roles?.includes(userRoles.outsideCollaborator)
        );
      }

      if (authInfo === organizationComponentPermissions.VIEW_ADMIN_PAGES) {
        return (
          roles?.includes(userRoles.orgAdmin) ||
          roles?.includes(userRoles.orgOwner) ||
          roles?.includes(userRoles.teamManager) ||
          teamRoles?.filter(
            (team) =>
              team.teamRole === userRoles.teamApprover ||
              team.teamRole === userRoles.teamManager,
          ).length > 0
        );
      }
    }
  };

  return {
    checkAuthHandler,
  };
};

export default useAuthorization;
