import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import classes from '../../../ProjectTable.module.scss';
import { transformMinsToHoursMinutesString } from '../../../../../../../../../../../utilities/timeConversion';
import {
  dateFormats,
  sortByDate,
} from '../../../../../../../../../../../utilities/dateUtils';

const ConfirmationDialogContent = (task, taskHasReg) => {
  return (
    <React.Fragment>
      {taskHasReg ? (
        <React.Fragment>
          <Typography variant="subtitle1" color="text.secondary">
            These registrations will be removed from your saved and/or submitted
            time registrations and will not be part of the manager/approver
            approval:
          </Typography>
          <div className={classes.delete_dialog_content}>
            <Typography variant="subtitle1" color="text.secondary">
              {task.taskTitle}
            </Typography>
            {sortByDate(Object.keys(task.taskReg)).map((date, index) => (
              <Grid key={index} container direction="row" alignItems="center">
                <Grid item xs={1.5}>
                  <Typography variant="subtitle1" color="text.secondary">
                    {format(new Date(date), dateFormats.short_date_dayOfWeek)}{' '}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" color="text.secondary">
                    -{' '}
                    {transformMinsToHoursMinutesString(
                      task.taskReg[date][0].regDuration,
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </div>
          <Typography variant="subtitle1" color="text.secondary">
            NOTE: All approved registrations are not allowed to be deleted.
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="subtitle1" color="text.secondary">
            The registrations are all approved and cannot be deleted.
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ConfirmationDialogContentTitle = (
  taskHasReg,
  task,
  startDate,
  endDate,
) => {
  const titleText = taskHasReg
    ? `Would you like to delete all registrations in "
    ${task?.taskTitle}" from ${startDate} to ${endDate}?`
    : `Oops, registrations for "${task?.taskTitle}" from  
    ${startDate} to ${endDate} cannot be deleted`;
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        fontWeight="fontWeightSemiBold"
        color="text.primary"
      >
        {titleText}
      </Typography>
    </React.Fragment>
  );
};

export { ConfirmationDialogContent, ConfirmationDialogContentTitle };
