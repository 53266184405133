import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextBox from '../../../ui/formcontrols/textBox/TextBox';
import useInputValidation from '../../../../hooks/use-input-validation';
import AutoCompleteComboBox from '../../../ui/formcontrols/autoCompleteComboBox/AutoCompleteComboBox';
import FormButton from '../../../ui/formcontrols/formButton/FormButton';
import classes from './SignUpForm.module.scss';
import { getCountryList } from '../../service/signupService';
import { decodeBase64toUtf8 } from '../../../../utilities/base64Utils';
import { validateUserName } from '../../service/signupService';
import ErrorAlert from '../../../ui/alerts/errorAlert/ErrorAlert';
import SignUpFormValidation from './SignUpFormValidation';
import PasswordTextBox from '../../../ui/formcontrols/passwordTextBox/PasswordTextBox';
import { useTranslation } from 'react-i18next';

const userCredentials = {
  newPassword: '',
};

const SignUpForm = (props) => {
  const { t } = useTranslation();
  document.title = t('login.sign-up-meewee');

  const [formValues, setFormValues] = useState(userCredentials);
  const [errorMessage, setErrorMessage] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [userInvitationid, setUserInvitationId] = useState(undefined);
  const [userTeamNameInvite, setUserTeamNameInvite] = useState(undefined);
  const [formErrors, setFormErrors] = useState({});
  const [showValidationList, setShowValidationList] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasAlphabet: false,
    hasNumber: false,
    hasSpecialCharacter: false,
  });

  const { state, pathname, search } = useLocation();

  const navigate = useNavigate();

  //username field validation and error handling
  const [inputUsername, setInputUsername] = useState('');
  const [usernameFieldErrorMessage, setusernameFieldErrorMessage] =
    useState('');

  const [userNameFieldIsValid, setUserNameFieldIsValid] = useState(true);

  //form error
  const resetFormError = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  useEffect(() => {
    getCountryList().then((data) => {
      if (data.result === 1) {
        setCountryList(data.data);
      }
    });
  }, []);

  useEffect(() => {
    let emailCode = searchParams.get('code');
    if (emailCode) {
      let decodedEmail = decodeBase64toUtf8(emailCode);
      setUserEmail(decodedEmail);
    }
    let token = searchParams.get('token');

    if (token) {
      setUserToken(token);
    }

    let invitationId = searchParams.get('invitation');

    if (invitationId) {
      let decodedInvitationId = Number(decodeBase64toUtf8(invitationId));
      setUserInvitationId(decodedInvitationId);
    }

    let teamName = searchParams.get('team');

    if (teamName) {
      let decodedTeamName = decodeBase64toUtf8(teamName);
      setUserTeamNameInvite(decodedTeamName);
    }
  }, []);

  useEffect(() => {
    //Check if error message was passed as a state of a preceding component
    if (state) {
      if (state.errorMessage) {
        setErrorMessage(state.errorMessage);
        navigate(pathname.concat(search), { replace: true, state: null });
      }
    }
  }, []);

  useEffect(() => {
    if (inputUsername) {
      const timeOut = setTimeout(() => {
        validateUserName({ username: inputUsername, field: 'username' }).then(
          (data) => {
            if (data.result === 1) {
              setUserNameFieldIsValid(true);
            } else {
              setUserNameFieldIsValid(false);
              setusernameFieldErrorMessage(data.errorMessage);
            }
          },
        );
      }, 800);

      return () => clearTimeout(timeOut);
    } else {
      setUserNameFieldIsValid(false);
    }
  }, [inputUsername]);

  const onUsernameChangeHandler = (event) => {
    setInputUsername(event.target.value.toLowerCase());
    if (usernameFieldErrorMessage) {
      setusernameFieldErrorMessage('');
    }
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const onUserNameBlurHandler = (event) => {
    if (!inputUsername) {
      setUserNameFieldIsValid(false);
      setusernameFieldErrorMessage(t('login.invalid-username'));
    }
  };

  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: onPasswordChangeHandler,
    inputBlurHandler: onPasswordBlurHandler,
  } = useInputValidation((value) => value.length > 6, resetFormError);

  const {
    value: fname,
    isValid: fnameIsValid,
    hasError: fnameHasError,
    valueChangeHandler: onFnameChangeHandler,
    inputBlurHandler: onFnameBlurHandler,
  } = useInputValidation((value) => value.length > 0, resetFormError);

  const {
    value: lname,
    isValid: lnameIsValid,
    hasError: lnameHasError,
    valueChangeHandler: onLnameChangeHandler,
    inputBlurHandler: onLnameBlurHandler,
  } = useInputValidation((value) => value.length > 0, resetFormError);

  const {
    value: country,
    isValid: countryIsValid,
    hasError: countryHasError,
    autoCompleteChangeHandler: onCountryChangeHandler,
    inputBlurHandler: onCountryBlurHandler,
  } = useInputValidation((value) => value.length > 0, resetFormError);

  const onClickContinueHandler = (event) => {
    setSearchParams(
      { key: 'termsAndConditions' },
      {
        replace: false,
        state: {
          userInfo: {
            email: userEmail,
            username: inputUsername,
            password: formValues.newPassword,
            'first-name': fname,
            'last-name': lname,
            country: country,
            token: userToken,
          },
          teamInvitationInfo: {
            'request-id': userInvitationid,
            teamName: userTeamNameInvite,
          },
        },
      },
    );
  };

  const handleChange = (e, fields) => {
    if (e != null) {
      const { value } = e.target;
      setFormValues({ ...formValues, [fields]: value });
    }
    if (fields === 'newPassword') {
      validateNewPassword(e.target.value);
    }
  };
  const onBlurHandler = (event, fieldName) => {
    handleChange(event, fieldName);
  };
  const enterKeyPressHandler = (event, fieldName) => {
    if (event.key === 'Enter') {
      handleChange(event, fieldName);
    }
  };
  const validateNewPassword = (password) => {
    const validations = {
      minLength: password.length >= 8,
      hasAlphabet: /[a-zA-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
        password,
      ),
    };

    setPasswordValidations(validations);
  };

  const onKeyPressHandler = () => {
    setShowValidationList(true);
  };

  const onKeyDownHandler = (e) => {
    if (e.key === 'Backspace' && e.target.value.length === 1) {
      setShowValidationList(false);
    }
  };

  const passwordValidationConfig = [
    {
      passwordValidationType: passwordValidations.minLength,
      textPasswordValidations: t('login.be-at-least-8-characters-long'),
    },
    {
      passwordValidationType: passwordValidations.hasAlphabet,
      textPasswordValidations: t('login.have-at-least-one-alphabet'),
    },
    {
      passwordValidationType: passwordValidations.hasNumber,
      textPasswordValidations: t('login.have-at-least-one-number'),
    },
    {
      passwordValidationType: passwordValidations.hasSpecialCharacter,
      textPasswordValidations: t('login.have-at-least-one-special-character'),
    },
  ];

  const formIsValid =
    userEmail &&
    fnameIsValid &&
    lnameIsValid &&
    countryIsValid &&
    userNameFieldIsValid &&
    !Object.values(passwordValidations).includes(false);

  return (
    <Grid
      container
      direction="column"
      className={classes.signup_form_container}
    >
      <Grid item>
        <Typography variant="heading">Almost there!</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Please provide us your basic profile.
        </Typography>
        <TextBox
          id="email"
          label={t('login.email')}
          type="text"
          ariaLabel="Email"
          value={userEmail}
          disabled={true}
        />
        <TextBox
          isError={usernameFieldErrorMessage !== ''}
          id="username"
          label={t('login.username')}
          type="text"
          value={inputUsername}
          onChange={onUsernameChangeHandler}
          onBlur={onUserNameBlurHandler}
          errorText={usernameFieldErrorMessage}
          ariaLabel="Username"
          inputProps={{
            autocomplete: 'none',
          }}
          autocomplete="off"
        />

        <div className={classes.password}>
          <PasswordTextBox
            id="newPassword"
            label={t('login.type-new-password')}
            type="password"
            onChange={(e) => handleChange(e, 'newPassword')}
            onBlur={(e) => onBlurHandler(e, 'newPassword')}
            onKeyPress={(e) => {
              enterKeyPressHandler(e, 'newPassword');
              onKeyPressHandler();
            }}
            onKeyDown={onKeyDownHandler}
            errorText={formErrors?.newPassword}
            isError={
              Object.keys(formErrors).length
                ? formErrors.newPassword !== undefined
                : false
            }
            validated={
              passwordValidations.minLength &&
              passwordValidations.hasAlphabet &&
              passwordValidations.hasNumber &&
              passwordValidations.hasSpecialCharacter
            }
          />
          {showValidationList &&
            Object.values(passwordValidations).includes(false) && (
              <div className={classes.validationList}>
                <div className={classes.headValidation}>Password must: </div>
                <ul>
                  {passwordValidationConfig.map((configItem, index) => (
                    <SignUpFormValidation
                      key={index}
                      passwordValidationType={configItem.passwordValidationType}
                      textPasswordValidations={
                        configItem.textPasswordValidations
                      }
                    />
                  ))}
                </ul>
              </div>
            )}
        </div>

        <div className={classes.signup_name_form}>
          <TextBox
            isError={fnameHasError}
            id="fname"
            label={t('login.first-name')}
            type="text"
            onChange={onFnameChangeHandler}
            onBlur={onFnameBlurHandler}
            errorText="Invalid First name"
            ariaLabel="First name"
          />

          <TextBox
            isError={lnameHasError}
            id="lname"
            label={t('login.last-name')}
            type="text"
            onChange={onLnameChangeHandler}
            onBlur={onLnameBlurHandler}
            errorText={t('login.invalid-last-name')}
            ariaLabel="Last name"
          />
        </div>

        <AutoCompleteComboBox
          isError={countryHasError}
          id="country"
          label={t('login.country')}
          onChange={onCountryChangeHandler}
          onBlur={onCountryBlurHandler}
          errorText={t('login.invalid-country')}
          listItems={countryList}
        />

        <div className={classes.continue_button}>
          <FormButton
            label={t('common.continue')}
            fullWidth={true}
            onClick={onClickContinueHandler}
            id="continue-button"
            disabled={!formIsValid}
          />
        </div>
        {errorMessage && (
          <div className={classes.signup_error_alert}>
            <ErrorAlert
              id="sign-up-error"
              message={
                <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
              }
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default SignUpForm;
