import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Avatar from '@mui/material/Avatar';
import SuccessSnackbar from '../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import ErrorSnackbar from '../../../ui/snackbar/errorSnackbar/ErrorSnackbar';
import {
  userRoles,
  getRolesAccess,
  checkKeywordsExist,
} from '../../../../utilities/roleUtils';
import { Tooltip } from '@mui/material';

import classes from './OrgManagement.module.scss';
import MemberInfo from '../MessageInfo';
import {
  getListOfPendingInvitations,
  getMeOrganization,
} from '../../service/orgmanagementService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import paths from '../../../../routing/Constants';
import {
  employeeMenuList,
  adminMenuList,
  personalMenuList,
  Paper,
  ListMenu,
} from './config';
import { useTranslation } from 'react-i18next';

const { ORGANIZATION_PATHS } = paths;

const OrgManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const listOfPendingRequests = useSelector(
    (state) => state.orgContext.ownPendingInvitations,
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const orgList = useSelector((state) => state.userContext.userOrganizations);
  const [userOrgList, setUserOrgList] = useState([]);
  const [route, setRoute] = useState({ id: '', tab: '' });
  const [snackbarCallback, setSnackbarCallback] = useState(null);
  const [openMessageInfo, setOpenMessageInfo] = useState(false);
  const [
    checkIfUserHasOwnPendingInvitationList,
    setCheckIfUserHasOwnPendingInvitationList,
  ] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(undefined);
  const [isCurrenltyShowingInvitations, setIsCurrenltyShowingInvitations] =
    useState(false);
  const hasOwnPendingInvitations = listOfPendingRequests.length > 0;
  const organization = useSelector((state) => state.orgContext.organization);
  const [isOWner, setIsOwner] = useState(false);

  useEffect(() => {
    if (organization) {
      setIsOwner(false);
      if (organization.owner) {
        var exist = organization.owner.find((owner) => owner.id === userId);
        if (exist) setIsOwner(true);
      }
    }
  }, [organization]);

  useEffect(() => {
    const { id, tab } = route;
    if (id && tab) {
      const path = tab ? `${id}/${tab}` : `${id}`;
      navigate(path);
      const selectedTab = getSelectedTabBySubPath(t, tab);
      setSelectedOrgMenu(selectedTab);
    }
  }, [route]);

  useEffect(() => {
    if (selectedOrg?.userAuth) {
      const isAdmin = checkKeywordsExist(selectedOrg?.userAuth, [
        'Administrator',
      ]);
      const isPersonalOrg = selectedOrg?.emp === userId;
      isPersonalOrg
        ? dispatch(getListOfPendingInvitations())
        : dispatch(getListOfPendingInvitations(selectedOrg?.emp, isAdmin));
    }

    const hasUserOrg = () => userOrgList.some((org) => org?.id === userId);
    if (!hasUserOrg()) {
      const overallOrgList = buildUserOrgList();
      setUserOrgList(overallOrgList);
    }
  }, [userId, selectedOrg, userOrgList]);

  const buildUserOrgList = () => {
    var newOrgList = [];
    const personalOrg = {
      id: userId,
      name: `${userFirstName} ${userLastName}`,
      emp: userId,
      organizations: { id: userId, name: `${userFirstName} ${userLastName}` },
      otherName: userFirstName,
      owner: { id: userId, fname: userFirstName, lname: userLastName },
      userAuth: ['Personal'],
    };
    newOrgList.push(personalOrg);
    if (orgList) {
      newOrgList = newOrgList.concat(orgList);
    }

    return newOrgList;
  };

  useEffect(() => {
    const [, , , currentOrgId, currentTab] = location.pathname.split('/');
    const hasValidTabId = () =>
      userOrgList.some((org) => `${org?.id}` === currentOrgId);

    const isInitialLoadOfOrganizationPage =
      (!currentOrgId && !currentTab) || !hasValidTabId();

    if (isInitialLoadOfOrganizationPage) {
      const personalOrg = userOrgList?.[0];
      const hasNonPersonalOrg = userOrgList.length >= 2;
      const { id, tab } = route;
      if (hasNonPersonalOrg) {
        getOrganizationInformation(userOrgList?.[1]?.organizations?.id);
        setSelectedOrg(userOrgList?.[1]);
        if (
          id !== userOrgList?.[1]?.organizations?.id &&
          tab !== employeeMenuList(t)[0].subPath
        ) {
          setRoute({
            id: userOrgList?.[1]?.organizations?.id,
            tab: employeeMenuList(t)[0].subPath,
          });
        }
        setSelectedOrgMenu(employeeMenuList(t)[0]);
      } else {
        setSelectedOrg(personalOrg);
        if (
          id !== personalOrg?.organizations?.id &&
          tab !== personalMenuList(t)?.[1].subPath
        ) {
          setRoute({
            id: personalOrg?.organizations?.id,
            tab: personalMenuList(t)?.[1].subPath,
          });
        }
        setSelectedOrgMenu(personalMenuList(t)?.[1]);
      }
    } else {
      getOrganizationInformation(currentOrgId);
      const currentOrg = userOrgList.find((org) => org.id === +currentOrgId);
      currentOrg && setSelectedOrg(currentOrg);
      const selectedTab = getSelectedTabBySubPath(t, currentTab);
      setSelectedOrgMenu(selectedTab);
    }
    setIsCurrenltyShowingInvitations(false);
  }, [orgList, location, userOrgList]);

  useEffect(() => {
    setShouldShowPersonalData(false);
    if (selectedOrg) {
      getRoleList();
      if (selectedOrg?.organizations?.id === userId) {
        setShouldShowPersonalData(true);
        setListMenu(personalMenuList(t));
      } else {
        setListMenu(adminMenuList(t));
      }
    }
  }, [selectedOrg]);

  const getOrganizationInformation = (organizationId) => {
    getOrganizationInfo(organizationId);
  };

  useEffect(() => {
    if (checkIfUserHasOwnPendingInvitationList) {
      setOpenMessageInfo(hasOwnPendingInvitations);
    }
    setCheckIfUserHasOwnPendingInvitationList(false);
  }, [checkIfUserHasOwnPendingInvitationList]);

  useEffect(() => {
    setOpenMessageInfo(hasOwnPendingInvitations);
  }, [hasOwnPendingInvitations]);

  const onOrgMenuItemSelectHandler = (event, menuItem) => {
    setSelectedOrgMenu(menuItem);
    setRoute({ id: selectedOrg?.id, tab: menuItem.subPath });
    if (openInvitation) {
      setViewTeamInvitation(false);
      setOpenMessageInfo(hasOwnPendingInvitations);
    }

    if (isCurrenltyShowingInvitations) {
      setIsCurrenltyShowingInvitations(false);
      setOpenMessageInfo(hasOwnPendingInvitations);
    }
  };

  const [selectedOrgMenu, setSelectedOrgMenu] = useState(
    employeeMenuList(t)[0],
  );
  const [openOrgList, setOpenOrgList] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openInvitation, setViewTeamInvitation] = useState(false);

  const userTeamRoles = useSelector((state) =>
    state.userContext.userTeamRoles.filter(
      (team) => team.teamOrg === selectedOrg?.id,
    ),
  );

  const successSnackbarRef = useRef(null);
  const errorSnackbarRef = useRef(null);

  const [listMenu, setListMenu] = useState(personalMenuList(t));
  const [showPersonalData, setShouldShowPersonalData] = useState(false);

  const getRoleList = () => {
    const authorizationOfUserBySelectedOrganization =
      selectedOrg?.userAuth || [];

    const rolesAccess = getRolesAccess(
      authorizationOfUserBySelectedOrganization,
    );

    setRoleList(rolesAccess);
  };

  const getOrganizationInfo = (id) => {
    if (userId.toString() !== id.toString()) {
      dispatch(getMeOrganization(id));
    }
  };

  const handleMenuItemClick = (item) => {
    getOrganizationInfo(item.id);
    setSelectedOrg(item);
    const isPersonalOrg = item?.id === userId;
    const defaultTab = isPersonalOrg
      ? `${ORGANIZATION_PATHS.MY_PROJECTS}/${ORGANIZATION_PATHS.MY_PROJECTS_PATHS.LIST}`
      : ORGANIZATION_PATHS.TEAMS_AND_PROJECT;
    setRoute({ id: item?.id, tab: defaultTab });
    setOpenOrgList(false);
    if (openInvitation) {
      setViewTeamInvitation(false);
    }
  };

  const handleToggle = () => {
    setOpenOrgList((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenOrgList(false);
  };

  const showSuccessSnackBar = (message, cb) => {
    successSnackbarRef.current.snackBarContentHandler(message);
    setSnackbarCallback(cb);
  };

  const showErrorSnackBar = (message, cb) => {
    errorSnackbarRef.current.snackBarContentHandler(message);
    setSnackbarCallback(cb);
  };

  const onBackClickInTeamsAndInvitationPage = () => {
    setViewTeamInvitation(false);
    setOpenMessageInfo(false);
    setCheckIfUserHasOwnPendingInvitationList(true);
    setIsCurrenltyShowingInvitations(!isCurrenltyShowingInvitations);
    navigate(-1);
  };

  const showTeamRole = (role) => {
    let teamsList = userTeamRoles.filter((team) => team.teamRole === role);

    return (
      <React.Fragment>
        {teamsList.length > 0 && (
          <Tooltip
            title={teamsList.map((team, index) => (
              <div key={index}>{team.teamName}</div>
            ))}
          >
            <div className={classes.org_select_role}>
              <span>{role}</span>
            </div>
          </Tooltip>
        )}
      </React.Fragment>
    );
  };

  const onCloseSnackCallback = {
    type: snackbarCallback,
    reset: () => setSnackbarCallback(null),
  };
  const isActivePageTeamInvitation = location?.path?.includes(
    ORGANIZATION_PATHS.VIEW_TEAM_INVITATION,
  );
  const showRoles = () => {
    let tagRole = 0;
    const divResult = [];
    selectedOrg?.userAuth
      ?.filter((role) => role !== userRoles.orgOwner)
      .forEach((role, index) => {
        let roleName = role;
        if (role === userRoles.user && tagRole === 0) {
          tagRole++;
          roleName = userRoles.employee;
          divResult.push(returnDivRole(roleName, index));
        }
        if (role === userRoles.employee && tagRole === 0) {
          tagRole++;
          roleName = userRoles.employee;
          divResult.push(returnDivRole(roleName, index));
        }

        if (role !== userRoles.employee && role !== userRoles.user) {
          divResult.push(returnDivRole(roleName, index));
        }
      });

    return divResult;
  };

  const returnDivRole = (roleName, index) => {
    return (
      <div className={classes.org_select_role} key={index}>
        <Typography>
          <span>{roleName}</span>
        </Typography>
      </div>
    );
  };

  return (
    <Grid
      container
      item
      className={`${classes.organization_menu_grid_container} organizationMenuRoot`}
    >
      {openMessageInfo ? (
        <MemberInfo
          selectedEmploymentId={selectedOrg?.emp}
          orgId={selectedOrg?.id}
          onClickHandler={() => {
            setIsCurrenltyShowingInvitations(true);
            setRoute({
              id: selectedOrg?.id,
              tab: ORGANIZATION_PATHS.VIEW_TEAM_INVITATION,
            });
          }}
          isAdmin={checkKeywordsExist(selectedOrg?.userAuth, ['Administrator'])}
        />
      ) : (
        <></>
      )}
      <Grid item id="fullheight" className={classes.org_list_menu}>
        <Paper variant="outlined" className={classes.paper}>
          <div className={classes.avatar}>
            <div className={classes.avatarContainer}>
              <Button
                onClick={handleToggle}
                ref={anchorRef}
                className={classes.btnAvatar}
              >
                {selectedOrg !== undefined && (
                  <div className={classes.flex}>
                    <Avatar
                      alt={selectedOrg?.organizations?.name}
                      src="/broken-image.jpg"
                    />
                    <div className={classes.name}>
                      <Typography className={classes.nameAvatar}>
                        {selectedOrg?.otherName
                          ? selectedOrg?.otherName
                          : selectedOrg?.organizations?.name}
                      </Typography>
                    </div>
                    <div className={classes.divArrowColor}>
                      <ArrowDropDownIcon className={classes.arrowColor} />
                    </div>
                  </div>
                )}
              </Button>
              <div>
                <div className={classes.org_con_role}>
                  {isOWner && (
                    <div className={classes.org_select_role}>
                      <Typography>
                        <span>{userRoles.orgOwner}</span>
                      </Typography>
                    </div>
                  )}
                  {showRoles()}
                  {showTeamRole(userRoles.teamApprover)}
                </div>
              </div>
            </div>
          </div>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={openOrgList}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className={classes.org_selection_list}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <Typography>
                        {t('organization.select-an-organization')}
                      </Typography>
                      {userOrgList.map((org, index) => (
                        <MenuItem
                          className={classes.org_select_org}
                          key={index}
                          selected={
                            org?.organizations?.id ===
                            selectedOrg?.organizations?.id
                          }
                          onClick={() => handleMenuItemClick(org)}
                        >
                          <div className={classes.select_org_container}>
                            <div className={classes.org_select_name}>
                              <Avatar
                                alt={org.organizations?.name}
                                src="/broken-image.jpg"
                                className={classes.org_header_icon}
                              />
                            </div>
                            <div
                              className={`${classes.org_select_name} ${classes.org_select_name_width}`}
                            >
                              {org.organizations?.name}
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {roleList.length > 0 && (
            <ListMenu className={classes.org_list}>
              <React.Fragment>
                {!showPersonalData && (
                  <React.Fragment>
                    {employeeMenuList(t).map((menuItem, index) => (
                      <ListItemButton
                        key={menuItem.key}
                        selected={
                          isActivePageTeamInvitation
                            ? false
                            : selectedOrgMenu?.key === menuItem.key
                        }
                        className={classes.list_item_button}
                        onClick={(event) =>
                          onOrgMenuItemSelectHandler(event, menuItem)
                        }
                      >
                        <Typography variant="body2">
                          {menuItem.label}
                        </Typography>
                      </ListItemButton>
                    ))}
                    <Divider className={classes.menu_divider} />
                  </React.Fragment>
                )}

                <React.Fragment>
                  {listMenu.map((menuItem, index) => (
                    <>
                      {roleList.find(
                        (f) => f.key === menuItem.permissionKey && f.access,
                      ) && (
                        <ListItemButton
                          key={menuItem.key}
                          selected={selectedOrgMenu?.key === menuItem.key}
                          className={classes.list_item_button}
                          onClick={(event) =>
                            onOrgMenuItemSelectHandler(event, menuItem)
                          }
                        >
                          <Typography variant="body2">
                            {menuItem.label}
                          </Typography>
                        </ListItemButton>
                      )}
                    </>
                  ))}
                </React.Fragment>
              </React.Fragment>
            </ListMenu>
          )}
        </Paper>
      </Grid>
      <Outlet
        context={{
          selectedOrg: selectedOrg,
          cbShowSuccessSnackBarFn: showSuccessSnackBar,
          cbShowErrorSnackBarFn: showErrorSnackBar,
          cbOnBackClickInTeamsAndInvitationPage:
            onBackClickInTeamsAndInvitationPage,
        }}
      />
      <ErrorSnackbar
        ref={errorSnackbarRef}
        snack={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          sx: {
            left: '50%!important',
          },
        }}
      />
      <SuccessSnackbar ref={successSnackbarRef} cb={onCloseSnackCallback} />
    </Grid>
  );
};

export default OrgManagement;

const getSelectedTabBySubPath = (t, subPath) => {
  const indexedAdminMenu = adminMenuList(t);
  const indexedEmployeeMenu = employeeMenuList(t);
  const indexedPersonalMenu = personalMenuList(t);
  const allMenu = [
    ...indexedAdminMenu,
    ...indexedEmployeeMenu,
    ...indexedPersonalMenu,
  ];

  const selectedTab = allMenu.find(
    (menu) => menu?.subPath?.split('/')?.[0] === subPath,
  );
  return selectedTab;
};
