import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Typography from '@mui/material/Typography';
import classes from './DialogUploadImage.module.scss';
import {
  AWSUploadImage,
  AWSPath,
} from '../../../../../../../utilities/awsUploadImage';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DialogUploadImage = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageInfo, setUploadImageInfo] = useState();

  const handleClickOpen = () => {
    setUploadImage(null);
    setOpen(true);
  };
  const uploadHandler = () => {
    if (uploadImage) {
      uploadFile();
      setUploadImageInfo(uploadImage);
      setOpen(false);
      props.uploadHandler();
    }
  };

  const uploadFile = async () => {
    var response = await AWSUploadImage(binaryFile, props.handle);
  };

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };
  const [binaryFile, setBinaryFile] = useState();
  const onFileChange = (event) => {
    // var binary = readFileDataAsBase64(event);
    // setBinaryFile(binary);
    setBinaryFile(event.target.files[0]);
    const fsize = event.target.files[0].size;
    const fileSize = Math.round(fsize / 1024);

    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    img.onload = () => {
      validateImage(fileSize, img.width, img.height, img.src);
    };
  };

  function readFileDataAsBase64(e) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  const validateImage = (size, width, height, src) => {
    if (size <= 1024 && width === 400 && height === 400) {
      setUploadImage(src);
    }
  };

  const [bUpload, setBUpload] = useState(false);
  const onImageError = () => {
    setBUpload(true);
  };
  return (
    <>
      <div className={classes.flexContainer}>
        <div className="padR20">
          {bUpload ? (
            <img src={uploadImageInfo} className={classes.img} />
          ) : (
            <img
              onError={onImageError}
              src={AWSPath(props.handle)}
              className={classes.img}
            />
          )}
        </div>
        <div className="padT10">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickOpen}
          >
            {t('organization.upload-image').toUpperCase()}
          </Button>
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className={classes.dialog}
        >
          {t('organization.upload-image')}
        </DialogTitle>

        <DialogContent className={classes.dialog}>
          <div className="padB20">
            <div className="padR20">
              <img src={uploadImage} className={classes.img} />
            </div>
            <div className="padT10">
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={onFileChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  color="secondary"
                  component="span"
                  className={classes.button}
                >
                  {t('organization.browse-image')}
                </Button>
              </label>
            </div>
          </div>
          <Typography gutterBottom className={classes.desc}>
            {t('organization.image-must-be-40x40-pixel-and-below-1MB')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button autoFocus onClick={uploadHandler}>
            {t('common.upload').toUpperCase()}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DialogUploadImage;
