import { createSlice } from '@reduxjs/toolkit';
import { removeTeam } from './orgContextSlice';

const initialState = {
  userId: -1,
  userOrganizationHandle: '',
  userLastName: '',
  userFirstName: '',
  userEmail: '',
  userOrganizations: [],
  userTeamRoles: [],
};

const userContextSlice = createSlice({
  name: 'userContext',
  initialState: initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.userOrganizationHandle = action.payload.userOrganizationHandle;
      state.userLastName = action.payload.userLastName;
      state.userFirstName = action.payload.userFirstName;
      state.userEmail = action.payload.userEmail;
      state.userOrganizations = action.payload.employments;
      state.userTeamRoles = Object.assign([], action.payload.userTeamRoles);
    },
    clearPasswordUpdateMessage: (state) => {
      state.password.success = null;
      state.password.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeTeam, (state, action) => {
      //this will remove user team role if current user logged in requested to leave the team and accepted it on behalf of the team
      let updatedRolesList = state.userTeamRoles.filter(
        (team) => team.teamId !== action.payload.teamId,
      );
      state.userTeamRoles = updatedRolesList;
    });
  },
});

export const { setUserProfile, removeUserTeamRole } = userContextSlice.actions;

export const actionTypes = {
  clearLeaveRequestMessage: 'userContext/clearPasswordUpdateMessage',
};

export default userContextSlice.reducer;
