import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format, isToday, isWeekend } from 'date-fns';
import { getArrayDays } from '../../../../../../../utilities/dateUtils';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import { TableFooter, Typography } from '@mui/material';
import ProjectPicker from '../../../../timeRegistrationPickers/projectPicker/ProjectPicker';
import {
  getTotalRegistration,
  getTotalRegistrationsPerDate,
} from '../../../../../service/timeregistrationService';
import ProjectTable from './projectTable/ProjectTable';
import classes from './WeeklyTimeRegistrationList.module.scss';
import TotalCell from './totalCell/TotalCell';
import { setTotalRegHoursPerDate } from '../../../../../../../store/registrationContextSlice';
import OrganizationTable from './organizationTable/OrganizationTable';
import { useTranslation } from 'react-i18next';
import { datePickerLanguage } from '../../../../../../../i18n/i18nUtil';

const WeeklyTimeRegistrationList = ({ orgRegistrations, ...props }) => {
  const { t } = useTranslation();
  const [dateList, setDateList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let listOfDays = getArrayDays(
      props.dateInfo.startDate,
      props.dateInfo.endDate,
    );
    setDateList(listOfDays);
  }, [props.dateInfo]);

  return (
    <React.Fragment>
      <TableContainer className={`timesheetTable ${classes.reg_table_header}`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {dateList.map((date) => (
                <TableCell
                  align="center"
                  key={date}
                  className={`${
                    isWeekend(date) && classes.weekend_table_header
                  }`}
                >
                  <div className={`${isToday(date) && classes.today}`}>
                    <Typography variant="subtitle2">
                      {format(date, dateFormats.month_day)}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      fontWeight={'fontWeightMedium'}
                    >
                      {/* {format(date, dateFormats.day_of_week)} */}
                      {format(date, dateFormats.day_of_week, {
                        locale: datePickerLanguage(),
                      })}
                    </Typography>
                  </div>
                </TableCell>
              ))}

              <TableCell align="center">
                <Typography variant="caption" color="secondary">
                  {t('me.total')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.table_totals_header}>
              <TableCell>
                <Typography variant="caption" color="secondary">
                  {t('me.total')}
                </Typography>
              </TableCell>
              {dateList.map((date, dateIndex) => {
                const totalHours = getTotalRegistrationsPerDate(
                  orgRegistrations,
                  format(date, dateFormats.default),
                );

                dispatch(
                  setTotalRegHoursPerDate({
                    date: format(date, dateFormats.default),
                    totalHours: totalHours,
                  }),
                );
                return (
                  <TableCell
                    align="center"
                    key={dateIndex}
                    className={`${
                      isWeekend(date) && classes.weekend_table_header
                    }`}
                  >
                    <TotalCell
                      color="text.primary"
                      fontWeight="fontWeightSemiBold"
                      sumOfReg={getTotalRegistrationsPerDate(
                        orgRegistrations,
                        format(date, dateFormats.default),
                      )}
                    />
                  </TableCell>
                );
              })}
              <TableCell align="center">
                <TotalCell
                  color="text.primary"
                  sumOfReg={getTotalRegistration(orgRegistrations)}
                  fontWeight="fontWeightSemiBold"
                />
              </TableCell>
            </TableRow>
          </TableHead>

          {props.selectedOrg?.id === -1 ? (
            <React.Fragment>
              <OrganizationTable
                registrations={orgRegistrations}
                dateList={dateList}
                showSuccessSnackBarFn={props.showSuccessSnackBarFn}
                showErrorSnackBarFn={props.showErrorSnackBarFn}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TableBody>
                {orgRegistrations && (
                  <React.Fragment>
                    {orgRegistrations.map((proj, projIndex) => (
                      <ProjectTable
                        key={projIndex}
                        proj={proj}
                        dateList={dateList}
                        selectedOrg={props.selectedOrg}
                        showSuccessSnackBarFn={props.showSuccessSnackBarFn}
                        showErrorSnackBarFn={props.showErrorSnackBarFn}
                      />
                    ))}
                  </React.Fragment>
                )}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.tabProjectPicker}>
                  <TableCell colSpan={9} className={classes.footer}>
                    <ProjectPicker org={props.selectedOrg?.id} />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default WeeklyTimeRegistrationList;
