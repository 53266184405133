import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import classes from './SuccessSnackbar.module.scss';
import { useDispatch } from 'react-redux';

const SuccessSnackbar = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    setOpen(!!messageInfo)
  }, [messageInfo, open]);

  useImperativeHandle(ref, () => ({
    snackBarContentHandler(message) {
      setMessageInfo(message);
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessageInfo(undefined);
    callback()
  };

  const callback = () => {
    if (props.cb) {
      const { type, reset } = props.cb
      type && dispatch({ type })
      reset && reset()
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open && messageInfo}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity={props.severity || 'success'}
        className={classes.alertBox}
        sx={{
          backgroundColor: 'info.main',
          '& .MuiAlert-icon': { color: 'info.contrastText' },
        }}
      >
        <div>
          <Typography
            variant={'subtitle2'}
            color="info.contrastText"
            className={classes.alertMessage}
          >
            {messageInfo}
          </Typography>
          <IconButton
            aria-label="close"
            sx={{ p: 0.5, fontSize: '14px' }}
            onClick={handleClose}
            className={classes.closeIconButton}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </Alert>
    </Snackbar>
  );
});

export default SuccessSnackbar;
