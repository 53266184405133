import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import classes from './ProfileNavBar.module.scss';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import paths from '../../../routing/Constants';
import { reset } from '../../../store/authContextSlice';
import { removeAllItemsFromLocalStorage } from '../../../utilities/localStorageUtlis';
import { Toolbar } from '@mui/material';
import meeweelogodark from '../../../assets/meewee-dark-logo.svg';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { setToolBarShow } from '../../../store/toolbarContextSlice';
import i18n from '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

import leaveicon from '../../../assets/leave_icon.svg';

const leaveIcon = <img src={leaveicon} alt="leave icon blue color" />;

const expandeddrawerWidth = 226;
const collapsedDrawerWidth = 65;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '@media (min-width:1024px)': {
    ...(open && {
      marginLeft: expandeddrawerWidth,
      width: `calc(100% - ${expandeddrawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      marginLeft: collapsedDrawerWidth,
      width: `calc(100% - ${collapsedDrawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const HideOnScroll = (props) => {
  const dispatch = useDispatch();
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  dispatch(setToolBarShow(trigger));
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const ProfileNavBar = (props) => {
  const { t } = useTranslation();
  const loginPath = `${i18n.language}/${paths.LOGIN}`;

  const loc = useLocation();
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const userLastName = useSelector((state) => state.userContext.userLastName);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    dispatch(reset());
    removeAllItemsFromLocalStorage();
    navigate(loginPath);
  };

  const onPrivacyAccountClick = () => {
    navigate(`/${i18n.language}/profile`);
  };

  const handleDrawerToggle = () => {
    props.onMobileDrawerToggle();
  };

  const [headingTitle, setHeadingTitle] = useState();

  useEffect(() => {
    let title = '';
    let image = '';
    let meewee = '';

    if (loc.pathname === '/'.concat(paths.ME, '/', paths.LEAVE)) {
      title = <span className={classes.menuTitle}>My Leave </span>;
      image = <span className={classes.icon}>{leaveIcon} </span>;
    }
    if (loc.pathname === '/'.concat(paths.WE, '/', paths.LEAVE_MANAGEMENT)) {
      title = <span className={classes.menuTitle}>Team Leave</span>;
      image = <span className={classes.icon}>{leaveIcon} </span>;
      meewee = <span className={classes.meeweeText}>We</span>;
    }
    if (loc.pathname === '/'.concat(paths.ME, '/', paths.LEAVE_REQUEST)) {
      title = <span className={classes.menuTitle}>My Leave </span>;
      image = <span className={classes.icon}>{leaveIcon} </span>;
    }
    if (loc.pathname === '/'.concat(paths.WE, '/', paths.TEAM_TIMESHEET)) {
      title = <span className={classes.menuTitle}>Timesheet</span>;
      image = (
        <span className={classes.icon}>
          <PunchClockIcon />{' '}
        </span>
      );
      meewee = <span className={classes.meeweeText}>We</span>;
    }

    setHeadingTitle(
      <h1 className={classes.leaveTitleContainer}>
        {image}
        {meewee}
        {title}
      </h1>,
    );
  }, [loc.pathname]);

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        open={props.open}
        className={classes.appBar}
        elevation={0}
      >
        {headingTitle}

        <Toolbar className={classes.appBar_mobile_menu}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <img
            className={classes.mobile_logo}
            src={meeweelogodark}
            alt="meewee logo"
          ></img>
        </Toolbar>

        <Toolbar>
          <div className={classes.appBar_profile}>
            {/* TODO: change src value to real image path */}
            <Avatar alt={userFirstName} src="/broken-image.jpg">
              {userFirstName.charAt(0) + userLastName.charAt(0)}
            </Avatar>
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
        </Toolbar>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={onPrivacyAccountClick}>
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">
              {t('privacy-account.privacy-account')}
            </Typography>
          </MenuItem>
          <MenuItem onClick={onLogoutClick}>
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2"> {t('common.logout')}</Typography>
          </MenuItem>
        </Menu>
      </AppBar>
    </HideOnScroll>
  );
};

export default ProfileNavBar;
