import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import classes from './ListSkeleton.module.scss';
import { Grid } from '@mui/material';

const ListSkeleton = (props) => {
  return (
    <Stack className={classes.list_skeleton} spacing={1}>
      <Grid container className={classes.list_skeleton_details}>
        <Grid item xs={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={50}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={140}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.list_skeleton_details}>
        <Grid item xs={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={50}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={140}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.list_skeleton_details}>
        <Grid item xs={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={50}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={140}
            height={11}
            className={classes.list_skeleton_variant}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ListSkeleton;
