import i18n from './i18n';
import {
  saveStateToLocalStorage,
  getItemFromLocalStorage,
} from '../utilities/localStorageUtlis';
import { de, enUS } from 'date-fns/locale';

export const pathUpdate = (path) => {
  const lang = `/${i18n.language}/`;
  return `${lang}${path}`;
};

export const changeLanguage = () => {
  var checkLang = listLanguage.find((f) =>
    window.location.pathname.toLowerCase().includes(f.name.toLowerCase()),
  );
  if (checkLang) {
    if (checkLang.name !== 'signup' && checkLang.name !== 'reset-pw') {
      saveStateToLocalStorage('lang', checkLang.name);
      i18n.changeLanguage(checkLang.name);
    } else {
      var lang = JSON.parse(getItemFromLocalStorage('lang'));
      i18n.changeLanguage(lang);
    }
  }
};

export const datePickerLanguage = () => {
  var checkLang = listLanguage.find((f) =>
    window.location.pathname.toLowerCase().includes(f.name.toLowerCase()),
  );
  switch (checkLang?.id) {
    case 'da':
      return de;
    default:
      return enUS;
  }
};

export const listLanguage = [
  {
    id: 'da',
    name: 'da_DK',
  },
  {
    id: 'en',
    name: 'en_US',
  },
  {
    id: 'signup',
    name: 'signup',
  },
  {
    id: 'reset-pw',
    name: 'reset-pw',
  },
];
