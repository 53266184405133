import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import classes from './../ProjectTable.module.scss';
import { transformMinsToHoursMinutesString } from '../../../../../../../../../utilities/timeConversion';
import {
  dateFormats,
  sortByDate,
} from '../../../../../../../../../utilities/dateUtils';

const ConfirmationDialogContentTitle = (text) => {
  return (
    <Typography
      variant="h6"
      fontWeight="fontWeightSemiBold"
      color="text.primary"
    >
      {text}
    </Typography>
  );
};

const ConfirmationDialogContent = (text, regList) => {
  return (
    <React.Fragment>
      {regList && (
        <>
          <Typography variant="subtitle1" color="text.secondary">
            These registrations will be removed from your saved and/or submitted
            time registrations and will not be part of the manager/approver
            approval:
          </Typography>
          <div className={classes.delete_dialog_content}>
            {regList.map((task, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  className={classes.delete_task_info}
                >
                  {task.taskTitle}
                </Typography>
                {sortByDate(Object.keys(task.taskReg)).map((date, index) => (
                  <Grid
                    key={index}
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={1.5}>
                      <Typography variant="subtitle1" color="text.secondary">
                        {format(
                          new Date(date),
                          dateFormats.short_date_dayOfWeek,
                        )}{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle1" color="text.secondary">
                        -{' '}
                        {transformMinsToHoursMinutesString(
                          task.taskReg[date][0].regDuration,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      <Typography variant="subtitle1" color="text.secondary">
        {text}
      </Typography>
    </React.Fragment>
  );
};

export { ConfirmationDialogContent, ConfirmationDialogContentTitle };
