import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../../../../utilities/dateUtils';

export const dialogContentText =
  'This will notify your manager/approver to approve this task.';
export const deleteContentText =
  'This will be removed from your submitted time registrations and will not be part of the manager/approver approval.';
export const submitType = 'SUBMIT';
export const deleteType = 'DELETE';

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}));

export const getProjectTableContentTitleConfig = (type, props) => {
  const projectTableContentTitleMeta = {
    submitAllReg: `Would you like to submit all registrations for "
    ${props?.proj?.projectName}" from
    ${format(props?.dateList?.[0], dateFormats.short_date)} to
    ${format(props?.dateList?.[6], dateFormats.short_date)}?`,
    cantSubmitAllReg: `Oops, registrations for "${props.proj.projectName}" from
    ${format(props.dateList[6], dateFormats.short_date)} cannot be
    submitted`,
    deleteAllReg: `Would you like to delete all registrations in "
    ${props.proj.projectName}" from ${format(
      props.dateList[0],
      dateFormats.short_date,
    )} to ${format(props.dateList[6], dateFormats.short_date)}?`,
    cantDeleteAllReg: ` Oops, registrations for "${props.proj.projectName}" from
    ${format(props.dateList[0], dateFormats.short_date)} to
    ${format(props.dateList[6], dateFormats.short_date)} cannot be
    deleted`,
  };
  return projectTableContentTitleMeta[type];
};
