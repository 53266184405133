export function util() {
  const isNullOrUndefined = (value) => {
    return (
      value === undefined || value === null || value.length <= 0 || value === ''
    );
  };

  return { isNullOrUndefined };
}

export function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;
  return items.slice(0).sort((a, b) => {
    const lowerCompare =
      String(a[key]).toLowerCase() > String(b[key]).toLowerCase();
    return (isSortedDescending ? String(a[key]) < String(b[key]) : lowerCompare)
      ? 1
      : -1;
  });
}
