import React, { useState, useRef } from 'react';
import classes from './CancelledRequest.module.scss';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Avatar from '@mui/material/Avatar';
import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import {
  DialogActionsType,
  LeaveStatusType,
} from '../../../../../utilities/constantUtils';
import { sendCancelledLeaveRequestService } from '../../service/leaveService';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CancelledRequest = (props) => {
  const { t } = useTranslation();
  const { leaveList } = props;
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: <></>,
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    SubmitLabelText: '',
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const successSnackbarRef = useRef(null);
  const [severity, setSeverity] = useState('info');
  const [isLoading, SetLoading] = useState(false);

  const DataGridContainer = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-toolbarContainer': {
      borderBottom: '1px solid #e9e9e9',
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-row:hover svg': {
      visibility: 'visible !important',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:nth-of-type(1)': {
      position: 'relative',
    },
  }));

  const columns = [
    {
      field: 'action',
      headerName: t('we-leave.action'),
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <Grid>
              <div className="buttonNotUpperCase">
                <Button onClick={() => approveRequestHandler(item)}>
                  <div className={`${classes.flex} ${classes.buttonColor} `}>
                    <div class>
                      <EventBusyIcon />
                    </div>
                    <div>
                      <Typography className="padL5">Cancel Leave</Typography>
                    </div>
                  </div>
                </Button>
              </div>
            </Grid>
          </React.Fragment>
        );
      },
    },
    {
      field: 'ownerName',
      headerName: t('we-leave.member'),
      width: 200,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <Avatar
              className={classes.member_avatar}
              alt={item.row.ownerName}
              src="./broken-image.jpg"
            />
            <div>{item.row.ownerName}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'requestedDate',
      headerName: t('we-leave.requested-date'),
      width: 200,
    },
    {
      field: 'leaveTypeName',
      headerName: t('we-leave.leave-type'),
      width: 210,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>{item.row.leaveTypeName}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'reason',
      headerName: t('we-leave.reason'),
      width: 200,
    },
    {
      field: 'teamsList',
      headerName: t('we-leave.team'),
      width: 250,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.wrapDiv}>{item.row.teamsList}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'submittedDate',
      headerName: t('we-leave.cancellation-request-date'),
      width: 200,
    },
  ];

  const approveRequestHandler = (item) => {
    setSelectedItem(item);
    var value = {
      title: t(
        'we-leave.would-you-like-to-approve-the-cancellation-of-this-leave',
      ),
      description: t(
        'we-leave.this-will-cancel-and-remove-the-employees-leave',
      ),
      type: t('we-leave.cancel-leave'),
      actionType: DialogActionsType.error,
      leaveType: t('we-leave.cancel-leave'),
    };
    dialogBox(value);
  };

  const approveAllRequest = () => {
    var value = {
      title: t(
        'we-leave.would-you-like-to-approve-the-cancellation-of-all-leaves',
      ),
      description: t(
        'we-leave.this-will-cancel-and-remove-the-employees-leaves',
      ),
      type: t('we-leave.cancel-all-leaves'),
      actionType: DialogActionsType.error,
      leaveType: t('we-leave.cancel-all-leaves'),
    };
    dialogBox(value);
  };

  const dialogBox = (value) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: t('common.close'),
      submitLabelText: value.type,
      actionType: value.actionType,
      leaveType: value.leaveType,
    }));
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const confirmSubmissionHandler = async () => {
    SetLoading(true);
    setSeverity('success');
    var response = '';
    switch (confirmationBoxInfo.leaveType) {
      case t('we-leave.decline-cancellation'):
        response = await sendCancelledLeaveRequestService(
          selectedItem.row,
          false,
        );
        responseMessage(t('we-leave.leave-is-not-cancelled'), response);
        break;
      case t('we-leave.cancel-leave'):
        response = await sendCancelledLeaveRequestService(
          selectedItem.row,
          true,
        );
        responseMessage(t('we-leave.leave-is-now-cancelled'), response);
        break;
      case t('we-leave.decline-all-cancellations'):
        response = await processReqestALL(
          false,
          t('we-leave.leaves-are-not-cancelled'),
        );
        handleCloseDialogBox();
        showSuccessSnackBar(response);
        props.getCancelledLeaves(LeaveStatusType.cancel, null, null);
        break;
      case t('we-leave.cancel-all-leaves'):
        response = await processReqestALL(
          true,
          t('we-leave.leaves-are-now-cancelled'),
        );
        handleCloseDialogBox();
        showSuccessSnackBar(response);
        props.getCancelledLeaves(LeaveStatusType.cancel, null, null);
        break;
      default:
    }
    SetLoading(false);
  };

  const processReqestALL = async (type, message) => {
    var row = 0;
    var erroRow = 0;
    await Promise.all(
      leaveList.map(async (f) => {
        var response = await sendCancelledLeaveRequestService(f, type);
        if (response.errorMessage === '') {
          row = row + 1;
        } else {
          erroRow = erroRow + 1;
        }
      }),
    );
    var result = message;
    return result;
  };

  const responseMessage = (message, response) => {
    handleCloseDialogBox();
    if (response.errorMessage === '') {
      handleCloseDialogBox();
      showSuccessSnackBar(message);
      props.getCancelledLeaves(LeaveStatusType.cancel, null, null);
    } else {
      setSeverity('error');
      // showSuccessSnackBar('Error while processing request.');
      showSuccessSnackBar(response.errorMessage);
    }
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const PendingReqActions = () => {
    return (
      <GridToolbarContainer>
        <Grid container className={classes.request_action_container}>
          <Grid>
            <Button onClick={approveAllRequest}>
              <div className={`${classes.flex} ${classes.buttonColor}`}>
                <div class>
                  <EventBusyIcon />
                </div>
                <div className={`padL5 padT4 padR10`}>
                  <Typography className={classes.cancelALL}>
                    {t('we-leave.cancel-all')}
                  </Typography>
                </div>
              </div>
            </Button>
          </Grid>
          <GridToolbarFilterButton />
        </Grid>
      </GridToolbarContainer>
    );
  };

  return (
    <div className={classes.filter_tab} style={{ height: 400, width: '100%' }}>
      <div className={classes.container}>
        <DataGridContainer
          autoHeight={true}
          columns={columns}
          rows={leaveList}
          components={{
            Toolbar: PendingReqActions,
          }}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 100]}
          sx={{
            '.MuiTablePagination-root': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          localeText={{
            toolbarFilters: t('we-leave.advance-filters'),
          }}
        />
        {props.loader && (
          <div
            className={
              classes.box + ' ' + classes.stackTop + ' ' + classes.box2
            }
          >
            <div className={classes.center}>
              <CircularProgress />
              <Typography>{t('we-leave.getting-cancelled-leaves')}</Typography>
            </div>
          </div>
        )}
      </div>

      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={confirmSubmissionHandler}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isLoading={isLoading}
      />
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </div>
  );
};

export default CancelledRequest;
