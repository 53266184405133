import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormButtonLoading from '../../../ui/formcontrols/formButtonLoading/FormButtonLoading';
import ContentLayout from '../../../ui/layout/contentlayout/ContentLayout';
import TextBox from '../../../ui/formcontrols/textBox/TextBox';
import ErrorAlert from '../../../ui/alerts/errorAlert/ErrorAlert';
import classes from './ResetPassword.module.scss';
import SuccessAlert from '../../../ui/alerts/successAlert/SuccessAlert';
import paths from '../../../../routing/Constants';
import {
  validateTokenRequestService,
  forgetPasswordRequestService,
  resetPasswordRequestService,
} from '../../service/loginService';
import { RegEx } from '../../../../utilities/constantUtils';
import loginbl from '../../../../assets/login-bl-arc.svg';
import logintr from '../../../../assets/login-tr-arc.svg';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n/i18n';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const [isRequestValid, setIsRequestValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    validateRequest();
  }, []);

  const validateRequest = async () => {
    let token = searchParams.get('token');
    let requestId = atob(searchParams.get('request'));
    if (!token) navigate(`/${paths.LOGIN}`);

    var response = await validateTokenRequestService(token, requestId);
    if (response.status !== 200) setIsRequestValid(false);
    else {
      setIsRequestValid(true);
    }
  };

  const sendRecoveryHandler = async () => {
    setIsLoading(true);
    setIsError(false);
    let email = atob(searchParams.get('code'));
    var response = await forgetPasswordRequestService(email);
    if (response.status !== 200) setIsError(true);
    else setIsSuccess(true);
    setIsLoading(false);
  };

  const resetPasswordHandler = async () => {
    if (!passwordError && password.length) {
      let token = searchParams.get('token');
      let requestId = atob(searchParams.get('request'));
      setIsLoading(true);
      setIsError(false);
      setIsError(false);
      var response = await resetPasswordRequestService(
        token,
        requestId,
        password,
      );
      if (response.status !== 200) {
        setIsError(true);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
          navigate(`/${i18n.language}/${paths.LOGIN}`);
        }, 2000);
      }
    }
  };

  const onChangeHandler = (e) => {
    if (e != null) {
      const { value } = e.target;
      setPassword(value);
    }
  };

  const enterKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      checkValid(e);
    }
  };

  const onBlurHandler = (e) => {
    checkValid(e);
  };

  const checkValid = (e) => {
    if (e != null) {
      const { value } = e.target;
      if (value.length)
        setPasswordError(RegEx.passWordRegEx.test(value) ? false : true);
    }
  };

  return (
    <div className={classes.bgWhite}>
      <ContentLayout>
        <Grid item>
          <img src={logintr} alt="meewee logo" className={classes.logintr} />
        </Grid>
        {!isRequestValid ? (
          <Grid
            container
            direction="column"
            spacing={5}
            className={classes.container}
          >
            <Grid item className={classes.header}>
              <Typography variant="h4" className={classes.headerLabel}>
                ${t('login.sorry-but-this-link-has-already-expired')}
              </Typography>
            </Grid>
            <Grid item className={classes.subHeader}>
              <Typography variant="h6" className={classes.subLabel}>
                {t('login.would-you-like-to-re-send-a-new-recovery-link')}
              </Typography>
            </Grid>
            <Grid item className={classes.btnPad}>
              <div className={classes.button}>
                <FormButtonLoading
                  id="continue-button1"
                  label={t('login.send-recovery-link')}
                  loadingIndicator="Send recovery link"
                  loading={isLoading}
                  onClick={sendRecoveryHandler}
                  fullWidth={true}
                  loadingPosition="start"
                  color="primary"
                />
              </div>
              {isSuccess && (
                <div className={classes.alert}>
                  <SuccessAlert
                    id="fp-success"
                    message={t('login.recovery-link-send-to-your-email')}
                  />
                </div>
              )}
              {isError && (
                <div className={classes.alert}>
                  <ErrorAlert
                    id="sign-up-error"
                    message={t('login.we-couldnt-find-the-email')}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            spacing={5}
            className={classes.container}
          >
            <Grid item>
              <Typography variant="h4" className={classes.headerLabel}>
                {t('login.account-recovery')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.subLabel}>
                {t('login.please-enter-a-new-password')}:
              </Typography>
            </Grid>
            <Grid item>
              <TextBox
                isError={passwordError}
                id="password"
                label={t('login.enter-password')}
                type="text"
                onChange={(value) => onChangeHandler(value)}
                errorText={t(
                  'login.password-should-be-at-least-8-characters-with-a-mixed-of-alphanumeric-and-symbols',
                )}
                ariaLabel="Password"
                onBlur={(e) => onBlurHandler(e)}
                onKeyPress={(e) => enterKeyPressHandler(e)}
              />
              <div className={classes.button}>
                <FormButtonLoading
                  id="continue-button"
                  label={t('login.update-password')}
                  loadingIndicator="Update password"
                  loading={isLoading}
                  onClick={resetPasswordHandler}
                  fullWidth={true}
                  loadingPosition="start"
                  color="primary"
                />
              </div>
              {isError && (
                <div className={classes.alert}>
                  <ErrorAlert
                    id="sign-up-error"
                    message={t('login.error-processing-request')}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item>
          <div className={classes.loginbl}>
            <img
              src={loginbl}
              alt="meewee logo"
              className={classes.imgLogintr}
            />
          </div>
        </Grid>
      </ContentLayout>
    </div>
  );
};
export default ResetPassword;
