import React from 'react';
import LeaveHistoryFilter from './LeaveHistoryFilter';

const LeaveHistory = ({ onClickRequestLeave, reloadData }) => {
  return (
    <>
      <div>
        <LeaveHistoryFilter
          onClickRequestLeave={onClickRequestLeave}
          reloadData={reloadData}
        />
      </div>
    </>
  );
};

export default LeaveHistory;
