import React, { useState, useEffect, useRef } from 'react';
import classes from './PrivacyAccountTab.module.scss';
import { Typography, TextField, Button } from '@mui/material';
import PasswordTextBox from '../../../ui/formcontrols/passwordTextBox/PasswordTextBox';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  postValidateOldPassword,
  postChangePassword,
} from '../../service/profile';
import SuccessSnackbar from '../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import PasswordValidation from './PasswordValidation';
import { useTranslation } from 'react-i18next';

const userCredentials = {
  oldPassword: '',
  newPassword: '',
};

const PrivacyAccountTab = (props) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(userCredentials);
  const [formErrors, setFormErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const snackbarRef = useRef(null);
  const [severity, setSeverity] = useState('success');
  const [validateOldPassword, setValidateOldPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showValidationList, setShowValidationList] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasAlphabet: false,
    hasNumber: false,
    hasSpecialCharacter: false,
  });

  const [oldPasswordTyped, setOldPasswordTyped] = useState(false);

  const handleChange = (e, fields) => {
    const { value } = e.target;
    setFormValues({ ...formValues, [fields]: value });
    if (fields === 'oldPassword') {
      setOldPasswordTyped(!!value);
    }
    if (fields === 'newPassword') {
      validateNewPassword(value);
    }
  };

  const validateNewPassword = (password) => {
    const validations = {
      minLength: password.length >= 8,
      hasAlphabet: /[a-zA-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
        password,
      ),
    };
    setPasswordValidations(validations);
  };

  useEffect(() => {
    if (formValues) {
      setFormErrors(validateForm(formValues, validateOldPassword));
    }
  }, [formValues]);

  const validateForm = (value, currentPassword) => {
    setDisableSubmit(true && !IsNewPasswordValid);
    const errors = {};
    const errorMessage1 = t(
      'privacy-account.please-nominate-a-password-different-from-the-current',
    );

    if (value.oldPassword && value.newPassword) {
      if (value.oldPassword === value.newPassword) {
        errors.newPassword = errorMessage1;
      }
    }

    if (value.oldPassword.length && value.newPassword && !currentPassword) {
      if (!Object.keys(errors).length) {
        setDisableSubmit(false);
      }
    }

    return errors;
  };

  const IsNewPasswordValid = Object.values(passwordValidations).every(
    (value) => value === true,
  );

  const enterKeyPressHandler = (event, fieldName) => {
    if (event.key === 'Enter') {
      handleChange(event, fieldName);
    }
  };

  const onBlurHandler = (event, fieldName) => {
    handleChange(event, fieldName);
  };
  useEffect(() => {
    setFormSubmitted(false);
  }, [formValues]);

  const onSubmitHandler = async () => {
    setFormSubmitted(true);
    onUpdatePasswordHandler();
  };

  const onPasswordBlurHandler = () => {
    validatePassword();
  };

  const validatePassword = async () => {
    setValidateOldPassword(false);
    var response = await postValidateOldPassword(formValues);
    if (response.status !== 200) {
      setValidateOldPassword(true);
      validateForm(formValues, true);
    } else {
      setValidateOldPassword(false);
      validateForm(formValues, false);
    }
  };

  const onUpdatePasswordHandler = async () => {
    setDisableSubmit(true);
    var response = await postChangePassword(formValues);

    if (response.status !== 200) {
      if (formValues.oldPassword === formValues.newPassword) {
        showSnackBar(
          t(
            'privacy-account.please-nominate-a-password-different-from-the-current',
          ),
        );
      } else {
        showSnackBar(t('privacy-account.unable-to-update-password'));
      }
      setSeverity('error');
      setDisableSubmit(false);
    } else {
      setSeverity('success');
      showSnackBar(t('privacy-account.password-successfully-changed'));
      setDisableSubmit(true);
    }
  };

  const showSnackBar = (message) => {
    snackbarRef.current.snackBarContentHandler(message);
  };

  const onKeyPressHandler = () => {
    setShowValidationList(true);
  };

  const onKeyDownHandler = (e) => {
    if (e.key === 'Backspace' && e.target.value.length === 1) {
      setShowValidationList(false);
    }
  };
  const passwordValidationConfig = [
    {
      passwordValidationType: passwordValidations.minLength,
      textPasswordValidations: t(
        'privacy-account.be-at-least-8-characters-long',
      ),
    },
    {
      passwordValidationType: passwordValidations.hasAlphabet,
      textPasswordValidations: t('privacy-account.have-at-least-one-alphabet'),
    },
    {
      passwordValidationType: passwordValidations.hasNumber,
      textPasswordValidations: t('privacy-account.have-at-least-one-number'),
    },
    {
      passwordValidationType: passwordValidations.hasSpecialCharacter,
      textPasswordValidations: t(
        'privacy-account.have-at-least-one-special-character',
      ),
    },
  ];

  const hasErrorOnNewPassword =
    Object.values(passwordValidations).includes(false) && showValidationList;

  return (
    <div className={classes.card}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.flexContainer}>
            <div className={classes.margin}>
              <VpnKeyIcon fontSize="medium" />
            </div>
            <div>
              <Typography variant="h6" className={classes.lblWeigth}>
                {t('privacy-account.privacy-account')}
              </Typography>
            </div>
          </div>
          <Typography variant="subtitle2" color="text.secondary">
            {t('privacy-account.manage-your-privacy-and-account-in-meewee')}
          </Typography>
        </div>
        <div className={classes.body}>
          <div>
            <Typography
              variant="body1"
              color="text.primary"
              className={classes.lblWeigth}
            >
              {t('privacy-account.account')}
            </Typography>
          </div>
          <div className="padT15">
            <TextField
              disabled
              id="standard-disabled"
              label={t('privacy-account.email')}
              value={props.user.userEmail}
              variant="standard"
              className={classes.textBox}
            />
          </div>
          <div className="padT15">
            <TextField
              disabled
              id="standard-disabled"
              label={t('privacy-account.username')}
              value={props.user?.username}
              variant="standard"
              className={classes.textBox}
            />
          </div>
        </div>
        <div className={classes.bodyPassword}>
          <div>
            <Typography
              variant="body1"
              color="text.primary"
              className={classes.lblWeigth}
            >
              {t('privacy-account.change-password')}
            </Typography>
          </div>
          <div
            className={`${classes.password} ${classes.oldPasswordTextbox} padT15 `}
          >
            <PasswordTextBox
              id="oldPassword"
              label={t('privacy-account.type-old-password')}
              type="password"
              onChange={(e) => handleChange(e, 'oldPassword')}
              onBlur={onPasswordBlurHandler}
              isError={validateOldPassword}
              errorText={t('privacy-account.your-old-password-is-incorrect')}
            />
          </div>
          <div className={classes.password}>
            <PasswordTextBox
              id="newPassword"
              label={t('privacy-account.type-new-password')}
              type="password"
              onChange={(e) => handleChange(e, 'newPassword')}
              onBlur={(e) => onBlurHandler(e, 'newPassword')}
              onKeyPress={(e) => {
                enterKeyPressHandler(e, 'newPassword');
                onKeyPressHandler();
              }}
              onKeyDown={onKeyDownHandler}
              errorText={formErrors?.newPassword}
              isError={hasErrorOnNewPassword}
              validated={
                passwordValidations.minLength &&
                passwordValidations.hasAlphabet &&
                passwordValidations.hasNumber &&
                passwordValidations.hasSpecialCharacter
              }
              disabled={!oldPasswordTyped}
            />
            {hasErrorOnNewPassword && (
              <div className={classes.validationList}>
                <div className={classes.headValidation}>
                  {t('privacy-account.password-must')}
                </div>
                <ul>
                  {passwordValidationConfig.map((configItem, index) => (
                    <PasswordValidation
                      key={index}
                      passwordValidationType={configItem.passwordValidationType}
                      textPasswordValidations={
                        configItem.textPasswordValidations
                      }
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className={classes.updatePasswordBtn}>
            <Button
              variant="contained"
              onClick={onSubmitHandler}
              disabled={!IsNewPasswordValid || disableSubmit}
            >
              {t('privacy-account.update-password')}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={snackbarRef} severity={severity} />
      </div>
    </div>
  );
};

export default PrivacyAccountTab;
