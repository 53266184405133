export const transformHoursMinsStringToMins = (timeString) => {
  const [hours, minutes] = timeString.split(' ');

  const totalHours = parseFloat(hours) * 60 + parseFloat(minutes);

  return Number(totalHours);
}; // convert '1h 30m' to 90

export const transformMinsToHoursMinutesString = (
  minutes,
  numberOnly,
  isShortFormat,
) => {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = Math.floor(minutes % 60);
  const formattedMinutes = remainingMinutes
    ? String(remainingMinutes).padStart(2, '0')
    : '00';
  const rawNumber = hours
    ? `${hours}${formattedMinutes}`
    : `${formattedMinutes}`;

  const shortHours = hours !== 0 ? `${hours}h` : '';
  const shortMinutes = formattedMinutes !== '00' ? `${formattedMinutes}m` : '';
  const spacer = hours !== 0 && formattedMinutes !== '00' ? ' ' : '';
  const shortTimeFormat = `${shortHours}${spacer}${shortMinutes}`;

  const longTimeFormat = `${hours}h ${formattedMinutes}m`;
  const timeFormat = isShortFormat ? shortTimeFormat : longTimeFormat;

  return numberOnly ? `${rawNumber}` : timeFormat;
}; // convert 90 to '1h 30m' || 130

export const convertStringHoursMinstoDisplayValue = (text) => {
  const numberString = text;
  if (numberString.length >= 1 && numberString.length <= 4) {
    const hours = text.slice(0, -2).padStart(1, '0');
    const minutes = text.slice(-2).padStart(2, '0');

    return `${hours}h ${minutes}m`;
  } else {
    return '0h 00m';
  }
}; //convert 130 to 1h 30m

export const transformRawTimeToMinutes = (rawTime) => {
  const nonLeadingZeroRawTime = parseInt(rawTime).toString();
  const hoursInMinutes = nonLeadingZeroRawTime.slice(-4, -2) * 60;
  const minutes = parseInt(
    nonLeadingZeroRawTime.substring(nonLeadingZeroRawTime.length - 2),
    10,
  );
  return hoursInMinutes + minutes;
}; //convert 130 to 90
