import httpRequestHandler from '../../../utilities/httpRequestHandler';
import {
  createTransitData,
  getTransitDataCollection,
} from '../../../utilities/transitJsUtils';
import {
  setLeaveTypes,
  setLeaveHistoryRegistrations,
  setCollisionList,
  setLeaveTotalLength,
  setLeaveHistoryListById,
  setLeaveHistoryList,
  setLeaveHistoryListCount,
} from '../../../store/leaveContextSlice';

import store from '../../../store/store';
import moment from 'moment';

export const getLeaves = (year) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getLeaveType, {
        year: year,
      });

      if (response.result === 1) {
        let leaveTypeList = [];
        let mapLeavesToObject = {
          leaveTypeid: 'id',
          entitlement: 'entitlement',
          label: 'label',
          approved: 'approved',
          pending: 'pending',
          remaining: 'remaining',
          type: 'type',
        };

        response.data.forEach((leaveTypeData) => {
          let mappedData = getTransitDataCollection(
            leaveTypeData,
            mapLeavesToObject,
          );

          leaveTypeList.push(mappedData);
        });

        response.data = leaveTypeList;

        dispatch(setLeaveTypes({ leaveTypes: response.data }));
      } else {
        dispatch(setLeaveTypes({ leaveTypes: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getLeaveHistoryReg = (leaveHistoryRequestParams) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getLeaveRegistrationsHistory, {
        from: leaveHistoryRequestParams.from,
        to: leaveHistoryRequestParams.to,
        'reg-type': leaveHistoryRequestParams.type,
        status: leaveHistoryRequestParams.status,
      });
      if (response.result === 1) {
        let leaveHistoryList = [];
        let mapLeavesHistoryToObject = {
          id: 'db/id',
          owner: 'registration/owner',
          desc: 'registration/description',
          status: 'registration/status',
          start: 'registration/start',
          end: 'registration/end',
          type: 'type',
          typeName: 'registration/type',
          halfDay: 'registration/day-length',
          createdAt: 'meta/created-at',
          createdBy: 'meta/created-by',
          updatedAt: 'meta/updated-at',
          updatedBy: 'meta/updated-by',
          quantity: 'registration/quantity',
          forCancellation: 'for-cancellation',
          vacation: 'leave-registration-empty',
          calculate: 'calculate',
        };
        let mappedCreatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedUpdatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedTypeObj = {
          id: 'db/id',
          type: 'registration-type/name',
        };

        response.data.rep.forEach((leaveHistoryData) => {
          let mappedData = getTransitDataCollection(
            leaveHistoryData,
            mapLeavesHistoryToObject,
          );

          mappedData.createdBy = getTransitDataCollection(
            mappedData.createdBy,
            mappedCreatedByObj,
          );

          mappedData.typeName = getTransitDataCollection(
            mappedData.typeName,
            mappedTypeObj,
          );
          mappedData.calculate =
            mappedData.calculate?._entries !== undefined
              ? getLengthLeave(mappedData.calculate?._entries[1])
              : null;
          if (mappedData.updatedBy) {
            mappedData.updatedBy = getTransitDataCollection(
              mappedData.updatedBy,
              mappedUpdatedByObj,
            );
          }

          leaveHistoryList.push(mappedData);
        });

        dispatch(
          setLeaveHistoryRegistrations({
            leaveRegistrationsHistory: leaveHistoryList,
          }),
        );
      } else {
        dispatch(
          setLeaveHistoryRegistrations({ leaveRegistrationsHistory: [] }),
        );
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getLeaveHistoryRegAll = (leaveHistoryRequestParams) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getLeaveRegistrationsHistory, {
        from: leaveHistoryRequestParams.from,
        to: leaveHistoryRequestParams.to,
        'reg-type': leaveHistoryRequestParams.type,
        status: leaveHistoryRequestParams.status,
      });
      if (response.result === 1) {
        let leaveHistoryList = [];
        let mapLeavesHistoryToObject = {
          id: 'db/id',
          owner: 'registration/owner',
          desc: 'registration/description',
          status: 'registration/status',
          start: 'registration/start',
          end: 'registration/end',
          type: 'type',
          typeName: 'registration/type',
          halfDay: 'registration/day-length',
          createdAt: 'meta/created-at',
          createdBy: 'meta/created-by',
          updatedAt: 'meta/updated-at',
          updatedBy: 'meta/updated-by',
          quantity: 'registration/quantity',
          forCancellation: 'for-cancellation',
          vacation: 'leave-registration-empty',
        };

        let mappedCreatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedUpdatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedTypeObj = {
          id: 'db/id',
          type: 'registration-type/name',
        };

        response.data.rep.forEach((leaveHistoryData) => {
          let mappedData = getTransitDataCollection(
            leaveHistoryData,
            mapLeavesHistoryToObject,
          );

          mappedData.createdBy = getTransitDataCollection(
            mappedData.createdBy,
            mappedCreatedByObj,
          );

          mappedData.typeName = getTransitDataCollection(
            mappedData.typeName,
            mappedTypeObj,
          );

          if (mappedData.updatedBy) {
            mappedData.updatedBy = getTransitDataCollection(
              mappedData.updatedBy,
              mappedUpdatedByObj,
            );
          }

          leaveHistoryList.push(mappedData);
        });
        dispatch(
          setLeaveHistoryList({
            leaveHistoryListAll: leaveHistoryList,
          }),
        );
      } else {
        dispatch(
          setLeaveHistoryList({
            leaveHistoleaveHistoryListAllryList: [],
          }),
        );
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getLeaveHistoryAllCount = (leaveHistoryRequestParams) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getLeaveRegistrationsHistory, {
        from: leaveHistoryRequestParams.from,
        to: leaveHistoryRequestParams.to,
        'reg-type': leaveHistoryRequestParams.type,
        status: leaveHistoryRequestParams.status,
      });
      if (response.result === 1) {
        let leaveHistoryList = [];
        let mapLeavesHistoryToObject = {
          id: 'db/id',
          owner: 'registration/owner',
          desc: 'registration/description',
          status: 'registration/status',
          start: 'registration/start',
          end: 'registration/end',
          type: 'type',
          typeName: 'registration/type',
          halfDay: 'registration/day-length',
          createdAt: 'meta/created-at',
          createdBy: 'meta/created-by',
          updatedAt: 'meta/updated-at',
          updatedBy: 'meta/updated-by',
          quantity: 'registration/quantity',
          forCancellation: 'for-cancellation',
          vacation: 'leave-registration-empty',
        };
        let mappedCreatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedUpdatedByObj = {
          id: 'id',
          name: 'name',
        };

        let mappedTypeObj = {
          id: 'db/id',
          type: 'registration-type/name',
        };

        response.data.rep.forEach((leaveHistoryData) => {
          let mappedData = getTransitDataCollection(
            leaveHistoryData,
            mapLeavesHistoryToObject,
          );

          mappedData.createdBy = getTransitDataCollection(
            mappedData.createdBy,
            mappedCreatedByObj,
          );

          mappedData.typeName = getTransitDataCollection(
            mappedData.typeName,
            mappedTypeObj,
          );

          if (mappedData.updatedBy) {
            mappedData.updatedBy = getTransitDataCollection(
              mappedData.updatedBy,
              mappedUpdatedByObj,
            );
          }

          leaveHistoryList.push(mappedData);
        });

        dispatch(
          setLeaveHistoryListCount({
            leaveHistoryListAllCount: leaveHistoryList,
          }),
        );
      } else {
        dispatch(
          setLeaveHistoryListCount({
            leaveHistoryListAllCount: [],
          }),
        );
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const sendLeaveRequestService = async (leaveRequestParams) => {
  try {
    const userContext = store.getState().userContext;

    let requestParamObj = {
      'registration/owner': { value: userContext.userId, isKeyword: false },
      'registration/description': {
        value: leaveRequestParams.desc,
        isKeyword: false,
      },
      'registration/type': { value: leaveRequestParams.type, isKeyword: false },
      'registration/status': {
        value: 'registration.status/' + leaveRequestParams.status,
        isKeyword: true,
      },
      'registration/start': {
        value: new Date(Date.parse(leaveRequestParams.start)),
        isKeyword: false,
      },
      'registration/end': {
        value: new Date(Date.parse(leaveRequestParams.end)),
        isKeyword: false,
      },
    };

    const isHalday = {
      'registration/day-length': {
        value: leaveRequestParams.halfDay ? 'day-length/half' : null,
        isKeyword: true,
      },
    };

    const requestParamObjIsHalfday = leaveRequestParams.halfDay
      ? Object.assign(requestParamObj, isHalday)
      : requestParamObj;

    let mapObjToTransit = createTransitData(requestParamObjIsHalfday);
    let registrationsData = await httpRequestHandler(
      sendLeaveRequestInfo,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const getLeaveCollision = (leaveCollisionRequestParams) => {
  return async (dispatch) => {
    try {
      const userContext = store.getState().userContext;
      let requestParamObj = {
        'registration/owner': { value: userContext.userId, isKeyword: false },
        'registration/description': {
          value: leaveCollisionRequestParams.description,
          isKeyword: false,
        },
        'registration/type': {
          value: leaveCollisionRequestParams.type,
          isKeyword: false,
        },
        'registration/status': {
          value: 'registration.status/' + leaveCollisionRequestParams.status,
          isKeyword: true,
        },
        'registration/start': {
          value: new Date(
            moment(Date.parse(leaveCollisionRequestParams.start)).format(
              'YYYY-MM-DD',
            ),
          ),
          isKeyword: false,
        },
        'registration/end': {
          value: new Date(
            moment(Date.parse(leaveCollisionRequestParams.end)).format(
              'YYYY-MM-DD',
            ),
          ),
          isKeyword: false,
        },
      };
      let mapObjToTransit = createTransitData(requestParamObj);
      let registrationsData = await httpRequestHandler(
        requestLeaveValidation,
        mapObjToTransit,
      );
      if (registrationsData.result === 1) {
        let leaveCollisionList = [];
        let leaveCollisionListData = [];
        let mapLeaveCollisionToObject = {
          span: 'span',
          length: 'length',
          collisions: 'collisions',
        };

        let mapCollisionToObject = {
          description: 'description',
          start: 'start',
          end: 'registration/end',
          type: 'type',
        };

        registrationsData.data.forEach((leaveCollisionData) => {
          let mappedData = getTransitDataCollection(
            leaveCollisionData,
            mapLeaveCollisionToObject,
          );
          leaveCollisionList.push(mappedData);
        });

        leaveCollisionList.forEach((items) => {
          items.collisions.forEach((item) => {
            let mappedData = getTransitDataCollection(
              item,
              mapCollisionToObject,
            );
            leaveCollisionListData.push(mappedData);
          });
        });

        dispatch(
          setCollisionList({
            collisionList: leaveCollisionListData,
          }),
        );
        dispatch(
          setLeaveTotalLength({
            leaveTotalLength: leaveCollisionList,
          }),
        );
      } else {
        dispatch(setCollisionList({ collisionList: [] }));
        dispatch(setLeaveTotalLength({ leaveTotalLength: [] }));
      }

      return registrationsData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getLeaveHistoryById = (id) => {
  return async (dispatch) => {
    try {
      const requestParamObj = {
        ':id': { value: id, isKeyword: false },
      };

      let mapObjToTransit = createTransitData(requestParamObj);
      let response = await httpRequestHandler(
        getLeaveRegistrationsHistory,
        mapObjToTransit,
      );
      if (response.result === 1) {
        let leaveHistoryListById = [];
        let mapLeavesHistoryToObject = {
          id: 'db/id',
          owner: 'registration/owner',
          desc: 'registration/description',
          status: 'registration/status',
          start: 'registration/start',
          end: 'registration/end',
          type: 'registration/type',
          halfDay: 'registration/day-length',
          createdBy: 'meta/created-by',
          createdAt: 'meta/created-at',
          updatedAt: 'meta/updated-at',
          updatedBy: 'meta/updated-by',
          forCancellation: 'for-cancellation',
          vacation: 'leave-registration-empty',
        };
        response.data.forEach((leaveHistoryData) => {
          let mappedData = getTransitDataCollection(
            leaveHistoryData,
            mapLeavesHistoryToObject,
          );
          leaveHistoryListById.push(mappedData);
        });
        dispatch(
          setLeaveHistoryListById({
            leaveHistoryListById: leaveHistoryListById,
          }),
        );
      } else {
        dispatch(setLeaveHistoryListById({ leaveHistoryListById: [] }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const updateLeaveRequestService = async (value, status) => {
  try {
    let requestParamObj = {
      'db/id': { value: value.id, isKeyword: false },
      'registration/status': {
        value: 'registration.status/' + status,
        isKeyword: true,
      },
    };

    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestLeaves,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const deleteLeaveRequestService = async (id) => {
  try {
    let requestParamObj = {
      'db/id': { value: id, isKeyword: false },
    };

    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      deleteRequestLeaves,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const editLeaveRequestService = async (leaveRequestParams) => {
  try {
    const userContext = store.getState().userContext;

    let requestParamObj = {
      'db/id': { value: leaveRequestParams.id, isKeyword: false },
      'registration/owner': { value: userContext.userId, isKeyword: false },

      'registration/description': {
        value: leaveRequestParams.desc,
        isKeyword: false,
      },
      'registration/type': { value: leaveRequestParams.type, isKeyword: false },
      'registration/status': {
        value: 'registration.status/' + leaveRequestParams.status,
        isKeyword: true,
      },
      'registration/start': {
        value: new Date(Date.parse(leaveRequestParams.start)),
        isKeyword: false,
      },
      'registration/end': {
        value: new Date(Date.parse(leaveRequestParams.end)),
        isKeyword: false,
      },
      'registration/day-length': {
        value: leaveRequestParams.halfDay
          ? 'day-length/half'
          : 'day-length/full',
        isKeyword: true,
      },
    };
    // const isHalday = {
    //   'registration/day-length': {
    //     value: leaveRequestParams.halfDay ? 'day-length/half' : null,
    //     isKeyword: true,
    //   },
    // };

    // const requestParamObjIsHalfday = leaveRequestParams.halfDay
    //   ? Object.assign(requestParamObj, isHalday)
    //   : requestParamObj;

    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      editLeaveRequestInfo,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const cancelLeaveRequestService = async (id, isCancel) => {
  try {
    let requestParamObj = {
      id: { value: id, isKeyword: false },
      'for-cancellation': { value: isCancel, isKeyword: false },
    };

    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestLeaves,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

const getLeaveRegistrationsHistory = {
  url: 'me/leaves',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const getLeaveType = {
  url: 'leave-types',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],

  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const sendLeaveRequestInfo = {
  url: 'me/registrations',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const requestLeaveValidation = {
  url: 'leaves/calculate',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const putRequestLeaves = {
  url: 'me/leaves',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const deleteRequestLeaves = {
  url: 'me/leaves',
  method: 'DELETE',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const editLeaveRequestInfo = {
  url: 'me/registrations',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getLengthLeave = (value) => {
  if (value !== undefined) {
    let mapToObject = {
      length: 'length',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);

    return mappedData;
  }
  return null;
};
