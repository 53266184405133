import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import classes from './Tooltips.module.scss';

const CustomTooltip = ({ title, placement, fontSize, color, child }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: color ?? theme.palette.text.primary,
      boxShadow: '0px 3px 14px -3px rgba(0, 0, 0, 0.78)',
      textAlign: 'center',
      lineHeight: '20px',
      textTransform: 'capitalize',
    },
  }));

  return (
    <>
      <LightTooltip title={title} placement={placement}>
        {child}
      </LightTooltip>
    </>
  );
};

export default CustomTooltip;
