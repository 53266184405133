import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

const SwitchUI = () => {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Switch</Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Default
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="default" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Primary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Switch checked />} label="Label" />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Secondary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="secondary" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Error
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="error" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Warning
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="warning" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Info
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="info" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Success
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Switch checked color="success" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Disabled
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel disabled control={<Switch />} label="label" />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Off
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Switch />} label="label" />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default SwitchUI;
