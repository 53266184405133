import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormHelperText from "@mui/material/FormHelperText";
import { useState } from "react";

const TextFieldUI = () => {
  const [values, setValues] = useState({
    password: "value",

    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">No Icon</Typography>
            <Typography variant="subtitle2">Default*</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Value</Typography>
            <TextField
              id="standard-basic"
              label="Label"
              variant="standard"
              helperText="Helper Text/Hint"
              defaultValue="value"
            />
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">No Value</Typography>
            <TextField
              id="standard-basic"
              label="Label"
              variant="standard"
              helperText="Helper Text/Hint"
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">End Icon</Typography>
            <Typography variant="subtitle2">Default*</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Value</Typography>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                Helper Text/Hint
              </FormHelperText>
            </FormControl>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">No Value</Typography>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                Helper Text/Hint
              </FormHelperText>
            </FormControl>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">Start Icon</Typography>
            <Typography variant="subtitle2">Default*</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Value</Typography>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                Helper Text/Hint
              </FormHelperText>
            </FormControl>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">No Value</Typography>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                Helper Text/Hint
              </FormHelperText>
            </FormControl>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">Start Icon</Typography>
            <Typography variant="subtitle2">Default*</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Value</Typography>
            <TextField
              id="filled-multiline-static"
              label="Label"
              multiline
              rows={4}
              defaultValue="Lorem Ipsum is simply dummy text of the printing "
              variant="standard"
              helperText="Helper Text/Hint"
            />
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">No Value</Typography>
            <TextField
              id="filled-multiline-static"
              label="Label"
              multiline
              rows={4}
              variant="standard"
              helperText="Helper Text/Hint"
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default TextFieldUI;
