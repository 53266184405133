import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import classes from "./InfoAlert.module.scss";
const InfoAlert = (props) => {
  const [open, setOpen] = useState(true);

  const onClickHandler = () => {
    setOpen(false);
  };

  return (
    <Collapse in={open}>
      <div id={props.id} className={classes.info_alert_container}>
        <Alert
          variant="filled"
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={onClickHandler}>
              {props.actionButton}
            </Button>
          }
        >
          {props.message}
        </Alert>
      </div>
    </Collapse>
  );
};

export default InfoAlert;
