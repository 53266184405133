import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import UITypography from "./Typography";
import ColorPalette from "./ColorPalette";
import DateTime from "./DateTime";
import Fabutton from "./Fabutton";
import CheckBoxUI from "./CheckBoxUI";
import RadioButtonUI from "./RadioButtonUI";
import SwitchUI from "./SwitchUI";
import SelectUI from "./SelectUI";
import TabsUI from "./TabsUI";
import AlertUI from "./AlertUI";
import DialogUI from "./DialogUI";
import ProgressUI from "./ProgressUI";
import TableUI from "./TableUI";
import BadgeUI from "./BadgeUI";
import AvatarUI from "./AvatarUI";
import TooltipUI from "./TooltipUI";
import TextFieldUI from "./TextFieldUI";
import ButtonUI from "./ButtonUI";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const UiKit = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Typography" {...a11yProps(0)} />
          <Tab label="Color Palette" {...a11yProps(1)} />
          <Tab label="Date/Time" {...a11yProps(2)} />
          <Tab label="FAB" {...a11yProps(3)} />
          <Tab label="Checkbox" {...a11yProps(4)} />
          <Tab label="Radio" {...a11yProps(5)} />
          <Tab label="Button" {...a11yProps(6)} />
          <Tab label="Switch" {...a11yProps(7)} />
          <Tab label="Text Field" {...a11yProps(8)} />
          <Tab label="Select" {...a11yProps(9)} />
          <Tab label="Tabs" {...a11yProps(10)} />
          <Tab label="Alert" {...a11yProps(11)} />
          <Tab label="Dialog" {...a11yProps(12)} />
          <Tab label="Progress" {...a11yProps(13)} />
          <Tab label="Table" {...a11yProps(14)} />
          <Tab label="Badge" {...a11yProps(15)} />
          <Tab label="Avatar" {...a11yProps(16)} />
          <Tab label="Tooltip" {...a11yProps(17)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UITypography />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ColorPalette />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DateTime />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Fabutton />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CheckBoxUI />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <RadioButtonUI />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ButtonUI />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <SwitchUI />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <TextFieldUI />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <SelectUI />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <TabsUI />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <AlertUI />
      </TabPanel>
      <TabPanel value={value} index={12}>
        <DialogUI />
      </TabPanel>
      <TabPanel value={value} index={13}>
        <ProgressUI />
      </TabPanel>
      <TabPanel value={value} index={14}>
        <TableUI />
      </TabPanel>
      <TabPanel value={value} index={15}>
        <BadgeUI />
      </TabPanel>
      <TabPanel value={value} index={16}>
        <AvatarUI />
      </TabPanel>
      <TabPanel value={value} index={17}>
        <TooltipUI />
      </TabPanel>
    </Box>
  );
};

export default UiKit;
