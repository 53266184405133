import { setAuthToken, setCredentials } from "../../../store/authContextSlice";
import httpRequestHandler from "../../../utilities/httpRequestHandler";
import {
  getTransitData,
} from "../../../utilities/transitJsUtils";

import {
  createTransitData,
} from '../../../utilities/transitJsUtils'

import store from "../../../store/store";
import { saveStateToLocalStorage } from "../../../utilities/localStorageUtlis";

export const signInUser = (authObj) => {
  return async (dispatch) => {
    try {
      const authenticationData = await httpRequestHandler(
        getAuthenticationInfo,
        authObj
      );

      if (authenticationData.result === 1) {
        dispatch(setCredentials({ username: authObj.username }));
        dispatch(
          setAuthToken({
            authToken: authenticationData.data,
            loginType: "DIRECT",
          })
        );

        const authContext = store.getState()["authContext"];
        saveStateToLocalStorage("authContext", authContext);
      }

      return authenticationData;
    } catch (error) {
      throw new Error("Error on Service Request");
    }
  };
};

export const signInViaLinkedin = (authObj) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(
        getSignInViaLinkedinInfo,
        authObj
      );

      if (response.result === 1) {
        dispatch(
          setAuthToken({
            authToken: getTransitData(response.data, "meewee-session"),
            loginType: "LINKEDIN",
          })
        );

        const authContext = store.getState()["authContext"];
        saveStateToLocalStorage("authContext", authContext);
      }

      return response;
    } catch (error) {
      throw new Error("Error on Service Request");
    }
  };
};

export const forgetPasswordRequestService = async (value) => {
  try {
    let requestParamObj = {
      'email': { value: value, isKeyword: false }
    }
    let mapObjToTransit = createTransitData(requestParamObj)
    let registrationsData = await httpRequestHandler(
      postRequest("send-recovery"),
      mapObjToTransit,
    )
    return registrationsData
  } catch (error) {
    return { errorMessage: 'Error on Service Request' }
  }
}

export const validateTokenRequestService = async (token, requestId) => {
  try {
    let requestParamObj = {
      'field': { value: "link", isKeyword: false },
      'token': { value: token, isKeyword: false },
      'id': { value: requestId, isKeyword: false }
    }
    let mapObjToTransit = createTransitData(requestParamObj)
    let registrationsData = await httpRequestHandler(
      postRequest("reset-password/validate"),
      mapObjToTransit,
    )
    return registrationsData
  } catch (error) {
    return { errorMessage: 'Error on Service Request' }
  }
}

export const resetPasswordRequestService = async (token, requestId, newPassword) => {
  try {
    let requestParamObj = {
      'field': { value: "link", isKeyword: false },
      'token': { value: token, isKeyword: false },
      'id': { value: requestId, isKeyword: false },
      'password': { value: newPassword, isKeyword: false }
    }
    let mapObjToTransit = createTransitData(requestParamObj)
    let registrationsData = await httpRequestHandler(
      postRequest("reset-password"),
      mapObjToTransit,
    )
    return registrationsData
  } catch (error) {
    return { errorMessage: 'Error on Service Request' }
  }
}





//REQUEST CONFIGURATION
const getAuthenticationInfo = {
  url: "login",
  method: "POST",
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: "json",
};

const postRequest = (url) => {
  var request = {
    url: url,
    method: 'POST',
    transformResponse: [
      function (data) {
        return data
      },
    ],
    hasAuth: true,
    contentTypeHeader: 'application/transit+json',
    requestParamType: 'transit-json',
  }
  return request
}

const getSignInViaLinkedinInfo = {
  url: "signin/linkedin",
  method: "POST",
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: "json",
};


