import { format } from 'date-fns';
import { datePickerLanguage } from '../i18n/i18nUtil';

export const dateFormats = {
  default: 'yyyy-MM-dd',
  default_v2: 'dd-MM-yyyy',
  default_v2_slashes: 'dd/MM/yyyy',
  day: 'dd',
  month: 'MMM',
  year: 'yyyy',
  day_of_week: 'EEEEE',
  month_day: 'd',
  long_date: 'MMMM dd, yyyy EEEE',
  long_date_V2: 'MMMM dd, yyyy',
  week: 'w',
  short_date: 'MMMM d',
  short_date_dayOfWeek: 'MMMM dd, EEE',
  time: 'HH:mm:ss',
  date_slashes: 'MM/dd/yyyy',
  datetime: 'dd/MM/yyyy hh:mm',
  yyyymmdd: 'yyyy-MM-dd',
  date_slashes_dayOfWeek: 'MM/dd/yyyy, EEE',
  date_slashes_ddmmyyyy: 'dd/MM/yyyy',
  date_slashes_ddmmyyyy_EEE: 'dd/MM/yyyy, EEE',
  datepicker: 'MM/dd/yyyy',
  moment_datepicker: 'DD/MM/yyyy',
  ddMMyyyy: 'MM/dd/yyyy',
  MMddyyyy: 'MM/dd/yyyy',
  date_short_date_dayOfWeek: 'DD/MM/YYYY, ddd',
  dateTimeHH: 'DD/MM/YYYY HH:mm',
  day_of_week_half: 'EEEEE',
  mmddyyyyhhmmssampm: 'MM/DD/YYYY, hh:mm:ss A',
  short_date_picker: 'MMMM dd',
  moment_MMddyyyy: 'yyyy-MM-DD',

  mmdd: 'MM/DD',
};

export const getArrayDays = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const sortByDate = (array) => {
  let sortedArray = array.sort((a, b) => new Date(a) - new Date(b));

  return sortedArray;
};

export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;

  const diffInTime = date2.getTime() - date1.getTime();

  const diffInDays = Math.round(diffInTime / oneDay) + 1;

  return diffInDays;
};

export const getBusinessDays = (start, end) => {
  // input given as Date objects
  var iWeeks,
    iDateDiff,
    iAdjust = 0;
  if (end < start) return -1; // error code if dates transposed
  var iWeekday1 = new Date(start).getDay(); // day of week
  var iWeekday2 = new Date(end).getDay();
  iWeekday1 = iWeekday1 === 0 ? 7 : iWeekday1; // change Sunday from 0 to 7
  iWeekday2 = iWeekday2 === 0 ? 7 : iWeekday2;
  if (iWeekday1 > 5 && iWeekday2 > 5) iAdjust = 1; // adjustment if both days on weekend
  iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1; // only count weekdays
  iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2;

  // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
  iWeeks = Math.floor(
    (new Date(end).getTime() - new Date(start).getTime()) / 604800000,
  );

  if (iWeekday1 <= iWeekday2) {
    iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1);
  } else {
    iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2);
  }

  iDateDiff -= iAdjust; // take into account both days on weekend

  return iDateDiff + 1; // add 1 because dates are inclusive
};

export const isDateGreaterThan = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  if (date1 > date2) {
    return true;
  }
};

export const isDateLessThan = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  if (date1 < date2) {
    return true;
  }
};

export const isEqualToCurrentDate = (date) => {
  const date1 = format(new Date(), dateFormats.default);
  const date2 = format(new Date(date), dateFormats.default);

  if (date1 === date2) {
    return true;
  }
};

export const handleDateFormatRangeYear = (startDate, endDate) => {
  if (!startDate) {
    return;
  }

  endDate = !endDate ? startDate : endDate;

  const sameDate = dateCompare(startDate, endDate);
  const startDateFormat = new Date(startDate);
  const endDateFormat = new Date(endDate);

  const month = format(startDateFormat, 'MMM', {
    locale: datePickerLanguage(),
  });

  const endMonth = format(endDateFormat, 'MMM', {
    locale: datePickerLanguage(),
  });

  const sameMonth = month === endMonth;

  const startDay = startDateFormat.getDate();
  const endDay = endDateFormat.getDate();
  const startYear = startDateFormat.getFullYear();
  const startDayName = format(startDateFormat, 'eee', {
    locale: datePickerLanguage(),
  });
  const endDayName = format(endDateFormat, 'eee', {
    locale: datePickerLanguage(),
  });

  const date = `${month} ${startDay}, ${startYear} (${startDayName})`;

  const dateRange = `${month} ${startDay}${
    endDate === undefined ? '' : `${sameMonth ? '' : `-${endMonth}`}-${endDay}`
  }, ${startYear} (${startDayName}${
    endDate === undefined ? '' : `-${endDayName}`
  })`;

  return sameDate ? date : dateRange;
};

export const handleDateFormatRangeMonth = (startDate, endDate) => {
  if (!startDate) {
    return;
  }
  endDate = !endDate ? startDate : endDate;

  try {
    const sameDate = dateCompare(startDate, endDate);

    const startDateFormat = new Date(startDate);
    const endDateFormat = new Date(endDate);

    const startMonth = format(startDateFormat, 'MMM', {
      locale: datePickerLanguage(),
    });

    const startDay = startDateFormat.getDate();
    let endMonth = '';
    let endDay = '';
    if (endDate) {
      endMonth = format(endDateFormat, 'MMM', {
        locale: datePickerLanguage(),
      });
      endDay = endDateFormat.getDate();
    }

    const date = `${startMonth} ${startDay}`;

    const dateRange = `${startMonth} ${startDay}${
      endDate === undefined
        ? ''
        : `-${startMonth === endMonth ? '' : endMonth} ${endDay}`
    }`;

    return sameDate ? date : dateRange;
  } catch {
    return '';
  }
};

export const handleDateFormatFullYearRange = (startDate, endDate) => {
  if (!startDate) {
    return;
  }
  endDate = !endDate ? startDate : endDate;

  const start = startDate && new Date(startDate);
  const end = endDate && new Date(endDate);
  const sameDate = dateCompare(startDate, endDate);

  const endDateMeta = {
    endDateFormat: end && format(end, dateFormats.date_slashes_ddmmyyyy),
    endDayName: end && format(end, 'eee', { locale: datePickerLanguage() }),
  };

  const { endDateFormat, endDayName } = endDateMeta;
  const startDateFormat = format(start, dateFormats.date_slashes_ddmmyyyy);

  const startDayName = format(start, 'eee', { locale: datePickerLanguage() });

  const date = format(
    new Date(startDate),
    dateFormats.date_slashes_ddmmyyyy_EEE,
    { locale: datePickerLanguage() },
  );
  const dateRange = `${startDateFormat} - ${endDateFormat}, ${startDayName}-${endDayName}`;

  return sameDate || !endDate ? date : dateRange;
};

export const dateCompare = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  if (date1 > date2 || date1 < date2) {
    return false;
  } else {
    return true;
  }
};
