import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: [],
  activeMemberList: [],
  pendingRequestsList: [],
  pendingInvitationsList: [],
  ownPendingInvitations: [],
  usersList: [],
  projectList: [],
  myProjectList: [],
  myTeams: [],
  projectListAll: [],
  organization: null,
  membersPerTeam: [],
  allTeams: [],
};

const orgContextSlice = createSlice({
  name: 'orgContext',
  initialState: initialState,
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload.teamList.filter((team) => team.isActive);
    },
    setMyTeams: (state, action) => {
      state.myTeams = action.payload.myTeamList;
    },
    setActiveMembers: (state, action) => {
      state.activeMemberList = action.payload.activeMemberList;
    },
    setMembersPerTeam: (state, action) => {
      state.membersPerTeam = action.payload;
    },
    setPendingRequests: (state, action) => {
      state.pendingRequestsList = action.payload.pendingRequestsList;
    },
    setPendingInvitations: (state, action) => {
      const { pendingInvitationsList, userId } = action.payload;
      state.ownPendingInvitations = getOwnPendingInvitations(
        pendingInvitationsList,
        userId,
      );
      state.pendingInvitationsList = pendingInvitationsList;
    },
    removePendingRequest: (state, action) => {
      let requestIndex = state.pendingRequestsList.findIndex(
        (request) => request.requestId === action.payload.requestId,
      );
      state.pendingRequestsList.splice(requestIndex, 1);
    },
    removeTeam: (state, action) => {
      let teamIndex = state.teams.findIndex(
        (team) => team.teamId === action.payload.teamId,
      );
      state.teams.splice(teamIndex, 1);
    },
    removeActiveMember: (state, action) => {
      let memberIndex = state.activeMemberList.findIndex(
        (member) => member.memberId === action.payload.id,
      );

      state.activeMemberList.splice(memberIndex, 1);
    },
    removeActiveMemberTeam: (state, action) => {
      let memberIndex = state.activeMemberList.findIndex(
        (member) => member.memberId === action.payload.id,
      );

      let copyMemberInfo = Object.assign(
        {},
        state.activeMemberList[memberIndex],
      );

      let newTeamList = copyMemberInfo.memberTeamEmployment.filter(
        (team) => team.teamId !== action.payload.team,
      );

      copyMemberInfo = { ...copyMemberInfo, memberTeamEmployment: newTeamList };

      state.activeMemberList[memberIndex] = copyMemberInfo;
    },
    removePendingTeamInvitation: (state, action) => {
      let inviteIndex = state.pendingInvitationsList.findIndex(
        (invite) => invite.invitationId === action.payload.inviteId,
      );
      state.pendingInvitationsList.splice(inviteIndex, 1);
    },
    updatePendingTeamInvitation: (state, action) => {
      const { invitationId, userId } = action.payload;
      const updatedPendingInvitationsList = state.pendingInvitationsList.filter(
        (invitation) => invitation.invitationId !== invitationId,
      );
      state.ownPendingInvitations = getOwnPendingInvitations(
        updatedPendingInvitationsList,
        userId,
      );
      state.pendingInvitationsList = updatedPendingInvitationsList;
    },
    setUsersApprover: (state, action) => {
      state.usersList = action.payload.usersList;
    },
    setProjectList: (state, action) => {
      state.projectList = action.payload.projectList;
    },
    setProjectListALL: (state, action) => {
      state.projectListAll = action.payload.projectListAll;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload.organization;
    },
    setMyProjectList: (state, action) => {
      state.myProjectList = action.payload;
    },
  },
});

export const {
  setTeams,
  setMyTeams,
  setActiveMembers,
  setPendingRequests,
  setPendingInvitations,
  removePendingRequest,
  removeTeam,
  removeActiveMember,
  removeActiveMemberTeam,
  removePendingTeamInvitation,
  updatePendingTeamInvitation,
  setUsersApprover,
  setProjectList,
  setProjectListALL,
  setOrganization,
  setMembersPerTeam,
  setMyProjectList,
} = orgContextSlice.actions;

export default orgContextSlice.reducer;

function getOwnPendingInvitations(pendingInvitationsList, userId) {
  return pendingInvitationsList.filter(
    (invitation) => invitation.invitationUser.userId === userId,
  );
}
