import { dashboardMenuFeatureOn } from '../utilities/featureToggleUtils';

/* FEATURE TOGGLE CODE CLEANUP - remove function */
const homePathString = () => {
  return dashboardMenuFeatureOn ? 'dashboard' : 'me/timesheet';
  return dashboardMenuFeatureOn ? 'dashboard' : 'me/leave';
};

const paths = {
  ROOT: '/',
  LOGIN: 'login',
  SIGNUP: 'signup',
  LINKEDIN: 'linkedin',
  FORGETPASSWORD: 'forget-password',
  RESETPASSWORD: 'reset-pw',
  HOME: homePathString(),
  UIKIT: 'uikit',
  ME: 'me',
  TIMESHEET: 'timesheet',
  LEAVE: 'leave',
  WE: 'we',
  ORGANIZATION: 'organization',
  ORGANIZATION_PATHS: {
    VIEW_TEAM_INVITATION: 'team-invitation',
    TEAMS_AND_PROJECT: 'my-teams-and-project',
    TEAMMATES: 'my-teammates',
    INFORMATION: 'information',
    INFORMATION_PATHS: {
      VIEW_INFORMATION: 'view',
      EDIT_INFORMATION: 'edit',
    },
    MANAGE_INFORMATION: 'manage-information',
    ALL_MEMBERS: 'members',
    ALL_PROJECTS: 'projects',
    ALL_PROJECTS_PATHS: {
      LIST: 'list',
      MANAGE: 'manage',
    },
    ALL_TEAMS: 'teams',
    ALL_TEAMS_PATHS: {
      LIST: 'list',
      MANAGE: 'manage',
    },
    MY_INFORMATION: 'my-information',
    MY_PROJECTS: 'my-projects',
    MY_PROJECTS_PATHS: {
      LIST: 'list',
      MANAGE: 'manage',
    },
  },
  WELECOME_TEAM: 'welcome',
  ACCEPT_TEAM: 'accept-invitation',
  LEAVE_MANAGEMENT: 'leave-management',
  LEAVE_REQUEST: 'leave-request',
  Profile: 'profile',
  TEAM_TIMESHEET: 'team-timesheet',
};

export default paths;
