import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const TextBox = (props) => {
  const [value, setValue] = useState('');
  const inputRef = useRef();
  const onChangeHandler = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const onBlurHandler = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onKeyPressHandler = (event) => {
    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };

  useEffect(() => {
    if (props.autofocus) {
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  let isError = props.isError ? props.isError : false;
  let isRequired = props.required ? props.isRequired : false;
  let helpertext = isError ? props.errorText : ' ';
  let isDisabled = props.disabled ? props.disabled : false;
  return (
    <Box>
      <TextField
        error={isError}
        required={isRequired}
        id={props.id}
        label={props.label}
        type={props.type}
        variant="standard"
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        fullWidth={true}
        helperText={helpertext}
        inputProps={{ 'aria-label': props.ariaLabel }}
        value={props.value ? props.value : value}
        disabled={isDisabled}
        onKeyDown={onKeyPressHandler}
        inputRef={inputRef}
      />
    </Box>
  );
};

export default TextBox;
