import React, { useEffect, useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import classes from './OrgProjectList.module.scss';
import { Button, Box, Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getMeProjectList,
  deleteProject,
} from '../../../../service/orgmanagementService';
import MeProjectList from './list/MeProjectList';
import { styled } from '@mui/material/styles';
import {
  projectStatusName,
  DialogActionsType,
} from '../../../../../../utilities/constantUtils';
import ConfirmationDialogBox from '../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import SuccessSnackbar from '../../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TabStyled = styled(Tabs)(({ theme }) => ({
  '& .Mui-selected': {
    color: '#00B8D4 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00B8D4 !important',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="orgtabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.org_tab_panel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const OrgProjectList = () => {
  const { t } = useTranslation();
  const { selectedOrg, cbOnCreateHandler, cbOnManageMeProjectHandler } =
    useOutletContext();
  const dispatch = useDispatch();
  const myProjectList = useSelector((state) => state.orgContext.myProjectList);
  const [list, setList] = useState([]);
  const [listInActive, setListInActive] = useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: <></>,
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    SubmitLabelText: '',
  });
  const [isLoading, SetLoading] = useState(false);
  const successSnackbarRef = useRef(null);
  const [severity, setSeverity] = useState('success');

  useEffect(() => {
    load_data();
  }, [selectedOrg]);

  useEffect(() => {
    setList(
      myProjectList
        .filter(
          (proj) =>
            proj.status !== projectStatusName.cancelled &&
            proj.status !== projectStatusName.paused &&
            proj.status !== projectStatusName.draft,
        )
        .filter((proj) => proj.ownerDetails.id === selectedOrg?.id),
    );
    setListInActive(
      myProjectList
        .filter(
          (proj) =>
            proj.status === projectStatusName.cancelled ||
            proj.status === projectStatusName.paused ||
            proj.status === projectStatusName.draft,
        )
        .filter((proj) => proj.ownerDetails.id === selectedOrg?.id),
    );
  }, [myProjectList]);

  const load_data = async () => {
    selectedOrg?.emp &&
      (await dispatch(getMeProjectList(selectedOrg?.emp, true)));
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [selected, setSelectedValue] = useState(null);

  const onDeleteHandler = (item) => {
    setSelectedValue(item);
    var value = {
      title: `${t('organization.would-you-like-to-delete')} “${item.name}” ${t(
        'organization.project-permanently',
      )}`,
      actionType: DialogActionsType.error,
      type: t('common.delete').toUpperCase(),
      loaderText: t('organization.deleting'),
    };
    if (activeTab === 0) {
      value.description = t(
        'organization.the-projects-deletion-will-be-effective-immediately-and-you-will-not-be-able-to-see-nor-register-time',
      );
    } else {
      value.description = t(
        'organization.once-deleted-you-will-no-longer-be-able-to-update-the-information-of-this-project',
      );
    }
    dialogBox(value, t('common.cancel'));
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
      loaderText: value.loaderText,
    }));
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const confirmSubmissionHandler = async () => {
    SetLoading(true);
    var response = await deleteProject(selected);
    if (response.status === 200) {
      setSeverity('success');
      showSuccessSnackBar(t('organization.project-deleted'));
      handleCloseDialogBox();
      load_data();
    } else {
      setSeverity('warning');
      showSuccessSnackBar(t('organization.error-while-processing-request'));
    }
    SetLoading(false);
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };
  const onManageMeProjectHandler = (item) => {
    cbOnManageMeProjectHandler(item);
  };
  return (
    <>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={confirmSubmissionHandler}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isLoading={isLoading}
        loaderText={confirmationBoxInfo.loaderText}
      />
      <div container className={classes.invite_card}>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.name}>
              {t('organization.my-personal-projects')}
            </Typography>
            <Typography className={classes.desc}>
              {t('organization.view-and-manage-your-personal-projects')}
            </Typography>
          </div>
          <div className={classes.invite_button}>
            <div className="padR10">
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={cbOnCreateHandler}
                startIcon={<AddIcon />}
              >
                {t('organization.create-project')}
              </Button>
            </div>
          </div>
        </div>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabStyled
              aria-label="basic tabs example"
              value={activeTab}
              className={classes.approveLeave_tab_name}
              onChange={handleChange}
              columnseparator="false"
            >
              <Tab
                label={
                  <div>
                    <span className="capitalize">{t('organization.on')}-</span>
                    <span className="tolowercase">
                      {t('organization.going')}
                    </span>
                  </div>
                }
                {...a11yProps(1)}
              ></Tab>
              <Tab
                label={
                  <div className="capitalize">{t('organization.inactive')}</div>
                }
                {...a11yProps(1)}
              ></Tab>
            </TabStyled>
          </Box>

          <TabPanel value={activeTab} index={0} className="leaveTable">
            {' '}
            <MeProjectList
              list={list}
              tab={true}
              onDelete={onDeleteHandler}
              onManageMeProject={onManageMeProjectHandler}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={1} className="leaveTable">
            {' '}
            <MeProjectList
              list={listInActive}
              tab={false}
              onDelete={onDeleteHandler}
              onManageMeProject={onManageMeProjectHandler}
            />
          </TabPanel>
        </Box>
      </div>
    </>
  );
};
