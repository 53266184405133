import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoAlert from '../../ui/alerts/infoAlert/InfoAlert';
import DashboardLayout from '../../ui/layout/dashboardlayout/DashboardLayout';
import classes from './Home.module.scss';
import paths from '../../../routing/Constants';
import { getUserProfile } from '../service/homeService';
import { removeAllItemsFromLocalStorage } from '../../../utilities/localStorageUtlis';
import { reset } from '../../../store/authContextSlice';
import i18n from '../../../i18n/i18n';
//paths

const Home = () => {
  const loginPath = `/${i18n.language}/${paths.LOGIN}`;
  const homePath = `/${i18n.language}/${paths.HOME}`;
  document.title = 'Dashboard';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isNewUser, setIsNewUser] = useState(null);
  const [userInvitationInfo, setUserInvitationInfo] = useState({
    isInvited: false,
    teamName: null,
  });
  const sessionToken = useSelector((state) => state.authContext.authToken);

  useEffect(() => {
    sessionToken ? loadUserProfile() : logout();
  }, [sessionToken]);

  useEffect(() => {
    if (state) {
      if (state.isNewUser !== undefined) {
        setIsNewUser(state.isNewUser);
        navigate(homePath, { replace: true, state: null });
      }

      if (state.teamName !== undefined) {
        setUserInvitationInfo({ isInvited: true, teamName: state.teamName });
        navigate(homePath, { replace: true, state: null });
      }
    }
  }, []);

  const loadUserProfile = async () => {
    var response = await dispatch(getUserProfile());
    if (response.status) {
      if (response.status !== 200) {
        logout();
      }
    } else {
      logout();
    }
  };

  const logout = () => {
    dispatch(reset());
    removeAllItemsFromLocalStorage();
    navigate(loginPath);
  };

  return (
    <DashboardLayout>
      {!isNewUser && isNewUser !== null && (
        <div className={classes.info_alert}>
          <InfoAlert
            id="new-user-info-alert"
            message="You already have an existing account so we logged you in instead."
            actionButton="Got it"
          />
        </div>
      )}
      {userInvitationInfo.isInvited && userInvitationInfo.teamName !== null && (
        <div className={classes.info_alert}>
          <InfoAlert
            id="new-user-info-alert"
            message={`Welcome to Team ${userInvitationInfo.teamName}`}
            actionButton="Got it"
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default Home;
