import React, { useState } from 'react';
import classes from './ProjectList.module.scss';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import {
  Grid,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import moment from 'moment';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const ProjectList = (props) => {
  const { t } = useTranslation();
  const { list } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openViewMore, setOpenViewMore] = React.useState(false);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('organization.projects'),
      width: 250,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.pad}>{item?.row.name}</div>
            {/* Hide for the meantime -- TASK #21577 */}
            {/* <div className={classes.task_menu_button_container}>
              <IconButton
                className={classes.task_menu_button}
                onClick={(e) => handleClick(e, item.row)}
              >
                <MoreVertIcon
                  className={`${!open && classes.task_menu_button}`}
                  aria-label="reg menu"
                  aria-controls={open ? 'reg-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                />
              </IconButton>
            </div> */}
          </React.Fragment>
        );
      },
    },
    {
      field: 'tasks',
      headerName: t('organization.tasks'),
      width: 150,
      renderCell: (item) => {
        return (
          <React.Fragment>
            {item?.row.task.length} {t('organization.on-going')}
          </React.Fragment>
        );
      },
    },
    {
      field: 'duration',
      headerName: t('organization.duration'),
      width: 120,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.pad}>
              {item?.row?.start
                ? moment(item?.row?.start).format(dateFormats.moment_datepicker)
                : ''}{' '}
              {item?.row?.end ? ' to ' : ''}
              {item?.row?.end
                ? moment(item?.row?.end).format(dateFormats.moment_datepicker)
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'approver',
      headerName: t('organization.approver'),
      width: 200,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {item?.row?.approver
                ? item?.row?.approver.name
                  ? item?.row?.approver?.name !== undefined
                    ? item?.row?.approver?.name
                    : ''
                  : ''
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const ProjectActions = () => {
    return (
      <GridToolbarContainer>
        <Grid container className={classes.request_action_container}>
          <div className={classes.padT8}>
            <GridToolbarFilterButton />
          </div>
        </Grid>
      </GridToolbarContainer>
    );
  };

  const handleClose = () => {
    setOpenViewMore(false);
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      {/* Hide for the meantime -- TASK #21577 */}
      {/* <Menu
        className="menuReg"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem className={classes.menu_cell_icon}>
          <EditIcon className={classes.menu_icon} />
          Manage project
        </MenuItem>

        <MenuItem>
          <DeleteForeverIcon className={classes.menu_icon} />
          Delete
        </MenuItem>
      </Menu> */}
      <div
        className={classes.filter_tab}
        style={{ height: 400, width: '100%' }}
      >
        <DataGridContainer
          autoHeight={true}
          columns={columns}
          rows={list}
          getRowHeight={() => 'auto'}
          components={{
            Toolbar: ProjectActions,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 100]}
          sx={{
            '.MuiTablePagination-root': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          localeText={{
            toolbarFilters: t('organization.filters'),
          }}
        />

        {props.loader && (
          <div
            className={
              classes.box + ' ' + classes.stackTop + ' ' + classes.box2
            }
          >
            <div className={classes.center}>
              <CircularProgress />
              <Typography>
                {' '}
                {t('organization.gettting-project-list')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
