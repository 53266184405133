import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useState } from "react";
const CheckBoxUI = () => {
  const [state, setState] = useState({
    value1: true,
    value2: false,
    value3: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { value1, value2, value3 } = state;
  const error = [value1, value2, value3].filter((v) => v).length !== 2;
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Wtih Label</Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Primary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Secondary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="secondary" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Error
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="error" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Warning
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="warning" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Info
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="info" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Success
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="success" />}
              label="Label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Disabled
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox disabled checked />}
              label="Label"
            />
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h6">Wtih FormGroup</Typography>
      <Box sx={{ display: "flex" }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Group Label</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value1}
                  onChange={handleChange}
                  name="value1"
                />
              }
              label="Value 1"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={value2}
                  onChange={handleChange}
                  name="value2"
                />
              }
              label="Value 2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={value3}
                  onChange={handleChange}
                  name="value3"
                />
              }
              label="Value 3"
            />
          </FormGroup>
          <FormHelperText>Helper Text</FormHelperText>
        </FormControl>
        <FormControl
          required
          error={error}
          component="fieldset"
          sx={{ m: 3 }}
          variant="standard"
        >
          <FormLabel component="legend">Pick two</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value1}
                  onChange={handleChange}
                  name="value1"
                />
              }
              label="Value 1"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={value2}
                  onChange={handleChange}
                  name="value2"
                />
              }
              label="Value 2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={value3}
                  onChange={handleChange}
                  name="value3"
                />
              }
              label="Value 3"
            />
          </FormGroup>
          <FormHelperText>You can display an error</FormHelperText>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default CheckBoxUI;
