import { TextField, Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import classes from './TimeInput.module.scss';
import {
  transformMinsToHoursMinutesString,
  convertStringHoursMinstoDisplayValue,
  transformRawTimeToMinutes,
} from '../../../../../../../../../utilities/timeConversion';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '::selection': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

const TimeInput = ({
  inputLabelProps,
  regPlaceHolder,
  minutesValue,
  setMinutesValue,
  onBlur,
  onKeyDown,
  isDisabled,
  isAutoFocus,
  withLabel = false,
}) => {
  const defaultHoursAndMinsValue =
    transformMinsToHoursMinutesString(minutesValue);
  const [displayValue, setDisplayValue] = useState(
    regPlaceHolder
      ? transformMinsToHoursMinutesString(regPlaceHolder)
      : defaultHoursAndMinsValue,
  ); // displays -h --m format
  const [isFocused, setIsFocused] = useState(true);
  const [rawTime, setRawTime] = useState(
    transformMinsToHoursMinutesString(minutesValue, true),
  );

  const baseRef = useRef(null);

  useEffect(() => {
    if (isAutoFocus) {
      const timeout = setTimeout(() => {
        baseRef?.current?.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  const handleInputChange = (event) => {
    var pattern = /[^0-9]/g;
    const rawTimeText = event?.target?.value?.replace(pattern, '');
    let inputTime = '';
    if (rawTimeText?.length === 3 && rawTimeText?.[0] === '0') {
      inputTime = rawTimeText.substring(1);
    } else {
      inputTime = rawTimeText;
    }
    if (
      (inputTime.length <= 4 || inputTime === undefined) &&
      inputTime !== '00'
    ) {
      setRawTime(inputTime);
      const minutesValue = transformRawTimeToMinutes(inputTime);
      setMinutesValue(minutesValue);
      const formattedDisplayValue =
        convertStringHoursMinstoDisplayValue(inputTime);
      setDisplayValue(formattedDisplayValue);
    }
  };

  if (!withLabel) {
    return (
      <div className={classes.time_wrapper_noLabel}>
        <div className={classes.time_display_noLabel}>
          <Typography className={classes.timeText}>{displayValue}</Typography>
        </div>
        <ThemeProvider theme={theme}>
          <TextField
            className={classes.time_input_noLabel}
            type="standard"
            value={rawTime}
            onChange={(e) => handleInputChange(e)}
            inputProps={{ style: { fontSize: 12 } }}
            InputLabelProps={inputLabelProps}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            inputRef={baseRef}
            disabled={isDisabled}
            focused
          />
        </ThemeProvider>
      </div>
    );
  } else {
    const enterHoursDefaultStyle = isFocused
      ? classes.enter_hours_focused
      : classes.enter_hours_unfocused;

    const timeDisplayDefaultStyle = isFocused
      ? classes.time_display_focused
      : classes.time_display_unfocused;

    return (
      <div className={classes.withLabel_wrapper}>
        <p
          className={
            isDisabled
              ? classes.enter_hours_disabled_withLabel
              : enterHoursDefaultStyle
          }
        >
          Enter hours
        </p>
        <div className={classes.time_wrapper}>
          <div
            className={
              isDisabled
                ? classes.time_display_disabled
                : timeDisplayDefaultStyle
            }
          >
            <Typography>{displayValue}</Typography>
          </div>
          {!isDisabled ? (
            <TextField
              className={classes.time_input}
              variant="standard"
              value={rawTime}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={inputLabelProps}
              onBlur={(e) => {
                onBlur(e);
                setIsFocused(false);
              }}
              onFocus={() => setIsFocused(true)}
              onKeyDown={onKeyDown}
              inputRef={baseRef}
              disabled={isDisabled}
              focused
            />
          ) : (
            <div className={classes.spacer} />
          )}
        </div>
      </div>
    );
  }
};

export default TimeInput;
