import React from "react";
import Alert from "@mui/material/Alert";
import classes from "./ErrorAlert.module.scss";

const ErrorAlert = (props) => {
  return (
    <div id={props.id} className={classes.error_alert}>
      <Alert
        variant="outlined"
        severity="error"
        sx={{ color: "error.main", borderColor: "error.main" }}
      >
        {props.message}
      </Alert>
    </div>
  );
};

export default ErrorAlert;
