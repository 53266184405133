import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  timeset: null,
};

const buildProjectTaskArrayToProjectArrayOfTransitObjectTask = (
  projectList,
) => {
  const ArrayProjectList = Object.assign([], projectList);
  const refinedArrayProjectList = ArrayProjectList.map((proj) => {
    let newProj = Object.assign({}, proj);
    let newProjTasks = Object.assign([], newProj.projectTasks);

    newProjTasks = newProjTasks.map((task) => {
      let newTask = Object.assign({}, task);
      delete newTask.taskRegistrations;
      return newTask;
    });
    newProj.projectTasks = newProjTasks;
    return newProj;
  });
  return refinedArrayProjectList;
};

const projectContextSlice = createSlice({
  name: 'projectContext',
  initialState: initialState,
  reducers: {
    setProjects: (state, action) => {
      //remove task registratiions from property
      var newList = [];
      action.payload.projects.forEach((f) => {
        var checkExist = state.projects.find(
          (ff) => f.projectId === ff.projectId,
        );
        if (checkExist === undefined) {
          newList.push(f);
        }
      });

      let sortedProjectList = newList.sort((a, b) =>
        a.projectName.localeCompare(b.projectName),
      );
      const projectList =
        buildProjectTaskArrayToProjectArrayOfTransitObjectTask(
          sortedProjectList,
        );

      var temp = [...state.projects];
      state.projects = temp.concat(projectList);
    },
    setProjectTeam: (state, action) => {
      const sortedProjectList =
        action?.payload?.projects.sort((a, b) =>
          a.projectName.localeCompare(b.projectName),
        ) || [];

      const projectListWithArrayOfTransitObjectTask =
        buildProjectTaskArrayToProjectArrayOfTransitObjectTask(
          sortedProjectList,
        );
      state.projects = projectListWithArrayOfTransitObjectTask;
    },
    setEmptyProject: (state, action) => {
      state.projects = [];
    },
    addProjectTask: (state, action) => {
      const projIndex = state.projects.findIndex(
        (proj) => proj.projectId == action.payload.taskProjId,
      );

      let proj = state.projects.filter(
        (proj) => proj.projectId == action.payload.taskProjId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let taskCopy = Object.assign([], copyProj.projectTasks);

      let copyNewTask = Object.assign({}, action.payload.newTask);

      let isTaskExisting =
        taskCopy.findIndex((task) => task.taskId === copyNewTask.taskId) !== -1;

      //check first if task already exists on the project
      if (!isTaskExisting) {
        let taskInsertIndex = taskCopy.length - 1;
        delete copyNewTask.taskRegistrations; // remove task registration
        taskCopy.splice(taskInsertIndex, 0, copyNewTask);
        copyProj.projectTasks = taskCopy;

        state.projects[projIndex] = copyProj;
      }
    },
    deleteProjectTask: (state, action) => {
      const projIndex = state.projects.findIndex(
        (proj) => proj.projectId == action.payload.taskObj.taskProjectId,
      );

      let proj = state.projects.filter(
        (proj) => proj.projectId == action.payload.taskObj.taskProjectId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let taskCopy = Object.assign([], copyProj.projectTasks);

      let taskIndex = taskCopy.findIndex(
        (task) => task.taskId == action.payload.taskObj.taskId,
      );

      taskCopy.splice(taskIndex, 1);
      copyProj.projectTasks = taskCopy;

      state.projects[projIndex] = copyProj;
    },
    setTimesheetTime: (state, action) => {
      state.timeset = action.payload;
    },
  },
});

export const {
  setProjects,
  setProjectTeam,
  addProjectTask,
  deleteProjectTask,
  setTimesheetTime,
  setEmptyProject,
} = projectContextSlice.actions;

export default projectContextSlice.reducer;
