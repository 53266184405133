import HealingIcon from '@mui/icons-material/Healing';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

export const leaveProperties = (t) => {
  return {
    sickness: {
      typeLabel: 'sickness',
      buttonLabel: t('me-leave.report-sickness'),
      ButtonIcon: HealingIcon,
      buttonSumbitLabel: t('me-leave.report-sickness'),
    },
    leave: {
      typeLabel: 'vacation',
      buttonLabel: t('me-leave.request-leave'),
      ButtonIcon: EmojiTransportationIcon,
      buttonSumbitLabel: t('me-leave.submit-for-approval'),
    },
    others: {
      typeLabel: 'vacation',
      buttonSumbitLabel: t('me-leave.submit-for-approval'),
      buttonLabel: t('me-leave.request-leave'),
    },
  };
};

export const leaveStatusType = {
  vacation: [
    {
      label: 'Approved',
      key: 'approved',
    },
    {
      label: 'Pending',
      key: 'pending',
    },
    {
      label: 'Remaining',
      key: 'remaining',
    },
    {
      label: 'Entitlement',
      key: 'entitlement',
    },
  ],
  sickness: [
    {
      label: 'Approved',
      key: 'approved',
    },
    {
      label: 'Entitlement',
      key: 'entitlement',
    },
  ],
};

export const getStatuses = (t) => {
  return [
    { value: t('me-leave.all-status'), label: t('me-leave.all-status') },
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'declined', label: 'Declined' },
  ];
};
