export const orgTeamsAndProjectFeatureOn =
  process.env.REACT_APP_ORG_TEAMS_AND_PROJECT_FEATURE_ON === 'true'
    ? true
    : false;

export const orgMenuFeatureOn =
  process.env.REACT_APP_ORG_MENU_FEATURE_ON === 'true' ? true : false;
export const dashboardMenuFeatureOn =
  process.env.REACT_APP_DASHBOARD_MENU_FEATURE_ON === 'true' ? true : false;
export const leaveFeatureOn =
  process.env.REACT_APP_LEAVE_FEATURE_ON === 'true' ? true : false;
//WE
export const weMenuFeatureOn =
  process.env.REACT_APP_WE_MENU_FEATURE_ON === 'true' ? true : false;
export const leaveManagementFeatureOn =
  process.env.REACT_APP_LEAVE_FEATURE_ON === 'true' ? true : false;
export const teamTimesheetFeatureOn =
  process.env.REACT_APP_TEAM_TIMESHEET_FEATURE_ON === 'true' ? true : false;
export const createProjectFeatureOn =
  process.env.REACT_APP_TEAM_TIMESHEET_FEATURE_ON === 'true' ? true : false;
export const orgMyTeammatesMenuFeatureOn =
  process.env.REACT_APP_ORGANIZATION_MYTEAMMATES_MENU_FEATURE_ON === 'true'
    ? true
    : false;
export const peronalOrgInformationMenuFeatureOn =
  process.env.REACT_APP_PERSONAL_ORGANIZATION_INFORMATION_MENU_FEATURE_ON ===
  'true'
    ? true
    : false;

export const OT =
  process.env.REACT_APP_ORGANIZATION_MYTEAMMATES_MENU_FEATURE_ON;
export const PI =
  process.env.REACT_APP_PERSONAL_ORGANIZATION_INFORMATION_MENU_FEATURE_ON;
