import React from "react";
import classes from "./ContentLayout.module.scss";

const ContentLayout = ({ children }) => {
  return (
    <div>
      <span className={classes.arc_tr}></span>
      <span className={classes.arc_bl}></span> {children}
    </div>
  );
};

export default ContentLayout;
