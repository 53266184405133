import httpRequestHandler from '../../../utilities/httpRequestHandler';
import { setAuthToken, setCredentials } from '../../../store/authContextSlice';
import {
  readTransitJs,
  getTransitData,
  createTransitData,
} from '../../../utilities/transitJsUtils';
import { saveStateToLocalStorage } from '../../../utilities/localStorageUtlis';
import store from '../../../store/store';

export const signUpUserViaLinkedin = (authObj) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(
        getsignUpViaLinkedinInfo,
        authObj,
      );

      if (response.result === 1) {
        dispatch(
          setAuthToken({
            authToken: getTransitData(response.data, 'meewee-session'),
            loginType: 'LINKEDIN',
          }),
        );

        const authContext = store.getState()['authContext'];
        saveStateToLocalStorage('authContext', authContext);
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const signUpUser = (authObj) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getsignUpInfo, authObj);

      if (response.result === 1) {
        dispatch(setCredentials({ username: authObj.username }));
        dispatch(
          setAuthToken({
            authToken: response.data,
            loginType: 'DIRECT',
          }),
        );

        const authContext = store.getState()['authContext'];
        saveStateToLocalStorage('authContext', authContext);
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getCountryList = async () => {
  try {
    const response = await httpRequestHandler(getCountriesListInfo);

    if (response.result === 1) {
      let mappedCountriesList = [];
      response.data.forEach((country) => {
        mappedCountriesList.push(country._name);
      });

      response.data = mappedCountriesList;
    }

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const validateEmailAddress = async (emailInfo) => {
  try {
    const response = await httpRequestHandler(
      validateCredentialsInfo,
      emailInfo,
    );

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const validateUserName = async (usernameInfo) => {
  try {
    const response = await httpRequestHandler(
      validateCredentialsInfo,
      usernameInfo,
    );

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const sendVerificationEmail = async (emailInfo) => {
  try {
    const response = await httpRequestHandler(sendEmailInfo, emailInfo);

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const acceptInvitationService = async (acceptInviteObj) => {
  try {
    let requestParamObj = {
      email: { value: acceptInviteObj.email, isKeyword: false },
      'assignment-id': {
        value: acceptInviteObj['assignment-id'],
        isKeyword: false,
      },
    };

    let mappedrequestParamObj = createTransitData(requestParamObj);
    const response = await httpRequestHandler(
      acceptInvitationInfo,
      mappedrequestParamObj,
    );
    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

const acceptInvitationInfo = {
  url: 'accept-invitation',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getsignUpViaLinkedinInfo = {
  url: 'signin/linkedin',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'json',
};

const getsignUpInfo = {
  url: 'signup',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
};

const getCountriesListInfo = {
  url: 'countries',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
};

const validateCredentialsInfo = {
  url: 'signup/validate',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
};

const sendEmailInfo = {
  url: 'signup/email',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
};
