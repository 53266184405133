import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiPaper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import {
  ListItemButton,
  List,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { format } from 'date-fns';
import PunchClockRoundedIcon from '@mui/icons-material/PunchClockRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeeklyTimeRegistrationList from './weeklyTimeRegistration/weeklyTimeRegistrationList/WeeklyTimeRegistrationList';
import WeeklyDatePicker from './weeklyTimeRegistration/weeklyDatePicker/WeeklyDatePicker';
import {
  getRegistrations,
  getTotalRegistration,
  submitRegistrations,
} from '../../../service/timeregistrationService';
import { dateFormats } from '../../../../../utilities/dateUtils';
import classes from './AllOrgTimeRegistration.module.scss';
import {
  setProjects,
  setEmptyProject,
} from '../../../../../store/projectContextSlice';
import {
  setRegistration,
  setEmptyRegistration,
} from '../../../../../store/registrationContextSlice';

import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import { transformMinsToHoursMinutesString } from '../../../../../utilities/timeConversion';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import { successRegSubmissionMessage } from '../../../../../utilities/contentMessagesUtils';
import ErrorSnackbar from '../../../../ui/snackbar/errorSnackbar/ErrorSnackbar';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TimeRegTipsAndTricks = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickTips = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTips = () => {
    setAnchorEl(null);
  };

  const openTipsPopover = Boolean(anchorEl);
  const id = openTipsPopover ? 'tips-and-tricks-popover' : undefined;
  return (
    <React.Fragment>
      <Button variant="text" onClick={handleClickTips}>
        <div>
          <TipsAndUpdatesOutlinedIcon />
          <Typography>{t('me.tips-tricks')}</Typography>
        </div>
      </Button>
      <Popover
        id={id}
        open={openTipsPopover}
        anchorEl={anchorEl}
        onClose={handleCloseTips}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.tips_popover}>
          <div className={classes.tips_popover_header}>
            <div className={classes.tips_title}>
              <TipsAndUpdatesOutlinedIcon />
              <Typography>{t('me.tips-tricks')}</Typography>
            </div>
            <Typography className={classes.tips_desc}>
              {t('me.use-these-time-registration-keyboard-shortcuts')}
            </Typography>
          </div>

          <div className={classes.tips_nav_con}>
            <ArrowBackIcon className={classes.tips_nav_icon} />
            <ArrowUpwardIcon className={classes.tips_nav_icon} />
            <ArrowDownwardIcon className={classes.tips_nav_icon} />
            <ArrowForwardIcon className={classes.tips_nav_icon} />
            <Typography>{t('me.navigate-days')}</Typography>
          </div>

          <div className={classes.tips_nav_con}>
            <Typography className={classes.tips_nav_text}>
              {t('me.enter')}
            </Typography>
            <Typography>{t('me.activate-edit-mode-on-days-cell')}</Typography>
            <Typography>{t('me.save-and-exit-entered-hours')}</Typography>
          </div>

          <div className={classes.tips_nav_con}>
            <Typography className={classes.tips_nav_text}>
              {t('me.esc')}
            </Typography>
            <Typography>{t('me.exit-edit-mode-on-days-cell')}</Typography>
            <Typography>{t('me.exit-registration-popup')}</Typography>
          </div>

          <div className={classes.tips_nav_con}>
            <Typography className={classes.tips_nav_text}>
              {t('me.tab')}
            </Typography>
            <Typography>{t('me.navigate-to-next-day-cell')}</Typography>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
};
const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  paddingTop: '26px',
  width: '200px',
  height: '100vh',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

// const TeamInvitationAction = ({invitationUser, invitationId, handleJoinInvite, handleDeclineInvite, ...props}) => {
const AllOrgTimeRegistration = () => {
  const { t } = useTranslation();

  const selectedOrgPlaceHolder = {
    id: -1,
    organizations: {
      name: t('me.all-organizations'),
    },
  };

  const successSnackbarRef = useRef(null);
  const errorSnackbarRef = useRef(null);
  const anchorRef = React.useRef(null);
  const [openOrgList, setOpenOrgList] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const userOrgList = useSelector(
    (state) => state.userContext.userOrganizations,
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const registrations = useSelector(
    (state) => state.registrationContext.registrations,
  );
  const [selectedOrg, setSelectedOrg] = useState(selectedOrgPlaceHolder);
  const [dateInfo, setDateInfo] = useState({});
  const dispatch = useDispatch();
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: <></>,
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
  });

  const [orgList, setOrgList] = useState([]);

  useEffect(() => {
    var newList = [];
    var orgItem = {
      id: userId,
      emp: userId,
      name: `${userFirstName} ${userLastName}`,
      organizations: { id: userId, name: `${userFirstName} ${userLastName}` },
      owner: { id: userId, fname: userFirstName, lname: userLastName },
      userAuth: ['Personal'],
    };
    newList.push(orgItem);
    if (userOrgList.length > 0) {
      loadRegistration();
      newList = newList.concat(userOrgList);
    }
    setOrgList(newList);
  }, [userOrgList]);

  useEffect(() => {
    return () => {
      dispatch(setProjects({ projects: [] }));
      dispatch(setRegistration({ registrations: [] }));
    };
  }, [dispatch]);

  const onOrgNavItemSelectHandler = (event, orgInfo) => {
    setSelectedOrg(orgInfo);
  };

  const onDateChangeHandler = async (startDate, endDate, selectedDate) => {
    //every date change, reset projects and registration
    dispatch(setEmptyProject({ projects: [] }));
    dispatch(setEmptyRegistration({ registrations: [] }));

    //call get registrations
    setDateInfo({
      startDate: startDate,
      endDate: endDate,
      selectedDate: selectedDate,
    });

    userOrgList.forEach(async (f) => {
      setLoader(true);
      let regObj = {
        from: format(startDate, dateFormats.default),
        to: format(endDate, dateFormats.default),
        employment: f.emp,
      };
      await dispatch(getRegistrations(regObj));
      setLoader(false);
    });
  };

  const loadRegistration = async () => {
    if (dateInfo.startDate !== undefined) {
      dispatch(setEmptyProject({ projects: [] }));
      dispatch(setEmptyRegistration({ registrations: [] }));

      userOrgList.forEach(async (f) => {
        setLoader(true);
        let regObj = {
          from: format(dateInfo.startDate, dateFormats.default),
          to: format(dateInfo.endDate, dateFormats.default),
          employment: f.emp,
        };
        await dispatch(getRegistrations(regObj));
        setLoader(false);
      });
    }
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
    }));
  };

  const onSubmitRegistrations = () => {
    //get projects with registrations for selected/active org
    let orgRegistrations = [];

    if (selectedOrg.id === -1) {
      orgRegistrations = registrations;
    } else {
      orgRegistrations = registrations.filter(
        (proj) =>
          proj.projectOrganizationId === selectedOrg?.id &&
          proj.projectTasks.length > 1,
      );
    }

    let regListObject = [];
    let invalidObject = [];
    //get registrations that are in draft for each project
    orgRegistrations.forEach((proj) => {
      var empId = '';
      if (proj.projectOrganizationId === userId) {
        empId = userId;
      } else {
        empId = userOrgList.find(
          (f) => f.id === proj.projectOrganizationId,
        ).emp;
      }

      proj.projectTasks.forEach((task) => {
        let buildTaskObj = {};
        buildTaskObj.taskId = task.taskId;
        buildTaskObj.taskProjectId = task.taskProjectId;
        buildTaskObj.taskReg = {};
        buildTaskObj.empId = empId;
        for (const [date, value] of Object.entries(task.taskRegistrations)) {
          if (value.length > 0) {
            let draftRegistrations = value
              .filter(
                (reg) =>
                  reg.regStatus === 'draft' || reg.regStatus === 'declined',
              )
              .map((reg) => {
                let newReg = Object.assign({}, reg);
                newReg.regStatus = 'pending';
                return newReg;
              });
            let draftRegDuration = value.filter(
              (reg) =>
                (reg.regStatus === 'draft' || reg.regStatus === 'declined') &&
                reg.regDuration <= 0,
            );
            if (draftRegDuration.length > 0) {
              invalidObject.push(draftRegDuration);
            }

            if (draftRegistrations.length > 0) {
              buildTaskObj.taskReg[date] = draftRegistrations;
            }
          }
        }

        if (Object.keys(buildTaskObj.taskReg).length > 0) {
          regListObject.push(buildTaskObj);
        }
      });
    });

    if (invalidObject.length > 0) {
      setconfirmationBoxInfo((prevState) => ({
        ...prevState,
        open: true,
        dialogContentTitle: (
          <Typography
            variant="h6"
            fontWeight="fontWeightSemiBold"
            color="text.primary"
          >
            {t('me.Oops-you-cant-submit-this-registration')}
          </Typography>
        ),
        dialogContent: <>{t('me.youve-entered-an-invalid-number-of-hours')}</>,
        selectedTasks: [],
        showActionButton: false,
        closeLabelText: 'OK, GOT IT',
      }));
    } else {
      if (regListObject.length > 0) {
        setconfirmationBoxInfo((prevState) => ({
          ...prevState,
          open: true,
          dialogContentTitle: (
            <Typography
              variant="h6"
              fontWeight="fontWeightSemiBold"
              color="text.primary"
            >
              {selectedOrg?.id === -1 ? (
                <>{t('me.would-you-like-to-submit-all-registrations-from')} </>
              ) : (
                <>
                  {t('me.would-you-like-to-submit-all-registrations-for')}{' '}
                  {selectedOrg?.name} {t('me.organization-from')}{' '}
                </>
              )}
              {format(dateInfo.startDate, dateFormats.short_date)}{' '}
              {t('common.to')}{' '}
              {format(dateInfo.endDate, dateFormats.short_date)}?
            </Typography>
          ),
          dialogContent: (
            <Typography variant="subtitle1" color="text.secondary">
              {t(
                'me.this-will-notify-your-manager-approver-to-approve-all-saved-registrations',
              )}
            </Typography>
          ),
          selectedTasks: regListObject,
          showActionButton: true,
          closeLabelText: t('common.cancel'),
        }));
      } else {
        setconfirmationBoxInfo((prevState) => ({
          ...prevState,
          open: true,
          dialogContentTitle: (
            <Typography
              variant="h6"
              fontWeight="fontWeightSemiBold"
              color="text.primary"
            >
              {selectedOrg?.id === -1 ? (
                <>{t('me.oops-there-are-no-registrations-from')} </>
              ) : (
                <>
                  {t('me.oops-there-are-no-registrations-for')}{' '}
                  {selectedOrg?.name} {t('me.organization-from')}{' '}
                </>
              )}
              {format(dateInfo.startDate, dateFormats.short_date)}{' '}
              {t('common.to')}{' '}
              {format(dateInfo.endDate, dateFormats.short_date)}{' '}
              {t('me.that-can-be-submitted')}
            </Typography>
          ),
          dialogContent: <></>,
          selectedTasks: [],
          showActionButton: false,
          closeLabelText: t('me.ok-got-it'),
        }));
      }
    }
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const showErrorSnackBar = (message) => {
    errorSnackbarRef.current.snackBarContentHandler(message);
  };

  const confirmSubmissionHandler = () => {
    dispatch(submitRegistrations(confirmationBoxInfo.selectedTasks)).then(
      (data) => {
        if (data.result === 1) {
          handleCloseDialogBox();
          showSuccessSnackBar(successRegSubmissionMessage);
        } else {
          handleCloseDialogBox();
          showErrorSnackBar(data.errorMessage);
        }
      },
    );
  };

  const getOverallRegistrationTotal = () => {
    let orgRegistrations = [];

    if (selectedOrg.id === -1) {
      orgRegistrations = registrations;
    } else {
      orgRegistrations = registrations.filter(
        (proj) => proj.projectOrganizationId === selectedOrg?.id,
      );
    }

    return getTotalRegistration(orgRegistrations);
  };

  const handleToggle = () => {
    setOpenOrgList((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenOrgList(false);
  };

  const orgRegistrations =
    selectedOrg?.id === -1
      ? registrations
      : registrations.filter(
          (proj) => proj.projectOrganizationId === selectedOrg?.id,
        );

  const draftRegistrations = orgRegistrations.reduce(
    (totalCount, registration) => {
      const projectDraftTask = registration.projectTasks.reduce(
        (draftTask, projectTask) => {
          const draftTaskReg = Object.keys(
            projectTask.taskRegistrations,
          ).reduce((draftCount, task) => {
            const dateRegistrations = projectTask.taskRegistrations[task];
            const draftTaskOnDate = dateRegistrations.filter(
              (reg) =>
                reg.regStatus === 'draft' || reg.regStatus === 'declined',
            ).length;

            const declinedTaskOnDate = dateRegistrations.filter(
              (reg) => reg.regStatus === 'declined',
            ).length;

            draftCount += draftTaskOnDate;
            draftCount += declinedTaskOnDate;
            return draftCount;
          }, 0);

          draftTask += draftTaskReg;
          return draftTask;
        },
        0,
      );

      totalCount += projectDraftTask;
      return totalCount;
    },
    0,
  );

  const organizationName = selectedOrg?.organizations?.name || '';

  return (
    <Grid container item className={classes.time_registration_grid_container}>
      <Grid item className={classes.org_list_nav}>
        <Paper variant="outlined">
          <List>
            <ListItemButton
              key={-1}
              selected={selectedOrg.organizations?.id === -1}
              className={classes.list_item_button}
              onClick={(event) =>
                onOrgNavItemSelectHandler(event, selectedOrgPlaceHolder)
              }
            >
              <Typography variant="body2">
                {t('me.all-organizations')}
              </Typography>
            </ListItemButton>
            {orgList && (
              <React.Fragment>
                {orgList.map((org, orgIndex) => (
                  <ListItemButton
                    key={org.organizations.id}
                    selected={
                      selectedOrg.organizations?.id === org?.organizations?.id
                    }
                    className={classes.list_item_button}
                    onClick={(event) => onOrgNavItemSelectHandler(event, org)}
                  >
                    <Avatar
                      alt={
                        org?.id === userId
                          ? org?.owner?.fname
                          : org?.organizations?.name
                      }
                      src="/broken-image.jpg"
                      className={classes.header_icon}
                    />
                    <Typography variant="body2">
                      {org?.id === userId
                        ? org?.owner?.fname
                        : org?.organizations?.name}
                    </Typography>
                  </ListItemButton>
                ))}
              </React.Fragment>
            )}
          </List>
        </Paper>
      </Grid>
      <Grid
        container
        item
        direction="column"
        className={classes.time_registration_content}
      >
        <Grid item className={classes.org_header}>
          <Avatar
            alt={organizationName}
            src="/broken-image.jpg"
            className={classes.org_icon}
          />
          <Typography
            variant="h6"
            fontWeight="fontWeightSemiBold"
            color="text.primary"
            className={classes.all_org_timesheet}
          >
            {organizationName + ' Timesheet'}
          </Typography>

          <div className={classes.all_org_button}>
            <ButtonGroup variant="outlined" ref={anchorRef} color="secondary">
              <Button onClick={handleToggle}>
                {selectedOrg !== undefined && <div>{organizationName}</div>}
              </Button>
              <Button
                size="small"
                aria-controls={openOrgList ? 'split-button-menu' : undefined}
                aria-expanded={openOrgList ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={openOrgList}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper className={classes.org_button_list}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        <MenuItem
                          key={-1}
                          selected={selectedOrg?.id === -1}
                          onClick={(event) =>
                            onOrgNavItemSelectHandler(
                              event,
                              selectedOrgPlaceHolder,
                            )
                          }
                        >
                          {t('me.all-organizations')}
                        </MenuItem>
                        {orgList.map((org, index) => (
                          <MenuItem
                            key={index}
                            selected={org?.id === selectedOrg?.id}
                            onClick={(event) =>
                              onOrgNavItemSelectHandler(event, org)
                            }
                          >
                            <div
                              className={`${classes.org_select_name} ${classes.org_select_name_width}`}
                            >
                              {org?.id === userId
                                ? org?.owner?.fname
                                : org?.organizations?.name}
                            </div>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Grid>
        <Grid item className={classes.org_header_subtitle}>
          <Typography variant="body2">
            {t(
              'me.select-your-preferred-date-to-register-time-for-this-employer',
            )}
          </Typography>
        </Grid>
        <Grid item className={classes.action_buttons_container}>
          <WeeklyDatePicker onChange={onDateChangeHandler} loader={loader} />
          <Button
            variant="contained"
            onClick={onSubmitRegistrations}
            disabled={!draftRegistrations}
            className={classes.btnSubmit}
          >
            <CheckCircleOutlineIcon />
            {t('me.submit-all-for-approval')}
          </Button>
        </Grid>
        <Divider variant="fullWidth" />
        {/* timesheetContainer */}
        <Grid item>
          <div className={classes.loadContainer}>
            {selectedOrg !== undefined && (
              <div>
                <div className={classes.reg_header_container}>
                  <div className={classes.reg_header_icon}>
                    <PunchClockRoundedIcon className={classes.clock_icon} />
                  </div>
                  <div className={classes.reg_header_time}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontWeight="fontWeightSemiBold"
                    >
                      {transformMinsToHoursMinutesString(
                        getOverallRegistrationTotal(),
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" color="text.secondary">
                      {t('me.total-registered-hours')}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Grid
                    container
                    spacing={2}
                    className={classes.timesheet_tips}
                  >
                    <Grid item xs={8}>
                      <Typography variant="body2" color="text.primary">
                        {t(
                          'me.register-your-time-weekly-by-typing-your-hours-along-the-tasks-row-under-an-orgs-project',
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.timesheet_tips_button}>
                      <TimeRegTipsAndTricks />
                    </Grid>
                  </Grid>
                </div>
                {/* timesheetDataGrid */}
                <WeeklyTimeRegistrationList
                  dateInfo={dateInfo}
                  selectedOrg={selectedOrg}
                  registrations={registrations}
                  showSuccessSnackBarFn={showSuccessSnackBar}
                  showErrorSnackBarFn={showErrorSnackBar}
                  orgRegistrations={orgRegistrations}
                />
              </div>
            )}
            {loader && (
              <div
                className={
                  classes.box + ' ' + classes.stackTop + ' ' + classes.box2
                }
              >
                <div className={classes.center}>
                  <CircularProgress />
                  <Typography>
                    {t('me.getting-all-registration-data')}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={confirmSubmissionHandler}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText="SUBMIT"
        showActionButton={confirmationBoxInfo.showActionButton}
      />
      <div className={`${classes.snackbar_wrapper} `}>
        <SuccessSnackbar
          className={classes.time_success}
          ref={successSnackbarRef}
        />
        <ErrorSnackbar ref={errorSnackbarRef} />
      </div>
    </Grid>
  );
};

export default AllOrgTimeRegistration;
