import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import AvatarGroup from "@mui/material/AvatarGroup";
import PersonIcon from "@mui/icons-material/Person";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const AvatarUI = () => {
  return (
    <Box>
      <Typography variant="h6">Witn Badge</Typography>
      <Box>
        <Stack spacing={10}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Small</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 35, height: 35, fontSize: 15 }}
                />
              </StyledBadge>
            </Box>
            <Box sx={{ m: 5, ml: 10 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar sx={{ width: 35, height: 35 }}>
                  <PersonIcon />
                </Avatar>
              </StyledBadge>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Medium</Typography>
            </Box>
            <Box sx={{ m: 3 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </StyledBadge>
            </Box>
            <Box sx={{ m: 3, ml: 10 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </StyledBadge>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Large</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 56, height: 56, fontSize: 30 }}
                />
              </StyledBadge>
            </Box>
            <Box sx={{ m: 5 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar sx={{ width: 56, height: 56 }}>
                  <PersonIcon />
                </Avatar>
              </StyledBadge>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Typography variant="h6">Grouped</Typography>
      <Box>
        <Stack spacing={10}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Small</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup
                total={24}
                sx={{
                  "& .MuiAvatar-root": { width: 35, height: 35, fontSize: 15 },
                }}
              >
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar
                  alt="Trevor Henderson"
                  src="/static/images/avatar/5.jpg"
                />
              </AvatarGroup>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup
                total={24}
                sx={{
                  "& .MuiAvatar-root": { width: 35, height: 35, fontSize: 15 },
                }}
              >
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </AvatarGroup>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Medium</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup total={24}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar
                  alt="Trevor Henderson"
                  src="/static/images/avatar/5.jpg"
                />
              </AvatarGroup>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup total={24}>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </AvatarGroup>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Large</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup
                total={24}
                sx={{
                  "& .MuiAvatar-root": { width: 56, height: 56, fontSize: 30 },
                }}
              >
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar
                  alt="Trevor Henderson"
                  src="/static/images/avatar/5.jpg"
                />
              </AvatarGroup>
            </Box>
            <Box sx={{ m: 5 }}>
              <AvatarGroup
                total={24}
                sx={{
                  "& .MuiAvatar-root": { width: 56, height: 56, fontSize: 30 },
                }}
              >
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </AvatarGroup>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default AvatarUI;
