import t from 'transit-js';

export const readTransitJs = (data) => {
  let r = t.reader('json');

  return r.read(data);
};

export const getTransitData = (mappedData, keyword) => {
  return mappedData.get(t.keyword(keyword));
};

export const getTransitDataCollection = (transitData, mapToObject) => {
  let mappedData = {};

  for (const [key, value] of Object.entries(mapToObject)) {
    mappedData[key] = transitData.get(t.keyword(value));

    if (t.isKeyword(mappedData[key])) {
      mappedData[key] = mappedData[key].name();
    }
  }

  return mappedData;
};

export const mapTransitArrayMapToObject = (transitData) => {
  return t.mapToObject(transitData);
};

export const mapObjectToTransitArrayMap = (obj) => {
  return t.objectToMap(obj);
};
export const createTransitData = (rawData) => {
  let w = t.writer('json');
  let objectArray = [];
  for (const [key, value] of Object.entries(rawData)) {
    objectArray.push(t.keyword(key));

    if (value.isKeyword) {
      objectArray.push(t.keyword(value.value));
    } else {
      objectArray.push(value.value);
    }
  }
  let mappedData = t.map(objectArray);
  let transitData = w.write(mappedData);
  return transitData;
};
