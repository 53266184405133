//Time Registration
export const successRegSubmissionMessage =
  'Time registration is now submitted.';

export const failedRegSubmissionMessage =
  'Uh, oh. We encountered some errors. Please try submitting again.';

export const successRegDeletionMessage = 'Time registration is now deleted.';

export const failedRegDeletionMessage =
  'Uh, oh. We encountered some errors. Please try deleting again.';

export const failedRegSaveMessage =
  'Uh, oh. We encountered some errors. Please try saving again.';

export const failedReg24HrLimitMessage =
  'You cannot register more than 24 hours, please try again. ';

//Task Management
export const createTaskSuccessMessage = 'Task created.';
export const createTaskFailedMessage =
  'Uh, oh. We encountered some errors. Please try creating again.';
export const unauthorized = 'Unauthorized request. Please try creating again.';
export const tasksExistsMessage =
  'Uh, oh. We encountered some errors. Task already exists.';
export const deleteTaskSuccessMessage = 'Task is now deleted.';
export const deleteTaskFailedMessage =
  'Uh, oh. We encountered some errors. Please try deleting again.';
export const createTaskLongMessage =
  'Task title must not be over 40 characters.';

//Team Invitation
export const sendEmailSuccessMessage = 'Team invitation successfully sent.';
export const joinTeamInvitationSuccessMessage =
  'You have successfully joined the team.';
export const declineTeamInvitationSuccessMessage =
  'You have declined the team invitation.';
export const joinTeamInvitationFailureMessage =
  'Uh, oh. We encountered some errors. Please try again.';

//Pending Requests
export const declineRequestSuccessMessage = 'Request successfully declined.';
export const declineRequestFailureMessage =
  'Uh, oh. We encountered some errors. Please try declining the request again.';

export const approveRequestSuccessMessage = 'Request successfully approved.';
export const approveRequestFailureMessage =
  'Uh, oh. We encountered some errors. Please try approving the request again.';

export const declineAllRequestSuccessMessage =
  'All requests successfully declined.';
export const declineAllRequestFailureMessage =
  'Uh, oh. We encountered some errors. Please try declining all the requests again.';

export const approveAllRequestSuccessMessage =
  'All requests successfully approved.';
export const approveAllRequestFailureMessage =
  'Uh, oh. We encountered some errors. Please try approving all the requests again.';

//Remove Member from Organization
export const removeMemberSuccessMessage =
  'Member was successfully removed from the organization.';
export const removeMemberFailureMessage =
  'Uh, oh. We encountered some errors. Please try removing the member from the organization again.';

//Remove member from Team
export const removeMemberFromTeamSuccessMessage =
  'Member was successfully removed from the team.';
export const removeMemberFromTeamFailureMessage =
  'Uh, oh. We encountered some errors. Please try removing the member from the team again.';

//Leave a team
export const requestToLeaveSuccessMessage = 'Leave request successfully sent.';

//Cancel Team Invitation
export const cancelTeamInviteSuccessMessage =
  'Team Invitation was successfully cancelled.';
export const cancelTeamInviteFailureMessage =
  'Uh, oh. We encountered some errors. Please try canceling the team invitation again.';

export const retrieveDataFailureMessage = 'Failed to retrieve data.';

//Feature Status Notifcation
export const featureUpdateMessage =
  'This section is still in the works. Watch out for more Meewee updates!';

//Request Leave
export const sendRequestLeaveSuccessMessage = 'Leave submitted for approval.';
export const sendRequestLeaveDraftMessage = 'Leave saved as draft.';
export const confirmModalTitle =
  'Would you like to submit your vacation leave for approval ?';
export const confirmModalVacationTitle =
  'Would you like to submit your vacation leave?';
export const confirmModalSicknessTitle =
  'Would you like to report your sickness?';
export const confirmModalDescription =
  'This will notify your manager/approver to approve your leave. You can still make some changes before its approval';
export const confirmModalDescriptionSickNess =
  'Your sickness will be reported and will be automatically approved.';
export const discardModalTitle = 'Would you like to discard this leave?';
export const discardModalDescription =
  'This will remove all your unsaved changes.';
export const sendReportSicknessSuccessMessage = 'Sickness is now reported.';
export const leaveRequestCollision =
  'Uh, oh. Your selected dates have existing leave requests. Please select another date aside from the leaves below:';
export const confirmModalSubContent =
  "This will show on the employee's time registration details.";
export const confirmModalApprove =
  'Would you like to approve this time registrations?';
export const opeEditingALL =
  'We will set the status of all selected time registrations to "Draft". This will enable the employee/s to edit their entries again. ';
export const openEditingSingle =
  'We will set the status of this time registration to "Draft". This will enable the employee to edit this registration again. ';
