import React, { useState, useEffect } from "react";
import { Outlet , useLocation} from "react-router-dom"; 
import { styled } from "@mui/material/styles";
import MuiBox from "@mui/material/Box";
import classes from "./DashboardLayout.module.scss";
import ProfileNavBar from "../../../navigation/profilenavbar/ProfileNavBar";
import SideNavBar from "../../../navigation/sidenavbar/SideNavBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const expandeddrawerWidth = 226;
const collapsedDrawerWidth = 65;

const Box = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "@media (min-width:1024px)": {
    ...(open && {
      marginLeft: expandeddrawerWidth - 5,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      marginLeft: collapsedDrawerWidth - 5,

      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const DashboardLayout = (props) => {
  const isScreenSmall = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1919px)"
  );
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const onDrawerToggleHandler = () => {
    setOpen(!open);
  };

  const onMobileDrawerToggleHandler = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (isScreenSmall && open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isScreenSmall]);

  return (
    <div className={classes.dashboard_main_container}>
      <ProfileNavBar
        open={open}
        onMobileDrawerToggle={onMobileDrawerToggleHandler}
      />
      <SideNavBar
        open={open}
        mobileOpen={mobileOpen}
        onDrawerToggle={onDrawerToggleHandler}
        onMobileDrawerToggle={onMobileDrawerToggleHandler}
      />
      {props.children}
      <div className={`${classes.dashboard_content_container} dashboardHeight`}>
        <Box component={"div"} open={open}>
          <Outlet context={[open, setOpen]} />
        </Box>
      </div>
    </div>
  );
};

export default DashboardLayout;
