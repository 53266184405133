import React from "react";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const BadgeUI = () => {
  return (
    <Box>
      <Typography variant="h6">Badge</Typography>
      <Box sx={{ m: 5 }}>
        <Stack spacing={10}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Dot</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="primary" badgeContent=" " variant="dot">
                <Typography variant="caption">Primary</Typography>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="secondary" badgeContent=" " variant="dot">
                <Typography variant="caption">Secondary</Typography>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="error" badgeContent=" " variant="dot">
                <Typography variant="caption">Error</Typography>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="warning" badgeContent=" " variant="dot">
                <Typography variant="caption">Warning</Typography>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="info" badgeContent=" " variant="dot">
                <Typography variant="caption">Info</Typography>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="success" badgeContent=" " variant="dot">
                <Typography variant="caption">Success</Typography>
              </Badge>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">With count</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="primary" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Primary</Typography>
                </Box>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="secondary" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Secondary</Typography>
                </Box>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="error" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Error</Typography>
                </Box>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="warning" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Warning</Typography>
                </Box>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="info" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Info</Typography>
                </Box>
              </Badge>
            </Box>
            <Box sx={{ m: 5 }}>
              <Badge color="success" badgeContent={1000} max={999}>
                <Box sx={{ m: 1 }}>
                  <Typography variant="caption">Success</Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Typography variant="h6">Notification</Typography>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Badge color="primary" variant="dot">
            <NotificationsIcon />
          </Badge>
        </Box>
        <Box sx={{ m: 5 }}>
          <Badge color="primary" badgeContent={1000} max={999}>
            <NotificationsIcon />
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export default BadgeUI;
