import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import lettermarkLogo from '../../../assets/meewee-lettermark-logo.svg';
import LinkedinButton from '../../ui/socialButtons/linkedinButton/LinkedinButton';
import TextBox from '../../ui/formcontrols/textBox/TextBox';
import FormButtonLoading from '../../ui/formcontrols/formButtonLoading/FormButtonLoading';
import PasswordTextBox from '../../ui/formcontrols/passwordTextBox/PasswordTextBox';
import ErrorAlert from '../../ui/alerts/errorAlert/ErrorAlert';
import useInputValidation from '../../../hooks/use-input-validation';
import { signInUser } from '../service/loginService';
import classes from './Login.module.scss';
import paths from '../../../routing/Constants';
import meeweeInitials from '../../../assets/meewee-initials.svg';
import { RegEx } from '../../../utilities/constantUtils';
import i18n from '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

//paths

const Login = () => {
  const lang = `/${i18n.language}/`;
  const homePath = `${lang}`.concat(paths.HOME);
  const signUpPath = '/'.concat(paths.SIGNUP);
  const loginPath = `${lang}`.concat(paths.LOGIN);
  const forgotPasswordPath = `${lang}`.concat(paths.FORGETPASSWORD);

  const { t } = useTranslation();

  document.title = 'Meewee';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const resetFormError = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  useEffect(() => {
    //Check if error message was passed as a state of a preceding component
    if (state) {
      if (state.errorMessage) {
        setErrorMessage(state.errorMessage);
        navigate(loginPath, { replace: true, state: null });
      }
    }
  }, []);

  const {
    value: username,
    isValid: usernameIsValid,
    hasError: usernameHasError,
    valueChangeHandler: onUsernameChangeHandler,
    inputBlurHandler: onUserNameBlurHandler,
  } = useInputValidation(
    (value) =>
      RegEx.emailRegEx.test(value.trim()) ||
      RegEx.userNameRegEx.test(value.trim()),
    resetFormError,
  );

  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: onPasswordChangeHandler,
    inputBlurHandler: onPasswordBlurHandler,
  } = useInputValidation((value) => value.length > 6, resetFormError);

  let formIsValid = passwordIsValid && usernameIsValid;
  const onClickHandler = (event) => {
    if (formIsValid) {
      setIsLoading(true);

      setTimeout(() => {
        dispatch(
          signInUser({ username: username, password: password, otp: '' }),
        ).then((data) => {
          if (data.result === 1) {
            setIsLoading(false);
            navigate(homePath);
          } else {
            setIsLoading(false);
            setErrorMessage(data.errorMessage);
          }
        });
      }, 1000);
    }
  };

  const onLinkedinSignInHandler = (url) => {
    window.location.href = url;
  };

  const enterKeyPressHandler = (event) => {
    if (event.key === 'Enter') {
      onClickHandler();
    }
  };

  return (
    <Grid container direction="row" className={classes.login_container}>
      <Grid item xs={7} className={classes.login_bg}>
        <div className={classes.logo_initials}>
          <img src={meeweeInitials} alt="meewee logo" />
          <Typography>{t('login.your-collaborative')}</Typography>
          <Typography>{t('login.time-and-attendance-app')}!</Typography>
        </div>
      </Grid>
      <Grid item xs={5} className={classes.signin_form}>
        <div className={classes.login_form}>
          <Typography className={classes.lettermark_logo} variant="heading">
            {t('login.sign-in-to')}
            <img src={lettermarkLogo} alt="meewee logo" />
          </Typography>
          <div className={classes.signin_inputbox}>
            <TextBox
              isError={usernameHasError}
              id="username"
              label={t('login.email-or-username')}
              type="text"
              onChange={onUsernameChangeHandler}
              onBlur={onUserNameBlurHandler}
              errorText={t('login.invalid-email-or-username')}
              ariaLabel={t('login.email-or-username')}
              onKeyPress={enterKeyPressHandler}
            />
            <PasswordTextBox
              isError={passwordHasError}
              id="password"
              label={t('login.password')}
              type="password"
              onChange={onPasswordChangeHandler}
              onBlur={onPasswordBlurHandler}
              errorText={t('login.invalid-password')}
              onKeyPress={enterKeyPressHandler}
            />
          </div>
          <div>
            {/* {errorMessage && */}
            <Typography variant="subtitle2" className={classes.forgetPassWord}>
              <Link to={forgotPasswordPath} className={classes.link}>
                {t('login.forgot-password')}?
              </Link>
            </Typography>

            {/* }      */}
          </div>

          <div className={classes.sign_in_button}>
            <FormButtonLoading
              id="sign-in-button"
              label={t('login.sign-in')}
              loadingIndicator={t('login.signing-in')}
              loading={isLoading}
              onClick={onClickHandler}
              fullWidth={true}
              loadingPosition="start"
              color="primary"
            />
          </div>
          {errorMessage && (
            // <FormHelperText id="my-helper-text" error={true}>
            //   {errorMessage}
            // </FormHelperText>
            <div className={classes.signin_error_alert}>
              <ErrorAlert
                id="sign-in-error"
                message={
                  <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                }
              />
            </div>
          )}
          <div className={classes.socials_container}>
            <Typography variant="body2" color="text.secondary">
              {t('login.or-sign-in-using')}
            </Typography>
            <LinkedinButton
              onClick={onLinkedinSignInHandler}
              id="linkedinButton"
              signin={true}
            ></LinkedinButton>
            <Divider />

            <Typography variant="body2" color="text.secondary">
              {t('login.dont-have-an-account')}? &nbsp;
              <Box component="span" color="info.main">
                <Link to={signUpPath} className={classes.link}>
                  {t('login.sign-up-now')}!
                </Link>
              </Box>
            </Typography>
          </div>
          <div>
            <a
              href="https://what.meewee.com/GDPR/"
              target="_blank"
              className={classes.link}
              rel="noreferrer"
            >
              <Typography variant="caption" color="text.secondary">
                {t('login.help')} &#8226; {t('login.terms')}
              </Typography>
            </a>
          </div>
          <div>
            <Typography variant="caption" color="text.secondary">
              {t('common.meewee')} &copy; 2012-2024
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
