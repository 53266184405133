import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import classes from "./OrgTimeRegistration.module.scss";
const OrgTimeRegistration = () => {
  const { id } = useParams();
  const orgList = useSelector((state) => state.userContext.userOrganizations);
  const orgInfo = orgList.find((org) => org.id.toString() === id);

  return (
    <Box sx={{ paddingTop: "30px", marginLeft: "250px" }}>
      <Typography
        variant="h6"
        fontWeight="fontWeightSemiBold"
        color="text.primary"
      >
        {orgInfo && orgInfo.name + " Timesheet"}
      </Typography>
    </Box>
  );
};

export default OrgTimeRegistration;
