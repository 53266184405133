import React, { Fragment, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import classes from '../leaveTypePieChart/LeaveTypePieChart.module.scss';
import HealingIcon from '@mui/icons-material/Healing';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { LeaveType } from '../../../../../utilities/constantUtils';

Chart.register(ArcElement);
const chartColors = {
  vacation_approved: '#67E36C',
  vacation_pending: '#FFC81A',
  vacation_remaining: '#F2F2F2',
  sickness_approved: '#67E36C',
};

const LeavepieChart = ({ leaveType, leaveData, typeConfig }) => {
  const [pieChartConfig, setPieChartConfig] = useState([]);
  const pieIcon =
    leaveType === LeaveType.sickness ? (
      <HealingIcon />
    ) : (
      <EmojiTransportationIcon />
    );
  useEffect(() => {
    let pieData = [],
      pieColors = [];

    for (var config of typeConfig) {
      const val = leaveData[config.key];
      pieColors.push(chartColors[`${leaveType}_${config.key}`]);
      pieData.push(val == null ? 0 : val);
    }
    setPieChartConfig([
      {
        data: pieData,
        backgroundColor: pieColors,
        cutout: '70%',
      },
    ]);
  }, [leaveData]);

  return (
    <>
      <div style={{ width: '10%' }} className={classes.con}>
        <Doughnut
          data={{ datasets: pieChartConfig }}
          className={classes.doughnut}
        />
        <div className={classes.pie_icon}> {pieIcon}</div>
      </div>
    </>
  );
};
export default LeavepieChart;
