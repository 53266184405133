import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { addTask } from '../../../../../store/registrationContextSlice';
import {
  addTaskService,
  deleteTaskService,
  getTaskService,
} from '../../../service/timeregistrationService';
import classes from './TaskPicker.module.scss';
import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import { addProjectTask } from '../../../../../store/projectContextSlice';
import {
  createTaskFailedMessage,
  createTaskSuccessMessage,
  deleteTaskFailedMessage,
  deleteTaskSuccessMessage,
  tasksExistsMessage,
  createTaskLongMessage,
  unauthorized,
} from '../../../../../utilities/contentMessagesUtils';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import theme from '../../../../../utilities/themeProvider';
import { useTranslation } from 'react-i18next';

const filter = createFilterOptions();

const CounterTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    componentsProps={{ tooltip: { className: className } }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.counter.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.counter.main,
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
    color: '#6b1211',
    width: '215px',
    padding: '15px',
    boxShadow: '0px 4px 14px -2px rgba(0, 0, 0, 0.78)',
  },
}));

const TaskInputField = (props) => {
  const inputRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <TextField
      {...props.params}
      inputRef={inputRef}
      label="Task Title"
      variant="standard"
      maxLength={40}
    />
  );
};
const TaskPicker = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const userContext = useSelector((state) => state.userContext);
  const [click, setClick] = useState(false);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: '',
    dialogContent: '',
    closeLabelText: t('common.cancel'),
    showActionButton: true,
  });
  const [currentTask, setCurrentTask] = useState({});
  const projectTasks = useSelector(
    (state) =>
      state.projectContext.projects.filter(
        (proj) => proj.projectId === props.projectId,
      )[0]?.projectTasks,
  );

  const projRegistrations = useSelector(
    (state) =>
      state.registrationContext.registrations.filter(
        (proj) => proj.projectId == props.projectId,
      )[0]?.projectTasks,
  );

  const [nProjectTask, setNProjectTask] = useState([]);
  useEffect(() => {
    if (projectTasks) {
      var newProjectTask = projectTasks.map((f) => {
        let item = Object.assign({}, f);
        item.taskTitle = separateWord(item.taskTitle);
        return item;
      });
      setNProjectTask(newProjectTask);
    }
  }, [projectTasks]);

  const separateWord = (value) => {
    var result = value;

    if (!containsOnlySpaces(value)) {
      if (value.length > 25) {
        result = `${value.substring(25, 0)} ${value.substring(25)}`;
      }
    }

    return result;
  };

  const containsOnlySpaces = (str) => {
    return str.indexOf(' ') >= 0;
  };

  const onButtonClick = () => {
    setClick(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => {
      setInputValue('');
      setClick(false);
    }, 100);
  };

  const checkTaskExist = (item) => {
    var validate = projectTasks.find((f) => f.taskTitle === item);
    if (validate !== undefined) {
      assignTask(validate);
    }

    return validate !== undefined ? true : false;
  };

  const onTaskValueChange = async (event, newValue) => {
    if (checkTaskExist(newValue.inputValue)) {
      return;
    }
    if (newValue && newValue.inputValue) {
      let taskObj = {};
      taskObj.taskId = -1;
      taskObj.taskProjectId = props.projectId;
      taskObj.taskTitle = newValue.inputValue;
      taskObj.taskTotalRegTime = 0;
      taskObj.taskRegistrations = {};
      taskObj.empId = props.org?.emp;

      const newTaskData = await addTaskService(taskObj);

      if (newTaskData.result === 1 && newValue.inputValue.length <= 40) {
        taskObj.taskId = newTaskData.data[0];
        taskObj.taskCreator = userContext.userId;
        dispatch(
          addProjectTask({
            newTask: taskObj,
            taskProjId: taskObj.taskProjectId,
          }),
        );

        props.showSuccessSnackBarFn(createTaskSuccessMessage);
      } else if (newTaskData.statusCode === 401) {
        props.showErrorSnackBarFn(unauthorized);
      } else {
        // 303 status code means that task title is already existing for the project,
        //so just add it to the current list of projects and registration
        if (newTaskData.statusCode === 303) {
          let isTaskExisting =
            projectTasks.findIndex(
              (task) => task.taskId === newTaskData.errorMessage[0],
            ) !== -1;

          if (!isTaskExisting) {
            const existingTaskData = await getTaskService(
              newTaskData.errorMessage[0],
            );

            if (existingTaskData.result === 1) {
              dispatch(
                addProjectTask({
                  newTask: existingTaskData.data,
                  taskProjId: existingTaskData.data.taskProjectId,
                }),
              );

              props.showSuccessSnackBarFn(createTaskSuccessMessage);
            } else {
              props.showErrorSnackBarFn(existingTaskData.errorMessage);
            }
          } else {
            props.showErrorSnackBarFn(newTaskData.errorMessage);
          }
        } else {
          props.showErrorSnackBarFn(newTaskData.errorMessage);
        }
        // message too long
        props.showErrorSnackBarFn(createTaskLongMessage);
      }

      // if (newTaskData.result === 1 && newValue.inputValue.length <= 40) {
      //   taskObj.taskId = newTaskData.data[0];
      //   taskObj.taskCreator = userContext.userId;
      //   dispatch(
      //     addProjectTask({
      //       newTask: taskObj,
      //       taskProjId: taskObj.taskProjectId,
      //     }),
      //   );

      //   props.showSuccessSnackBarFn(createTaskSuccessMessage);
      // } else if (newTaskData.statusCode === 401) {
      //   props.showErrorSnackBarFn(unauthorized);
      // } else {
      //   // 303 status code means that task title is already existing for the project,
      //   //so just add it to the current list of projects and registration
      //   if (newTaskData.statusCode === 303) {
      //     let isTaskExisting =
      //       projectTasks.findIndex(
      //         (task) => task.taskId === newTaskData.errorMessage[0],
      //       ) !== -1;

      //     if (!isTaskExisting) {
      //       const existingTaskData = await getTaskService(
      //         newTaskData.errorMessage[0],
      //       );

      //       if (existingTaskData.result === 1) {
      //         dispatch(
      //           addProjectTask({
      //             newTask: existingTaskData.data,
      //             taskProjId: existingTaskData.data.taskProjectId,
      //           }),
      //         );

      //         props.showSuccessSnackBarFn(createTaskSuccessMessage);
      //       } else {
      //         props.showErrorSnackBarFn(createTaskFailedMessage);
      //       }
      //     } else {
      //       props.showErrorSnackBarFn(tasksExistsMessage);
      //     }
      //   } else {
      //     props.showErrorSnackBarFn(createTaskFailedMessage);
      //   }
      //   // message too long
      //   props.showErrorSnackBarFn(createTaskLongMessage);
      // }

      setInputValue('');
    } else {
      assignTask(newValue);
    }
  };

  const assignTask = (newValue) => {
    let taskObj = Object.assign({}, newValue);
    taskObj.taskRegistrations = {}; //add taskRegistrations property before adding task to registration slice
    if (newValue) {
      dispatch(
        addTask({ newTask: taskObj, taskProjId: newValue.taskProjectId }),
      );
      setTimeout(() => {
        setInputValue('');
      }, 100);
    }
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: '',
      dialogContent: '',
      closeLabelText: t('common.cancel'),
      showActionButton: true,
    }));
    setCurrentTask({});
  };

  const onDeleteConfirmation = () => {
    dispatch(deleteTaskService(currentTask)).then((data) => {
      if (data.result == 1) {
        handleCloseDialogBox();
        props.showSuccessSnackBarFn(deleteTaskSuccessMessage);
      } else {
        if (data.statusCode === 401) {
          setconfirmationBoxInfo((prevState) => ({
            ...prevState,
            dialogContentTitle: `${t('me.oops')} "${currentTask.taskTitle}" ${t(
              'me.cannot-be-deleted',
            )}`,
            dialogContent: t(
              'me.the-task-you-are-trying-to-delete-has-an-existing-registration',
            ),
            closeLabelText: t('me.ok-got-it'),
            showActionButton: false,
          }));
        } else {
          handleCloseDialogBox();
          // props.showErrorSnackBarFn(deleteTaskFailedMessage);
          props.showErrorSnackBarFn(data.errorMessage);
        }
      }
    });
  };

  const ondeleteTaskHandler = (event, task) => {
    event.stopPropagation();
    setCurrentTask(task);
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: `${t('me.would-you-like-to-delete')} "${
        task.taskTitle
      }"?`,
      dialogContent: t(
        'me.this-will-be-removed-from-the-current-list-of-tasks',
      ),
    }));
  };

  const counterText = () => {
    if (inputValue.length > 40) {
      return (
        <CounterTooltip
          open
          placement="top"
          title={t('me.task-title-must-not-be-over-40-characters')}
          className={classes.counter_tooltip}
        >
          <span
            className={classes.counter_circle}
            style={{ color: inputValue.length > 40 ? 'red' : 'green' }}
          >
            {40 - inputValue.length}
          </span>
        </CounterTooltip>
      );
    } else {
      return null;
    }
  };
  return (
    <React.Fragment>
      {!click && (
        <Button
          size="small"
          onClick={onButtonClick}
          className={`${classes.task_picker_button} taskPickerButton`}
        >
          <AddCircleIcon
            sx={{
              marginRight: '5px',
              color: '#0091EA',
              width: '16px',
              height: '16px',
            }}
          />
          <Typography variant="body2" color="info.main" component="p">
            {t('me.add-a-task')}
          </Typography>
        </Button>
      )}
      {click && (
        <React.Fragment>
          <Autocomplete
            className={`${classes.task_picker_input} taskPickerInput`}
            value={inputValue}
            inputProps={{ maxLength: 10 }}
            autoHighlight
            maxLength={40}
            onBlur={onBlurHandler}
            // inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onKeyDown={(event) => {
              event.stopPropagation(); //to allow spacebar to work on autocomplete component inside data grid
            }}
            sx={{ width: '200px' }}
            getOptionDisabled={(option) =>
              projRegistrations.findIndex(
                (task) => task.taskId == option.taskId,
              ) != -1
            }
            onChange={(event, newValue) => {
              onTaskValueChange(event, newValue);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  taskTitle: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            id="task-picker"
            options={nProjectTask}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.taskTitle;
            }}
            clearOnBlur
            handleHomeEndKeys
            openOnFocus
            blurOnSelect
            //NOTE: Commented out to be used when showing the top 10 recently used task
            // onInputChange={(event, value, reason) => {
            //   if (reason == "input" && value != "") {
            //     setShowTop(false);
            //   } else if (reason == "input" && value == "") {
            //     setShowTop(true);
            //   }
            // }}
            renderOption={(props, option) => (
              <li {...props} key={option.taskId ? option.taskId : -1}>
                <Grid
                  container
                  spacing={1}
                  columns={16}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={14}>
                    <Typography variant="body2">{option.taskTitle}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {option.inputValue === undefined &&
                      option.taskCreator === userContext.userId &&
                      !option.taskHasReg &&
                      projRegistrations.findIndex(
                        (task) => task.taskId == option.taskId,
                      ) === -1 && (
                        <CloseRoundedIcon
                          className={classes.delete_button}
                          onClick={(e) => {
                            ondeleteTaskHandler(e, option);
                          }}
                        />
                      )}
                  </Grid>
                </Grid>
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <div className={classes.text_input}>
                <TaskInputField params={params} maxLength={40} />
                <span className={classes.counter_input}>{counterText()}</span>
              </div>
            )}
          />
        </React.Fragment>
      )}
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={onDeleteConfirmation}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={t('common.delete')}
        showActionButton={confirmationBoxInfo.showActionButton}
      />
    </React.Fragment>
  );
};

export default TaskPicker;
