export const userRoles = {
  employee: 'Employee',
  orgAdmin: 'Administrator',
  orgOwner: 'Owner',
  teamApprover: 'Approver',
  teamManager: 'Manager',
  outsideCollaborator: 'Outside Collaborator',
  user: 'User',
  personal: 'Personal',
};

export const authTypes = {
  ACTION: 'action',
  PAGE: 'page',
};

export const organizationComponentPermissions = {
  ADD_MEMBERS: 'organization/add_members',
  VIEW_EMPLOYEE_PAGES: 'organization/view_org_emp_pages',
  VIEW_ADMIN_PAGES: 'organization/view_admin_pages',
  VIEW_ORG_INFO: 'organization/view_org_info',
  VIEW_ORG_MEMBERS: 'organization/view_org_members',
  VIEW_ORG_PROJECTS: 'organization/view_org_proj',
  VIEW_ORG_TEAMS: 'organization/view_org_teams',
};

export const permissionkey = {
  dashboard: 'dashboard.read',
  me: 'me.read',
  meTimesheet: 'meTimesheet.read',
  meLeave: 'meLeave.read',
  empTimesheet: 'empTimesheet.read',
  empLeave: 'empTimesheet.read',
  we: 'we.read',
  weLeave: 'weLeave.read',
  organization: 'organization.read',
  orgMyTeamsProject: 'orgMyTeamsProject.read',
  orgMyTeammates: 'orgMyTeammates.read',
  orgInformation: 'orgInformation.read',
  orgAllMembers: 'orgAllMembers.read',
  orgAllProject: 'orgAllProject.read',
  orgMeProject: 'orgMeProject.read',
  orgAllTeams: 'orgAllTeams.read',
  weTimesheet: 'weTimesheet.read',
};

export const permissionList = () => {
  const roles = [
    {
      key: permissionkey.dashboard,
      access: false,
      route: '/dashboard',
    },
    {
      key: permissionkey.me,
      access: false,
      route: '/me',
    },
    {
      key: permissionkey.meTimesheet,
      access: false,
      route: '/me/timesheet',
    },
    {
      key: permissionkey.meLeave,
      access: false,
      route: '/me/leave',
    },
    {
      key: permissionkey.empTimesheet,
      access: false,
      route: '/timesheet',
    },
    {
      key: permissionkey.empLeave,
      access: false,
      route: '/leave',
    },
    {
      key: permissionkey.we,
      access: false,
      route: '/we',
    },
    {
      key: permissionkey.weLeave,
      access: false,
      route: '/we/leave-management',
    },
    {
      key: permissionkey.organization,
      access: false,
      route: '/organization',
    },
    {
      key: permissionkey.orgMyTeamsProject,
      access: false,
      route: null,
    },
    {
      key: permissionkey.orgMyTeammates,
      access: false,
      route: null,
    },
    {
      key: permissionkey.orgInformation,
      access: false,
      route: null,
    },
    {
      key: permissionkey.orgAllMembers,
      access: false,
      route: null,
    },
    {
      key: permissionkey.orgAllProject,
      access: false,
      route: null,
    },
    {
      key: permissionkey.orgAllTeams,
      access: false,
      route: null,
    },
    {
      key: permissionkey.weTimesheet,
      access: false,
      route: '/we/team-timesheet',
    },
    {
      key: permissionkey.orgMeProject,
      access: false,
      route: null,
    },
  ];
  return roles;
};

const checkRoles = (roles, role) => {
  return roles.find((f) => f.toLowerCase() === role.toLowerCase())
    ? true
    : false;
};

export const getRolesAccess = (roles) => {
  const permissions = permissionList();
  const assignPermission = permissions.map((element) => {
    element.access = false;
    if (
      checkRoles(roles, userRoles.employee) ||
      checkRoles(roles, userRoles.outsideCollaborator) ||
      checkRoles(roles, userRoles.user) ||
      checkRoles(roles, userRoles.personal)
    ) {
      switch (element.key) {
        case permissionkey.me:
          element.access = false;
          break;
        case permissionkey.meLeave:
          element.access = false;
          break;
        case permissionkey.meTimesheet:
          element.access = false;
          break;
        case permissionkey.we:
          element.access = false;
          break;
        case permissionkey.weLeave:
          element.access = false;
          break;

        case permissionkey.orgAllMembers:
          element.access = false;
          break;
        case permissionkey.orgAllProject:
          element.access = false;
          break;
        case permissionkey.orgAllTeams:
          element.access = false;
          break;
        default:
          element.access = true;
          break;
      }
    }

    if (
      checkRoles(roles, userRoles.teamManager) ||
      checkRoles(roles, userRoles.teamApprover)
    ) {
      switch (element.key) {
        case permissionkey.orgAllTeams:
          element.access = false;
          break;
        case permissionkey.empLeave:
          element.access = false;
          break;
        case permissionkey.empTimesheet:
          element.access = false;
          break;
        default:
          element.access = true;
          break;
      }
    }

    if (
      checkRoles(roles, userRoles.orgAdmin) ||
      checkRoles(roles, userRoles.orgOwner)
    ) {
      switch (element.key) {
        case permissionkey.empLeave:
          element.access = false;
          break;
        case permissionkey.empTimesheet:
          element.access = false;
          break;
        default:
          element.access = true;
          break;
      }
    }
    return element;
  });

  return assignPermission;
};

export const checkKeywordsExist = (array, keywords) => {
  if (array) {
    const lowerCaseArray = array.map((item) => item.toLowerCase());
    const lowerCaseKeywords = keywords.map((keyword) => keyword.toLowerCase());
    return lowerCaseKeywords.every((keyword) => lowerCaseArray.includes(keyword));
  } else {
    return false
  }
};