import React from "react";
import classes from "./ColorPalette.module.scss";
import Typography from "@mui/material/Typography";

const primaryPallete = [
  {
    name: "Primary / Main",
    sample: "#00B8D4",
  },
  {
    name: "Primary / Light",
    sample: "#62EBFF",
  },
  {
    name: "Primary / Dark",
    sample: "#0088A3",
  },
  {
    name: "Primary / Background",
    sample: "#EBF9FC",
  },
];

const secondaryPallete = [
  {
    name: "Secondary / Main",
    sample: "#072E36",
  },
  {
    name: "Secondary / Light",
    sample: "#34575F",
  },
  {
    name: "Secondary / Dark",
    sample: "#00040F",
  },
  {
    name: "Secondary / Background",
    sample: "#ECEFEF",
  },
];

const textPallete = [
  {
    name: "Light / Text / Primary",
    sample: "#212121",
  },
  {
    name: "Light / Text / Secondary",
    sample: "#666666",
  },
  {
    name: "Light / Text / Disabled",
    sample: "#9E9E9E",
  },
];

const errorPallete = [
  {
    name: "Error / Main",
    sample: "#F44336",
  },
  {
    name: "Error / Light",
    sample: "#FF7961",
  },
  {
    name: "Error / Dark",
    sample: "#B71C1C",
  },
  {
    name: "Error / Background",
    sample: "#FDECEB",
  },
  {
    name: "Error / Content",
    sample: "#621B16",
  },
];

const warningPallete = [
  {
    name: "Error / Main",
    sample: "#FFAB00",
  },
  {
    name: "Error / Light",
    sample: "#FFCA28",
  },
  {
    name: "Error / Dark",
    sample: "#FF8F00",
  },
  {
    name: "Error / Background",
    sample: "#FFF5E6",
  },
  {
    name: "Error / Content",
    sample: "#663D00",
  },
];

const infoPallete = [
  {
    name: "Info / Main",
    sample: "#0091EA",
  },
  {
    name: "Info / Light",
    sample: "#64C1FF",
  },
  {
    name: "Info / Dark",
    sample: "#004B89",
  },
  {
    name: "Info / Background",
    sample: "#E9F5FE",
  },
  {
    name: "Info / Content",
    sample: "#0D3C61",
  },
];

const actionPallete = [
  {
    name: "Dark / Action / Disabled",
    sample: "#BDBDBD",
  },
  {
    name: "Dark / Action / Hover",
    sample: "#F5F5F5",
  },
  {
    name: "Dark / Action / Selected",
    sample: "#EBEBEB",
  },
];

const successPallete = [
  {
    name: "Success / Main",
    sample: "#67E36C",
  },
  {
    name: "Success / Light",
    sample: "#8AEF8E",
  },
  {
    name: "Success / Dark",
    sample: "#59C65D",
  },
  {
    name: "Success / Background",
    sample: "#EDF7EE",
  },
  {
    name: "Success / Content",
    sample: "#1E4620",
  },
];

const otherPallete = [
  {
    name: "Misc / Other / Backdrop",
    sample: "#7F7F7F",
  },
  {
    name: "Misc / Other / Divider",
    sample: "#E0E0E0",
  },
  {
    name: "Misc / Other / Snackbar",
    sample: "#323232",
  },
  {
    name: "Misc / Other / Outline Border",
    sample: "#FFFFFF",
  },
];
const ColorPalette = () => {
  return (
    <React.Fragment>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography variant="h5">Primary</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {primaryPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="h5">Secondary</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {secondaryPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column} style={{ paddingBottom: 100 }}>
          <Typography variant="h5">Text</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {textPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography variant="h5">Error</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {errorPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="h5">Warning</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {warningPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="h5">Info</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {infoPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.column}>
          <Typography variant="h5">Action</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {actionPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="h5">Success</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {successPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="h5">Other</Typography>
          <hr />
          <div className={classes.divTable}>
            <div className={classes.divTableBody}>
              <div className={classes.divTableRow}>
                <div>
                  <Typography variant="subtitle2">Cell Header</Typography>
                </div>
                <div>
                  <Typography variant="subtitle2">Sample</Typography>
                </div>
              </div>
              {otherPallete.map((color) => (
                <div className={classes.divTableRow} key={color.name}>
                  <div className={classes.name}>
                    <Typography variant="subtitle2">{color.name}</Typography>
                  </div>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: color.sample }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ColorPalette;
