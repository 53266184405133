import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

const SelectUI = () => {
  const [option, setOption] = useState("");

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6">Standard</Typography>
      </Box>
      <Box sx={{ m: 2 }}>
        <Typography variant="subtitle2">
          Default*Selected Value Inactive
        </Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Options
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={option}
            onChange={handleChange}
            label="Age"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Op1"}>Option1</MenuItem>
            <MenuItem value={"Op2"}>Option2</MenuItem>
            <MenuItem value={"Op3"}>Option3</MenuItem>
          </Select>
          <FormHelperText>Helper Text/Hint</FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SelectUI;
