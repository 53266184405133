import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./Loader.module.scss";
import meeweeicon from "../../../assets/meewee-icon-logo.svg";

const Loader = (props) => {
  document.title = props.title;
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      className={classes.loader_container}
    >
      <Grid item>
        <div className={classes.progress_bar_container}>
          <CircularProgress color="primary" size="108px"></CircularProgress>
          <div className={classes.meewee_icon}>
            <img src={meeweeicon} alt="meewee icon"></img>
          </div>
        </div>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{props.text}</Typography>
      </Grid>
    </Grid>
  );
};

export default Loader;
