import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import classes from './LeaveTypeCard.module.scss';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getLeaves } from '../../service/leaveService';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import LeavepieChart from './leaveTypePieChart/LeaveTypePieChart';
import { leaveProperties, leaveStatusType } from '../LeaveProperties.config';
import CardSkeleton from '../../../ui/skeleton/cardSkeleton/CardSkeleton';
import { util } from '../../../../utilities/utilFunc';
import { useTranslation } from 'react-i18next';

const LeaveTypeCard = ({ onClickRequestLeave, selectedYear, leaveTypes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = React.useState(true);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    setIsDataLoaded(true);
    await dispatch(getLeaves());
    setIsDataLoaded(false);
  };
  return (
    <>
      <div>
        <div>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickRequestLeave(true, 'leave', {}, false, '', true)}
            className={classes.request_button}
          >
            {t('me-leave.request-leave')}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickRequestLeave(true, 'sickness', {}, false, '', true)}
            className={classes.request_button}
          >
            {t('me-leave.report-sickness')}
          </Button>
        </div>
      </div>
      {leaveTypes.map((item) => {
        const { typeLabel, buttonLabel, ButtonIcon } =
          leaveProperties(t)[item.type] || leaveProperties(t).Others;

        return (
          <Card
            sx={{
              width: 810,
              height: 156,
              boxShadow: 'none',
              border: '1px solid #E0E0E0',
              borderRadius: '4px',
            }}
            key={item.leaveTypeid}
          >
            {isDataLoaded ? (
              <CardSkeleton />
            ) : (
              <Box>
                <h3 className={classes.vacation_type_title}>{item?.label}</h3>

                <div className={classes.leave_type_card_container}>
                  <LeavepieChart
                    leaveType={typeLabel}
                    leaveData={item}
                    typeConfig={leaveStatusType[typeLabel].filter(
                      (f) => f.key !== 'entitlement',
                    )}
                  />
                  <div className={classes.leave_status_grp}>
                    <div>
                      <RenderLeaveTypeStatus
                        type={typeLabel}
                        leaveData={item}
                        leaveId={item.leaveTypeid}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      color="secondary"
                      // variant="contained"
                      startIcon={<AddIcon />}
                      onClick={onClickRequestLeave(
                        true,
                        typeLabel,
                        {},
                        false,
                        item.leaveTypeid,
                      )}
                      className={classes.report_button}
                    >
                      {buttonLabel}
                    </Button>
                  </div>
                </div>
              </Box>
            )}
          </Card>
        );
      })}
    </>
  );
};

function RenderLeaveTypeStatus({ type, leaveData, leaveId }) {
  const { t } = useTranslation();
  const uf = util();

  return (
    <div className={classes.status_container}>
      {leaveStatusType[type].map((data) => {
        const value = leaveData ? leaveData[data.key] : null;

        const typeValue =
          isNaN(value) || uf.isNullOrUndefined(value)
            ? value
            : Math.floor(value * 100) / 100;
        let elem = <>-</>;

        switch (typeValue) {
          case 'unlimited':
            elem = (
              <>
                <AllInclusiveIcon className={classes.entitlement_icon} />
              </>
            );
            break;
          default:
            elem = <>{isNaN(typeValue) ? 0 : typeValue}</>;
        }
        return (
          <>
            {uf.isNullOrUndefined(typeValue) ? null : (
              <li
                className={classes.leave_status_title}
                key={`${type}_${leaveId}`}
              >
                <li className={classes.dataLI}> {elem}</li>{' '}
                <div className={classes.badge_container}>
                  <div
                    className={`${classes[data.key]} ${classes.badge}`}
                  ></div>
                  {t(`me-leave.${data.label.toLowerCase()}`)}
                </div>
              </li>
            )}
          </>
        );
      })}
    </div>
  );
}

export default LeaveTypeCard;
