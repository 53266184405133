import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  authToken: "",
  isAuthenticated: false,
  loginType: "",
};

const authContextSlice = createSlice({
  name: "authContext",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.username = action.payload.username;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload.authToken;
      state.isAuthenticated = true;
      state.loginType = action.payload.loginType;
    },
    reset: (state) => {
      // From here we can take action only at this "authContext" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
  },
});

export const { setCredentials, setAuthToken, reset } = authContextSlice.actions;

export default authContextSlice.reducer;
