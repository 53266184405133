import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const FormButtonLoading = (props) => {
  const onClickHandler = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };
  return (
    <LoadingButton
      id={props.id}
      fullWidth={props.fullWidth}
      size="large"
      color={props.color}
      onClick={onClickHandler}
      loading={props.loading}
      loadingPosition={props.loadingPosition}
      variant="contained"
      startIcon={<></>}
      sx={{ textTransform: "none" }}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.loading ? props.loadingIndicator : props.label}
    </LoadingButton>
  );
};

export default FormButtonLoading;
