import React, { useState, useRef } from 'react';
import classes from './MeOrgProjects.module.scss';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import paths from '../../../../../routing/Constants';

const { ORGANIZATION_PATHS } = paths;
const MeOrgProject = () => {
  const { selectedOrg } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const onNavigateToManageMeOrgProject = () => {
    const [, _, orgPath, orgId, myProjPath] = location.pathname.split('/');
    const pathToManageTeam = `/${_}/${orgPath}/${orgId}/${myProjPath}/${ORGANIZATION_PATHS.ALL_PROJECTS_PATHS.MANAGE}`;
    navigate(pathToManageTeam);
  };

  const [severity, setSeverity] = useState('info');
  const successSnackbarRef = useRef(null);
  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const [selectedItem, setSelectedtIem] = useState();
  const [isEdit, setIsEdit] = useState();

  const onCreateHandler = () => {
    setIsEdit(false);
    setSelectedtIem(null);
    onNavigateToManageMeOrgProject();
  };
  const onManageMeProjectHandler = (item) => {
    setIsEdit(true);
    setSelectedtIem(item);
    onNavigateToManageMeOrgProject();
  };

  return (
    <>
      <div className={classes.fullwidth}>
        <Outlet
          context={{
            selectedOrg,
            selectedItem,
            isEdit,
            cbSetSeverity: setSeverity,
            cbOnCreateHandler: onCreateHandler,
            cbOnManageMeProjectHandler: onManageMeProjectHandler,
            cbShowSuccessSnackBar: showSuccessSnackBar,
          }}
        />
      </div>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </>
  );
};

export default MeOrgProject;
