import { Alert } from "@mui/material";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import React from "react";

const FeatureStatusNotification = (props) => {
  return (
    <Alert
      icon={<ConstructionRoundedIcon fontSize="inherit" />}
      severity="info"
    >
      {props.message}
    </Alert>
  );
};

export default FeatureStatusNotification;
