import React from 'react';
import { useLottie } from 'lottie-react-with-react-17';
import animationData from './../../../assets/lotties/empty-view';
import classes from './EmptyState.module.scss';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmptyState = (props) => {
  const { t } = useTranslation();
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
    width: '300',
  };
  const { View } = useLottie(options);

  return (
    <>
      <div className={classes.empty_state_wrapper}>{View}</div>
      <div className={classes.empty_state_text}>
        <Typography variant="subtitle1" fontWeight="fontWeightSemiBold">
          {t('me-leave.you-dont-have-any-leave-entitlememt-for-this-year')}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className={classes.empty_state_details}
        >
          {t(
            'me-leave.please-wait-for-an-update-from-your-organization-about-the-activation',
          )}
        </Typography>
      </div>
    </>
  );
};

export default EmptyState;
