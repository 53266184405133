export function formatRegDurationNumber(regDuration) {
  const numberRegDuration = +regDuration;
  if (isNaN(numberRegDuration)) {
    return 'hr';
  } else if (Number.isInteger(numberRegDuration)) {
    return numberRegDuration;
  } else {
    const roundedNumber = numberRegDuration.toFixed(2);
    return parseFloat(roundedNumber).toString();
  }
}

const MINS_IN_A_DAY = 1440;
export const isTotalHoursInTheDayLessThanADay = (
  totalHoursPerDayOfTheWeekObject,
  reg,
  timeOffset,
) => {
  const { taskReg, regDate } = reg;
  const regDuration = Number(taskReg.regDuration);
  let currentTotalHoursOnSelectedDate =
    totalHoursPerDayOfTheWeekObject[regDate];

  currentTotalHoursOnSelectedDate -= timeOffset || 0;

  const sumTime = currentTotalHoursOnSelectedDate + regDuration;
  return sumTime <= MINS_IN_A_DAY;
};

export const isRegDurationValid = (registrationObj) => {
  const regDuration = Number(registrationObj?.taskReg?.regDuration);
  const isRegDurationNumericWithNoDecimal = /^[0-9]+$/.test(regDuration);
  const isRegDurationZeroOrNegativeValue = regDuration <= 0;
  const isRegDurationARealNumber = !isNaN(regDuration);
  const isRegDurationWholeNumber = regDuration === Math.round(regDuration);

  return (
    isRegDurationNumericWithNoDecimal &&
    !isRegDurationZeroOrNegativeValue &&
    isRegDurationWholeNumber &&
    isRegDurationARealNumber
  );
};
