import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { FormHelperText } from '@mui/material';

const PasswordTextBox = (props) => {
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const onChangeHandler = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const onBlurHandler = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onKeyPressHandler = (event) => {
    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };

  let isError = props.isError ? props.isError : false;
  let isRequired = props.required ? props.isRequired : false;
  let helpertext = isError ? props.errorText : ' ';
  return (
    <Box>
      <FormControl fullWidth={true} variant="standard">
        <InputLabel htmlFor={props.id} error={isError}>
          {props.label}
        </InputLabel>
        <Input
          id={props.id}
          error={isError}
          required={isRequired}
          autoComplete="new-password"
          value={null}
          type={values.showPassword ? 'text' : 'password'}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          onKeyPress={onKeyPressHandler}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {isError ? (
          <FormHelperText id="my-helper-text" error={true}>
            {helpertext}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default PasswordTextBox;
