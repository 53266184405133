import React from "react";

import { useSelector } from "react-redux";
import classes from "./Dashboard.module.scss";
import "../../../assets/styles/dashboard.scss";

const meeweeDashboard = require("meewee/mwebash.core");

const Dashboard = () => {
  const userContext = useSelector((state) => state.userContext);
  return (
    <div className={`${classes.dashboard_container} dashboard-class`}>
      <meeweeDashboard.dashboard username={userContext.userFirstName} />
    </div>
  );
};

export default Dashboard;
