import httpRequestHandler from '../../../utilities/httpRequestHandler';
import { createTransitData } from '../../../utilities/transitJsUtils';

export const postValidateOldPassword = async (value) => {
  try {
    let requestParamObj = {
      field: { value: 'old-pw', isKeyword: false },
      'old-pw': { value: value.oldPassword, isKeyword: false },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postRequest('/validate'),
      mapObjToTransit,
    );
    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const postChangePassword = async (value) => {
  try {
    let requestParamObj = {
      'old-pw': { value: value.oldPassword, isKeyword: false },
      'new-pw': { value: value.newPassword, isKeyword: false },
      'retype-pw': { value: value.newPassword, isKeyword: false },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postRequest(''),
      mapObjToTransit,
    );
    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const postRequest = (url) => {
  var request = {
    url: `change-password${url}`,
    method: 'POST',
    transformResponse: [
      function (data) {
        return data;
      },
    ],
    hasAuth: true,
    contentTypeHeader: 'application/transit+json',
    requestParamType: 'transit-json',
  };
  return request;
};
