import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import { addProject } from '../../../../../store/registrationContextSlice';
import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import { createMeProject } from '../../../../orgmanagement/service/orgmanagementService';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import { getListofProject } from '../../../service/timeregistrationService';
import { projectStatusName } from '../../../../../utilities/constantUtils';
import { useTranslation } from 'react-i18next';

const filter = createFilterOptions();
const ProjectPicker = (props) => {
  const { t } = useTranslation();
  const projectsWithRegistration = useSelector((state) =>
    state.registrationContext.registrations.filter(
      (proj) => proj.projectOrganizationId === props.org,
    ),
  );
  const projList = useSelector((state) =>
    state.projectContext.projects.filter(
      (proj) => proj.projectOrganizationId === props.org,
    ),
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const timeset = useSelector((state) => state.projectContext.timeset);
  const [value, setValue] = useState(null);
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [dialogContentTitle, setDialogContentTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [projectName, setProjectName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [severity, setSeverity] = useState('info');
  const successSnackbarRef = useRef(null);

  const openDialogBox = (title) => {
    setProjectName(title);
    setOpenConfirmationBox(true);
    setDialogContentTitle(
      `${t('me.would-you-like-to-create')} "${title}" ${t(
        'me.as-your-personal-project',
      )}?`,
    );
    setDialogContent(
      `${t(
        'me.only-you-can-see-and-select-this-project-under-your-personal-organization-called',
      )} "${userFirstName}"`,
    );
  };

  const selectProjectHandler = (projInfo) => {
    if (projInfo.inputValue) {
      openDialogBox(projInfo.inputValue);
    } else if (projInfo != null) {
      assignProject(projInfo);
    }
  };

  const assignProject = (projInfo) => {
    let copySelectedProj = Object.assign({}, projInfo);
    copySelectedProj.projectTasks = []; // remove task list before adding to registration slice
    let taskPlaceHolder = {
      taskId: -1,
      taskProjectId: copySelectedProj.projectId,
      taskTitle: '',
      taskRegistrations: {},
      taskTotalRegTime: 0,
    };
    copySelectedProj.projectTasks.push(taskPlaceHolder); //add the placeholder task for the task picker
    dispatch(addProject({ project: copySelectedProj }));
    setValue(null);
  };

  const onButtonClick = () => {
    setClick(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => {
      setClick(false);
    }, 100);
  };

  const getOptions = () => {
    let projWithRegMap = projectsWithRegistration.map((proj) => {
      return proj.projectId;
    });

    return projList.filter(
      (proj) =>
        !projWithRegMap.includes(proj.projectId) &&
        proj.projectStatus !== projectStatusName.completed &&
        proj.projectStatus !== projectStatusName.draft &&
        proj.projectStatus !== projectStatusName.paused &&
        proj.projectStatus !== projectStatusName.cancelled,
    );
  };

  const renderDetailsOption = (props, option) => {
    var result = (
      <li {...props} key={option.projectId ? option.projectId : -1}>
        <Grid
          container
          spacing={1}
          columns={16}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={14}>
            <Typography variant="body2">
              {option.title ? option.title : option.projectName}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
    return result;
  };

  const handleAction = () => {
    postData();
  };
  const postData = async () => {
    var item = {
      title: projectName,
      startDate: new Date(),
      endDate: null,
      status: 'project.status/active',
    };
    setIsLoading(true);
    var response = await createMeProject(item);
    if (response.status === 200) {
      var result = await dispatch(getListofProject(timeset));
      var project = result.find((f) => f.projectName === projectName);
      setTimeout(() => {
        assignProject(project);
        setIsLoading(false);
        setOpenConfirmationBox(false);
        setSeverity('info');
        showSuccessSnackBar(t('me.new-project-created'));
      }, 1000);
    } else {
      setIsLoading(false);
      setSeverity('error');
      showSuccessSnackBar(t('common.error-processing-request'));
    }
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };
  const onKeyPressHrModal = (e, value) => {
    if (e.key === 'Enter') {
      if (e.target.value) {
        openDialogBox(e.target.value);
      }
    }
  };

  const focusInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };

  return (
    <React.Fragment>
      {!click && (
        <div className="">
          <Button
            size="small"
            onClick={onButtonClick}
            className="projPickerButton"
          >
            <AddCircleIcon
              sx={{
                marginRight: '5px',
                color: '#0091EA',
                width: '16px',
                height: '16px',
              }}
            />
            <Typography variant="body2" color="info.main" component="p">
              {userId === props.org
                ? t('me.add-a-personal-project')
                : t('me.add-a-project')}
            </Typography>
          </Button>
        </div>
      )}

      {click && (
        <React.Fragment>
          <ClickAwayListener onClickAway={onBlurHandler}>
            {userId === props.org ? (
              <Autocomplete
                sx={{ width: '250px' }}
                className="projPickerInput"
                id="project-picker"
                options={getOptions()}
                open={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('me.personal-project-title')}
                    variant="standard"
                    autoFocus={true}
                  />
                )}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.projectName;
                }}
                onChange={(e, newValue) => {
                  setValue(newValue);
                  selectProjectHandler(newValue);
                }}
                onKeyDown={(e, value) => onKeyPressHrModal(e, value)}
                clearOnBlur={true}
                blurOnSelect={true}
                value={value}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `${t('me.create-new-project')} "${
                        params.inputValue
                      }"`,
                    });
                  }
                  return filtered;
                }}
                renderOption={(props, option) =>
                  renderDetailsOption(props, option)
                }
              />
            ) : (
              <Autocomplete
                sx={{ width: '250px' }}
                className="projPickerInput"
                id="project-picker"
                options={getOptions()}
                open={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    variant="standard"
                    autoFocus={true}
                  />
                )}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.projectName;
                }}
                onChange={(e, newValue) => {
                  setValue(newValue);
                  selectProjectHandler(newValue);
                }}
                clearOnBlur={true}
                blurOnSelect={true}
                value={value}
              />
            )}
          </ClickAwayListener>
        </React.Fragment>
      )}

      <ConfirmationDialogBox
        open={openConfirmationBox}
        title={dialogContentTitle}
        content={dialogContent}
        handleClose={() => setOpenConfirmationBox(false)}
        handleAction={handleAction}
        closeLabelText={t('common.canlce')}
        actionLabelText={t('me.create-projects')}
        showActionButton={true}
        isLoading={isLoading}
      />
      <div>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </React.Fragment>
  );
};

export default ProjectPicker;
