import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoAlert from '../../ui/alerts/infoAlert/InfoAlert';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getListOfPendingInvitations } from '../service/orgmanagementService';
import classes from '../component/orgManagement/OrgManagement.module.scss';

const MemberInfo = ({
  onClickHandler,
  orgId,
  selectedEmploymentId,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userContext.userId);
  const [selectedOrgId, setSelectedOrgId] = useState(undefined);

  useEffect(() => {
    if (orgId !== selectedOrgId && orgId) {
      const isPersonalOrg = selectedEmploymentId === userId;
      isPersonalOrg
        ? dispatch(getListOfPendingInvitations())
        : dispatch(getListOfPendingInvitations(selectedEmploymentId, isAdmin));

      setSelectedOrgId(orgId);
    }
  }, [orgId, selectedOrgId, selectedEmploymentId, isAdmin]);

  return (
    <Fragment>
      <div className={`${classes.info_alert} infoAlertInvitation`}>
        <InfoAlert
          id="view-invitation-info-alert"
          message={`There are pending invitation for you to join a team. `}
          // message={`There are pending  ${userInvitationInfo.} invitation for you to join a team. `}
          actionButton={
            <Button
              color="inherit"
              size="small"
              onClick={() => onClickHandler(true)}
            >
              View here{' '}
              <ArrowForwardIcon
                sx={{ fontSize: '17px', fontWeight: 'bold', margin: '0 5px' }}
              />
            </Button>
          }
        />
      </div>
      <div></div>
    </Fragment>
  );
};

export default MemberInfo;
