import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import classes from './CreateMeOrgProjects.module.scss';
import { Button } from '@mui/material';
import InfoTab from './InfoTab/InfoTab';
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';

import {
  createMeProject,
  updateMeProject,
} from '../../../../service/orgmanagementService';
import { useSelector } from 'react-redux';
import ConfirmationDialogBox from '../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';

import {
  LeaveTypeAll,
  DialogActionsType,
} from '../../../../../../utilities/constantUtils';
import moment from 'moment';
import { dateFormats } from '../../../../../../utilities/dateUtils';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateMeOrgProjects = () => {
  const { t } = useTranslation();
  const statusText = {
    status1: t(
      'organization.this-project-is on-going-you-can-register-time-for-this-project',
    ),
    status2: t('organization.you-cannot-register-time-for-this-project'),
  };
  const { selectedItem, isEdit, cbSetSeverity, cbShowSuccessSnackBar } =
    useOutletContext();
  const navigate = useNavigate();
  const usersList = useSelector((state) => state.orgContext.usersList);
  const [status, setStatus] = useState('project.status/active');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [projectTitle, setProjectTitle] = useState();
  const [orgWideText, setOrgWideText] = useState('');
  const [statusLabel, setStatusLabel] = useState(statusText.status1);
  const [activeDialog, setActiveDialog] = useState(false);
  const [onChangeUpdate, setOnChangeUpdate] = useState(false);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: t(
      'organization.would-you-like-to-save-this-new-personal-project',
    ),
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    submitLabelText: t('common.save'),
  });

  const navigateBack = () => navigate(-1);

  useEffect(() => {
    loadSelectedData();
  }, [selectedItem]);

  const loadSelectedData = () => {
    if (selectedItem) {
      setProjectTitle(selectedItem?.name);
      setStatus('project.status/' + selectedItem?.status);
      loadTestStatus('project.status/' + selectedItem?.status);
      setEndDate(selectedItem?.end ? selectedItem?.end : null);
      setStartDate(selectedItem?.start ? selectedItem?.start : null);
    } else {
      setIsLoading(false);
    }
  };

  const onProjectTitle = (value) => {
    if (value) {
      setProjectTitle(value.target.value);
      setOnChangeUpdate(true);
    }
  };

  const onChangeDate = (value, field) => {
    switch (field) {
      case 0:
        setStartDate(new Date(value));
        break;
      default:
        setEndDate(new Date(value));
        break;
    }
    setOnChangeUpdate(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (activeDialog === 0) {
      if (isEdit) {
        putData();
      } else {
        postData();
      }
    } else {
      navigateBack();
    }
  };

  const postData = async () => {
    var item = {
      title: projectTitle,
      startDate: startDate,
      endDate: endDate,
      status: status,
    };
    setIsLoading(true);

    var response = await createMeProject(item);
    if (response.status === 200) {
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        cbSetSeverity('info');
        cbShowSuccessSnackBar(t('organization.new-personal-project-created'));
      }, 1000);
    } else {
      setIsLoading(false);
      cbSetSeverity('error');
      cbShowSuccessSnackBar(t('organization.error-while-processing-request'));
    }
  };

  const putData = async () => {
    var item = {
      id: selectedItem?.id,
      title: projectTitle,
      startDate: startDate,
      endDate: endDate,
      status: status,
    };
    setIsLoading(true);
    var response = await updateMeProject(item);
    if (response.status === 200) {
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        cbSetSeverity('info');
        cbShowSuccessSnackBar(t('organization.personal-project-updated'));
      }, 1000);
    } else {
      setIsLoading(false);
      cbSetSeverity('error');
      cbShowSuccessSnackBar(t('organization.error-while-processing-request'));
    }
  };
  const onChangeStatus = (e, value) => {
    loadTestStatus(value.props.value);
    setStatus(value.props.value);
    setOnChangeUpdate(true);
  };

  const loadTestStatus = (value) => {
    setStatusLabel(statusText.status2);
    if (value === 'project.status/active') {
      setStatusLabel(statusText.status1);
    }
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
    }));
  };

  const confirmSubmissionHandler = async () => {
    setActiveDialog(0);
    setIsLoading(false);
    var value = {};
    if (isEdit) {
      value = {
        title: t(
          'organization.would-you-like-to-save-your-changes-on-this-project',
        ),
        description: t(
          'organization.the-changes-will-be-effective-immediately-after-saving',
        ),
        type: t('organization.save-changes'),
        actionType: DialogActionsType.success,
        leaveType: LeaveTypeAll.approveALL,
      };
    } else {
      value = {
        title: t('organization.would-you-like-to-save-this-new-project'),
        description: t(
          'organization.this-project-will-be-available-to-all-its-members-when-tagged-as-on-going-otherwise-in-will-remain-as-hidden',
        ),
        type: t('common.save'),
        actionType: DialogActionsType.success,
        leaveType: LeaveTypeAll.approveALL,
      };
    }

    dialogBox(value, t('common.cancel'));
  };

  const [disabledBtn, setDisabledBtn] = useState(true);

  useEffect(() => {
    if (projectTitle && status && startDate) {
      if (onChangeUpdate) {
        if (isEdit) {
          var eDate = selectedItem?.end ? selectedItem?.end : null;
          if (
            selectedItem?.name !== projectTitle ||
            `project.status/${selectedItem?.status}` !== status ||
            moment(selectedItem?.start).format(dateFormats.default) !==
              moment(startDate).format(dateFormats.default) ||
            moment(eDate).format(dateFormats.default) !==
              moment(endDate).format(dateFormats.default)
          ) {
            setDisabledBtn(false);
          } else {
            setDisabledBtn(true);
          }
        } else {
          setDisabledBtn(false);
        }
      }
    } else {
      setDisabledBtn(true);
    }
  }, [projectTitle, status, startDate, endDate]);

  const discardHandler = async () => {
    setActiveDialog(1);
    if (onChangeUpdate) {
      setIsLoading(false);
      var value = {
        title: t('organization.oops-you-have-unsaved-changes'),
        description: t(
          'organization.discarding-these-changes-will-be-removed-permanently',
        ),
        type: t('organization.discard').toUpperCase(),
        actionType: DialogActionsType.error,
        leaveType: LeaveTypeAll.approveALL,
      };
      dialogBox(value, t('common.close'));
    } else {
      navigateBack();
    }
  };

  return (
    <>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={onSubmit}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isLoading={isLoading}
      />
      <div container className={classes.bck_btn}>
        <Button
          color="inherit"
          size="small"
          onClick={() => discardHandler()}
          className={classes.back_btn}
        >
          <KeyboardBackspaceSharpIcon
            sx={{ fontSize: '17px', margin: '0 5px' }}
          />
          {t('common.back')}
        </Button>
      </div>
      <div container className={classes.invite_card}>
        <div className={classes.header_con}>
          <div className={classes.flexContainer}>
            <div>
              <Typography className={classes.name}>
                {isEdit
                  ? `${t('organization.manage-project')} ${selectedItem?.name}`
                  : t('organization.create-a-new-personal-project')}
              </Typography>
              <Typography className={classes.desc}>
                {t('organization.update-details-of-this-project')}
              </Typography>
            </div>
            <div className={classes.invite_button}>
              <div className="padR10">
                <Button
                  color="secondary"
                  size="medium"
                  className={classes.button}
                  onClick={() => discardHandler()}
                >
                  {t('organization.discard')}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={confirmSubmissionHandler}
                  disabled={disabledBtn}
                >
                  {isEdit ? t('common.save-changes') : t('common.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tabs}>
          <InfoTab
            usersList={usersList}
            onChangeStatus={onChangeStatus}
            startDate={startDate}
            endDate={endDate}
            onChangeDate={onChangeDate}
            projectTitle={projectTitle}
            status={status}
            onProjectTitle={onProjectTitle}
            orgWideText={orgWideText}
            statusLabel={statusLabel}
            selectedItem={selectedItem}
            isEdit={isEdit}
          />
        </div>
        <div className={classes.manage_details_wrapper}>
          {isEdit ? (
            <div className={classes.manage_details}>
              <div className={classes.manage_sub}>
                <Typography className={classes.sub_title}>
                  {t('organization.modified-by')}
                </Typography>
                <Typography className={classes.desc}>
                  <span>{t('organization.you')} </span>
                  {selectedItem?.updatedat &&
                    moment(selectedItem?.updatedat).format('DD-MM-YYYY')}
                </Typography>
              </div>
              <div className={classes.manage_sub}>
                <Typography className={classes.sub_title}>
                  {t('organization.created-by')}
                </Typography>
                <Typography className={classes.desc}>
                  <span>{t('organization.you')} </span>
                  {selectedItem?.createdat &&
                    moment(selectedItem?.createdat).format('DD-MM-YYYY')}
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CreateMeOrgProjects;
