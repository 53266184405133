import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { format, isWeekend } from 'date-fns';
import { dateFormats } from '../../../../../../../../utilities/dateUtils';
import { Typography } from '@mui/material';
import ProjectPicker from '../../../../../timeRegistrationPickers/projectPicker/ProjectPicker';
import {
  getTotalRegistration,
  getTotalRegistrationsPerDate,
} from '../../../../../../service/timeregistrationService';
import ProjectTable from './../projectTable/ProjectTable';
import classes from './../WeeklyTimeRegistrationList.module.scss';
import TotalCell from './../totalCell/TotalCell';

const OrgRegistrationTable = (props) => {
  const [open, setOpen] = useState(false);
  const ArrowIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  return (
    <React.Fragment>
      <TableRow className={classes.org_row}>
        <TableCell>
          <IconButton
            className={classes.arrows}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <ArrowIcon />
          </IconButton>
          <Avatar
            alt={props.org.organizations?.name}
            src="/broken-image.jpg"
            className={classes.header_icon}
          />
          <Typography variant="body2" color="white">
            {props.org.organizations?.name}
          </Typography>
        </TableCell>
        {props.dateList.map((date, dateIndex) => (
          <TableCell
            align="center"
            key={dateIndex}
            className={`${isWeekend(date) && classes.weekend_table_header}`}
          >
            <TotalCell
              color="white"
              sumOfReg={getTotalRegistrationsPerDate(
                props.filteredReg,
                format(date, dateFormats.default),
              )}
            />
          </TableCell>
        ))}
        <TableCell align="center">
          <TotalCell
            color="white"
            sumOfReg={getTotalRegistration(props.filteredReg)}
          />
        </TableCell>
      </TableRow>
      {open && (
        <React.Fragment>
          {props.filteredReg.map((proj, projIndex) => (
            <ProjectTable
              selectedOrg={props.org}
              key={projIndex}
              proj={proj}
              dateList={props.dateList}
              showSuccessSnackBarFn={props.showSuccessSnackBarFn}
              showErrorSnackBarFn={props.showErrorSnackBarFn}
            />
          ))}
          <TableRow>
            <TableCell
              colSpan={9}
              sx={{ width: '100%' }}
              className={classes.footer}
            >
              <ProjectPicker org={props.org.id} />
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const OrganizationTable = (props) => {
  const userOrgList = useSelector(
    (state) => state.userContext.userOrganizations,
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);

  const [orgList, setOrgList] = useState([]);

  useEffect(() => {
    var newList = [];
    const orgItem = {
      id: userId,
      emp: userId,
      name: `${userFirstName} ${userLastName}`,
      organizations: { id: userId, name: `${userFirstName} ${userLastName}` },
      owner: { id: userId, fname: userFirstName, lname: userLastName },
      userAuth: ['Personal'],
    };
    newList.push(orgItem);
    if (userOrgList.length > 0) {
      newList = newList.concat(userOrgList);
    }

    setOrgList(newList);
  }, [userFirstName, userId, userLastName, userOrgList]);

  const filterRegistrations = (org) => {
    let filteredReg = props.registrations.filter(
      (proj) => proj.projectOrganizationId === org.id,
    );

    return filteredReg;
  };
  return (
    <TableBody className={`${classes.allOrg_table_body} allOrgReg`}>
      {orgList.map((org, orgIndex) => (
        <React.Fragment key={orgIndex}>
          <OrgRegistrationTable
            org={org}
            filteredReg={filterRegistrations(org)}
            orgIndex={orgIndex}
            dateList={props.dateList}
            showSuccessSnackBarFn={props.showSuccessSnackBarFn}
            showErrorSnackBarFn={props.showErrorSnackBarFn}
          />
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default OrganizationTable;
