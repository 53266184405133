import React from "react";
import Alert from "@mui/material/Alert";
import classes from "./SuccessAlert.module.scss"


const SuccessAlert = (props) => {
  return (
    <div id={props.id}  >
      <Alert
        variant="outlined"
        severity="success"
        // sx={{ color: "error.main", borderColor: "error.main" }}
      >
       <span className={classes.color}>{props.message}</span> 
      </Alert>
    </div>
  );
};

export default SuccessAlert;
