import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const AlertUI = () => {
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6">Message Bar</Typography>
        <Box sx={{ m: 5 }}>
          <Box sx={{ mb: 6 }}>
            <Typography variant="subtitle2">Error</Typography>
          </Box>
          <Box sx={{ mb: 6 }}>
            <Typography variant="subtitle2">Warning</Typography>
          </Box>
          <Box sx={{ mb: 6 }}>
            <Typography variant="subtitle2">Info</Typography>
          </Box>
          <Box sx={{ mb: 6 }}>
            <Typography variant="subtitle2">Success</Typography>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Alert variant="filled" severity="error" onClose={() => {}}>
              This is an error alert — check it out!
            </Alert>
            <Alert variant="filled" severity="warning" onClose={() => {}}>
              This is a warning alert — check it out!
            </Alert>
            <Alert variant="filled" severity="info" onClose={() => {}}>
              This is an info alert — check it out!
            </Alert>
            <Alert variant="filled" severity="success" onClose={() => {}}>
              This is a success alert — check it out!
            </Alert>
          </Stack>
        </Box>
        <Box sx={{ m: 2 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Alert severity="error" onClose={() => {}}>
              This is an error alert — check it out!
            </Alert>
            <Alert severity="warning" onClose={() => {}}>
              This is a warning alert — check it out!
            </Alert>
            <Alert severity="info" onClose={() => {}}>
              This is an info alert — check it out!
            </Alert>
            <Alert severity="success" onClose={() => {}}>
              This is a success alert — check it out!
            </Alert>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Typography variant="h6">Snackbar</Typography>
          <Typography variant="subtitle2">Default</Typography>
        </Stack>
        <Box sx={{ m: 1 }}>
          <Snackbar
            sx={{ top: { xs: 300, sm: 100 } }}
            open
            autoHideDuration={6000}
            message="Snackbar"
            action={
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AlertUI;
