import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextField, Box, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import classes from './DialogChangeOwner.module.scss';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DialogChangeOwner = (props) => {
  const { t } = useTranslation();
  const selectEmployee = useRef(null);
  const onSelectEmployee = (event, item) => {
    selectEmployee.current = item;
  };

  const saveHandler = () => {
    props.onChangeOwner(selectEmployee.current);
  };
  return (
    <>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        disableEscapeKeyDown
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className={classes.dialog}
        >
          Change Owner
        </DialogTitle>
        <DialogContent className={classes.dialog}></DialogContent>
        <div className={`AutcompleteFullWith padB30 ${classes.content} `}>
          <Grid container>
            <Autocomplete
              disablePortal={false}
              id="employees"
              //value={f}
              defaultValue={null}
              //defaultValue={'All employees'}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <div className="padR10">
                    {' '}
                    <Avatar
                      className={classes.avatarNameSmall}
                      alt={option.name}
                      src="/broken-image.jpg"
                    />
                  </div>
                  {option.name}
                </Box>
              )}
              options={props.employeeList}
              variant="standard"
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('organization.select-owner')}
                  variant="standard"
                  placeholder={t('organization.enter-name')}
                />
              )}
              onChange={onSelectEmployee}
            />
          </Grid>
        </div>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            {t('common.cancel')}
          </Button>
          <Button autoFocus onClick={saveHandler}>
            {t('common.save')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DialogChangeOwner;
