import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";

const TabsUI = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Typography variant="h6">Tabs</Typography>
      <Box sx={{ m: 5 }}>
        <Box sx={{ mb: 6 }}>
          <Typography variant="subtitle2">Primary</Typography>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography variant="subtitle2">Secondary</Typography>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography variant="subtitle2">Primary</Typography>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography variant="subtitle2">Secondary</Typography>
        </Box>
      </Box>
      <Box sx={{ m: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab icon={<FavoriteIcon />} label="Tab" />
          <Tab icon={<FavoriteIcon />} />
          <Tab label="Tab" />
        </Tabs>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab icon={<FavoriteIcon />} label="Tab" />
          <Tab icon={<FavoriteIcon />} />
          <Tab label="Tab" />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabsUI;
