import React, { useState, useRef } from 'react';
import classes from './OrgTeams.module.scss';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import ErrorSnackbar from '../../../../ui/snackbar/errorSnackbar/ErrorSnackbar';
import { getListofMembersPerTeam } from '../../../service/orgmanagementService';
import { useDispatch } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import paths from '../../../../../routing/Constants';

const { ORGANIZATION_PATHS } = paths;

const OrgTeams = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOrg } = useOutletContext();

  const onNavigateToManageOrgTeam = () => {
    const [, _, orgPath, orgId, allTeamsPath] = location.pathname.split('/');
    const pathToManageTeam = `/${_}/${orgPath}/${orgId}/${allTeamsPath}/${ORGANIZATION_PATHS.ALL_TEAMS_PATHS.MANAGE}`;
    navigate(pathToManageTeam);
  };

  const successSnackbarRef = useRef(null);
  const errorSnackbarRef = useRef(null);

  const showSuccessSnackBar = (message, severity) => {
    if (severity !== 'info') {
      errorSnackbarRef.current.snackBarContentHandler(message);
    } else {
      successSnackbarRef.current.snackBarContentHandler(message);
    }
  };

  const [selectedItem, setSelecteItem] = useState();
  const [isEdit, setIsEdit] = useState();

  const onManageTeamHandler = (item) => {
    setIsEdit(true);
    setSelecteItem(item);
    listofMembersPerTeam(item);
    onNavigateToManageOrgTeam();
  };

  const listofMembersPerTeam = async (item) => {
    await dispatch(getListofMembersPerTeam(item.id));
  };

  const onCreateHandler = () => {
    setIsEdit(false);
    setSelecteItem(null);
    onNavigateToManageOrgTeam();
  };
  return (
    <>
      <div className={classes.fullwidth}>
        <Outlet
          context={{
            selectedOrg,
            isEdit,
            selectedItem,
            cbShowSuccessSnackBar: showSuccessSnackBar,
            cbOnCreateHandler: onCreateHandler,
            cbOnManageTeamHandler: onManageTeamHandler,
          }}
        />
      </div>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} />
        <ErrorSnackbar ref={errorSnackbarRef} />
      </div>
    </>
  );
};

export default OrgTeams;
