import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leaveTypes: [],
  pendingLeaveList: [],
  historyLeaveList: [],
  cancelledLeaveList: [],
  collisionList: [],
  leaveRegistrationsHistory: [],
  leaveHistoryListById: [],
  leaveHistoryListAll: [],
  leaveHistoryListAllCount: [],
  leaveTotalLength: [],
};

const leaveContextSlice = createSlice({
  name: 'leaveContext',
  initialState: initialState,
  reducers: {
    setLeaveTypes: (state, action) => {
      state.leaveTypes = action.payload.leaveTypes;
    },
    setLeaveHistoryRegistrations: (state, action) => {
      state.leaveRegistrationsHistory =
        action.payload.leaveRegistrationsHistory;
    },
    setPendingLeaveList: (state, action) => {
      state.pendingLeaveList = action.payload.leaveList;
    },
    setHistoryLeaveList: (state, action) => {
      state.historyLeaveList = action.payload.leaveList;
    },
    setCancelledLeaveList: (state, action) => {
      state.cancelledLeaveList = action.payload.leaveList;
    },
    setCollisionList: (state, action) => {
      state.collisionList = action.payload.collisionList;
    },
    setLeaveHistoryListById: (state, action) => {
      state.leaveHistoryListById = action.payload.leaveHistoryListById;
    },
    setLeaveHistoryList: (state, action) => {
      state.leaveHistoryListAll = action.payload.leaveHistoryListAll;
    },
    setLeaveHistoryListCount: (state, action) => {
      state.leaveHistoryListAllCount = action.payload.leaveHistoryListAllCount;
    },
    setLeaveTotalLength: (state, action) => {
      state.leaveTotalLength = action.payload.leaveTotalLength;
    },
  },
});

export const {
  setLeaveTypes,
  setPendingLeaveList,
  setHistoryLeaveList,
  setCancelledLeaveList,
  setCollisionList,
  setLeaveHistoryRegistrations,
  setLeaveHistoryListById,
  setLeaveHistoryList,
  setLeaveHistoryListCount,
  setLeaveTotalLength,
} = leaveContextSlice.actions;

export default leaveContextSlice.reducer;
