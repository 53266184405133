import i18n from '../i18n/i18n.js';

export const LeaveStatusType = {
  pending: 'pending',
  history: 'history',
  cancel: 'cancel',
  approved: 'approved',
  declined: 'declined',
  draft: 'draft',
  cancelled: 'cancelled',
  discard: 'discard',
};

export const LeaveTypeAll = {
  approveALL: 'APPROVE ALL',
  declineALL: 'DECLINE ALL',
  approve: 'APPROVE',
  decline: 'DECLINE',
  cancelLeaved: 'CANCEL LEAVE',
  declineLeave: 'DECLINE CANCELLATION',
  cancelledAll: 'CANCEL ALL LEAVES',
  declineAllCancellation: 'DECLINE ALL CANCELLATIONS',
};
export const DialogActionsType = {
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export const LeaveType = {
  leave: 'leave',
  sickness: 'sickness',
  sick: 'sick',
};

export const LeaveLabels = {
  reportSickness: 'Report Sickness',
  reportLeave: 'Request a Leave',
  sicknessType: 'Sickness Type',
  leaveType: 'Leave Type',
  halfDay: 'Half-day Leave',
  totalDays: 'Total days',
  halfDayLeave: 'Half-day Leave',
};

export const ActionLabel = {
  submit: 'Submit',
  submitForApproval: 'SUBMIT FOR APPROVAL',
  saveAsDraft: 'SAVE AS DRAFT',
  discard: 'DISCARD',
  delete: 'DELETE',
  cancel: 'CANCEL',
  cancelLeave: 'CANCEL LEAVE',
  reSubmit: 'RE-SUBMIT',
  edit: 'EDIT',
  undo: 'UNDO CANCELLATION',
};

export const RegEx = {
  userNameRegEx: new RegExp(/^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i),
  emailRegEx: new RegExp(/^[\w\-\.+]+@([\w-]+\.)+[\w-]{2,4}$/i),
  passWordRegEx: new RegExp(
    // '^(?=.*[0-9])(?=.*d)(?=.*[#$@!%&*?])[0-9A-Za-zd#$@!%&*?]{8,}$',
    '(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[#$@!%&*?.])([a-zA-Z0-9d#$@!%&*?.]+){8,}$',
  ),
  //(?=.*?[#?!@$%^&*-])
};

export const LeaveEntitlement = {
  unlimited: 'unlimited',
};

export const projectStatus = (t) => {
  return [
    {
      id: 'project.status/active',
      name: t('project-status.on-going'),
    },
    {
      id: 'project.status/completed',
      name: t('project-status.done'),
    },
    {
      id: 'project.status/draft',
      name: t('project-status.draft'),
    },
    {
      id: 'project.status/paused',
      name: t('project-status.paused'),
    },
    {
      id: 'project.status/cancelled',
      name: t('project-status.cancelled'),
    },
  ];
};
export const projectStatusName = {
  cancelled: 'cancelled',
  paused: 'paused',
  draft: 'draft',
  completed: 'completed',
  active: 'active',
};

export const timeSheetStatus = {
  approved: 'registration.status/approved',
  declined: 'registration.status/declined',
  draft: 'registration.status/draft',
};

export const ListDayAbb = (t) => {
  return [
    t('days.mon'),
    t('days.tue'),
    t('days.wed'),
    t('days.thu'),
    t('days.fri'),
    t('days.sat'),
    t('days.sun'),
  ];
};

export const ListDay = (t) => {
  return [
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday'),
    t('days.sunday'),
  ];
};

export const getDayLabelAbb = (f, t) => {
  return `${ListDayAbb(t).find((_, index) => index + 1 === f)}, `;
};

export const getDayLabel = (f, t) => {
  return `${ListDay(t).find((_, index) => index + 1 === f)} `;
};

export const intl = (name) => {
  return i18n.t(name);
};

export const addDashName = (filename) => {
  return filename.toLowerCase().replace(' ', '-');
};
