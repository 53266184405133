import AWS from 'aws-sdk';

export const AWSPath = (filename) => {
  const { REACT_APP_AWS_URL } = process.env;

  return `${REACT_APP_AWS_URL}org_${filename}.png`;
};

export const AWSUploadImage = async (file, filename) => {
  const { REACT_APP_AWS_ACCESSKEYID } = process.env;
  const { REACT_APP_AWS_SECRETACCESSKEY } = process.env;
  const { REACT_APP_AWS_BUCKETNAME } = process.env;
  const { REACT_APP_AWS_REGION } = process.env;
  const { REACT_APP_AWS_FOLDER } = process.env;

  const S3_BUCKET = REACT_APP_AWS_BUCKETNAME;
  const REGION = REACT_APP_AWS_REGION;

  AWS.config.update({
    accessKeyId: REACT_APP_AWS_ACCESSKEYID,
    secretAccessKey: REACT_APP_AWS_SECRETACCESSKEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: `${REACT_APP_AWS_FOLDER}/org_${filename}.png`,
    Body: file,
  };

  var upload = s3
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      // console.log(
      //   'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%',
      // );
    })
    .promise();

  await upload.then((err, data) => {
    //console.log(err);
    //alert('File uploaded successfully.');
    return { err: err, data: data };
  });
};
