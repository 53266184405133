import React from 'react';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const FormButton = (props) => {
  let isDisabled = props.disabled ? props.disabled : false;
  const onClickHandler = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };
  return (
    <Button
      variant={props.variant ? props.variant : 'contained'}
      id={props.id}
      onClick={onClickHandler}
      fullWidth={props.fullWidth}
      disabled={isDisabled}
      color={props.color ? props.color : 'primary'}
    >
      <Typography
        variant={props.labelVariant ? props.labelVariant : 'buttonlarge'}
        sx={{ textTransform: 'none' }}
      >
        {props.label}
      </Typography>
    </Button>
  );
};

export default FormButton;
