import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './LeaveHistory.module.scss';
import { Typography, Grid, Divider, Badge } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import SelectMenu from '../../../ui/selectMenu/SelectMenu';
import CustomModal from '../../../ui/customModal/CustomModal';
import { getStatuses, leaveStatusType } from '../LeaveProperties.config';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HealingIcon from '@mui/icons-material/Healing';
import {
  getLeaveHistoryReg,
  deleteLeaveRequestService,
  editLeaveRequestService,
  getLeaveHistoryRegAll,
  cancelLeaveRequestService,
} from '../../service/leaveService';
import { handleDateFormatRangeMonth } from '../../../../utilities/dateUtils';
import {
  LeaveStatusType,
  LeaveType,
} from '../../../../utilities/constantUtils';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { util } from '../../../../utilities/utilFunc';
import SuccessSnackbar from '../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import ListSkeleton from '../../../ui/skeleton/listSkeleton/ListSkeleton';

import { addUniqueYearsGreaterThanCurrentYearIntoYearsArray } from '../utils';
import { useTranslation } from 'react-i18next';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 14px -3px rgba(0, 0, 0, 0.78)',
    fontSize: 11,
  },
}));

const date = new Date();
let years = [];
const currentYear = date.getFullYear();

for (let i = currentYear; i > currentYear - 5; i--) {
  years.push({ label: 'Year ' + i, value: i });
}

const LeaveHistoryFilter = ({ onClickRequestLeave, reloadData }) => {
  const { t } = useTranslation();
  const uf = util();
  const dispatch = useDispatch();
  const leaveTypesList = useSelector((state) => state.leaveContext.leaveTypes);
  const leaveHistoryData = useSelector(
    (state) => state.leaveContext.leaveRegistrationsHistory,
  );
  const leaveHistoryListAll = useSelector(
    (state) => state.leaveContext.leaveHistoryListAll,
  );
  const [mainPanelClass, setMainPanelClass] = useState(classes.main_panel);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedType, setSelectedType] = useState(t('me-leave.all-types'));
  const [selectedStatus, setSelectedStatus] = useState(
    t('me-leave.all-status'),
  );

  const [mainPanelTopClass, setMainPanelTopClass] = useState(
    classes.main_panel_top,
  );
  const [mainPanelContent, setMainContentClass] = useState(
    classes.main_panel_content,
  );

  const [leaveDetails, setLeaveDetails] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const successSnackbarRef = useRef(null);
  const [severity, setSeverity] = useState('info');
  const [isDataLoaded, setIsDataLoaded] = React.useState(true);
  const [isDataLoadedAll, setIsDataLoadedAll] = React.useState(true);
  const [yearsArray, setYearsArray] = useState(years);
  const userId = useSelector((state) => state.userContext.userId);

  useEffect(() => {
    const updatedYears = addUniqueYearsGreaterThanCurrentYearIntoYearsArray(
      leaveHistoryListAll,
      currentYear,
      years,
      true,
    );
    setYearsArray(updatedYears);
  }, [leaveHistoryListAll]);

  const getLeaveHistoryData = async (year, type, status) => {
    setIsDataLoaded(true);
    var nType = type === t('me-leave.all-types') ? '' : type;
    var nStatus = status === t('me-leave.all-status') ? '' : status;
    await dispatch(
      getLeaveHistoryReg({
        from: `${year}-01-01`,
        to: `${year}-12-31`,
        type: nType,
        status: nStatus,
      }),
    );
    setIsDataLoaded(false);
  };

  const loadLeaveHistoryAll = async () => {
    setIsDataLoadedAll(true);
    var nType = selectedType === t('me-leave.all-types') ? '' : selectedType;
    var nStatus =
      selectedStatus === t('me-leave.all-status') ? '' : selectedStatus;
    await dispatch(
      getLeaveHistoryRegAll({
        from: `${currentYear}-01-01`,
        to: `${currentYear + 1}-12-31`,
        type: nType,
        status: nStatus,
      }),
    );
    setIsDataLoadedAll(false);
  };

  const loadLeaveHistory = () => {
    getLeaveHistoryData(selectedYear, selectedType, selectedStatus);
  };

  useEffect(() => {
    loadLeaveHistory();
    loadLeaveHistoryAll();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  }, []);

  const types = useMemo(() => {
    const vTypes = leaveTypesList.map((item) => {
      return { label: item.label, value: item.leaveTypeid };
    });
    return [
      { label: t('me-leave.all-types'), value: t('me-leave.all-types') },
      ...vTypes,
    ];
  }, [leaveTypesList]);

  const getDrafts = leaveHistoryListAll.filter((item) => {
    return item.status === LeaveStatusType.draft;
  });

  const getLeaves = leaveHistoryData
    .filter((leave) => {
      return leave.owner === userId && leave.status !== LeaveStatusType.draft;
    })
    .sort((a, b) => {
      a = new Date(a.start);
      b = new Date(b.start);
      return a > b ? -1 : a < b ? 1 : 0;
    });

  const handleFilterChangeYear = (e) => {
    setSelectedYear(e.target.value);
    getLeaveHistoryData(e.target.value, selectedType, selectedStatus);
  };
  const handleFilterChangeType = (e) => {
    setSelectedType(e.target.value);
    getLeaveHistoryData(selectedYear, e.target.value, selectedStatus);
  };
  const handleFilterChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
    getLeaveHistoryData(selectedYear, selectedType, e.target.value);
  };

  const iconColor = (status) => {
    let color = { color: '#FFAB00' };
    switch (status) {
      case 'pending':
        color = { color: '#FFAB00' };
        break;
      case 'approved':
        color = { color: '#59C65D' };
        break;
      case 'declined':
        color = { color: '#F44336' };
        break;
      case 'cancelled':
        color = { color: '#59C65D' };
        break;
      default:
        color = { color: '#FFAB00' };
    }

    return color;
  };

  const onClickLeaveDetail = (items) => {
    setOpenConfirmModal(true);
    setLeaveDetails(items);
  };

  const handleCloseModal = () => setOpenConfirmModal(false);

  const listenScrollEvent = (e) => {
    if (window.scrollY > 120) {
      setMainPanelClass(classes.main_panel_onscroll);
      setMainPanelTopClass(classes.main_panel_top_onscroll);
      setMainContentClass(classes.main_panel_content_onscroll);
    } else {
      setMainPanelClass(classes.main_panel);
      setMainPanelTopClass(classes.main_panel_top);
      setMainContentClass(classes.main_panel_content);
    }
  };

  const cancelPendingRequest = async (id, type) => {
    const response = await deleteLeaveRequestService(id);
    if (response.result === 1) {
      type === LeaveStatusType.cancelled
        ? showSuccessSnackBar(t('me-leave.leave-cancelled'))
        : showSuccessSnackBar(t('me-leave.leave-deleted'));
      reloadData();
    } else {
      setSeverity('error');
      showSuccessSnackBar(response.errorMessage);
    }
    setOpenConfirmModal(false);
    loadLeaveHistory();
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const resubmitRequestLeave = async () => {
    const isHalfDay =
      leaveDetails.halfDay && leaveDetails.halfDay
        ? leaveDetails.halfDay
        : null;
    let leaveRequestParam = {
      id: leaveDetails.id,
      desc: leaveDetails.desc,
      type: leaveDetails.typeName.id,
      start: leaveDetails.start,
      end: leaveDetails.end,
      halfDay: isHalfDay,
      status: LeaveStatusType.pending,
    };

    const response = await editLeaveRequestService(leaveRequestParam);
    if (response.result === 1) {
      showSuccessSnackBar(t('me-leave.leave-re-submitted'));
      reloadData();
    } else {
      setSeverity('error');
      showSuccessSnackBar(response.errorMessage);
    }
    setOpenConfirmModal(false);
    loadLeaveHistory();
  };

  const cancelApproveRequest = async (id, isCancel) => {
    const response = await cancelLeaveRequestService(id, isCancel);
    if (response.result === 1) {
      reloadData();
    } else {
      showSuccessSnackBar(response.errorMessage);
    }
    loadLeaveHistory();
    setOpenConfirmModal(false);
  };

  const showHolidayConflict = (item) => {
    return (
      item.vacation && (
        <Badge color="error" variant="dot" className={classes.badge2} />
      )
    );
  };

  const showToolTip = (item, hasTypeName) => {
    if (
      (item.status === LeaveStatusType.approved ||
        item.status === LeaveStatusType.pending) &&
      item.vacation
    ) {
      return (
        <>
          <div className={classes.cancel_tooltip}>
            {t('me-leave.holiday-conflict-with')}{' '}
            {item.status === LeaveStatusType.approved
              ? t('me-leave.approved')
              : t('me-leave.pending')}
            {hasTypeName}
          </div>
        </>
      );
    } else {
      return item.forCancellation ? (
        <div className={classes.cancel_tooltip}>
          {t('me-leave.awaiting-approver-to-cancel-your-leave')}
        </div>
      ) : (
        <>
          <div className={classes.status_tooltip}>{item.status}</div>

          <div className={classes.leave_type_tooltip}>{hasTypeName}</div>
        </>
      );
    }
  };

  return (
    <>
      <div className={mainPanelClass}>
        <div className={mainPanelTopClass}>
          <div className={classes.top_panel_content}>
            <div>
              <Typography className={classes.leave_history_title}>
                {t('me-leave.utilized-leaves-history')}
              </Typography>
            </div>
            <div>
              <Typography className={classes.leave_history_desc}>
                {t('me-leave.these-are-your-utilized-leaves-and-its-status')}
              </Typography>
            </div>
            <div className={classes.select_container}>
              <SelectMenu
                data={yearsArray}
                handleChange={(value) => handleFilterChangeYear(value)}
                value={selectedYear}
                selectClasses={classes.select_container_default}
                selectTextClasses={classes.text}
              />
              <SelectMenu
                data={types}
                handleChange={(value) => handleFilterChangeType(value)}
                value={selectedType}
                selectClasses={classes.select_container_width}
                selectTextClasses={classes.text}
              />
              <SelectMenu
                sx={{ '& > fieldset': { border: 'none' } }}
                data={getStatuses(t)}
                handleChange={(value) => handleFilterChangeStatus(value)}
                value={selectedStatus}
                selectClasses={classes.select_container_default}
                selectTextClasses={classes.text}
              />
            </div>
          </div>
        </div>
        <div className={mainPanelContent}>
          {isDataLoaded ? (
            <ListSkeleton />
          ) : (
            <div className={classes.panel_content}>
              <>
                {getLeaves.length !== 0 ? (
                  <>
                    <div className={classes.details_list}>
                      {getLeaves.map((item) => {
                        const hasTypeName = uf.isNullOrUndefined(
                          item.typeName && item.typeName.type,
                        )
                          ? ''
                          : `${item.typeName && item.typeName.type} ${t(
                              'me-leave.leave',
                            )}`;
                        return (
                          <>
                            <List>
                              <ListItem disablePadding>
                                <LightTooltip
                                  title={showToolTip(item, hasTypeName)}
                                  placement="left-start"
                                >
                                  <ListItemButton
                                    onClick={() => onClickLeaveDetail(item)}
                                    sx={{ height: 30 }}
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      columns={12}
                                      className={classes.grid}
                                    >
                                      <Grid item xs={7}>
                                        <div className={classes.summary}>
                                          {item.type === LeaveType.sickness ? (
                                            <div className={classes.healM5}>
                                              <HealingIcon
                                                style={iconColor(item.status)}
                                              />
                                              <Badge
                                                color="error"
                                                variant="dot"
                                                className={classes.badge2}
                                                invisible={
                                                  !item.forCancellation
                                                }
                                              />
                                              {showHolidayConflict(item)}
                                            </div>
                                          ) : (
                                            <div className={classes.badgeWidth}>
                                              {!item.vacation && (
                                                <Badge
                                                  color="error"
                                                  variant="dot"
                                                  className={classes.badge}
                                                  invisible={
                                                    !item.forCancellation
                                                  }
                                                />
                                              )}
                                              <EmojiTransportationIcon
                                                style={iconColor(item.status)}
                                              />
                                              {(item.status ===
                                                LeaveStatusType.approved ||
                                                item.status ===
                                                  LeaveStatusType.pending) && (
                                                <>{showHolidayConflict(item)}</>
                                              )}
                                            </div>
                                          )}

                                          <div className={classes.date}>
                                            {handleDateFormatRangeMonth(
                                              item.start,
                                              item.end,
                                            )}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <div
                                          className={
                                            item.status ===
                                            LeaveStatusType.declined
                                              ? classes.desc_declined
                                              : classes.desc
                                          }
                                        >
                                          {uf.isNullOrUndefined(item.desc)
                                            ? '-'
                                            : item.desc}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItemButton>
                                </LightTooltip>
                              </ListItem>
                            </List>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className={classes.no_data}>
                    {t('me-leave.no-available-leave-data')}
                  </div>
                )}
              </>
            </div>
          )}
        </div>
        <div className={classes.divider}>
          <Divider variant="middle" />
        </div>
        <div className={classes.main_panel_bottom}>
          {isDataLoadedAll ? (
            <ListSkeleton />
          ) : (
            <div className={classes.panel_content_bottom}>
              <div className={classes.panel_content_bottom}>
                <div>
                  <Typography className={classes.draft_heading}>
                    {t('me-leave.you-have')} {getDrafts.length}{' '}
                    {t('me-leave.draft')}
                  </Typography>
                  <p className={classes.draft_desc}>
                    {t(
                      'me-leave.continue-where-you-left-off-in-the-leaves-below',
                    )}
                  </p>

                  {getDrafts.length !== 0 ? (
                    <>
                      <div className={classes.details_list}>
                        {getDrafts.map((item) => {
                          const hasTypeName = uf.isNullOrUndefined(
                            item.typeName && item.typeName.type,
                          )
                            ? ''
                            : `${item.typeName && item.typeName.type} ${t(
                                'me-leave.leave',
                              )}`;
                          return (
                            <>
                              <List>
                                <ListItem disablePadding>
                                  <LightTooltip
                                    title={
                                      <>
                                        <div className={classes.status_tooltip}>
                                          {item.status}
                                        </div>
                                        <div
                                          className={classes.leave_type_tooltip}
                                        >
                                          {hasTypeName}
                                        </div>
                                      </>
                                    }
                                    placement="left-start"
                                  >
                                    <ListItemButton
                                      onClick={onClickRequestLeave(
                                        true,
                                        item.type,
                                        item,
                                        true,
                                      )}
                                      sx={{ height: 30 }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        columns={12}
                                        className={classes.grid}
                                      >
                                        <Grid item xs={7}>
                                          <div className={classes.summary}>
                                            <div className={classes.healM5}>
                                              <EmojiTransportationIcon
                                                style={{ color: '#00000061' }}
                                              />
                                            </div>

                                            <div className={classes.date}>
                                              {handleDateFormatRangeMonth(
                                                item.start,
                                                item.end,
                                              )}
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <div
                                            className={
                                              item.status ===
                                              leaveStatusType.declined
                                                ? classes.desc_declined
                                                : classes.desc
                                            }
                                          >
                                            {uf.isNullOrUndefined(item.desc)
                                              ? '-'
                                              : item.desc}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </ListItemButton>
                                  </LightTooltip>
                                </ListItem>
                              </List>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className={classes.desc}>
                      {t('me-leave.you-have-no-drafts')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={handleCloseModal}
        width={349}
        height={310}
        data={leaveDetails}
        cancelRequest={cancelPendingRequest}
        resubmitRequestLeave={resubmitRequestLeave}
        editRequestLeave={onClickRequestLeave}
        cancelApproveRequest={cancelApproveRequest}
      />

      <div className={`${classes.snackbar_wrapper}   `}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </>
  );
};

export default LeaveHistoryFilter;
