import React, { useEffect, useRef, useState } from 'react';
import classes from './Leave.module.scss';
import { getLeaves } from '../service/leaveService';
import { useDispatch, useSelector } from 'react-redux';

import LeaveTypeCard from './leaveTypeCard/LeaveTypeCard';
import RequestLeave from './RequestLeave';
import LeaveHistoryFilter from './leaveHistory/LeaveHistory';
import { Typography } from '@mui/material';

import { LeaveStatusType } from '../../../utilities/constantUtils';

import EmptyState from '../../ui/emptyState/EmptyState';
import SelectMenu from '../../ui/selectMenu/SelectMenu';

import SuccessSnackbar from '../../ui/snackbar/successSnackbar/SuccessSnackbar';
import moment from 'moment';
import { addUniqueYearsGreaterThanCurrentYearIntoYearsArray } from './utils';
import { useTranslation } from 'react-i18next';

const date = new Date(),
  years = [],
  currentYear = date.getFullYear();

for (let i = currentYear; i > currentYear - 5; i--) {
  years.push({ label: i, value: i });
}

const LeavePageItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [leaveTypeList, setleaveTypeList] = useState([]);
  const leaveTypes = useSelector((state) => state.leaveContext.leaveTypes);
  const leaveHistoryListAllCount = useSelector(
    (state) => state.leaveContext.leaveHistoryListAllCount,
  );
  const leaveHistoryListAll = useSelector(
    (state) => state.leaveContext.leaveHistoryListAll,
  );
  const [requestLeave, btnCLickRequestLeave] = useState(false);
  const [leaveType, setLeaveType] = useState('');
  const [leaveDetails, setleaveDetails] = useState({});
  const [leaveTypeId, setleaveTypeId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const successSnackbarRef = useRef(null);
  const [yearsArray, setYearsArray] = useState(years);

  useEffect(() => {
    const updatedYears = addUniqueYearsGreaterThanCurrentYearIntoYearsArray(
      leaveHistoryListAll,
      currentYear,
      years,
      false,
    );
    setYearsArray(updatedYears);
  }, [leaveHistoryListAll]);

  const getTotalLeave = (leaves) => {
    var totalCount = 0;
    leaves.forEach((f) => {
      if (f.end !== undefined && f.start !== undefined) {
        var duration = moment.duration(moment(f.end).diff(f.start));
        var days = duration.asDays() + 1;
        for (var x = 1; x <= days; x++) {
          var date = moment(f.start).add(x, 'days').format('ddd');
          if (date.toLowerCase() !== 'sun' && date.toLowerCase() !== 'sat') {
            totalCount = totalCount + 1;
          }
        }
      } else {
        totalCount = totalCount + 1;
      }
    });
    return totalCount;
  };

  useEffect(() => {
    if (leaveTypes.length > 0 && leaveHistoryListAllCount.length > 0) {
      var newLeavesTypes = [];
      leaveTypes.forEach((f) => {
        var approved = leaveHistoryListAllCount.filter(
          (ff) =>
            ff.typeName.id === f.leaveTypeid &&
            ff.status === LeaveStatusType.approved &&
            ff.halfDay !== 'half',
        );

        var pending = leaveHistoryListAllCount.filter(
          (ff) =>
            ff.typeName.id === f.leaveTypeid &&
            ff.status === LeaveStatusType.pending &&
            ff.halfDay !== 'half',
        );

        var approvedhalf = leaveHistoryListAllCount.filter(
          (ff) =>
            ff.typeName.id === f.leaveTypeid &&
            ff.status === LeaveStatusType.approved &&
            ff.halfDay === 'half',
        );

        var pendingHalf = leaveHistoryListAllCount.filter(
          (ff) =>
            ff.typeName.id === f.leaveTypeid &&
            ff.status === LeaveStatusType.pending &&
            ff.halfDay === 'half',
        );

        var totalApproveHalf =
          approvedhalf.length > 0 ? approvedhalf.length / 2 : 0;
        var totalpendingHalf =
          pendingHalf.length > 0 ? pendingHalf.length / 2 : 0;

        var total = getTotalLeave(approved) + totalApproveHalf;
        let item = {
          approved: total,
          entitlement: f.entitlement,
          label: f.label,
          leaveTypeid: f.leaveTypeid,
          pending: getTotalLeave(pending) + totalpendingHalf,
          remaining: isNaN(f.entitlement)
            ? f.entitlement
            : f.entitlement - total,
          type: f.type,
        };

        newLeavesTypes.push(item);
      });
      setleaveTypeList(newLeavesTypes);
    } else {
      setleaveTypeList(leaveTypes);
    }
  }, [leaveHistoryListAllCount, leaveTypes]);

  const loadData = async () => {
    await dispatch(getLeaves(selectedYear));
    // await dispatch(
    //   getLeaveHistoryAllCount({
    //     from: `${selectedYear}-01-01`,
    //     to: `${selectedYear}-12-31`,
    //     type: '',
    //     status: '',
    //   }),
    // );
  };

  const onClickRequestLeave =
    (request, type, items, isEdit, leaveTypeId) => () => {
      setSaveLeave(null);
      setLeaveType(type);
      btnCLickRequestLeave(request);
      setleaveDetails(items);
      setleaveTypeId(leaveTypeId);
      setIsEdit(isEdit);
    };

  const [saveleave, setSaveLeave] = useState(null);
  useEffect(() => {
    if (saveleave) {
      successSnackbarRef.current.snackBarContentHandler(saveleave);
    }
  }, [saveleave]);

  const saveHandler = (message) => {
    btnCLickRequestLeave(false);
    setSaveLeave(true);
    setSaveLeave(message);
  };

  // useEffect(() => {
  //   dispatch(getLeaves());
  // }, []);

  const handleFilterChangeYear = (e) => {
    setSelectedYear(e.target.value);
    //dispatch(getLeaves(e.target.value));
  };
  useEffect(() => {
    if (!requestLeave) {
      loadData();
    }
  }, [selectedYear, requestLeave]);

  const onBackHandler = (item) => {
    btnCLickRequestLeave(false);
    if (item) {
      successSnackbarRef.current.snackBarContentHandler(
        t('me-leave.draft-leave-deleted'),
      );
    }
  };

  return (
    <>
      {requestLeave ? (
        <RequestLeave
          type={leaveType}
          onClickRequestLeave={onClickRequestLeave}
          leaveDetails={leaveDetails}
          leaveTypeId={leaveTypeId}
          edit={isEdit}
          saveHandler={saveHandler}
          onBack={onBackHandler}
        />
      ) : (
        <div>
          <div className={classes.leave_types_card_container}>
            <div className={classes.leave_header_container}>
              <div className={classes.leave_header_con}>
                <div>
                  <Typography className={classes.leave_name}>
                    {t('me-leave.my-leave-entitlement-for')}{' '}
                    <>
                      <SelectMenu
                        data={yearsArray}
                        handleChange={(value) => handleFilterChangeYear(value)}
                        value={selectedYear}
                        selectClasses={classes.select_container_default}
                        selectTextClasses={classes.year_select}
                      />
                    </>
                  </Typography>

                  <Typography className={classes.leave_desc}>
                    {t(
                      'me-leave.these-leave-entitlement-are-credited-to-you-by-dewise',
                    )}
                  </Typography>
                </div>
              </div>
            </div>
            {/* //add condition if null , leave-type*/}
            {leaveTypes.length === 0 ? (
              <EmptyState className={classes.empty_wrapper} />
            ) : (
              <LeaveTypeCard
                onClickRequestLeave={onClickRequestLeave}
                selectedYear={selectedYear}
                leaveTypes={leaveTypes}
              />
            )}
          </div>
          <div className={classes.right_panel}>
            <div className={classes.cont}>
              <LeaveHistoryFilter
                onClickRequestLeave={onClickRequestLeave}
                reloadData={loadData}
              />
            </div>
          </div>
          <div className={`${classes.snackbar_wrapper} `}>
            <SuccessSnackbar ref={successSnackbarRef} />
          </div>
        </div>
      )}
    </>
  );
};
export default LeavePageItems;
