import { createSlice } from '@reduxjs/toolkit';
import { removeTeam } from './orgContextSlice';

const initialState = {
  toolBarShow: true,
};

const toolBarContextSlice = createSlice({
  name: 'toolBarContext',
  initialState: initialState,
  reducers: {
    setToolBarShow: (state, action) => {
      state.toolBarShow = action.payload;
    },
  },
});

export const { setToolBarShow } = toolBarContextSlice.actions;

export default toolBarContextSlice.reducer;
