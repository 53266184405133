import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ContentLayout from '../../../../ui/layout/contentlayout/ContentLayout';
import Loader from '../../../../ui/loader/Loader';
import paths from '../../../../../routing/Constants';
import { signUpUserViaLinkedin } from '../../../service/signupService';
import { getTransitData } from '../../../../../utilities/transitJsUtils';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../i18n/i18n';
//paths

const LinkedinSignUp = () => {
  const homePath = `/${i18n.language}/${paths.HOME}`;
  const loginPath = `/${i18n.language}/${paths.LOGIN}`;
  const { t } = useTranslation();
  document.title = t('login.sign-up-meewee');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');

    if (state === process.env.REACT_APP_LINKEDIN_STATE && code) {
      dispatch(
        signUpUserViaLinkedin({
          'auth-token': code,
          'redirect-uri': process.env.REACT_APP_LINKEDIN_REDIRECT_SIGNUP,
        }),
      ).then((data) => {
        if (data.result === 1) {
          let isNewUser = getTransitData(data.data, 'new-user');
          navigate(homePath, { state: { isNewUser: isNewUser } });
        } else {
          navigate(loginPath, {
            state: {
              errorMessage: t(
                'login.something-went-wrong-linking-your-account-please-try-again',
              ),
            },
          });
        }
      });
    } else if (
      error &&
      ['user_cancelled_login', 'user_cancelled_authorize'].includes(error)
    ) {
      navigate(loginPath);
    }
  }, []);

  return (
    <ContentLayout>
      <Loader
        title={t('login.sign-up-meewee')}
        text={t('login.one-moment-we-are-finalizing-your-account')}
      ></Loader>
    </ContentLayout>
  );
};

export default LinkedinSignUp;
