import React from 'react';
import Typography from '@mui/material/Typography';
import { transformMinsToHoursMinutesString } from '../../../../../../../../utilities/timeConversion';

const TotalCell = (props) => {
  return (
    <Typography
      variant="body2"
      color={props.color}
      fontWeight={props.fontWeight}
    >
      {props.sumOfReg === 0 ? (
        <React.Fragment> &#8212;</React.Fragment>
      ) : (
        transformMinsToHoursMinutesString(props.sumOfReg)
      )}
    </Typography>
  );
};

export default TotalCell;
