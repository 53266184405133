import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translateEN from './locales/en-US.json';
import translateDK from './locales/da-DK.json';
import { listLanguage } from './i18nUtil';

const resources = {
  en_US: { translation: translateEN },
  da_DK: { translation: translateDK },
};

i18n.on('languageChanged', (lng) => {
  const checkLang = listLanguage.find((lang) =>
    window.location.pathname.toLowerCase().includes(lang.name.toLowerCase()),
  );
  if (!checkLang) {
    const navlang = navigator.language;
    var defaultLang = listLanguage.find(
      (lang) => lang.id === navlang.toLowerCase(),
    );
    if (defaultLang) {
      window.location.replace(
        window.location.origin +
          '/' +
          defaultLang.name +
          window.location.pathname,
      );
    } else {
      window.location.replace(
        window.location.origin + '/' + lng + window.location.pathname,
      );
    }
  }
});

i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  lng: 'en_US',
  resources,
});

export default i18n;
