import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormButton from "../../../ui/formcontrols/formButton/FormButton";
import lettermarklogo from "../../../../assets/meewee_logo_white.svg";
import ContentLayout from "../../../ui/layout/contentlayout/ContentLayout";
import classes from "./Welcome.module.scss";
import paths from "../../../../routing/Constants";

const loginPagePath = paths.ROOT.concat(paths.LOGIN);

const Welcome = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSignInHandler = () => {
    navigate(loginPagePath);
  };

  return (
    <React.Fragment>
      {state?.teamName && (
        <ContentLayout>
          <Grid
            container
            direction="column"
            spacing={5}
            className={classes.welcome_container}
          >
            <Grid item>
              <Typography variant="heading">
                Welcome to Team {state.teamName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" fontSize={18}>
                Thank you for accepting the invitation. Please log in to your
                meewee account to view the team.
              </Typography>
            </Grid>
            <Grid item>
              <FormButton
                label={
                  <div className={classes.login_button_container}>
                    <Typography>Sign in to </Typography>
                    <img
                      src={lettermarklogo}
                      alt="meewee logo"
                      className={classes.meewee_logo_button}
                    />
                  </div>
                }
                fullWidth={true}
                onClick={onSignInHandler}
                id="signin-button"
              />
            </Grid>
          </Grid>
        </ContentLayout>
      )}
    </React.Fragment>
  );
};

export default Welcome;
