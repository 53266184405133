import React from 'react';
import { Button, Grid, Typography, Box, Modal } from '@mui/material';
import classes from './ConfirmModal.module.scss';

const ConfirmModal = ({
  title,
  description,
  openConfirmModal,
  setOpenConfirmModal,
  submitConfirmation,
  width,
  height,
  submitLabel,
  handleCloseConfirm,
}) => {
  const handleClose = () => setOpenConfirmModal(false);

  const submit = () => {
    submitConfirmation();
    setOpenConfirmModal(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width,
    height: height,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={openConfirmModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <Typography className={classes.title}>{title}</Typography>
          <div className={classes.space_bottom}></div>
          <Typography className={classes.desc}>{description}</Typography>
          {description && <div className={classes.space_bottom}></div>}
          <Grid>
            <Grid item className={classes.confirm_btn}>
              <Button onClick={handleClose} className={classes.cancel_btn}>
                Cancel
              </Button>
              <Button onClick={submit}>{submitLabel}</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;
