import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const AutoCompleteComboBox = (props) => {
  let isError = props.isError ? props.isError : false;
  let helpertext = isError ? props.errorText : " ";

  const onChangeHandler = (event, value) => {
    if (props.onChange) {
      props.onChange(event, value);
    }
  };

  const onBlurHandler = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <Box>
      <Autocomplete
        options={props.listItems}
        getOptionLabel={(option) => option}
        id={props.id}
        autoComplete
        includeInputInList
        renderInput={(params) => (
          <TextField
            {...params}
            error={isError}
            label={props.label}
            helperText={helpertext}
            variant="standard"
          />
        )}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        fullWidth={true}
      />
    </Box>
  );
};

export default AutoCompleteComboBox;
