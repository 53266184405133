import React from 'react';
import classes from './InfoTab.module.scss';
import { TextField, FormControl, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InfoTab = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.flexContainer}>
        <div>
          <div className="padB30">
            <TextField
              id="standard-basic"
              className={classes.fieldWidth}
              label={t('organization.team-name')}
              variant="standard"
              value={props.teamName}
              onChange={props.onChangeTeamName}
            />
          </div>

          <div className={`${classes.row1} padB30`}>
            <FormControl variant="standard" sx={{ minWidth: 250 }}>
              <Autocomplete
                id="disable-close-on-select"
                className={classes.fieldWidth}
                value={props.selectedTeamManager}
                clearOnEscape
                onChange={(e, value) => props.onChangeTeamManager(e, value)}
                options={props.usersList.map((user) => user.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('organization.team-manager')}
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoTab;
