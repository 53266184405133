import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormButton from '../../../ui/formcontrols/formButton/FormButton';
import classes from './TermsAndConditions.module.scss';
import Loader from '../../../ui/loader/Loader';
import paths from '../../../../routing/Constants';
import {
  signUpUser,
  acceptInvitationService,
} from '../../service/signupService';
import i18n from '../../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

import { encodeUtf8toBase64 } from '../../../../utilities/base64Utils';

const TermsAndConditions = () => {
  const homePath = `/${i18n.language}/${paths.HOME}`;

  const { t } = useTranslation();
  const genericErrorMessage = t('login.an-error-has-occurred');
  document.title = t('login.terms-and-conditions-meewee');
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userProfile, setUserProfile] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state) {
      searchParams.delete('key');
      navigate({ search: searchParams.toString() });
    } else {
      setUserProfile(state);
    }
  }, []);

  const onCheckboxChangeHandler = (event) => {
    setChecked(event.target.checked);

    setUserProfile((prevState) => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        'legal-accept': event.target.checked,
      },
    }));
  };

  const onClickContinueHandler = (event) => {
    setIsLoading(true);
    setSearchParams({ key: 'creatingAccount' });

    let signUpParams = Object.assign({}, userProfile.userInfo);

    //This means that user is being invited to a team on sign up
    if (userProfile.teamInvitationInfo['request-id']) {
      signUpParams['request-id'] = userProfile.teamInvitationInfo['request-id'];
    }

    dispatch(signUpUser(signUpParams)).then((data) => {
      if (data.result === 1) {
        if (signUpParams['request-id']) {
          navigate(homePath, {
            state: { teamName: userProfile.teamInvitationInfo.teamName },
          });
        } else {
          navigate(homePath);
        }
      } else {
        setSearchParams(
          {
            key: 'profileDetails',
            token: userProfile.userInfo.token,
            code: encodeUtf8toBase64(userProfile.userInfo.email),
            ...(userProfile.teamInvitationInfo['request-id'] && {
              invitation: encodeUtf8toBase64(
                userProfile.teamInvitationInfo['request-id'].toString(),
              ),
            }),
            ...(userProfile.teamInvitationInfo.teamName && {
              team: encodeUtf8toBase64(
                userProfile.teamInvitationInfo.teamName.toString(),
              ),
            }),
          },
          {
            state: {
              errorMessage:
                data.errorMessage === genericErrorMessage
                  ? t(
                      'login.something-went-wrong-in-creating-your-account-please-try-again',
                    )
                  : data.errorMessage,
            },
          },
        );
      }
    });
  };

  return (
    <div className="terms-and-conditions">
      {!isLoading && state && (
        <Grid
          container
          direction="column"
          spacing={5}
          className={classes.terms_and_conditions_container}
        >
          <Grid item>
            <Typography variant="heading">
              {t('login.one-more-to-go')}!
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1">
              {t('login.please-read-and-accept-terms-and-conditions-below')}
            </Typography>
            <Typography
              variant="body2"
              className={classes.terms_conditions_body}
            >
              <div className={`padB10 ${classes.title}`}>
                {t('terms-and-conditions.title1')}
              </div>
              <div className="padB10">
                {t('terms-and-conditions.description1')}
              </div>

              <div className={`padB10 ${classes.title}`}>
                {t('terms-and-conditions.title2')}
              </div>
              <div className="padB10">
                {t('terms-and-conditions.description2')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description2-1')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description2-2')}
              </div>

              <div className={`padB10 ${classes.title}`}>
                {t('terms-and-conditions.title3')}
              </div>
              <div className="padB10">
                {t('terms-and-conditions.description3')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description3-1')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description3-2')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description3-3')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description3-4')}
              </div>

              <div className={`padB10 ${classes.title}`}>
                {t('terms-and-conditions.title4')}
              </div>
              <div className="padB10">
                {t('terms-and-conditions.description4')}
              </div>

              <div className={`padB10 ${classes.title}`}>
                {t('terms-and-conditions.title5')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description5')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description5-1')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description5-2')}
              </div>
              <div className="padB5">
                {t('terms-and-conditions.description5-3')}
              </div>
            </Typography>

            <FormControlLabel
              className={classes.terms_and_conditions_checkbox}
              control={
                <Checkbox
                  aria-label="Checkbox"
                  checked={checked}
                  onChange={onCheckboxChangeHandler}
                />
              }
              label={t(
                'login.i-have-read-and-agreed-to-the-terms-and-conditions',
              )}
            />

            <div className={classes.continue_button}>
              <FormButton
                label={t('common.continue')}
                fullWidth={true}
                onClick={onClickContinueHandler}
                id="continue-button"
                disabled={state && !checked}
              />
            </div>
          </Grid>
        </Grid>
      )}

      {isLoading && (
        <Loader
          text={t('login.one-moment-we-are-finalizing-your-account')}
          title={t('login.sign-up-meewee')}
        />
      )}
    </div>
  );
};

export default TermsAndConditions;
