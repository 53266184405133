import httpRequestHandler from '../../../../utilities/httpRequestHandler';
import {
  createTransitData,
  getTransitDataCollection,
} from '../../../../utilities/transitJsUtils';
import {
  setPendingLeaveList,
  setHistoryLeaveList,
  setCancelledLeaveList,
} from '../../../../store/leaveContextSlice';
import { LeaveStatusType } from '../../../../utilities/constantUtils';
import { dateFormats } from '../../../../utilities/dateUtils';
import { format } from 'date-fns';
import moment from 'moment';

export const getLeaves = (type, from, to) => {
  if (from == null) {
    const dateToday = new Date();
    from = moment.utc(dateToday).add(-10, 'y');
    to = moment.utc(dateToday).add(1, 'y');
  }
  let requestParam = {
    status: type,
  };
  if (type === 'history') {
    requestParam = {
      from: format(new Date(from), dateFormats.yyyymmdd),
      to: format(new Date(to), dateFormats.yyyymmdd),
      status: type,
    };
  }

  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getLeaveType, requestParam);
      if (response.result === 1) {
        var data = response.data;
        let leaveList = [];
        let mapToObject = {
          id: 'db/id',
          teams: 'employment/teams',
          owner: 'registration/owner',
          type: 'registration/type',
          reason: 'registration/description',
          createdBy: 'meta/created-by',
          createdAt: 'meta/created-at',
          registrationStart: 'registration/start',
          registrationEnd: 'registration/end',
          registrationStartOffset: 'registration/start-offset',
          registrationEndOffset: 'registration/end-offset',
          status: 'registration/status',
          updatedAt: 'meta/updated-at',
          updatedBy: 'meta/updated-by',
          halfDay: 'registration/day-length :day-length/half',
        };
        data.rep.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          var startDate =
            mappedData.registrationStart !== undefined
              ? format(
                  mappedData.registrationStart,
                  dateFormats.default_v2_slashes,
                )
              : null;
          var endDate =
            mappedData.registrationEnd !== undefined &&
            format(mappedData.registrationEnd, dateFormats.default_v2_slashes);
          const nMappedData = {
            id: mappedData.id,
            owner: getOwner(mappedData.owner),
            ownerName: getOwner(mappedData.owner).name,
            reason: mappedData.reason,
            requestedDate:
              mappedData.halfDay !== undefined
                ? `${startDate}(half-day)`
                : endDate
                ? `${startDate}-${endDate}`
                : startDate,
            leaveType: getType(mappedData.type),
            leaveTypeName: getType(mappedData.type).description,
            teams: getEmployeeTeams(mappedData.teams),
            teamsList: getEmployeeTeamsList(mappedData.teams),
            submittedDate: format(mappedData.createdAt, dateFormats.datetime),
            status: mappedData.status,
            createdBy: getUser(mappedData.createdBy),
            updatedAt:
              mappedData.updatedAt !== undefined
                ? format(mappedData.updatedAt, dateFormats.datetime)
                : '',
            updatedBy: getUser(mappedData.updatedBy),
            updatedByName: getUser(mappedData.updatedBy)?.name,
            metaUpdatedAt: mappedData.updatedAt,
            metaCreatedAt: mappedData.createdAt,
          };
          leaveList.push(nMappedData);
        });

        response.data = leaveList;
        switch (type) {
          case LeaveStatusType.pending:
            dispatch(setPendingLeaveList({ leaveList: response.data }));
            break;
          case LeaveStatusType.history:
            dispatch(setHistoryLeaveList({ leaveList: response.data }));
            break;
          case LeaveStatusType.cancel:
            dispatch(setCancelledLeaveList({ leaveList: response.data }));
            break;
          default:
            break;
        }
      } else {
        dispatch(setPendingLeaveList({ leaveList: [] }));
        dispatch(setHistoryLeaveList({ leaveList: [] }));
        dispatch(setCancelledLeaveList({ leaveList: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const sendLeaveRequestService = async (value, status) => {
  try {
    let requestParamObj = {
      'db/id': { value: value.id, isKeyword: false },
      'registration/status': {
        value: 'registration.status/' + status,
        isKeyword: true,
      },
      'registration/comments': {
        value: '',
        isKeyword: false,
      },
      'meta/updated-at': {
        value:
          value.metaUpdatedAt !== undefined
            ? value.metaUpdatedAt
            : value.metaCreatedAt,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestLeaves,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const sendCancelledLeaveRequestService = async (value, status) => {
  try {
    let requestParamObj = {
      'db/id': { value: value.id, isKeyword: false },
      'is-approve': {
        value: status,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestLeaves,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

const getEmployeeTeams = (value) => {
  var result = [];
  let mapToObject = {
    id: 'db/id',
    name: 'team/name',
  };
  value.forEach((rowData) => {
    let mappedData = getTransitDataCollection(rowData, mapToObject);
    result.push(mappedData);
  });
  return result;
};

const getEmployeeTeamsList = (value) => {
  var result = [];
  let mapToObject = {
    id: 'db/id',
    name: 'team/name',
  };
  value.forEach((rowData) => {
    let mappedData = getTransitDataCollection(rowData, mapToObject);
    result.push(mappedData);
  });
  var resultItem = result.map(function (item, index) {
    return item.name;
  });
  return resultItem.join(', ');
};

const getOwner = (value) => {
  let mapToObject = {
    id: 'db/id',
    firstName: 'user/firstname',
    lastName: 'user/lastname',
  };

  let mappedData = getTransitDataCollection(value, mapToObject);
  mappedData = {
    ...mappedData,
    name: `${mappedData.firstName} ${mappedData.lastName}`,
  };
  return mappedData;
};

const getType = (value) => {
  let mapToObject = {
    id: 'db/id',
    description: 'registration-type/description',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  return mappedData;
};

const getUser = (value) => {
  if (value !== undefined) {
    let mapToObject = {
      id: 'db/id',
      name: 'name',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);

    return mappedData;
  }
  return null;
};

const getLeaveType = {
  url: 'leaves',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const putRequestLeaves = {
  url: 'leaves',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};
