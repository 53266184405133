import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
const ProgressUI = () => {
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [buffer, setBuffer] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box>
      <Typography variant="h6">Linear</Typography>
      <Box sx={{ m: 5 }}>
        <Stack spacing={10}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Determinate</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Typography variant="caption">Primary</Typography>
                <Typography variant="caption">Secondary</Typography>
                <Typography variant="caption">Default</Typography>
              </Stack>
            </Box>
            <Box sx={{ m: 5, width: "100%" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <LinearProgressWithLabel
                  value={progress}
                  variant="determinate"
                  color="primary"
                />
                <LinearProgressWithLabel
                  value={progress}
                  variant="determinate"
                  color="secondary"
                />
                <LinearProgressWithLabel
                  value={progress}
                  variant="determinate"
                  color="inherit"
                />
              </Stack>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Indeterminate</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack spacing={4}>
                <Typography variant="caption">Primary</Typography>
                <Typography variant="caption">Secondary</Typography>
                <Typography variant="caption">Default</Typography>
              </Stack>
            </Box>
            <Box sx={{ m: 5, width: "100%" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <LinearProgressWithLabel
                  value={progress}
                  variant="indeterminate"
                  color="primary"
                />
                <LinearProgressWithLabel
                  value={progress}
                  variant="indeterminate"
                  color="secondary"
                />
                <LinearProgressWithLabel
                  value={progress}
                  variant="indeterminate"
                  color="inherit"
                />
              </Stack>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Buffer</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack spacing={4}>
                <Typography variant="caption">Primary</Typography>
                <Typography variant="caption">Secondary</Typography>
                <Typography variant="caption">Default</Typography>
              </Stack>
            </Box>
            <Box sx={{ m: 5, width: "100%" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <LinearProgressWithLabel
                  value={progress2}
                  valueBuffer={buffer}
                  variant="buffer"
                  color="primary"
                />
                <LinearProgressWithLabel
                  value={progress2}
                  valueBuffer={buffer}
                  variant="buffer"
                  color="secondary"
                />
                <LinearProgressWithLabel
                  value={progress2}
                  valueBuffer={buffer}
                  variant="buffer"
                  color="inherit"
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Typography variant="h6">Circular</Typography>
      <Box sx={{ m: 5 }}>
        <Stack spacing={10}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Determinate</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Typography variant="caption">Primary</Typography>
                <Typography variant="caption">Secondary</Typography>
                <Typography variant="caption">Default</Typography>
              </Stack>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  color="primary"
                />

                <CircularProgress
                  variant="determinate"
                  value={progress}
                  color="secondary"
                />
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  color="inherit"
                />
              </Stack>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <CircularProgressWithLabel
                  variant="determinate"
                  value={progress}
                  color="primary"
                />

                <CircularProgressWithLabel
                  variant="determinate"
                  value={progress}
                  color="secondary"
                />
                <CircularProgressWithLabel
                  variant="determinate"
                  value={progress}
                  color="inherit"
                />
              </Stack>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Typography variant="subtitle2">Indeterminate</Typography>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack spacing={4}>
                <Typography variant="caption">Primary</Typography>
                <Typography variant="caption">Secondary</Typography>
                <Typography variant="caption">Default</Typography>
              </Stack>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <CircularProgress
                  variant="indeterminate"
                  value={progress}
                  color="primary"
                />

                <CircularProgress
                  variant="indeterminate"
                  value={progress}
                  color="secondary"
                />
                <CircularProgress
                  variant="indeterminate"
                  value={progress}
                  color="inherit"
                />
              </Stack>
            </Box>
            <Box sx={{ m: 5 }}>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <CircularProgressWithLabel
                  variant="indeterminate"
                  value={progress}
                  color="primary"
                />

                <CircularProgressWithLabel
                  variant="indeterminate"
                  value={progress}
                  color="secondary"
                />
                <CircularProgressWithLabel
                  variant="indeterminate"
                  value={progress}
                  color="inherit"
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ProgressUI;
