import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FaceIcon from '@mui/icons-material/Face';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import IconButton from '@mui/material/IconButton';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import classes from './SideNavBarListItems.module.scss';
import Tooltip from '@mui/material/Tooltip';
import paths from '../../../../routing/Constants';
import { useTranslation } from 'react-i18next';
import {
  dashboardMenuFeatureOn,
  orgMenuFeatureOn,
  weMenuFeatureOn,
  leaveFeatureOn,
  leaveManagementFeatureOn,
  teamTimesheetFeatureOn,
} from '../../../../utilities/featureToggleUtils';
import leaveIcon_Grey from '../../../../assets/leaveIconWhite.svg';
import { permissionkey } from '../../../../utilities/roleUtils';
import i18n from '../../../../i18n/i18n';

const leaveIcon_grey = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="25"
    viewBox="0 -960 960 960"
    width="25"
  >
    <path d="M175.769-470.447q-17.551 0-29.897-12.423t-12.346-29.641q0-17.217 12.257-29.73 12.256-12.513 29.807-12.513 16.692 0 29.467 12.424 12.776 12.423 12.776 29.64 0 17.218-12.686 29.73-12.686 12.513-29.378 12.513Zm-7.768 377.126q-13.071 0-21.465-8.35-8.394-8.349-8.394-21.355V-263.23h-19.231q-6.692 0-11.186-4.513-4.493-4.513-4.493-10.833v-137.846q0-10.173 7.257-16.195t18.457-6.022h93.296q11.007 0 18.446 6.022 7.439 6.022 7.439 16.195v137.846q0 6.32-4.833 10.833-4.834 4.513-10.846 4.513h-18.821v122.026h365.848v-244.233h-85.539q-58.957 0-99.875-39.497-40.919-39.498-40.919-95.374 0-44.05 26.911-82.12 26.91-38.071 73.32-55.93 4.397-66.256 49.153-107.493 44.757-41.237 102.692-41.237 57.077 0 101.961 41.237t49.884 107.493q45.885 17.859 73.058 55.93 27.173 38.07 27.173 82.12 0 55.876-41.355 95.374-41.355 39.497-99.849 39.497h-88.732v244.233h179.655q10.231 0 17.218 7.025 6.987 7.026 6.987 16.788 0 10.288-6.987 17.179-6.987 6.89-17.218 6.89H168.001Z" />
  </svg>
);

const SideNavBarListItems = (props) => {
  const updatePath = (path) => {
    const lang = `/${i18n.language}`;
    return `${lang}${path}`;
  };

  //paths
  const rootPath = paths.ROOT;
  const dashboardPath = updatePath('/'.concat(paths.HOME));
  const timeSheetPath = updatePath('/'.concat(paths.ME, '/', paths.TIMESHEET));
  const leavePath = updatePath('/'.concat(paths.ME, '/', paths.LEAVE));
  const leaveManagement = updatePath(
    '/'.concat(paths.WE, '/', paths.LEAVE_MANAGEMENT),
  );
  const organizationPath = updatePath(paths.ROOT.concat(paths.ORGANIZATION));
  const mePath = updatePath('/'.concat(paths.ME));
  const wePath = updatePath('/'.concat(paths.WE));
  const leaveRequestPath = updatePath(
    '/'.concat(paths.ME, '/', paths.LEAVE_REQUEST),
  );
  const teamTimesheet = updatePath(
    '/'.concat(paths.WE, '/', paths.TEAM_TIMESHEET),
  );

  const { t } = useTranslation();
  let open = props.open;
  const [expandMeItems, setExpandMeItems] = useState(false);
  const [expandWeItems, setExpandWeItems] = useState(false);
  const [expandTimesheetItems, setExpandTimesheetItems] = useState(false);
  const { pathname } = useLocation();

  const checkRoles = (roles, role) => {
    return roles != null
      ? roles.find((f) => f.key === role && f.access)
        ? true
        : false
      : false;
  };

  const handleClose = () => {
    // props.handleDrawerToggle()
  };
  // Me Item
  const handleMeItemCollapseExpandClick = (event, index) => {
    setExpandMeItems(!expandMeItems);

    //Collapse timesheet list if me list is collapsed
    if (!expandMeItems) {
      setExpandTimesheetItems(false);
    }
  };

  // We Item
  const handleWeItemCollapseExpandClick = (event, index) => {
    setExpandWeItems(!expandWeItems);
  };

  // Timesheet Item
  const handleTimesheetItemCollapseExpandClick = (event, index) => {
    setExpandTimesheetItems(!expandTimesheetItems);
  };

  useEffect(() => {
    if (pathname === timeSheetPath || timesheetPageIsRoot()) {
      setExpandMeItems(true);
    }
    if (pathname === leavePath || leaveSheetPageIsRoot()) {
      setExpandMeItems(true);
    }
    if (pathname === leaveManagement || leaveManagementSheetPageIsRoot()) {
      setExpandMeItems(true);
    }
    if (pathname === leaveRequestPath || leaveSheetPageIsRoot()) {
      setExpandMeItems(true);
    }
    if (pathname === teamTimesheet || teamTimesheetPageIsRoot()) {
      setExpandWeItems(true);
    }
  }, [pathname]);

  /* FEATURE TOGGLE CODE CLEANUP - remove function*/
  const timesheetPageIsRoot = () => {
    return dashboardMenuFeatureOn ? false : pathname === rootPath;
  };
  const leaveSheetPageIsRoot = () => {
    return leaveFeatureOn ? false : pathname === rootPath;
  };
  const leaveManagementSheetPageIsRoot = () => {
    return leaveManagementFeatureOn ? false : pathname === rootPath;
  };
  const teamTimesheetPageIsRoot = () => {
    return teamTimesheetFeatureOn ? false : pathname === rootPath;
  };

  return (
    <React.Fragment>
      {dashboardMenuFeatureOn &&
        checkRoles(props.roleList, permissionkey.dashboard) && (
          <Link
            to={dashboardPath}
            className={classes.navLink}
            key={'Dashboard'}
          >
            <ListItemButton
              key="Dashboard"
              sx={{
                justifyContent: open ? 'initial' : 'center',
              }}
              className={classes.main_navLink}
              selected={pathname === rootPath || pathname === dashboardPath}
              onClick={(event) => {
                handleClose();
              }}
            >
              <ListItemIcon
                sx={{
                  mr: open ? 3 : 'auto',
                }}
                className={classes.main_navLink_icon}
              >
                <Tooltip title="Dashboard" placement="right">
                  <DashboardIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="#E0E0E0">
                    {t('dashboard.dashboard')}
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        )}
      {checkRoles(props.roleList, permissionkey.empTimesheet) && (
        <Link
          to={timeSheetPath}
          className={classes.navLink}
          key={'empTimesheet'}
        >
          <ListItemButton
            key="empTimesheet"
            sx={{
              justifyContent: open ? 'initial' : 'center',
            }}
            className={classes.main_navLink}
            selected={pathname === timeSheetPath || timesheetPageIsRoot()}
            onClick={(event) => {
              handleClose();
            }}
          >
            <ListItemIcon
              sx={{
                mr: open ? 3 : 'auto',
              }}
              className={classes.main_navLink_icon}
            >
              <Tooltip title="Timesheet" placement="right">
                <PunchClockIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="#E0E0E0">
                  {t('dashboard.timesheet')}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      )}
      {checkRoles(props.roleList, permissionkey.empLeave) && (
        <Link to={leavePath} className={classes.navLink} key={'empLeave'}>
          <ListItemButton
            key="empLeave"
            sx={{
              justifyContent: open ? 'initial' : 'center',
            }}
            className={classes.main_navLink}
            selected={pathname === leavePath}
            onClick={(event) => {
              handleClose();
            }}
          >
            <ListItemIcon
              sx={{
                mr: open ? 3 : 'auto',
              }}
              className={classes.main_navLink_icon}
            >
              <Tooltip title="Leave" placement="right">
                {leaveIcon_grey}
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="#E0E0E0">
                  {t('dashboard.leave')}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      )}
      {checkRoles(props.roleList, permissionkey.me) && (
        <ListItemButton
          key="Me"
          sx={{
            justifyContent: open ? 'initial' : 'center',
          }}
          className={`${classes.main_navLink} ${
            (expandMeItems || pathname.includes(mePath, 0)) &&
            classes.item_selected
          }`}
          onClick={(event) => {
            handleMeItemCollapseExpandClick();
          }}
        >
          <ListItemIcon
            sx={{
              mr: open ? 3 : 'auto',
            }}
            className={classes.main_navLink_icon}
          >
            <Tooltip title="Me" placement="right">
              <FaceIcon />
            </Tooltip>
            {props.open ? null : (
              <>
                <ArrowRightIcon className={classes.responsive_arrow} />
              </>
            )}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                className={classes.main_menu}
                variant="body2"
                color="#E0E0E0"
              >
                {t('dashboard.me')}
              </Typography>
            }
            sx={{ opacity: open ? 1 : 0 }}
          />

          {
            <IconButton
              className={open ? classes.arrow : classes.arrow_collapse}
            >
              {expandMeItems ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          }
        </ListItemButton>
      )}
      <Collapse in={expandMeItems} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nav_sub_list}>
          {checkRoles(props.roleList, permissionkey.meTimesheet) && (
            <Link
              to={timeSheetPath}
              className={classes.navLink}
              key="teamTimesheet"
            >
              <ListItemButton
                key="teamTimesheet"
                selected={pathname === timeSheetPath || timesheetPageIsRoot()}
                className={`${classes.main_navLink} ${
                  expandMeItems && classes.item_selected_sub
                }`}
                onClick={(event) => {
                  handleClose();
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: open ? 3 : 'auto',
                  }}
                  className={classes.main_navLink_icon}
                >
                  <Tooltip title="Me - Timesheet" placement="right">
                    <PunchClockIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="#E0E0E0">
                      {t('dashboard.timesheet')}
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          )}

          {checkRoles(props.roleList, permissionkey.meLeave) && (
            <Link to={leavePath} className={classes.navLink} key="Leavesheet">
              <ListItemButton
                key="Leavesheet"
                selected={pathname === leavePath}
                className={`${classes.main_navLink} ${classes.nature_icon} ${
                  expandMeItems && classes.item_selected_sub
                }`}
                onClick={(event) => {
                  handleClose();
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: open ? 3 : 'auto',
                  }}
                  className={classes.main_navLink_icon}
                >
                  <Tooltip title="Me - Leave" placement="right">
                    {leaveIcon_grey}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="#E0E0E0">
                      {t('dashboard.leave')}
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          )}
        </List>
      </Collapse>

      {/* We Item */}
      {/* FEATURE TOGGLE CODE CLEANUP - remove toggle*/}
      {checkRoles(props.roleList, permissionkey.we) && (
        <React.Fragment>
          <ListItemButton
            key="We"
            sx={{
              justifyContent: open ? 'initial' : 'center',
            }}
            selected={pathname === wePath}
            className={`${classes.main_navLink} ${
              (expandWeItems || pathname.includes(wePath, 0)) &&
              classes.item_selected
            }`}
            onClick={(event) => {
              handleWeItemCollapseExpandClick();
            }}
          >
            <ListItemIcon
              sx={{
                mr: open ? 3 : 'auto',
              }}
              className={classes.main_navLink_icon}
            >
              <Tooltip title="We" placement="right">
                <SupervisedUserCircleOutlinedIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="#E0E0E0">
                  {t('dashboard.we')}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
            {
              <IconButton
                className={open ? classes.arrow : classes.arrow_collapse}
              >
                {expandWeItems ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            }
          </ListItemButton>

          <Collapse in={expandWeItems} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              className={classes.nav_sub_list}
            >
              {checkRoles(props.roleList, permissionkey.weTimesheet) && (
                <>
                  {teamTimesheetFeatureOn && (
                    <Link
                      to={teamTimesheet}
                      className={classes.navLink}
                      key="leaveManagement"
                    >
                      <ListItemButton
                        key="leaveManagement"
                        selected={pathname === teamTimesheet}
                        className={`${classes.main_navLink} ${
                          expandWeItems && classes.item_selected_sub
                        }`}
                        onClick={(event) => {
                          handleClose();
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            mr: open ? 3 : 'auto',
                          }}
                          className={classes.main_navLink_icon}
                        >
                          <Tooltip title="We - Timesheet" placement="right">
                            <PunchClockIcon />
                          </Tooltip>
                        </ListItemIcon>

                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body2" color="#E0E0E0">
                              {t('dashboard.timesheet')}
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  )}
                </>
              )}
              {/*Leave-Management-feature*/}
              {checkRoles(props.roleList, permissionkey.weLeave) && (
                <Link
                  to={leaveManagement}
                  className={classes.navLink}
                  key="leaveManagement"
                >
                  <ListItemButton
                    key="leaveManagement"
                    selected={pathname === leaveManagement}
                    className={`${classes.main_navLink} ${
                      expandWeItems && classes.item_selected_sub
                    }`}
                    onClick={(event) => {
                      handleClose();
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : 'auto',
                      }}
                      className={classes.main_navLink_icon}
                    >
                      <Tooltip title="We - Leave" placement="right">
                        {leaveIcon_grey}
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2" color="#E0E0E0">
                          {t('dashboard.leave')}
                        </Typography>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              )}
            </List>
          </Collapse>
        </React.Fragment>
      )}

      {/* </Link> */}

      {/* Organization Item */}
      {/* FEATURE TOGGLE CODE CLEANUP - remove toggle*/}
      {checkRoles(props.roleList, permissionkey.organization) && (
        <Link
          to={organizationPath}
          className={classes.navLink}
          key={'Organization'}
        >
          <ListItemButton
            key="Organization"
            sx={{
              justifyContent: open ? 'initial' : 'center',
            }}
            className={classes.main_navLink}
            selected={pathname === organizationPath}
            onClick={(event) => {
              handleClose();
            }}
          >
            <ListItemIcon
              sx={{
                mr: open ? 3 : 'auto',
              }}
              className={classes.main_navLink_icon}
            >
              <Tooltip title="Organization" placement="right">
                <WorkspacesRoundedIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="#E0E0E0">
                  {t('dashboard.organization')}
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      )}
    </React.Fragment>
  );
};

export default SideNavBarListItems;
