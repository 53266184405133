import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import FormButtonLoading from '../../ui/formcontrols/formButtonLoading/FormButtonLoading';
import ContentLayout from '../../ui/layout/contentlayout/ContentLayout';
import TextBox from '../../ui/formcontrols/textBox/TextBox';
import ErrorAlert from '../../ui/alerts/errorAlert/ErrorAlert';
import LinkedinButton from '../../ui/socialButtons/linkedinButton/LinkedinButton';
import classes from './Signup.module.scss';
import paths from '../../../routing/Constants';
import EmailVerification from './emailVerification/EmailVerification';
import SignUpForm from './signupForm/SignUpForm';
import TermsAndConditions from './termsAndConditions/TermsAndConditions';
import i18n from '../../../i18n/i18n';
import lettermarkLogo from '../../../assets/meewee-lettermark-logo.svg';
import { useTranslation } from 'react-i18next';
import loginbl from '../../../assets/login-bl-arc.svg';
import logintr from '../../../assets/login-tr-arc.svg';

import {
  validateEmailAddress,
  sendVerificationEmail,
} from '../service/signupService';
// paths

const Signup = () => {
  const loginPath = `/${i18n.language}/${paths.LOGIN}`;
  const { t } = useTranslation();
  document.title = t('login.sign-up-meewee');
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageView, setPageView] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //email user input validation
  const [inputEmail, setInputEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  //form error
  const [formError, setFormError] = useState('');

  const onEmailChangeHandler = (event) => {
    setInputEmail(event.target.value);
    if (errorMessage) {
      setErrorMessage('');
      setEmailHasError(false);
    }

    if (formError) {
      setFormError('');
    }
  };

  useEffect(() => {
    if (inputEmail) {
      const timeOut = setTimeout(() => {
        validateEmailAddress({ email: inputEmail, field: 'email' }).then(
          (data) => {
            if (data.result === 1) {
              setDisableButton(false);
            } else {
              setErrorMessage(data.errorMessage);
              setEmailHasError(true);
              setDisableButton(true);
            }
          },
        );
      }, 800);

      return () => clearTimeout(timeOut);
    } else {
      setDisableButton(true);
    }
  }, [inputEmail]);

  useEffect(() => {
    const key = searchParams.get('key');

    if (key !== 'creatingAccount') {
      switch (key) {
        case 'init':
          setPageView('init');
          break;
        case 'verify':
          setPageView('verify');
          break;
        case 'profileDetails':
          setPageView('profileDetails');
          break;
        case 'termsAndConditions':
          setPageView('termsAndConditions');
          break;
        default:
          setPageView('init');
      }
    }
  }, [searchParams]);

  const onClickContinueHandler = (event) => {
    setIsLoading(true);
    sendVerificationEmail({ email: inputEmail }).then((data) => {
      setIsLoading(false);
      if (data.result === 1) {
        setSearchParams({ key: 'verify' });
      } else {
        setFormError(data.errorMessage);
      }
    });
  };

  const onLinkedinSignUpHandler = (url) => {
    window.location.href = url;
  };

  return (
    <ContentLayout className="content_wrapper">
      {pageView === 'init' && (
        <>
          <Grid item>
            <img src={logintr} alt="meewee logo" className={classes.logintr} />
          </Grid>
          <Grid container direction="row" className={classes.grid_parent}>
            <Grid
              item
              lg={6}
              md={12}
              xs={12}
              className={`${classes.signup_grid} ${classes.signup_display}`}
            >
              <div className={classes.grid_right}>
                <Typography variant="heading" color="text.primary" align="left">
                  Brug{' '}
                  <img
                    src={lettermarkLogo}
                    className={classes.logoHeading}
                    alt="meewee lettermark"
                  />{' '}
                  helt gratis <br />– få adgang nu
                </Typography>
                <Typography color="gray.light">
                  Tilmeld dig en gratis personlig konto. Tag den med dig, uanset
                  hvor du arbejder.
                </Typography>
                <Typography color="gray.light">
                  Du har adgang til alle dine tidligere tidsregistreringer, også
                  når du skifter arbejdsplads eller får nye kunder.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} md={12} xs={12} className={classes.signup_grid}>
              <Grid className={classes.signup_container}>
                <div className={classes.signup_wrapper}>
                  <div className={classes.grid_mobile}>
                    <Typography
                      variant="heading"
                      color="text.primary"
                      align="left"
                    >
                      Brug{' '}
                      <img
                        src={lettermarkLogo}
                        className={classes.logoHeading}
                        alt="meewee logo"
                      />{' '}
                      helt gratis <br />– få adgang nu
                    </Typography>
                    <Typography color="gray.light">
                      Tilmeld dig en gratis personlig konto. Tag den med dig,
                      uanset hvor du arbejder.
                    </Typography>
                    <Typography color="gray.light">
                      Du har adgang til alle dine tidligere tidsregistreringer,
                      også når du skifter arbejdsplads eller får nye kunder.
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      color="gray.light"
                      className={classes.lettermark_logo}
                    >
                      {/* {t('login.sign-up-head')} */}
                      Du skal blot angive din e-mail-adresse herunder. Så er du
                      i gang.
                    </Typography>
                  </div>
                  <div className={classes.email_field}>
                    <TextBox
                      isError={emailHasError}
                      id="email"
                      label={t('login.email')}
                      type="text"
                      onChange={onEmailChangeHandler}
                      errorText={errorMessage}
                      ariaLabel="Email"
                    />
                    <div className={classes.continue_button}>
                      <FormButtonLoading
                        id="continue-button"
                        label={t('common.continue')}
                        loadingIndicator="Continue"
                        loading={isLoading}
                        onClick={onClickContinueHandler}
                        fullWidth={true}
                        loadingPosition="start"
                        color="primary"
                        disabled={disableButton}
                      />
                    </div>
                    {formError && (
                      <div className={classes.signup_error_alert}>
                        <ErrorAlert id="sign-up-error" message={formError} />
                      </div>
                    )}
                  </div>
                  <div>
                    <Typography variant="body2" color="text.secondary">
                      {t('login.or-sign-up-using')}
                    </Typography>

                    <div className={classes.socials_container}>
                      <LinkedinButton
                        onClick={onLinkedinSignUpHandler}
                        signin={false}
                      ></LinkedinButton>
                    </div>

                    <Divider />

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={classes.sign_in_text}
                    >
                      {t('login.already-have-an-account')}? &nbsp;
                      <Box component="span" color="info.main">
                        <Link to={loginPath} className={classes.sign_in_link}>
                          {t('login.sign-in-here')}
                        </Link>
                      </Box>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.loginbl}>
              <img
                src={loginbl}
                alt="meewee logo"
                className={classes.imgLogintr}
              />
            </div>
          </Grid>
        </>
      )}
      {pageView === 'verify' && <EmailVerification email={inputEmail} />}
      {pageView === 'profileDetails' && <SignUpForm />}
      {pageView === 'termsAndConditions' && <TermsAndConditions />}
    </ContentLayout>
  );
};
export default Signup;
