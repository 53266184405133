import React, { useState } from 'react';
import classes from './TeamList.module.scss';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  Grid,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const TeamList = (props) => {
  const { t } = useTranslation();
  const { list } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openViewMore, setOpenViewMore] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isActiveTab, setIsActiveTab] = useState(true);

  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const onClickTeamName = (item) => {
    props.onManageTeam(item);
    handleClose();
  };

  const columnsWithoutStatus = [
    {
      field: 'label',
      headerName: t('organization.team-name'),
      minWidth: 180,
      flex: 1,
      renderCell: (team) => {
        return (
          <React.Fragment>
            {/* <div className={classes.pad}>{item?.row.name}</div> */}
            <div
              className={`${classes.pad} ${classes.name_hyperlink_div}`}
              onClick={(e) => {
                onClickTeamName(team.row);
              }}
            >
              {team?.row.label}
            </div>

            <div className={classes.task_menu_button_container}>
              <IconButton
                className={classes.task_menu_button}
                onClick={(e) => handleClick(e, team.row)}
              >
                <MoreVertIcon
                  className={`${!open && classes.task_menu_button}`}
                  aria-label="reg menu"
                  aria-controls={open ? 'reg-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                />
              </IconButton>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'managerName',
      headerName: t('organization.team-manager'),
      minWidth: 150,
      flex: 1,
    },
  ];

  const columns = columnsWithoutStatus;

  const DatagridActions = () => {
    return (
      <GridToolbarContainer className={classes.toolBarContainer}>
        <Grid container>
          <GridToolbarFilterButton />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const handleClose = () => {
    setOpenViewMore(false);
    setAnchorEl(null);
  };
  const onDeactivateHandler = () => {
    props.onDeactivate(selectedItem);
    handleClose();
  };

  const onReactivateHandler = () => {
    props.onReactivate(selectedItem);
    handleClose();
  };

  const onManageTeamHandler = () => {
    props.onManageTeam(selectedItem);
    handleClose();
  };

  return (
    <div className={classes.container}>
      <Menu
        className="menuReg"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          className={classes.menu_cell_icon}
          onClick={onManageTeamHandler}
        >
          <EditIcon className={classes.menu_icon} />
          {t('organization.manage-team')}
        </MenuItem>

        {props.isActiveTab ? (
          <MenuItem onClick={() => onDeactivateHandler()}>
            <DoNotDisturbAltIcon className={classes.menu_icon} />
            {t('organization.deactivate')}
          </MenuItem>
        ) : (
          <MenuItem onClick={() => onReactivateHandler()}>
            <CheckIcon className={classes.menu_icon} />
            {t('organization.reactivate')}
          </MenuItem>
        )}
      </Menu>
      <div
        className={classes.filter_tab}
        style={{ height: 400, width: '100%' }}
      >
        <DataGridContainer
          autoHeight={true}
          columns={columns}
          rows={list}
          getRowHeight={() => 'auto'}
          components={{
            Toolbar: DatagridActions,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 100]}
          sx={{
            '.MuiTablePagination-root': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          localeText={{
            toolbarFilters: t('organization.filters'),
          }}
        />

        {props.loader && (
          <div
            className={
              classes.box + ' ' + classes.stackTop + ' ' + classes.box2
            }
          >
            <div className={classes.center}>
              <CircularProgress />
              <Typography>{t('organization.getting-team-list')} </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamList;
