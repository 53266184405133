import { useReducer } from "react";

const initialInputState = {
  value: "",
  isTouched: false,
};

const inputStateReducer = (prevState, action) => {
  if (action.type === "change") {
    return { value: action.value, isTouched: prevState.isTouched };
  }

  if (action.type === "autocomplete change") {
    return { value: action.value, isTouched: true };
  }

  if (action.type === "blur") {
    return { value: prevState.value, isTouched: true };
  }

  if (action.type === "select") {
    return { value: prevState.value, isTouched: prevState.isTouched };
  }

  return initialInputState;
};

const useInputValidation = (
  inputValueValidation,
  changeCallback = () => { }
) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = inputValueValidation(inputState.value);
  const hasError = !valueIsValid && inputState.isTouched;

  const valueChangeHandler = (event) => {
    dispatch({ type: "change", value: event.target.value });
    changeCallback();
  };

  const inputBlurHandler = () => {
    dispatch({ type: "blur" });
  };

  const autoCompleteChangeHandler = (event, value) => {
    dispatch({ type: "autocomplete change", value: value ? value : "" });
  };

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    autoCompleteChangeHandler,
    inputBlurHandler,
  };
};

export default useInputValidation;
