import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import classes from './EmailVerification.module.scss';
import paths from '../../../../routing/Constants';
import i18n from '../../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

// paths

const EmailVerification = (props) => {
  const loginPath = `/${i18n.language}/${paths.LOGIN}`;
  const { t } = useTranslation();
  document.title = t('login.sign-up-meewee');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!props.email) {
      searchParams.delete('key');
      navigate({ search: searchParams.toString() });
    }
  }, []);

  return (
    <div className="email-verification">
      {props.email && (
        <Grid
          container
          direction="column"
          spacing={5}
          className={classes.email_container}
        >
          <Grid item className={classes.email_heading}>
            <Typography variant="heading">
              {t('login.we-have-sent-you-an-email')} <br /> {t('login.to')}{' '}
              &nbsp;
              {props.email ? props.email : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t('login.to-finish-setting-up-your-account')}, <br />{' '}
              {t('login.please-check-your-inbox-and-look-for-our-email')} <br />{' '}
              {t('login.then-click-the-link-to-confirm')}
            </Typography>
          </Grid>
          <Grid item className={classes.email_footer}>
            <Divider variant="middle" />
            <Typography
              variant="body2"
              color="text.secondary"
              className={classes.sign_in_text}
            >
              {t('login.already-have-an-account')}? &nbsp;
              <Box component="span" color="info.main">
                <Link to={loginPath} className={classes.sign_in_link}>
                  {t('login.sign-in-here')}
                </Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default EmailVerification;
