import List from '@mui/material/List';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { permissionkey } from '../../../../utilities/roleUtils';

import {
  createProjectFeatureOn,
  orgMyTeammatesMenuFeatureOn,
  peronalOrgInformationMenuFeatureOn,
} from '../../../../utilities/featureToggleUtils';
import paths from '../../../../routing/Constants';

const { ORGANIZATION_PATHS } = paths;

export const employeeMenuList = (t) => {
  return orgMyTeammatesMenuFeatureOn
    ? [
        {
          key: 0,
          name: 'teamsAndProjects',
          label: t('organization.my-teams-projects'),
          alias: 'Teams & Projects',
          subPath: ORGANIZATION_PATHS.TEAMS_AND_PROJECT,
        },
        {
          key: 1,
          name: 'teammates',
          label: t('organization.my-teammates'),
          alias: 'Teammates',
          subPath: ORGANIZATION_PATHS.TEAMMATES,
        },
      ]
    : [
        {
          key: 0,
          name: 'teamsAndProjects',
          label: t('organization.my-teams-projects'),
          alias: 'Teams & Projects',
          subPath: ORGANIZATION_PATHS.TEAMS_AND_PROJECT,
        },
      ];
};

export const adminMenuList = (t) => {
  return createProjectFeatureOn
    ? [
        {
          key: 2,
          name: 'info',
          label: t('organization.information'),
          alias: '',
          permissionKey: permissionkey.orgInformation,
          subPath: `${ORGANIZATION_PATHS.INFORMATION}/${ORGANIZATION_PATHS.INFORMATION_PATHS.VIEW_INFORMATION}`,
        },
        {
          key: 3,
          name: 'members',
          label: t('organization.all-members'),
          alias: '',
          permissionKey: permissionkey.orgAllMembers,
          subPath: ORGANIZATION_PATHS.ALL_MEMBERS,
        },
        {
          key: 4,
          name: 'projects',
          label: t('organization.all-projects'),
          alias: '',
          permissionKey: permissionkey.orgAllProject,
          subPath: `${ORGANIZATION_PATHS.ALL_PROJECTS}/${ORGANIZATION_PATHS.ALL_PROJECTS_PATHS.LIST}`,
        },
        {
          key: 5,
          name: 'teams',
          label: t('organization.all-teams'),
          alias: '',
          permissionKey: permissionkey.orgAllTeams,
          subPath: `${ORGANIZATION_PATHS.ALL_TEAMS}/${ORGANIZATION_PATHS.ALL_TEAMS_PATHS.LIST}`,
        },
      ]
    : [
        {
          key: 2,
          name: 'info',
          label: t('organization.information'),
          alias: '',
          permissionKey: permissionkey.orgInformation,
          subPath: `${ORGANIZATION_PATHS.INFORMATION}/${ORGANIZATION_PATHS.INFORMATION_PATHS.VIEW_INFORMATION}`,
        },
        {
          key: 3,
          name: 'members',
          label: t('organization.all-members'),
          alias: '',
          permissionKey: permissionkey.orgAllMembers,
          subPath: ORGANIZATION_PATHS.ALL_MEMBERS,
        },
        {
          key: 5,
          name: 'teams',
          label: t('organization.all-teams'),
          alias: '',
          permissionKey: permissionkey.orgAllTeams,
          subPath: `${ORGANIZATION_PATHS.ALL_TEAMS}/${ORGANIZATION_PATHS.ALL_TEAMS_PATHS.LIST}`,
        },
      ];
};
export const personalMenuList = (t) => {
  return [
    peronalOrgInformationMenuFeatureOn && {
      key: 6,
      name: 'info',
      label: t('organization.information'),
      alias: '',
      permissionKey: permissionkey.orgInformation,
      subPath: ORGANIZATION_PATHS.MY_INFORMATION,
    },
    {
      key: 7,
      name: 'projects',
      label: t('organization.my-projects'),
      alias: '',
      permissionKey: permissionkey.orgMeProject,
      subPath: `${ORGANIZATION_PATHS.MY_PROJECTS}/${ORGANIZATION_PATHS.MY_PROJECTS_PATHS.LIST}`,
    },
  ];
};

export const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  paddingTop: '26px',
  width: '200px',
  height: '100vh',
  borderRadius: '0',
  border: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const ListMenu = styled(List)(({ theme }) => ({
  '& .Mui-selected p': {
    fontWeight: '600',
  },
}));
