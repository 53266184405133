import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useState } from "react";
import Popover from "@mui/material/Popover";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [".MuiTooltip-arrow"]: {
    "&:before": {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    placement="bottom"
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const TooltipUI = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6">Tooltip</Typography>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="subtitle2">Dark</Typography>
            <Typography variant="subtitle2">Light</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Default*</Typography>
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <LightTooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          </Stack>
        </Box>
        <Box sx={{ m: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Arrow Top</Typography>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <LightTooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          </Stack>
        </Box>
        <Box sx={{ m: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Arrow Right</Typography>
            <Tooltip title="Delete" placement="right" arrow>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <LightTooltip title="Delete" placement="right" arrow>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          </Stack>
        </Box>
        <Box sx={{ m: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Arrow Bottom</Typography>
            <Tooltip title="Delete" placement="bottom" arrow>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <LightTooltip title="Delete" placement="bottom" arrow>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          </Stack>
        </Box>
        <Box sx={{ m: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Typography variant="subtitle2">Arrow Left</Typography>
            <Tooltip title="Delete" placement="left" arrow>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <LightTooltip title="Delete" placement="left" arrow>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          </Stack>
        </Box>
      </Box>
      <Typography variant="h6">Example</Typography>
      <CustomWidthTooltip title={longText}>
        <Button sx={{ ml: 12 }}>Custom Width [300px]</Button>
      </CustomWidthTooltip>
      <Typography variant="h6">Popover</Typography>
      <Box sx={{ ml: 12 }}>
        <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          Open Popover
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover>
      </Box>
    </Box>
  );
};

export default TooltipUI;
