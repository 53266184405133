import { updatePendingTeamInvitation } from '../../../store/orgContextSlice';
import httpRequestHandler from '../../../utilities/httpRequestHandler';
import {
  createTransitData,
  getTransitDataCollection,
} from '../../../utilities/transitJsUtils';
import store from '../../../store/store';
import { setAuthToken } from '../../../store/authContextSlice';
import { saveStateToLocalStorage } from '../../../utilities/localStorageUtlis';

export const acceptInvitationService = async (acceptInviteObj) => {
  try {
    let requestParamObj = {
      email: { value: acceptInviteObj.email, isKeyword: false },
      id: {
        value: acceptInviteObj['id'],
        isKeyword: false,
      },
    };

    let mappedrequestParamObj = createTransitData(requestParamObj);
    const response = await httpRequestHandler(
      acceptInvitationInfo,
      mappedrequestParamObj,
    );

    if (response.result === 1) {
      let mappedResponseDataObj = {
        // teamId: "db/id",
        // teamOwner: "team/owner",
        teamName: 'team/name',
        // teamManager: "team/manager",
      };
      let mappedResponseData = getTransitDataCollection(
        response.data,
        mappedResponseDataObj,
      );

      response.data = mappedResponseData;
    }
    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const asyncAcceptInvitationService = (acceptInviteObj) => {
  return async (dispatch) => {
    const userId = store.getState().userContext.userId;
    try {
      let requestParamObj = {
        email: { value: acceptInviteObj.userEmail, isKeyword: false },
        id: {
          value: acceptInviteObj.id,
          isKeyword: false,
        },
      };

      let mappedrequestParamObj = createTransitData(requestParamObj);
      const response = await httpRequestHandler(
        acceptInvitationInfo,
        mappedrequestParamObj,
      );

      if (response.result === 1) {
        let mappedResponseDataObj = {
          teamId: 'db/id',
          refreshSessionToken: 'meewee-session',
          teamOwner: 'team/owner',
          teamName: 'team/name',
          teamManager: 'team/manager',
        };
        let mappedResponseData = getTransitDataCollection(
          response.data,
          mappedResponseDataObj,
        );

        if (mappedResponseData.refreshSessionToken) {
          dispatch(
            setAuthToken({
              authToken: mappedResponseData.refreshSessionToken,
              loginType: 'DIRECT',
            }),
          );

          const authContext = store.getState()['authContext'];
          saveStateToLocalStorage('authContext', authContext);
        }

        dispatch(
          updatePendingTeamInvitation({
            invitationId: acceptInviteObj.id,
            userId,
          }),
        );
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const asyncDeclinetInvitationService = (ignoreInviteObj) => {
  return async (dispatch) => {
    const userId = store.getState().userContext.userId;
    try {
      let requestParamList = [];
      let requestParamObj = {
        id: {
          value: ignoreInviteObj.id,
          isKeyword: false,
        },
        expiration: {
          value: new Date(),
          isKeyword: false,
        },
      };

      let mappedrequestParamObj = createTransitData(requestParamObj);
      requestParamList.push(JSON.parse(mappedrequestParamObj));
      const response = await httpRequestHandler(
        ignoreInvitationInfo,
        mappedrequestParamObj,
      );

      if (response.result === 1) {
        dispatch(
          updatePendingTeamInvitation({
            invitationId: ignoreInviteObj.id,
            userId,
          }),
        );
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const acceptInvitationInfo = {
  url: 'accept-invitation',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const ignoreInvitationInfo = {
  url: 'ignore-invitation',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};
