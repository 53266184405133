import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Typography, TextField, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import classes from './OwnerAdmins.module.scss';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getListOfActiveMembers } from '../../../../../service/orgmanagementService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import ConfirmationDialogBox from '../../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import moment from 'moment';
import { DialogActionsType } from '../../../../../../../utilities/constantUtils';
import DialogChangeOwner from './DialogChangeOwner/DialogChangeOwner';
import { useTranslation } from 'react-i18next';

const filterNonAdmins = (builtEmployeeListData, AdminListData) => {
  const idsToFilter = AdminListData?.map((item) => item.id);
  return builtEmployeeListData?.filter(
    (item) => !idsToFilter?.includes(item.id),
  );
};

const OwnerAdmins = (props) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [organization, setOrganization] = useState(props?.organization);
  const [divlistOfAdmin, setDivListOfAdmin] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const activeMembers = useSelector(
    (state) => state.orgContext.activeMemberList,
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: '',
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    submitLabelText: t('common.save'),
  });

  const removeType = t('organization.remove').toUpperCase();
  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const onSubmit = async () => {
    handleCloseDialogBox();
    setDialogOpen(true);
  };

  const changeOwnerHandler = async () => {
    var value = {
      title: t('organization.change-organization-owner'),
      description: t(
        'organization.are-you-sure-you-want-to-change-the-owner-of-this-organization',
      ),
      submitLabelText: t('organization.yes-continue'),
      type: null,
      actionType: DialogActionsType.success,
      leaveType: t('organization.approve-all').toUpperCase(),
    };
    setconfirmationBoxInfo(value);
    dialogBox(value, t('common.cancel'));
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.submitLabelText,
      actionType: value.actionType,
      leaveType: value.leaveType,
    }));
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    selectEmployee.current = item;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOrganization(props?.organization);
    assignAdmin();
    loadActiveMembers(props?.organization?.id);
  }, [props?.organization]);

  const assignAdmin = () => {
    setOwnerList(props?.organization?.admins);
    setDivListOfAdmin(listAdminName(props?.organization?.admins));
  };

  const viewOwners = () => {
    return listName(props?.organization?.owner);
  };

  const loadActiveMembers = async (id) => {
    await dispatch(getListOfActiveMembers(props?.employmentId));
  };

  const [employeeList, setEmployeeList] = useState([]);
  const [owneremployeeList, setOwneremployeeList] = useState([]);
  const [unsavedAdminContainer, setUnsavedAdminContainer] = useState(
    props?.organization?.admins,
  );

  useEffect(() => {
    if (activeMembers) {
      const builtEmployeeListData = activeMembers.map((f) => {
        var item = {
          firstName: f.memberFirstName,
          id: f.id,
          lastName: f.memberLastName,
          name: f.name,
        };
        return item;
      });

      const employees = filterNonAdmins(
        builtEmployeeListData,
        unsavedAdminContainer,
      );
      setOwneremployeeList(builtEmployeeListData);
      setEmployeeList(
        employees.filter(
          (f) =>
            f.firstName !== props?.organization?.owner?.[0]?.firstName &&
            f.lastName !== props?.organization?.owner?.[0]?.lastName,
        ),
      );
    }
  }, [activeMembers, unsavedAdminContainer]);

  const listName = (owner) => {
    var result = [];
    owner.forEach((f) => {
      var item = (
        <Grid item xs={3}>
          <div className={classes.flex}>
            <div className="padR5">
              <div className="padR10">
                {' '}
                <Avatar
                  className={classes.avatarName}
                  alt={f.name}
                  src="/broken-image.jpg"
                />
              </div>
            </div>
            <div className="padT1">
              <Typography className={classes.avatar}>{f.name}</Typography>
              <Typography className={classes.avatar}>
                {t('organization.owner')}
              </Typography>
            </div>
          </div>
        </Grid>
      );
      result.push(item);
    });

    return result;
  };

  const AddHandler = () => {
    var newOwnerList = [];
    var item = {
      firstName: '',
      id: null,
      lastName: '',
      name: '',
    };

    newOwnerList.push(item);
    setUnsavedAdminContainer([item, ...unsavedAdminContainer]);
    newOwnerList = newOwnerList.concat([...ownerList]);
    setDivListOfAdmin(listAdminName(newOwnerList));
  };

  const addEmployeeHandler = (_, index) => {
    var nownerList = [...ownerList];
    var f = selectEmployee.current;
    var item = {
      firstName: f.firstName,
      id: f.id,
      lastName: f.lastName,
      name: f.name,
      createdById: userId,
      createdBy: `${userFirstName} ${userLastName}`,
      createdDate: new Date(),
    };
    nownerList.push(item);
    setOwnerList(nownerList);
    setUnsavedAdminContainer([item, ...unsavedAdminContainer]);
    setDivListOfAdmin(listAdminName(nownerList));
    props.onChangeAdmins(checkChangeAdmin(nownerList), nownerList);
  };

  const onRemoveHandler = () => {
    var emp = selectEmployee.current;
    const filteredUnsavedAdmins = unsavedAdminContainer.filter(
      (unsavedAdmin) => unsavedAdmin.id !== selectEmployee.current.id,
    );
    setUnsavedAdminContainer(filteredUnsavedAdmins);
    var nownerList = ownerList.filter((f) => f.id !== emp.id);
    setOwnerList(nownerList);
    setDivListOfAdmin(listAdminName(nownerList));
    props.onChangeRemoveAdmins(true, nownerList);
    handleClose();
  };

  const checkChangeAdmin = (value) => {
    var result = false;
    value.forEach((f) => {
      var check = props.organization.admins.find((ff) => ff.id === f.id);
      if (!check) {
        result = true;
        return result;
      }
    });
    return result;
  };

  const cancelHandler = (index) => {
    setDivListOfAdmin(listAdminName(ownerList));
  };

  const selectEmployee = useRef(null);
  const onSelectEmployee = (event, item) => {
    selectEmployee.current = item;
  };

  const listAdminName = (owner) => {
    var result = [];
    owner?.forEach((f, index) => {
      var item = f.id ? (
        <div>
          <Divider />
          <div
            className={`${classes.flexContainer} ${classes.pad} ${classes.btn}  padT5`}
          >
            <div className={`${classes.row}`}>
              <div className={classes.flex}>
                <div className="padR5 padT5">
                  <div className="padR10">
                    {' '}
                    <Avatar
                      className={classes.avatarNameSmall}
                      alt={f.name}
                      src="/broken-image.jpg"
                    />
                  </div>
                </div>
                <div className="padT12">
                  <Typography className={classes.avatar}>{f.name}</Typography>
                </div>
                <div className={`padT3 ${classes.alignRight}`}>
                  <div className={` ${classes.alignRight} padT2`}>
                    <IconButton
                      //  className={classes.task_menu_button}
                      onClick={(e) => handleClick(e, f)}
                    >
                      <MoreVertIcon
                        className={`${!open && classes.task_menu_button}`}
                        aria-label="reg menu"
                        aria-controls={open ? 'reg-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.row} padT9`}>
              <Typography className={classes.titleLabel2}>
                {f.createdBy} on{' '}
                {moment(f.createdDate).format(dateFormats.moment_datepicker)}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Divider />
          <div
            className={`${classes.flexContainer} ${classes.pad} ${classes.btn}  padT5`}
          >
            <div className={`${classes.row}`}>
              <div className={classes.flex}>
                <div className="padR5">
                  <div className="padR10">
                    <Autocomplete
                      disablePortal
                      id="employees"
                      //value={f}
                      defaultValue={null}
                      //defaultValue={'All employees'}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <div className="padR10">
                            {' '}
                            <Avatar
                              className={classes.avatarNameSmall}
                              alt={option.name}
                              src="/broken-image.jpg"
                            />
                          </div>
                          {option.name}
                        </Box>
                      )}
                      options={employeeList}
                      variant="standard"
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select name..."
                          variant="standard"
                        />
                      )}
                      onChange={onSelectEmployee}
                    />
                  </div>
                </div>
                <div className="padT7">
                  <Typography className={classes.avatar}>{f.name}</Typography>
                </div>
              </div>
            </div>
            <div className={`${classes.row} padT7`}>
              <IconButton onClick={() => addEmployeeHandler(f, index)}>
                <CheckCircleIcon color="primary" />
              </IconButton>
              <IconButton onClick={() => cancelHandler(index)}>
                <CancelIcon />
              </IconButton>
            </div>
          </div>
        </div>
      );
      result.push(item);
    });

    return result;
  };
  // // Remove owner confirmation modal of
  const removeConfirmationHandler = async () => {
    handleClose();
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: t('organization.would-you-like-to-remove-this-admin'),
      dialogContent: t(
        'organization.removing-an-admin-will-also-remove-all-their-admin-permission-in-Meewee',
      ),
      submitLabelText: t('organization.remove').toUpperCase(),
      type: removeType,
      actionType: DialogActionsType.error,
    }));
  };

  const confirmActionHandler = () => {
    switch (confirmationBoxInfo.type) {
      case removeType:
        handleCloseDialogBox();
        onRemoveHandler(true);
        break;

      default:
        handleCloseDialogBox();
        setDialogOpen(true);
        break;
    }
  };

  const dialogHandleClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={removeConfirmationHandler}>
          <DeleteForeverIcon />
          {t('organization.remove')}
        </MenuItem>
      </Menu>
      <div className={`${classes.flexContainer} padB20`}>
        <div>
          <Typography className={classes.titleLabel}>
            {t('organization.owner-and-Admins')}
          </Typography>
        </div>
      </div>
      <div className="padB20">
        <Divider />
      </div>
      <div className={classes.container}>
        <div className={`${classes.flexContainer} ${classes.pad}    padT5`}>
          {organization && viewOwners()}
          <div className={`${classes.alignRight} padT4`}>
            <Button
              size="medium"
              className={classes.button}
              startIcon={<CreateIcon />}
              onClick={() => changeOwnerHandler()}
            >
              {t('organization.change-owner')}
            </Button>
            <Button
              size="medium"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => AddHandler()}
            >
              {t('organization.add-admin')}
            </Button>
          </div>
        </div>
        <Divider />
        <div className={`${classes.flexContainer} ${classes.pad}    padT5`}>
          <div className={`${classes.row}`}>
            {' '}
            <Typography className={classes.titleLabel1}>
              {t('organization.administrator')}
            </Typography>
          </div>
          <div className={`${classes.row}`}>
            {' '}
            <Typography className={classes.titleLabel2}>
              {t('organization.added-by-on')}
            </Typography>
          </div>
        </div>
        {divlistOfAdmin}
      </div>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={confirmActionHandler}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
      />

      <DialogChangeOwner
        open={dialogOpen}
        handleClose={dialogHandleClose}
        employeeList={owneremployeeList}
        onChangeOwner={props.onChangeOwner}
      />
    </>
  );
};

export default OwnerAdmins;
