import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInViaLinkedin } from '../../../service/loginService';
import paths from '../../../../../routing/Constants';
import ContentLayout from '../../../../ui/layout/contentlayout/ContentLayout';
import Loader from '../../../../ui/loader/Loader';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../i18n/i18n';

//paths

const LinkedinLogin = () => {
  const homePath = `/${i18n.language}/${paths.HOME}`;
  const loginPath = `/${i18n.language}/${paths.LOGIN}`;
  const { t } = useTranslation();
  document.title = t('login.sign-up-meewee');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');

    if (state === process.env.REACT_APP_LINKEDIN_STATE && code) {
      dispatch(
        signInViaLinkedin({
          'auth-token': code,
          'redirect-uri': process.env.REACT_APP_LINKEDIN_REDIRECT_SIGNIN,
        }),
      ).then((data) => {
        if (data.result === 1) {
          navigate(homePath);
        } else {
          navigate(loginPath, {
            state: {
              errorMessage: data.errorMessage,
              // "Something went wrong linking your account. Please try again.",
            },
          });
        }
      });
    } else if (
      error &&
      ['user_cancelled_login', 'user_cancelled_authorize'].includes(error)
    ) {
      navigate(loginPath);
    }
  }, []);

  return (
    <ContentLayout>
      <Loader
        title={t('login.sign-up-meewee')}
        text={t('login.please-wait-while-we-prepare-your-dashboard')}
      ></Loader>
    </ContentLayout>
  );
};

export default LinkedinLogin;
