export function removeDuplicatesAndSort(arr) {
  const uniqueValues = new Set(arr);
  const sortedUniqueArray = Array.from(uniqueValues).sort((a, b) => a - b);

  return sortedUniqueArray;
}

export function addNewLeaveYearsIfDoesNotExistInYears(
  yearsArray,
  dataArray,
  hasYearLabel,
) {
  const yearsToAdd = yearsArray.filter(
    (year) => !dataArray.some((item) => item.value === year),
  );
  const newArray = dataArray.concat(
    yearsToAdd.map((year) => ({
      label: hasYearLabel ? `Year ${year}` : year,
      value: year,
    })),
  );

  newArray.sort((a, b) => b.value - a.value);

  return newArray;
}

export function addUniqueYearsGreaterThanCurrentYearIntoYearsArray(
  leaveHistoryObjArray,
  currentYear,
  years,
  hasYearLabel,
) {
  const yearsWithGreaterThanCurrentYear = leaveHistoryObjArray.reduce(
    (acc, dateObj) => {
      const yearStart = dateObj?.start?.getFullYear() || 0;
      const yearEnd = dateObj?.end?.getFullYear() || 0;

      if (yearStart > currentYear && !acc.includes(yearStart)) {
        acc.push(yearStart);
      }
      if (yearEnd > currentYear && !acc.includes(yearEnd)) {
        acc.push(yearEnd);
      }
      return acc;
    },
    [],
  );

  const filteredYears = removeDuplicatesAndSort(
    yearsWithGreaterThanCurrentYear,
  );

  return addNewLeaveYearsIfDoesNotExistInYears(
    filteredYears,
    years,
    hasYearLabel,
  );
}
