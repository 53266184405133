import React, { useState, useRef } from 'react';
import classes from './OrgProjects.module.scss';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import paths from '../../../../../routing/Constants';

const { ORGANIZATION_PATHS } = paths;

const OrgProject = () => {
  const { selectedOrg } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToManageProject = () => {
    const [, _, orgPath, orgId, allProjectPath] = location.pathname.split('/');
    const pathToManageInfo = `/${_}/${orgPath}/${orgId}/${allProjectPath}/${ORGANIZATION_PATHS.ALL_PROJECTS_PATHS.MANAGE}`;
    navigate(pathToManageInfo);
  };
  const [severity, setSeverity] = useState('info');
  const successSnackbarRef = useRef(null);

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const [selectedItem, setSelecteItem] = useState();
  const [isEdit, setIsEdit] = useState();
  const onManageProjectHandler = (item) => {
    setIsEdit(true);
    setSelecteItem(item);
    navigateToManageProject();
  };

  const onCreateHandler = () => {
    setIsEdit(false);
    setSelecteItem(null);
    navigateToManageProject();
  };
  return (
    <>
      <div className={classes.fullwidth}>
        <Outlet
          context={{
            selectedOrg,
            cbShowSuccessSnackBar: showSuccessSnackBar,
            setSeverity,
            isEdit,
            selectedItem,
            cbOnCreateHandler: onCreateHandler,
            cbOnManageProjectHandler: onManageProjectHandler,
          }}
        />
      </div>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </>
  );
};

export default OrgProject;
