import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RadioButtonUI = () => {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Radio Button</Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Primary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Radio checked />} label="label" />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Secondary
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio checked color="secondary" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Error
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio checked color="error" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Warning
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio checked color="warning" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Info
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio checked color="info" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Success
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio checked color="success" />}
              label="label"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" align="center">
              Disabled
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Radio disabled checked />}
              label="label"
            />
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h6">Radio Group</Typography>
      <Box sx={{ display: "flex" }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Radio Choices
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="op1"
              control={<Radio />}
              label="Option 1"
            />
            <FormControlLabel
              value="op2"
              control={<Radio />}
              label="Option 2"
            />
            <FormControlLabel
              value="op3"
              control={<Radio />}
              label="Option 3"
            />
            <FormControlLabel
              value="op4"
              control={<Radio disabled />}
              label="Option Disabled"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default RadioButtonUI;
