import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../features/home/component/Home';
import Login from '../features/login/component/Login';
import UiKit from '../features/uikit/component/UiKit';
import ProtectedRoute from './ProtectedRoute';
import Signup from '../features/signup/component/Signup';
import paths from './Constants';
import LinkedinSignUp from '../features/signup/component/socials/linkedin/LinkedinSignUp';
import LinkedinLogin from '../features/login/component/socials/linkedIn/LinkedinLogin';
import Dashboard from '../features/dashboard/component/Dashboard';
import AllOrgTimeRegistration from '../features/timeregistration/component/timeRegistrationList/allOrgTimeRegistration/AllOrgTimeRegistration';
import OrgTimeRegistration from '../features/timeregistration/component/timeRegistrationList/orgTimeRegistration/OrgTimeRegistration';
import OrgManagement from '../features/orgmanagement/component/orgManagement/OrgManagement';
import Welcome from '../features/teaminvitation/component/welcome/Welcome';
import AcceptInvitation from '../features/teaminvitation/component/acceptInvitation/AcceptInvitation';
import Leave from '../features/leave/component/Leave';
import Profile from '../features/profile/component/Profile';
import ForgotPassword from '../features/login/component/ForgotPassword/ForgotPassword';

import {
  dashboardMenuFeatureOn,
  orgMenuFeatureOn,
  leaveFeatureOn,
  leaveManagementFeatureOn,
  teamTimesheetFeatureOn,
  orgMyTeammatesMenuFeatureOn,
  peronalOrgInformationMenuFeatureOn,
} from '../utilities/featureToggleUtils';
import ApproveLeave from '../features/we/approveleave/component/ApproveLeave';
import ResetPassword from '../features/login/component/ResetPassword/ResetPassword';
import TeamTimesheet from '../features/we/teamTimesheet/component/TeamTimesheet';
import { pathUpdate, changeLanguage } from '../i18n/i18nUtil';
import ViewTeamInvitation from '../features/teaminvitation/component/viewTeamInvitation/ViewTeamInvitation';
import TeamsAndProjects from '../features/orgmanagement/component/orgManagement/teamsAndProjects/TeamsAndProjects';
import OrgTeams from '../features/orgmanagement/component/orgManagement/orgTeams/OrgTeams';
import OrgProjects from '../features/orgmanagement/component/orgManagement/orgProjects/OrgProjects';
import MeOrgProjects from '../features/orgmanagement/component/orgManagement/meOrgProjects/MeOrgProjects';
import OrgInfo from '../features/orgmanagement/component/orgManagement/orgInfo/OrgInfo';
import Information from '../features/orgmanagement/component/orgManagement/orgInfo/Information/Information';
import ManageInformation from '../features/orgmanagement/component/orgManagement/orgInfo/ManageInformation/ManageInformation';
import OrgMembers from '../features/orgmanagement/component/orgManagement/orgMembers/OrgMembers';
import OrgProjectList from '../features/orgmanagement/component/orgManagement/orgProjects/OrgProjectList/OrgProjectList';
import CreateOrgProjects from '../features/orgmanagement/component/orgManagement/orgProjects/CreateOrgProject/CreateOrgProjects';
import CreateOrgTeam from '../features/orgmanagement/component/orgManagement/orgTeams/CreateOrgTeam/CreateOrgTeam';
import OrgTeamList from '../features/orgmanagement/component/orgManagement/orgTeams/OrgTeamList/OrgTeamList';
import { OrgProjectList as MeOrgProjectList } from '../features/orgmanagement/component/orgManagement/meOrgProjects/OrgProjectList/OrgProjectList';
import CreateMeOrgProjects from '../features/orgmanagement/component/orgManagement/meOrgProjects/CreateMeOrgProject/CreateMeOrgProjects';

const RoutingConfig = () => {
  changeLanguage();

  return (
    <Routes>
      <Route
        path={pathUpdate(paths.ROOT)}
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      >
        {/* FEATURE TOGGLE CODE CLEANUP - remove toggle */}
        {dashboardMenuFeatureOn ? (
          <>
            <Route index={true} element={<Dashboard />} />
            <Route path={paths.HOME} element={<Dashboard />} />
          </>
        ) : (
          <Route index={true} element={<AllOrgTimeRegistration />} />
        )}

        {/* Profile  */}
        <Route path={`${paths.Profile}`} element={<Profile />}></Route>

        <Route
          path={`${paths.ME}/${paths.TIMESHEET}`}
          element={<AllOrgTimeRegistration />}
        ></Route>
        <Route
          path={`${paths.ME}/${paths.TIMESHEET}/:id`}
          element={<OrgTimeRegistration />}
        />
        {/* FEATURE TOGGLE CODE CLEANUP - remove toggle */}
        {leaveFeatureOn && (
          <Route
            path={`${paths.ME}/${paths.LEAVE}`}
            element={<Leave />}
          ></Route>
        )}

        {leaveManagementFeatureOn && (
          <Route
            path={`${paths.WE}/${paths.LEAVE_MANAGEMENT}`}
            element={<ApproveLeave />}
          ></Route>
        )}

        {teamTimesheetFeatureOn && (
          <Route
            path={`${paths.WE}/${paths.TEAM_TIMESHEET}`}
            element={<TeamTimesheet />}
          ></Route>
        )}

        {/* FEATURE TOGGLE CODE CLEANUP - remove toggle */}
        {orgMenuFeatureOn && (
          <>
            <Route
              path={pathUpdate(paths.ORGANIZATION)}
              element={<OrgManagement />}
            >
              <Route path=":id">
                <Route
                  index
                  path={paths.ORGANIZATION_PATHS.TEAMS_AND_PROJECT}
                  element={<TeamsAndProjects />}
                />
                <Route
                  path={paths.ORGANIZATION_PATHS.VIEW_TEAM_INVITATION}
                  element={<ViewTeamInvitation />}
                />
                {orgMyTeammatesMenuFeatureOn && (
                  <Route
                    path={paths.ORGANIZATION_PATHS.TEAMMATES}
                    element={<></>}
                  />
                )}

                <Route
                  path={paths.ORGANIZATION_PATHS.INFORMATION}
                  element={<OrgInfo />}
                >
                  <Route
                    index
                    path={
                      paths.ORGANIZATION_PATHS.INFORMATION_PATHS
                        .VIEW_INFORMATION
                    }
                    element={<Information />}
                  />
                  <Route
                    path={
                      paths.ORGANIZATION_PATHS.INFORMATION_PATHS
                        .EDIT_INFORMATION
                    }
                    element={<ManageInformation />}
                  />
                </Route>

                <Route
                  path={paths.ORGANIZATION_PATHS.ALL_MEMBERS}
                  element={<OrgMembers />}
                />
                <Route
                  path={paths.ORGANIZATION_PATHS.ALL_PROJECTS}
                  element={<OrgProjects />}
                >
                  <Route
                    index
                    path={paths.ORGANIZATION_PATHS.ALL_PROJECTS_PATHS.LIST}
                    element={<OrgProjectList />}
                  />
                  <Route
                    path={paths.ORGANIZATION_PATHS.ALL_PROJECTS_PATHS.MANAGE}
                    element={<CreateOrgProjects />}
                  />
                </Route>
                <Route
                  path={paths.ORGANIZATION_PATHS.ALL_TEAMS}
                  element={<OrgTeams />}
                >
                  <Route
                    index
                    path={paths.ORGANIZATION_PATHS.ALL_TEAMS_PATHS.LIST}
                    element={<OrgTeamList />}
                  />
                  <Route
                    path={paths.ORGANIZATION_PATHS.ALL_TEAMS_PATHS.MANAGE}
                    element={<CreateOrgTeam />}
                  />
                </Route>
                {peronalOrgInformationMenuFeatureOn && (
                  <Route
                    path={paths.ORGANIZATION_PATHS.MY_INFORMATION}
                    element={<></>}
                  />
                )}

                <Route
                  path={paths.ORGANIZATION_PATHS.MY_PROJECTS}
                  element={<MeOrgProjects />}
                >
                  <Route
                    index
                    path={paths.ORGANIZATION_PATHS.MY_PROJECTS_PATHS.LIST}
                    element={<MeOrgProjectList />}
                  />
                  <Route
                    path={paths.ORGANIZATION_PATHS.MY_PROJECTS_PATHS.MANAGE}
                    element={<CreateMeOrgProjects />}
                  />
                </Route>
                <Route path="*" element={<></>} />
              </Route>
              <Route path="*" element={<></>} />
            </Route>
            <Route path="*" element={<></>} />
          </>
        )}
      </Route>
      <Route
        path={pathUpdate(paths.UIKIT)}
        element={
          <ProtectedRoute>
            <UiKit />
          </ProtectedRoute>
        }
      />

      <Route path={pathUpdate(paths.LOGIN)}>
        <Route index={true} element={<Login />} />
        <Route path={paths.LINKEDIN} element={<LinkedinLogin />} />
      </Route>

      <Route path={pathUpdate(paths.FORGETPASSWORD)}>
        <Route index={true} element={<ForgotPassword />} />
      </Route>

      <Route path={paths.RESETPASSWORD}>
        <Route index={true} element={<ResetPassword />} />
      </Route>

      <Route path={paths.SIGNUP}>
        <Route index={true} element={<Signup />} />
        <Route path={paths.LINKEDIN} element={<LinkedinSignUp />} />
      </Route>

      {/* FEATURE TOGGLE CODE CLEANUP - remove toggle */}
      {orgMenuFeatureOn && (
        <>
          <Route path={pathUpdate(paths.ACCEPT_TEAM)}>
            <Route index={true} element={<AcceptInvitation />} />
          </Route>
          <Route path={pathUpdate(paths.WELECOME_TEAM)}>
            <Route index={true} element={<Welcome />} />
          </Route>
        </>
      )}
    </Routes>
  );
};

export default RoutingConfig;
