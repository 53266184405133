import React from 'react';
import { FormControl, NativeSelect, Select, MenuItem } from '@mui/material';
import classes from './SelectMenu.module.scss';

const SelectMenu = ({
  data,
  value,
  handleChange,
  selectClasses,
  selectTextClasses,
}) => {
  return (
    <>
      <FormControl variant="standard" className={selectClasses}>
        <Select
          labelId="select-detail"
          id="demo-simple-select-standard"
          value={value}
          onChange={handleChange}
          disableUnderline
          defaultValue={value}
          className={selectTextClasses}
        >
          {data.map((key) => {
            return (
              <MenuItem value={key.value} className={selectTextClasses}>
                {key.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectMenu;
