import React, { useState } from 'react';
import classes from './Profile.module.scss';
import SideNavBar from './Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import PrivacyAccountTab from './PrivacyAccountTab/PrivacyAccountTab';

const Profile = () => {
  const userContext = useSelector((state) => state.userContext);
  const [selectedMenu, setSelectedMenu] = useState(2);

  const onMenuHandler = (value) => {
    setSelectedMenu(value);
  };

  return (
    <div>
      <div className={classes.flexContainer}>
        <div className={classes.card}>
          <SideNavBar
            user={userContext}
            selectedMenu={selectedMenu}
            onMenuHandler={onMenuHandler}
          />
        </div>
        <div className={classes.tabContainer}>
          {selectedMenu === 2 && <PrivacyAccountTab user={userContext} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
