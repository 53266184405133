import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormButtonLoading from '../../../ui/formcontrols/formButtonLoading/FormButtonLoading';
import ContentLayout from '../../../ui/layout/contentlayout/ContentLayout';
import TextBox from '../../../ui/formcontrols/textBox/TextBox';
import ErrorAlert from '../../../ui/alerts/errorAlert/ErrorAlert';
import SuccessAlert from '../../../ui/alerts/successAlert/SuccessAlert';
import classes from './ForgotPassword.module.scss';
import { Link } from 'react-router-dom';
import paths from '../../../../routing/Constants';
import { forgetPasswordRequestService } from '../../service/loginService';
import { RegEx } from '../../../../utilities/constantUtils';
import loginbl from '../../../../assets/login-bl-arc.svg';
import logintr from '../../../../assets/login-tr-arc.svg';
import i18n from '../../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const loginPath = `${i18n.language}/${paths.LOGIN}`;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendRecoveryHandler = async () => {
    if (!emailError && email.length) {
      setIsLoading(true);
      setIsError(false);
      setIsError(false);
      var response = await forgetPasswordRequestService(email);
      if (response.status !== 200) setIsError(true);
      else setIsSuccess(true);
      setIsLoading(false);
    }
  };

  const onEmailChangeHandler = (e) => {
    if (e != null) {
      const { value } = e.target;
      setEmail(value);
    }
  };

  const enterKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      checkValidEmail(e);
    }
  };

  const onBlurHandler = (e) => {
    checkValidEmail(e);
  };

  const checkValidEmail = (e) => {
    if (e != null) {
      const { value } = e.target;
      if (value.length)
        setEmailError(RegEx.emailRegEx.test(value) ? false : true);
    }
  };

  return (
    <div className={classes.bgWhite}>
      <ContentLayout>
        <Grid item>
          <img src={logintr} alt="meewee logo" className={classes.logintr} />
        </Grid>
        <Grid
          container
          direction="column"
          spacing={5}
          className={classes.container}
        >
          <Grid item className={classes.header}>
            <Typography variant="h4" className={classes.fpLabel}>
              {t('login.forgot-password')}?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('login.we-will-send-a-recovery-link-to')}:
            </Typography>
          </Grid>
          <Grid item>
            <TextBox
              isError={emailError}
              id="email"
              label={t('login.enter-your-email')}
              type="text"
              onChange={(value) => onEmailChangeHandler(value)}
              errorText={t('login.invalid-email-or-username')}
              ariaLabel="Email"
              onBlur={(e) => onBlurHandler(e)}
              onKeyPress={(e) => enterKeyPressHandler(e)}
            />
            <div className={classes.button}>
              <FormButtonLoading
                id="continue-button"
                label={t('login.send-recovery-link')}
                loadingIndicator="Send recovery link"
                loading={isLoading}
                onClick={sendRecoveryHandler}
                fullWidth={true}
                loadingPosition="start"
                color="primary"
              />
            </div>
            {isError && (
              <div className={classes.alert}>
                <ErrorAlert
                  id="sign-up-error"
                  message={t('login.we-couldnt-find-the-email')}
                />
              </div>
            )}

            {isSuccess && (
              <div className={classes.alert}>
                <SuccessAlert
                  id="fp-success"
                  message={t('login.recovery-link-send-to-your-email')}
                />
              </div>
            )}
          </Grid>
          <Grid item>
            <Divider />
            <Typography
              variant="body2"
              color="text.secondary"
              className={classes.text}
            >
              <Box component="span" color="info.main">
                <Link to={loginPath} className={classes.link}>
                  {t('login.return-to-log-in')}
                </Link>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.loginbl}>
            <img
              src={loginbl}
              alt="meewee logo"
              className={classes.imgLogintr}
            />
          </div>
        </Grid>
      </ContentLayout>
    </div>
  );
};
export default ForgotPassword;
