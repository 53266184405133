import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import classes from './ErrorSnackbar.module.scss';

const ErrorSnackbar = forwardRef((props, ref) => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useImperativeHandle(ref, () => ({
    snackBarContentHandler(message) {
      showSnackbar(message);
    },
  }));

  const showSnackbar = (message) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      className={classes.snackbar_container}
      {...props?.snack}
    >
      <Alert
        severity="error"
        sx={{
          '& .MuiAlert-icon': { color: 'error.content', marginTop: '3px' },
        }}
      >
        <div>
          <Typography variant={'subtitle2'} className={classes.alertMessage}>
            {messageInfo ? messageInfo.message : ''}
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5, fontSize: '14px' }}
            onClick={handleClose}
            className={classes.closeIconButton}
          >
            <CloseIcon sx={{ fontSize: '22px' }} />
          </IconButton>
        </div>
      </Alert>
    </Snackbar>
  );
});

export default ErrorSnackbar;
