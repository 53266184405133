import httpRequestHandler from '../../../utilities/httpRequestHandler';
import {
  getTransitData,
  getTransitDataCollection,
} from '../../../utilities/transitJsUtils';
import { setUserProfile } from '../../../store/userContextSlice';
import { userRoles } from '../../../utilities/roleUtils';
import { setTeams } from '../../../store/orgContextSlice';

export const getUserProfile = () => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getUserProfileInfo);
      if (response.result === 1) {
        response.data = mapUserProfileResponseData(response.data);
        dispatch(getUserOrgTeamsList(response.data.userId)).then((res) => {
          if (res.result === 1) {
            response.data.userTeamRoles = Object.assign([], res.data);
          } else {
            response.data.userTeamRoles = [];
          }
          dispatch(setUserProfile(response.data));
        });
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getUserOrgTeamsList = (userId, employmentId) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(
        getUserOrgTeamsInfo,
        employmentId ? { employment: employmentId } : undefined,
      );
      let userTeamRoles = [];

      if (response.result === 1) {
        let teamList = [];
        let mappedResponseDataObj = {
          teamId: 'id',
          teamName: 'label',
          orgId: 'organization',
          teamManager: 'manager',
          isCurrentUserApprover: 'is-approver',
          isActive: 'is-active',
        };

        let mappedManagerDataObj = {
          userId: 'db/id',
          fname: 'user/firstname',
          lname: 'user/lastname',
        };

        response.data.forEach((team) => {
          let mappedResponseData = getTransitDataCollection(
            team,
            mappedResponseDataObj,
          );

          mappedResponseData.teamManager = getTransitDataCollection(
            mappedResponseData.teamManager,
            mappedManagerDataObj,
          );
          teamList.push(mappedResponseData);

          if (mappedResponseData.isCurrentUserApprover) {
            let team = {
              teamId: mappedResponseData.teamId,
              teamName: mappedResponseData.teamName,
              teamOrg: mappedResponseData.orgId,
              teamRole: userRoles.teamApprover,
              isActive: mappedResponseData.isActive,
            };

            userTeamRoles.push(team);
          }

          if (userId === mappedResponseData.teamManager.userId) {
            let team = {
              teamId: mappedResponseData.teamId,
              teamName: mappedResponseData.teamName,
              teamOrg: mappedResponseData.orgId,
              teamRole: userRoles.teamManager,
              isActive: mappedResponseData.isActive,
            };
            userTeamRoles.push(team);
          }
        });
        dispatch(setTeams({ teamList: teamList }));
        response.data = userTeamRoles;
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};
const getUserProfileInfo = {
  url: 'me',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
};

const mapUserProfileResponseData = (responseData) => {
  const mappedUserProfileData = {};
  mappedUserProfileData['userId'] = getTransitData(responseData, 'user');
  mappedUserProfileData['username'] = getTransitData(
    responseData,
    'user/username',
  );
  mappedUserProfileData['userLastName'] = getTransitData(
    responseData,
    'user/lastname',
  );
  mappedUserProfileData['userFirstName'] = getTransitData(
    responseData,
    'user/firstname',
  );
  mappedUserProfileData['userEmail'] = getTransitData(
    responseData,
    'user/email',
  );
  mappedUserProfileData['language'] = getTransitData(responseData, 'l');
  const transitLegalAcceptKeys = getTransitData(
    responseData,
    'user/legal-accept',
  );
  let legalAcceptArrayKeys = [];
  transitLegalAcceptKeys?.keySet()?.forEach((set) => {
    set?.name() && legalAcceptArrayKeys.push(set?.name());
  });

  mappedUserProfileData['legalAccept'] = legalAcceptArrayKeys;

  const transitUserAuthorization = getTransitData(responseData, 'user/auth');
  let userAuthorizationArrayKeys = [];
  transitUserAuthorization?.keySet()?.forEach((set) => {
    set?.name() && userAuthorizationArrayKeys.push(set?.name());
  });
  mappedUserProfileData['userAuthorization'] = userAuthorizationArrayKeys;

  mappedUserProfileData['loginTimestamp'] = getTransitData(
    responseData,
    'timestamp',
  );

  let transitArrayOfEmployments = getTransitData(responseData, 'employments');
  mappedUserProfileData['employments'] = [];
  if (transitArrayOfEmployments) {
    transitArrayOfEmployments.forEach((transitEmploymentData) => {
      let employment = {};

      employment['status'] = getTransitData(
        transitEmploymentData,
        'employment/status',
      )?.name();
      employment['type'] = getTransitData(
        transitEmploymentData,
        'employment/type',
      )?.name();
      let rawUserAuth = getTransitData(
        transitEmploymentData,
        'employment/auth',
      );
      employment['userAuth'] = [];

      let transitArrayOfOrganizations = getTransitData(
        transitEmploymentData,
        'organization',
      );
      employment['id'] = getOrganizationName(transitArrayOfOrganizations).id;
      employment['emp'] = getTransitData(transitEmploymentData, 'db/id');

      employment['organizations'] = {};
      if (transitArrayOfOrganizations) {
        employment['organizations']['id'] = getOrganizationName(
          transitArrayOfOrganizations,
        ).id;
        employment['organizations']['name'] = getOrganizationName(
          transitArrayOfOrganizations,
        ).name;
        // employment['organizations']['requiredWorkDayHours'] = getTransitData(
        //   transitArrayOfOrganizations,
        //   'organization/work-day-hours',
        // );
        // employment['organizations']['requiredWorkWeekStart'] = getTransitData(
        //   transitArrayOfOrganizations,
        //   'organization/work-week-start',
        // );
        // employment['organizations']['requiredWorkWeekDays'] = getTransitData(
        //   transitArrayOfOrganizations,
        //   'organization/work-week-days',
        // );

        const rawTransitOwnerData = getTransitData(
          transitArrayOfOrganizations,
          'organization/owner',
        );

        let mappedOrgOwner = {
          id: 'id',
          name: 'name',
        };
        employment['organizations']['owner'] = {
          id: undefined,
          name: undefined,
        };
        if (rawTransitOwnerData) {
          employment['organizations']['owner'] = getTransitDataCollection(
            rawTransitOwnerData,
            mappedOrgOwner,
          );
        }
        if (
          employment['organizations']['owner'].id ===
          mappedUserProfileData['userId']
        ) {
          employment['userAuth'].push(userRoles.orgOwner);
        }

        employment['organizations']['isEmployer'] = getTransitData(
          transitArrayOfOrganizations,
          'employer',
        );
      }

      rawUserAuth?.keySet()?.forEach((key) => {
        switch (key.name()) {
          case 'orgadmin':
            employment['userAuth'].push(userRoles.orgAdmin);
            break;
          case 'manager':
            employment['userAuth'].push(userRoles.teamManager);
            break;
          case 'employee':
            employment['userAuth'].push(userRoles.employee);
            break;
          case 'user':
            employment['userAuth'].push(userRoles.user);
            break;

          default:
            break;
        }
      });
      if (employment.status !== 'suspended')
        mappedUserProfileData['employments'].push(employment);
    });
  }
  return mappedUserProfileData;
};

const getOrganizationName = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'db/id',
      name: 'organization/name',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    return mappedData;
  }

  return result;
};

// SINGLE ORG IMPLEMENTATION
// const mapUserProfileResponseData = (responseData) => {
//   const mappedUserProfileData = {};
//   mappedUserProfileData['userId'] = getTransitData(responseData, 'user');
//   mappedUserProfileData['userOrganizationHandle'] = getTransitData(
//     responseData,
//     'organization/handle',
//   );
//   mappedUserProfileData['userLastName'] = getTransitData(
//     responseData,
//     'user/lastname',
//   );
//   mappedUserProfileData['userFirstName'] = getTransitData(
//     responseData,
//     'user/firstname',
//   );
//   mappedUserProfileData['userEmail'] = getTransitData(
//     responseData,
//     'user/email',
//   );

//   let orgList = getTransitData(responseData, 'organizations');
//   mappedUserProfileData['userOrganizations'] = [];

//   // let personalUserOrg = {};
//   // personalUserOrg["id"] = mappedUserProfileData["userId"];
//   // personalUserOrg["name"] = mappedUserProfileData["userFirstName"];
//   // personalUserOrg["requiredWorkDayHours"] = 0; //this is temporarily being set for now
//   // personalUserOrg["userAuth"] = [];
//   // mappedUserProfileData["userOrganizations"].push(personalUserOrg);

//   if (orgList) {
//     orgList.forEach((org) => {
//       let mappedOrgData = {};
//       mappedOrgData['id'] = getTransitData(org, 'db/id');
//       mappedOrgData['name'] = getTransitData(org, 'organization/name');
//       mappedOrgData['requiredWorkDayHours'] = getTransitData(
//         org,
//         'organization/work-day-hours',
//       );

//       let rawUserAuth = getTransitData(org, 'employment/auth');

//       mappedOrgData['userAuth'] = [];
//       rawUserAuth.keySet().forEach((set) => {
//         set.keySet().forEach((key) => {
//           switch (key.name()) {
//             case 'orgadmin':
//               mappedOrgData['userAuth'].push(userRoles.orgAdmin);
//               break;
//             case 'manager':
//               mappedOrgData['userAuth'].push(userRoles.teamManager);
//               break;
//             case 'employee':
//               mappedOrgData['userAuth'].push(userRoles.employee);
//               break;
//             default:
//               break;
//           }
//         });
//       });

//       // let rawEmploymentType = getEmploymentType(
//       //   getTransitData(org, "employment/type")?.name()
//       // );
//       // debugger
//       // if (rawEmploymentType) {
//       //   mappedOrgData["userAuth"].push(rawEmploymentType);
//       // }

//       let rawOrgOwner = getTransitData(org, 'organization/owner');
//       let mappedOrgOwner = {
//         id: 'db/id',
//         fname: 'user/firstname',
//         lname: 'user/lastname',
//       };

//       mappedOrgData['owner'] = getTransitDataCollection(
//         rawOrgOwner,
//         mappedOrgOwner,
//       );

//       if (mappedOrgData['owner'].id === mappedUserProfileData['userId']) {
//         mappedOrgData['userAuth'].push(userRoles.orgOwner);
//       }
//       mappedUserProfileData['userOrganizations'].push(mappedOrgData);
//     });
//   }

//   return mappedUserProfileData;
// };

const getEmploymentType = (type) => {
  let membershipType = '';
  switch (type) {
    case 'employee':
      membershipType = userRoles.employee;
      break;
    case 'outside-collaborator':
      membershipType = userRoles.outsideCollaborator;
      break;
  }

  return membershipType;
};

const getUserOrgTeamsInfo = {
  url: 'me/teams',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
};
