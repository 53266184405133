import React, { useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import {
  joinTeamInvitationSuccessMessage,
  declineTeamInvitationSuccessMessage,
} from '../../../../utilities/contentMessagesUtils';
import {
  asyncDeclinetInvitationService,
  asyncAcceptInvitationService,
} from '../../service/teaminvitationService';

import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classes from './ViewTeamInvitation.module.scss';

const TeamInvitationAction = ({
  invitation,
  handleJoinInvite,
  handleDeclineInvite,
}) => {
  return (
    <div className={classes.team_invitation_action}>
      <Button
        className={classes.team_invitation_join}
        size="small"
        key={1}
        onClick={() =>
          handleJoinInvite(
            invitation.invitationId,
            invitation.invitationUser.userEmail,
          )
        }
      >
        <Typography variant="buttonsmall" color="secondary">
          JOIN
        </Typography>
      </Button>
      <Button
        className={classes.team_invitation_decline}
        size="small"
        key={2}
        onClick={() =>
          handleDeclineInvite(
            invitation.invitationId,
            invitation.invitationUser.userEmail,
          )
        }
      >
        <Typography variant="buttonsmall" color="secondary">
          DECLINE
        </Typography>
      </Button>
    </div>
  );
};

const TeamInvitationContent = ({ showSuccessSnackBar, showErrorSnackBar }) => {
  const dispatch = useDispatch();
  const teamInvitationList = useSelector(
    (state) => state.orgContext.ownPendingInvitations,
  );

  const handleJoinInvite = useCallback((invitationId, userEmail) => {
    dispatch(
      asyncAcceptInvitationService({
        id: invitationId,
        userEmail: userEmail,
      }),
    ).then((data) => {
      if (data.result === 1) {
        showSuccessSnackBar(joinTeamInvitationSuccessMessage);
      } else {
        showErrorSnackBar(data.errorMessage);
      }
    });
  }, []);

  const handleDeclineInvite = useCallback((invitationId, userEmail) => {
    dispatch(
      asyncDeclinetInvitationService({
        id: invitationId,
        userEmail: userEmail,
      }),
    ).then((data) => {
      if (data.result === 1) {
        showSuccessSnackBar(declineTeamInvitationSuccessMessage);
      } else {
        showErrorSnackBar(data.errorMessage);
      }
    });
  }, []);

  return (
    <>
      <Grid item className={classes.team_invitation_grid}>
        <Typography className={classes.invitation_count} variant="text">
          <span>
            <InfoOutlinedIcon fontSize="small" />
          </span>
          <span>There are {teamInvitationList.length} invitations for you</span>
        </Typography>
        {teamInvitationList.map((invitation) => (
          <div className={classes.view_team_invitation_list}>
            <div className={classes.team_invitation_card}>
              <div className={classes.team_invitation_header}>
                <Avatar
                  // alt={selectedOrg.name}
                  src="/broken-image.jpg"
                  className={classes.org_header_icon}
                />
                <div className={classes.team_invitation_avatar}>
                  <Typography className={classes.team_invitation_name}>
                    {invitation.invitationCreatedByUser.userName}
                  </Typography>
                  <Typography className={classes.team_invitation_date}>
                    invited you on {invitation.invitationCreatedAt}
                  </Typography>
                </div>
              </div>

              <div className={classes.team_invitation_message}>
                <Typography>{invitation.invitationUser.userFname},</Typography>
                <Typography>
                  We would like to invite you to join our{' '}
                  {invitation.invitationTeam.teamName}. You can do this to your
                  Meewee!
                </Typography>
              </div>

              <TeamInvitationAction
                invitation={invitation}
                handleJoinInvite={handleJoinInvite}
                handleDeclineInvite={handleDeclineInvite}
                showSuccessSnackBar={showSuccessSnackBar}
                showErrorSnackBar={showErrorSnackBar}
              />
            </div>
          </div>
        ))}
      </Grid>
    </>
  );
};

const ViewTeamInvitation = () => {
  const {
    cbOnBackClickInTeamsAndInvitationPage,
    cbShowSuccessSnackBarFn,
    cbShowErrorSnackBarFn,
  } = useOutletContext();

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.team_invitation_container}
    >
      <div>
        <Button
          className={classes.go_back_btn}
          color="inherit"
          size="small"
          onClick={() => cbOnBackClickInTeamsAndInvitationPage()}
        >
          <KeyboardBackspaceSharpIcon
            sx={{ fontSize: '17px', fontWeight: 'bold', margin: '0 5px' }}
          />
          Go back
        </Button>

        <TeamInvitationContent
          showSuccessSnackBar={cbShowSuccessSnackBarFn}
          showErrorSnackBar={cbShowErrorSnackBarFn}
        />
      </div>
    </Grid>
  );
};

export default ViewTeamInvitation;
