import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import classes from './RegManagementList.module.scss';
import {
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Button,
  Avatar,
  ButtonGroup,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';

import {
  DialogActionsType,
  timeSheetStatus,
} from '../../../../../utilities/constantUtils';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { LeaveStatusType } from '../../../../../utilities/constantUtils';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { dateFormats } from '../../../../../utilities/dateUtils';
import { updateApproveNote } from '../../service/TeamTimesheetService';
import { transformMinsToHoursMinutesString } from '../../../../../utilities/timeConversion';
import { useTranslation } from 'react-i18next';
var _ = require('lodash');

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row': {
    lineHeight: '30px',
  },
  '& .MuiDataGrid-row:hover div:nth-of-type(2) > div': {
    color: '#00b8d4',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
  '& .MuiDataGrid-cell--textLeft': {
    position: 'relative',
  },
  '& .MuiDataGrid-row:hover .MuiDataGrid-cell:nth-of-type(2) > div:nth-of-type(2) > button > svg':
    {
      visibility: 'visible !important',
      cursor: 'pointer',
    },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreRoundedIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-root ': {
    backgroundColor: theme.palette.action.hover,
    borderBottom: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    margin: '10px',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '10px',
  },
  '&.MuiAccordionSummary-root': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute !important',
    right: '25px !important',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '13px 16px 16px',
  '& .MuiAccordionDetails-root ': {
    padding: '13px 16px 16px',
  },
}));

const UniqueProjectTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    fontSize: '15px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    boxShadow: '0px 0px 14px 0px #9e9e9e6e',
    backgroundColor: 'white',
    color: '#00b8d4',
    lineHeight: '14px',
  },
});

const RegManagementList = (props) => {
  const { t } = useTranslation();
  const { regTeamList } = props;
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openViewMore, setOpenViewMore] = React.useState(false);
  const handleOpenViewMore = () => setOpenViewMore(true);

  const [severity, setSeverity] = useState('info');
  const successSnackbarRef = useRef(null);
  const [note, setNote] = useState();
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: t('we-timesheet.would-you-like-to-save-this-new-project'),
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('we-timesheet.cancel'),
    submitLabelText: t('we-timesheet.save'),
  });
  const [confirmationAll, setconfirmationAll] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: t('we-timesheet.would-you-like-to-save-this-new-project'),
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('we-timesheet.cancel'),
    submitLabelText: t('we-timesheet.save'),
  });

  const [groupByCategory, setGroupByCategory] = useState([]);

  useEffect(() => {
    if (props.regTeamList.length > 0) {
      loadData(false, [], -1, false);
      setSelecAllCheck(false);
    } else {
      setGroupByCategory([]);
    }
  }, [props.regTeamList]);

  useEffect(() => {
    if (openViewMore) {
      setAnchorEl(false);
    }
  }, [openViewMore]);

  useEffect(() => {
    if (confirmationBoxInfo.open) {
      setAnchorEl(false);
    }
  }, [confirmationBoxInfo]);

  const [totalSelected, setTotalSelected] = useState(0);

  const loadData = (view, idList, nIndex, checked) => {
    const list = regTeamList.reduce((group, product) => {
      const { ownerName } = product;
      group[ownerName] = group[ownerName] ?? [];
      group[ownerName].push(product);
      return group;
    }, {});
    var total = 0;
    var temp = Object.values(list).map((f, index) => {
      var item = {
        id: index,
        name: f[0].ownerName,
        description: f[0].description.description,
        reg: f,
        ids: view
          ? nIndex === index
            ? f.map((r) => r.id).filter((arg) => idList.includes(arg))
            : f.map((r) => r.id)
          : [],
        checked: checked,
        expanded: false,
      };
      total = total + item.ids.length;
      return item;
    });
    setTotalSelected(total);
    setGroupByCategory(temp);
  };

  const handleChange1 = (value, id) => {
    if (value) {
      setSelecAllCheck(true);
    }
    loadExistingData(id, [], value);
  };
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };
  const handleClose = () => {
    setOpenViewMore(false);
    setAnchorEl(null);
  };

  const columns = [
    {
      field: t('we-timesheet.task'),
      width: 400,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div
              className={classes.task_name_hyperlink_div}
              onClick={(e) => {
                handleClick(e, item.row);
                handleOpenViewMore();
              }}
            >
              {item.row.task.task}
            </div>
            <div className={classes.task_menu_button_container}>
              <IconButton
                className={classes.task_menu_button}
                onClick={(e) => handleClick(e, item.row)}
              >
                <MoreVertIcon
                  className={`${!open && classes.task_menu_button}`}
                  aria-label="reg menu"
                  aria-controls={open ? 'reg-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                />
              </IconButton>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: t('we-timesheet.total-time'),
      width: 150,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {transformMinsToHoursMinutesString(
                item.row.regDuration,
                false,
                true,
              )}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: t('we-timesheet.date'),
      width: 150,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {moment(item.row.startDate).format(dateFormats.moment_datepicker)}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: t('we-timesheet.status'),
      width: 150,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div
              style={{ textTransform: 'Capitalize' }}
              className={
                item.row.status === 'approved'
                  ? classes.status_green
                  : item.row.status === 'pending'
                  ? classes.status_blue
                  : item.row.status === 'declined'
                  ? classes.status_red
                  : 'black'
              }
            >
              {item.row.status}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: t('we-timesheet.project'),
      width: 400,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>{item.row.description.description}</div>
          </React.Fragment>
        );
      },
    },
  ];

  const getTotalApprovedReg = (groupByCategory) => {
    let totalApproved = 0;
    for (let task = 0; Number(task) < groupByCategory.length; task++) {
      if (groupByCategory[task].status === 'approved') {
        totalApproved += groupByCategory[task].regDuration;
      }
    }
    return transformMinsToHoursMinutesString(totalApproved);
  };

  const getTotalPendingReg = (groupByCategory) => {
    let totalPending = 0;
    for (let task = 0; task < groupByCategory.length; task++) {
      if (groupByCategory[task].status === 'pending') {
        totalPending += groupByCategory[task].regDuration;
      }
    }
    // if (totalPending % 1 !== 0) {
    //   return totalPending.toFixed(2);
    // } else {
    //   return totalPending.toFixed(0);
    // }
    return transformMinsToHoursMinutesString(totalPending);
  };

  //Count the unique project
  const getAllUniqueProjectNamesAndCount = (groupByCategory) => {
    const uniqueProjectNames = groupByCategory.reduce(
      (uniqueProject, current) => {
        const projectName = current.description.description;

        if (!uniqueProject.includes(projectName)) {
          uniqueProject.push(projectName);
        }
        return uniqueProject;
      },
      [],
    );
    const bulletedProjectNames = uniqueProjectNames.map(
      (uniqueProject, index) => (
        <ul>
          <li key={index}>{uniqueProject}</li>
        </ul>
      ),
    );

    return {
      bulletedProjectNames: bulletedProjectNames,
      projectCount: uniqueProjectNames.length,
    };
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const onSingleSubmit = async (value) => {
    var status = '';
    var message = '';
    switch (savingType) {
      case 1:
        status = timeSheetStatus.approved;
        message = t('we-timesheet.approved-task-registration');
        break;
      case 2:
        status = timeSheetStatus.declined;
        message = t('we-timesheet.declined-task-registration');
        break;
      case 3:
        status = timeSheetStatus.draft;
        message = t('we-timesheet.draft-task-registration');
        break;
      default:
        break;
    }
    var response = await updateApproveNote(selectedItem, value || '', status);
    if (response.status === 200) {
      showSuccessSnackBar(message);
    }
    handleCloseDialogBox();
    handleCloseAll();
    handleClose();
    ReloadPage();
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      inputTextTitle: value.inputTextTitle,
      dialogContent: t(
        'we-timesheet.this-will-show-on-the-employee-time-registration-details',
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
      isError: value?.isError,
      helperText: value?.helperText,
      inputText: value?.inputText,
    }));
  };

  const ConfirmModal = () => {
    return (
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        inputTextTitle={confirmationBoxInfo.inputTextTitle}
        inpuText={confirmationBoxInfo.inputText}
        handleClose={handleCloseDialogBox}
        content={confirmationBoxInfo.dialogContent}
        handleAction={onSingleSubmit}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isError={confirmationBoxInfo.isError}
        helperText={confirmationBoxInfo.helperText}
      />
    );
  };

  // Approve using view more details modal
  const approveSubmitHandler = async () => {
    setSavingType(1);
    var value = {
      inputText: true,
      title: t(
        'we-timesheet.would-you-like-to-approve-this-time-registrations',
      ),
      type: t('we-timesheet.approve'),
      inputTextTitle: t('we-timesheet.Add-an-approval-note-optional'),
      actionType: DialogActionsType.success,
    };
    dialogBox(value, t('common.cancel'));
  };
  // Decline using view more details modal
  const declineSubmitHandler = async () => {
    setSavingType(2);
    var value = {
      inputText: true,
      title: t(
        'we-timesheet.would-you-like-to-decline-this-time-registrations',
      ),
      type: t('we-timesheet.decline'),
      inputTextTitle: t('we-timesheet.add-a-decline-note-required'),
      actionType: DialogActionsType.error,
      isError: true,
      helperText: t(
        'we-timesheet.please-enter-a-declination-note-before-proceeding',
      ),
    };
    dialogBox(value, t('common.cancel'));
  };

  const onOpenEditingSingle = async () => {
    setSavingType(3);
    var value = {
      inputText: false,
      title: t(
        'we-timesheet.would-you-like-to-open-for-editing-this-time-registration',
      ),
      type: t('we-timesheet.open-for-editing'),
      confirmModalSubContent: t(
        'we-timesheet.this-will-show-on-the-employees-time-registration-details',
      ),
      actionType: DialogActionsType.success,
      isError: false,
    };
    dialogBox(value, t('common.cancel'));
  };

  const onRowsSelectionHandler = (ids, id) => {
    if (groupByCategory.find((f) => f.id === id).reg.length === ids.length) {
      setSelecAllCheck(true);
    }
    loadExistingData(id, ids, null);
  };

  const loadExistingData = (nIndex, ids, checked) => {
    var total = 0;
    const temp = groupByCategory.map((f) => {
      let item = f;
      if (f.id === nIndex) {
        item = {
          id: f.id,
          name: f.name,
          description: f.description,
          reg: f.reg,
          ids:
            checked !== null
              ? checked
                ? f.reg.map((r) => r.id)
                : []
              : f.reg.map((r) => r.id).filter((arg) => ids.includes(arg)),
          checked: checked != null ? checked : ids.length > 0 ? true : false,
          expanded: f.expanded,
        };
      }
      total = total + item.ids.length;
      return item;
    });
    setTotalSelected(total);
    setGroupByCategory(temp);
    setSelecAllCheck(true);
  };

  const [selecAllCheck, setSelecAllCheck] = useState(false);
  const onHandleSelectAll = (e, value) => {
    setSelecAllCheck(value);
    // loadData(value, [], -1, value);
    loadExistingDataSelectAll(value);
  };

  const [savingType, setSavingType] = useState();

  const onApproveAll = () => {
    const { title, submit } =
      totalSelected > 1
        ? {
            title: t(
              'we-timesheet.would-you-like-to-approve-all-selected-time-registrations',
            ),
            submit: t('we-timesheet.approve-all'),
          }
        : {
            title: t(
              'we-timesheet.would-you-like-to-approve-this-time-registration',
            ),
            submit: t('we-timesheet.approve'),
          };

    setSavingType(1);

    onCallConfirmation(
      title,
      submit,
      'success',
      t('we-timesheet.add-an-approval-note-for-all-optional'),
      false,
      true,
      t(
        'we-timesheet.this-will-show-on-the-employee-time-registration-details',
      ),
    );
  };

  const onDeclineAll = () => {
    setSavingType(2);

    const confirmationMessage =
      totalSelected === 1
        ? t(
            'we-timesheet.would-you-like-to-decline-selected-time-registrations',
          )
        : t(
            'we-timesheet.would-you-like-to-decline-all-selected-time-registrations',
          );

    const noteLabel =
      totalSelected === 1
        ? t('we-timesheet.add-a-declination-note-required')
        : t('we-timesheet.add-a-declination-note-for-all-required');

    onCallConfirmation(
      confirmationMessage,
      totalSelected === 1
        ? t('we-timesheet.decline')
        : t('we-timesheet.decline-all'),
      'error',
      noteLabel,
      true,
      true,
      t(
        'we-timesheet.this-will-show-on-the-employee-time-registration-details',
      ),
      t('we-timesheet.please-enter-a-declination-note-before-proceeding'),
      (isSingleTask) => isSingleTask(true),
    );
  };

  const onOpenEditingALL = () => {
    const { title, submit, content } =
      totalSelected > 1
        ? {
            title: t(
              'we-timesheet.would-you-like-to-open-for-editing-all-selected-time-registration',
            ),
            submit: t('we-timesheet.open-all-for-editing'),
            content: t(
              'we-timesheet.we-will-set-the-status-of-all-selected-time-registrations-to-draft',
            ),
          }
        : {
            title: t(
              'we-timesheet.would-you-like-to-open-for-editing-this-time-registration',
            ),
            submit: t('we-timesheet.open-for-editing'),
            content: t(
              'we-timesheet.this-will-show-on-the-employees-time-registration-details',
            ),
          };

    setSavingType(3);
    onCallConfirmation(
      title,
      submit,
      'success',
      t('we-timesheet.add-a-declination-note-for-all-required'),
      false,
      false,
      content,
    );
  };

  const onCallConfirmation = (
    title,
    submitText,
    actionType,
    textTitle,
    isError,
    inputText,
    subContent,
    helperText,
  ) => {
    setconfirmationAll((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{title} </>
        </Typography>
      ),
      inputTextTitle: textTitle,
      dialogContent: subContent,
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      submitLabelText: submitText,
      actionType: actionType,
      isError: isError,
      inputText: inputText,
      helperText: helperText,
    }));
  };

  const handleCloseAll = () => {
    setconfirmationAll({
      open: false,
    });
  };

  const statusColor = (status) => {
    if (status === 'approved') {
      return classes.status_green;
    } else if (status === 'pending') {
      return classes.status_blue;
    } else if (status === 'declined') {
      return classes.status_red;
    } else {
      return classes.status_black;
    }
  };

  const onHandleSubmitALL = async (value) => {
    var newData = [];
    groupByCategory.forEach((f) => {
      var filter = f.reg.filter((arg) => f.ids.includes(arg.id));
      newData = [...newData, ...filter];
    });
    var response = '';
    setSeverity('success');
    switch (savingType) {
      case 1:
        response = await processReqestALL(
          newData,
          value,
          LeaveStatusType.approved,
          timeSheetStatus.approved,
          t('we-timesheet.timesheet-approved'),
        );
        showSuccessSnackBar(response);
        break;
      case 2:
        response = await processReqestALL(
          newData,
          value,
          LeaveStatusType.declined,
          timeSheetStatus.declined,
          t('we-timesheet.timesheet-declined'),
        );
        showSuccessSnackBar(response);
        break;
      case 3:
        response = await processReqestALL(
          newData,
          value != null ? value : '',
          LeaveStatusType.draft,
          timeSheetStatus.draft,
          t('we-timesheet.timesheet-draft'),
        );
        showSuccessSnackBar(response);
        break;
      default:
        break;
    }
    handleCloseAll();
    ReloadPage();
  };

  const ReloadPage = () => {
    props.onRefeshGetTeamRegList();
  };

  const processReqestALL = async (
    newData,
    value,
    status,
    updateStatus,
    message,
  ) => {
    var row = 0;
    var erroRow = 0;
    newData = newData.filter((r) => r.status !== status);
    await Promise.all(
      newData.map(async (f) => {
        var response = await updateApproveNote(f, value, updateStatus);
        if (response.errorMessage === '') {
          row = row + 1;
        } else {
          erroRow = erroRow + 1;
        }
      }),
    );
    var result = message;
    return result;
  };

  useEffect(() => {
    if (totalSelected === 0) {
      setSelecAllCheck(false);
    }
  }, [totalSelected]);

  const onChangeSelected = (id, expanded) => {
    loadExistingDataExpanded(id);
  };

  const loadExistingDataExpanded = (nIndex) => {
    var total = 0;
    const temp = groupByCategory.map((f) => {
      let item = f;
      if (f.id === nIndex) {
        item = {
          id: f.id,
          name: f.name,
          description: f.description,
          reg: f.reg,
          ids: f.ids,
          checked: f.checked,
          expanded: !f.expanded,
        };
      }
      total = total + item.ids.length;
      return item;
    });
    setTotalSelected(total);
    setGroupByCategory(temp);
  };

  const loadExistingDataSelectAll = (value) => {
    var total = 0;
    const temp = groupByCategory.map((f) => {
      let item = f;
      item = {
        id: f.id,
        name: f.name,
        description: f.description,
        reg: f.reg,
        ids: value ? f.reg.map((r) => r.id) : [],
        checked: value,
        expanded: f.expanded,
      };
      total = total + item.ids.length;
      return item;
    });
    setTotalSelected(total);
    setGroupByCategory(temp);
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openViewMore}
          onClose={handleClose}
        >
          <Fade in={openViewMore}>
            <Box className={classes.view_modal}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.view_avatar}
              >
                <Grid item>
                  <Avatar alt={selectedItem?.ownerName} src="/broken-image.jpg">
                    {selectedItem?.owner.firstName.charAt(0) +
                      selectedItem?.owner.lastName.charAt(0)}
                  </Avatar>
                </Grid>
                <Grid item className={classes.avatarName}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    className="padT4"
                  >
                    <>
                      {selectedItem?.ownerName}'s{' '}
                      {t('we-timesheet.time-registration')}
                    </>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.view_modal_f}
              >
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography className={classes.gray}>
                    {t('we-timesheet.status')}
                  </Typography>
                  <Typography className={statusColor(selectedItem?.status)}>
                    {selectedItem?.status}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.time')}</Typography>
                  <Typography>
                    {selectedItem?.regDuration &&
                      transformMinsToHoursMinutesString(
                        selectedItem?.regDuration,
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.registration-date')}</Typography>
                  <Typography>
                    {moment(selectedItem?.startDate).format(
                      dateFormats.moment_datepicker,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.task')}</Typography>
                  <Typography>{selectedItem?.task.task}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.project')}</Typography>
                  <Typography>
                    {selectedItem?.description.description}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.task-description')}</Typography>
                  <Typography>
                    {selectedItem?.regDesc?.length > 0 ? (
                      selectedItem?.regDesc
                    ) : (
                      <span>_</span>
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.view_modal_s}
              >
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.last-modified-by')}</Typography>
                  <Typography>
                    {selectedItem?.createdByName} on{' '}
                    {moment(selectedItem?.createdDate).format(
                      dateFormats.moment_datepicker,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.approved-by')}</Typography>
                  <Typography>
                    {selectedItem?.approvedByName && (
                      <>
                        {selectedItem?.approvedByName} on{' '}
                        {moment(selectedItem?.updatedDate).format(
                          dateFormats.moment_datepicker,
                        )}
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.view_modal_t}
              >
                <Grid item xs={12} className={classes.modal_desc}>
                  <Typography>{t('we-timesheet.notes')}</Typography>
                  <div className={classes.notes_scroll}>
                    {selectedItem ? (
                      selectedItem.note.length > 0 ? (
                        <>
                          {selectedItem.note?.map((f) => {
                            return (
                              <div className={classes.flex}>
                                <div className={classes.avatar}>
                                  <Avatar alt={f.name} src="/broken-image.jpg">
                                    {f.firstName.charAt(0) +
                                      f.lastName.charAt(0)}
                                  </Avatar>
                                </div>
                                <div className={classes.padNoteName}>
                                  <Typography>
                                    {f?.firstName}{' '}
                                    {moment(f?.timestamp).format(
                                      dateFormats.dateTimeHH,
                                    )}
                                  </Typography>
                                  <Typography>"{f.note}"</Typography>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className={classes.noNotes}>
                          <Typography>
                            {t('we-timesheet.no-existing-notes')}{' '}
                          </Typography>
                        </div>
                      )
                    ) : (
                      <div className={classes.noNotes}>
                        <Typography>
                          {' '}
                          {t('we-timesheet.no-existing-notes')}{' '}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.view_btn}
              >
                <Grid item className={classes.btn}>
                  <Button color="secondary" onClick={handleClose}>
                    {t('common.cancel')}
                  </Button>

                  <Button
                    color="primary"
                    onClick={approveSubmitHandler}
                    disabled={
                      selectedItem?.status.toLowerCase() ===
                      LeaveStatusType.approved.toLowerCase()
                        ? true
                        : false
                    }
                  >
                    {t('we-timesheet.approve')}
                  </Button>

                  <Button
                    color="primary"
                    onClick={declineSubmitHandler}
                    disabled={
                      selectedItem?.status.toLowerCase() ===
                      LeaveStatusType.declined.toLowerCase()
                        ? true
                        : false
                    }
                  >
                    {t('we-timesheet.decline')}
                  </Button>
                  <Button onClick={onOpenEditingSingle} color="primary">
                    {t('we-timesheet.open-for-editing')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
        <Menu
          className="menuReg"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            className={classes.menu_cell_icon}
            onClick={handleOpenViewMore}
          >
            <InfoOutlinedIcon className={classes.menu_icon} />
            {t('we-timesheet.view-more-details')}
          </MenuItem>
          <MenuItem
            onClick={approveSubmitHandler}
            disabled={
              selectedItem?.status.toLowerCase() ===
              LeaveStatusType.approved.toLowerCase()
                ? true
                : false
            }
          >
            <CheckIcon className={classes.menu_icon} />
            {t('we-timesheet.approve-s')}
          </MenuItem>

          <MenuItem
            onClick={declineSubmitHandler}
            disabled={
              selectedItem?.status.toLowerCase() ===
              LeaveStatusType.declined.toLowerCase()
                ? true
                : false
            }
          >
            <CloseIcon className={classes.menu_icon} />
            {t('we-timesheet.decline-s')}
          </MenuItem>

          <MenuItem onClick={onOpenEditingSingle}>
            <EditNoteIcon className={classes.menu_icon} />
            {t('we-timesheet.open-for-editing-s')}
          </MenuItem>
        </Menu>
        <div className={classes.reg_filter}></div>

        {selecAllCheck ? (
          <>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              className={classes.btnGroup}
            >
              <Button
                className={'ButtonSelectALL tolowercase ' + classes.fontSize14}
                variant="contained"
              >
                {' '}
                <FormControlLabel
                  className={' CheckboxWhiteColor'}
                  control={
                    <Checkbox
                      checked={selecAllCheck}
                      onChange={onHandleSelectAll}
                      // indeterminate={true}
                      name="antoine"
                    />
                  }
                />
                {`${totalSelected} task selected`}
              </Button>
              <Button
                className={'capitalize ' + classes.fontSize14}
                variant="contained"
                onClick={onApproveAll}
                startIcon={<CheckIcon />}
              >
                {t('we-timesheet.approve-s')}
              </Button>
              <Button
                className={'capitalize ' + classes.fontSize14}
                variant="contained"
                onClick={onDeclineAll}
                startIcon={<ClearIcon />}
              >
                {t('we-timesheet.decline-s')}
              </Button>
              <Button
                className={'capitalize ' + classes.fontSize14}
                variant="contained"
                onClick={onOpenEditingALL}
                startIcon={<EditNoteIcon />}
              >
                {t('we-timesheet.open')}
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <>
            <FormControlLabel
              className={classes.fontSize16}
              control={
                <Checkbox
                  checked={selecAllCheck}
                  // indeterminate={true}
                  onChange={onHandleSelectAll}
                  name="antoine"
                />
              }
              label={t('we-timesheet.select-all')}
            />
          </>
        )}

        {groupByCategory.map((groupRegByOwner, index) => (
          <Accordion
            expanded={groupRegByOwner.expanded}
            onChange={() =>
              onChangeSelected(groupRegByOwner.id, groupRegByOwner.expanded)
            }
            className={
              groupRegByOwner.ids.length > 0
                ? `${classes.reg_accordion1} AccourdionHeader`
                : classes.reg_accordion
            }
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordion_header}
            >
              <Typography
                variant="subtitle1"
                className={classes.accordion_header_org}
              >
                {groupRegByOwner.ids.length === 0 ? (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={groupRegByOwner.checked}
                        onChange={(e, value) =>
                          handleChange1(value, groupRegByOwner.id)
                        }
                      />
                    }
                  />
                ) : (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={groupRegByOwner.checked}
                        indeterminate={
                          groupRegByOwner.reg.length ===
                          groupRegByOwner.ids.length
                            ? false
                            : true
                        }
                        onChange={(e, value) =>
                          handleChange1(value, groupRegByOwner.id)
                        }
                      />
                    }
                  />
                )}
              </Typography>
              <Typography>
                <div className={classes.avatar}>
                  <Avatar alt={groupRegByOwner.name} src="/broken-image.jpg">
                    {groupRegByOwner.name.charAt(0)}
                  </Avatar>
                </div>
                <span className={classes.reg_ownerName}>
                  {groupRegByOwner.name}
                </span>
                <div className={classes.timesheet_reg}>
                  <span key={index} className={classes.timesheet_proj}>
                    {getAllUniqueProjectNamesAndCount(groupRegByOwner.reg)
                      .projectCount > 1 ? (
                      <UniqueProjectTooltip
                        title={
                          <div className={classes.tooltip_project}>
                            <p>Projects:</p>
                            <div>
                              {' '}
                              {
                                getAllUniqueProjectNamesAndCount(
                                  groupRegByOwner.reg,
                                ).bulletedProjectNames
                              }
                            </div>
                          </div>
                        }
                      >
                        <span>{groupRegByOwner.description}</span>
                      </UniqueProjectTooltip>
                    ) : (
                      <span>{groupRegByOwner.description}</span>
                    )}

                    {getAllUniqueProjectNamesAndCount(groupRegByOwner.reg)
                      .projectCount > 1
                      ? ` +${
                          getAllUniqueProjectNamesAndCount(groupRegByOwner.reg)
                            .projectCount - 1
                        }`
                      : ''}
                  </span>
                  <span className={classes.timesheet_approved}>
                    {getTotalApprovedReg(groupRegByOwner.reg)}
                    <span> {t('we-timesheet.approve').toLowerCase()}</span>
                  </span>
                  <span className={classes.timesheet_pending}>
                    {getTotalPendingReg(groupRegByOwner.reg)}
                    <span> {t('we-timesheet.pending').toLowerCase()}</span>
                  </span>
                </div>
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              className={
                selecAllCheck
                  ? `${classes.accordion_details}  DataGridHeader`
                  : classes.accordion_details
              }
            >
              <DataGridContainer
                className={classes.timesheet_grid}
                autoHeight={true}
                columns={columns}
                rows={groupRegByOwner.reg}
                getRowHeight={() => 'auto'}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 100]}
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={groupRegByOwner.ids}
                onRowSelectionModelChange={(ids) =>
                  onRowsSelectionHandler(ids, groupRegByOwner.id)
                }
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <ConfirmModal />
      <ConfirmationDialogBox
        open={confirmationAll.open}
        title={confirmationAll.dialogContentTitle}
        inputTextTitle={confirmationAll.inputTextTitle}
        inpuText={confirmationAll.inputText}
        handleClose={handleCloseAll}
        content={confirmationAll.dialogContent}
        handleAction={onHandleSubmitALL}
        actionType={confirmationAll.actionType}
        closeLabelText={confirmationAll.closeLabelText}
        actionLabelText={confirmationAll.submitLabelText}
        showActionButton={confirmationAll.showActionButton}
        isError={confirmationAll.isError}
        helperText={confirmationAll.helperText}
      />
      <div
        className={`${classes.snackbar_wrapper} snackbar-collapsed--we-timesheet`}
      >
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </>
  );
};
export default RegManagementList;
