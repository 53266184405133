import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import classes from './CreateOrgTeam.module.scss';
import { Button, Box, Tabs, Tab } from '@mui/material';
import InfoTab from './InfoTab/InfoTab';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ConfirmationDialogBox from '../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import MemberList from './list/MemberList';
import {
  getUsersApproverList,
  createTeam,
  getListOfActiveMembers,
  updateAllTeam,
  ManageTeamMembers,
  deactivateTeam,
  activateTeam,
} from '../../../../service/orgmanagementService';
import ErrorSnackbar from '../../../../../ui/snackbar/errorSnackbar/ErrorSnackbar';

import {
  LeaveTypeAll,
  DialogActionsType,
} from '../../../../../../utilities/constantUtils';
import { userRoles } from '../../../../../../utilities/roleUtils';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreateOrgTeam = () => {
  const { t } = useTranslation();
  const { selectedOrg, isEdit, selectedItem, cbShowSuccessSnackBar } =
    useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const activeMembers = useSelector((state) => state.orgContext.usersList);
  const membersPerTeam = useSelector(
    (state) => state.orgContext.membersPerTeam,
  );
  const memberListSelection = useSelector(
    (state) => state.orgContext.activeMemberList,
  );
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);
  const [selectedTeamManager, setSelectedTeamManager] = useState(null);
  const [teamName, setTeamName] = useState('');
  const [memberListLoader, setMemberListLoader] = useState(false);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: t('organization.would-you-like-to-save-this-new-team'),
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    submitLabelText: t('common.save'),
  });
  const [onChangeUpdate, setOnChangeUpdate] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [activeMembersState, setActiveMembersState] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberListSelectionState, setMemberListSelectionState] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const errorSnackbarRef = useRef(null);

  const navigateBack = () => navigate(-1);

  useEffect(() => {
    if (selectedItem) {
      setTeamName(selectedItem?.label);
      setSelectedTeamManager(selectedItem?.managerName);
    } else {
      setSelectedTeamManager(`${userFirstName} ${userLastName}`);
      onChangeTeamManager(null, `${userFirstName} ${userLastName}`);
    }
  }, [selectedItem, memberListSelection]);

  useEffect(() => {
    setMemberListSelectionState(memberListSelection);
  }, [memberListSelection]);

  useEffect(() => {
    if (membersPerTeam.length > 0 && selectedItem) {
      setMemberList(membersPerTeam);
    } else setMemberList([]);
  }, [membersPerTeam, selectedItem]);

  useEffect(() => {
    var nActiveMembers = [];
    activeMembers.forEach((mem) => {
      var role = mem.roles.find(
        (rol) => rol.toLowerCase() === userRoles.orgAdmin.toLowerCase(),
      );
      if (role) {
        nActiveMembers.push(mem);
      }
    });
    setActiveMembersState(nActiveMembers);
  }, [activeMembers]);

  const onChangeTeamManager = async (_, user) => {
    var selectedUser = memberListSelection.find(
      (member) => member.name === user,
    );
    if (selectedUser) {
      var nItem = { ...selectedUser };
      nItem.status = true;
      const exist = memberList.find((member) => member.id === selectedUser.id);
      let nMemberList = [];
      if (exist === undefined) {
        nMemberList = memberList.filter((member) => member.status !== true);
        nMemberList.push(nItem);
        setMemberList(nMemberList);
      } else {
        nMemberList = memberList.filter((member) => member.status !== true);
        setMemberList(nMemberList);
      }
    }
    setSelectedTeamManager(user);
    if (user !== `${userFirstName} ${userLastName}`) setOnChangeUpdate(true);
    setInitialLoad(false);
  };

  const onAddEmployeeHandler = () => {
    if (selectedEmployee) {
      var exist = memberList.find((emp) => emp.name === selectedEmployee.name);
      if (!exist) {
        var nActiveMembersState = [...memberList];
        var nselectedEmployee = { ...selectedEmployee };
        nselectedEmployee.id = memberList.length + 1;
        nActiveMembersState.unshift(nselectedEmployee);
        setMemberList(nActiveMembersState.filter((emp) => emp.name !== null));
        setDisabledBtn(false);
      }
    }
  };

  const deleteHandlerRef = useRef(null);
  const [selectedDeleteMember, setSelectedDeleteMember] = useState(null);

  const onDeleteHandler = (user) => {
    if (user.name === selectedTeamManager) {
      cbShowSuccessSnackBar(
        t(
          'organization.unable-to-delete-this-member-please-select-team-manager',
        ),
        'warning',
      );
    } else if (user.status) {
      var nActiveMembersState = memberList.filter(
        (emp) => emp.name !== user.name,
      );
      setMemberList(nActiveMembersState);
      setDisabledBtn(checkChangesMember(nActiveMembersState));
    } else if (isEdit) {
      setSelectedDeleteMember(user);
      confirmSubmissionHandler(1);
    }
  };

  const checkChangesMember = (members) => {
    var result = true;
    members.forEach((member) => {
      const exist = membersPerTeam.find(
        (mem) => mem.memberId === member.memberId,
      );
      if (exist === undefined) {
        result = false;
        return;
      }
    });
    return result;
  };

  const onChangeEmployee = (_, user) => {
    var nUser = memberListSelection.find((emp) => emp.name === user);
    var nItem = { ...nUser };
    nItem.status = true;
    setSelectedEmployee(nItem);
  };

  useEffect(() => {
    getListUserApprover();
    getActiveMembers();
  }, []);

  const getListUserApprover = async () => {
    await dispatch(getUsersApproverList(selectedOrg?.emp));
  };

  const getActiveMembers = async () => {
    await dispatch(getListOfActiveMembers(selectedOrg?.emp));
  };
  const handleChange = (_, tab) => {
    setTabValue(tab);
  };

  const onChangeTeamName = (event) => {
    if (event) {
      setTeamName(event.target.value);
      setOnChangeUpdate(true);
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setDisabledBtn(true);
      if (
        teamName !== selectedItem?.label ||
        selectedTeamManager !== selectedItem?.managerName
      ) {
        setDisabledBtn(false);
      }
    } else {
      if (teamName && selectedTeamManager) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
  }, [teamName, selectedTeamManager]);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    switch (actionButton) {
      case 0:
        if (activeDialog === 0) {
          if (isEdit) {
            putData();
          } else {
            postData();
          }
        } else {
          navigateBack();
        }
        break;
      case 1:
        deleteMembers();
        break;
      case 3:
        deActivateReActivate();
        break;
      case 4:
        deActivateReActivate();
        break;
      case 5:
        navigateBack();
        break;
      default:
        break;
    }
  };

  const deActivateReActivate = async () => {
    let response;
    if (selectedItem?.isActive) {
      response = await deactivateTeam(selectedItem);
    } else {
      response = await activateTeam(selectedItem);
    }
    if (response?.status === 204) {
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        const message = selectedItem?.isActive
          ? t('organization.team-deactivated')
          : t('team-reactivated');
        cbShowSuccessSnackBar(message, 'info');
      }, 1000);
    } else {
      errorSnackbarRef.current.snackBarContentHandler(response.errorMessage);
    }
  };

  const deleteMembers = async () => {
    const memberListData = memberList
      .filter((member) => member.id !== selectedDeleteMember.id)
      .map((mem) => mem.memberId);
    const team = {
      id: selectedItem?.id,
      members: memberListData,
    };
    var response = await ManageTeamMembers(team);
    if (response.status === 204) {
      var nActiveMembersState = [...memberList];
      setMemberList(
        nActiveMembersState.filter((emp) => emp.id !== selectedDeleteMember.id),
      );
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        cbShowSuccessSnackBar(t('organization.team-member-removed'), 'info');
      }, 1000);
    }
  };

  const postData = async () => {
    setIsLoading(true);
    var nMembers = memberListSelection.find(
      (mem) => mem.name === selectedTeamManager,
    );
    var memberListData = memberList.map((mem) => mem.memberId);
    var team = {
      name: teamName,
      manager: nMembers.id,
      owner: selectedOrg?.id,
      members: memberListData,
    };
    var response = await createTeam(team);
    if (response.status === 200) {
      const teamMembers = { ...team };
      teamMembers.id = response.data;
      await ManageTeamMembers(teamMembers, true);
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        cbShowSuccessSnackBar(t('organization.new-team-created'), 'info');
      }, 1000);
    } else {
      setIsLoading(false);
      cbShowSuccessSnackBar(
        t('organization.error-while-processing-request'),
        'error',
      );
    }
  };

  const putData = async () => {
    setIsLoading(true);
    var nMembers = memberListSelection.find(
      (mem) => mem.name === selectedTeamManager,
    );
    var memberListData = memberList.map((mem) => mem.memberId);
    var team = {
      id: selectedItem.id,
      name: teamName,
      manager: nMembers?.id,
      owner: selectedOrg?.id,
      members: memberListData,
    };
    var response = await updateAllTeam(team);
    if (response) {
      await ManageTeamMembers(team);
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        cbShowSuccessSnackBar(t('organization.team-updated'), 'info');
      }, 1000);
    } else {
      setIsLoading(false);
      cbShowSuccessSnackBar(
        t('organization.error-while-processing-request'),
        'error',
      );
    }
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const dialogBox = (dialogConfig, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{dialogConfig.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {dialogConfig.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: dialogConfig.type,
      actionType: dialogConfig.actionType,
      leaveType: dialogConfig.leaveType,
    }));
  };

  const [actionButton, setActionButton] = useState(null);

  const confirmSubmissionHandler = async (action) => {
    setActionButton(action);
    let dialogConfig = {};
    switch (action) {
      case 0:
        setActiveDialog(0);
        setIsLoading(false);
        if (isEdit) {
          dialogConfig = {
            title: t(
              'organization.would-you-like-to-save-your-changes-on-this-team',
            ),
            description: t(
              'organization.the-changes-will-be-effective-immediately-after-saving',
            ),
            type: t('common.save'),
            actionType: DialogActionsType.success,
            leaveType: t('organization.approve-all'),
          };
        } else {
          dialogConfig = {
            title: t('organization.would-you-like-to-save-this-new-team'),
            description: t(
              'organization.upon-saving-a-project-can-be-assigned-under-this-team-via-all-projects-page',
            ),
            type: t('common.save'),
            actionType: DialogActionsType.success,
            leaveType: t('organization.approve-all'),
          };
        }
        break;
      case 1:
        dialogConfig = {
          title: t(
            'organization.would-you-like-to-remove-this-member-from-this-team',
          ),
          description: t(
            'organization.upon-removing-this-member-can-no-longer-register-time-on-the-projects-of-this-team',
          ),
          type: t('organization.remove').toUpperCase(),
          actionType: DialogActionsType.error,
        };
        break;
      case 4:
        dialogConfig = {
          title: t('organization.would-you-like-to-reactivate-this-team'),
          actionType: DialogActionsType.success,
          type: t('organization.reactivate'),
          loaderText: t('organization.reactivating'),
          description: t(
            'organization.upon-reactivating-the-admin-managers-can-once-again-create-projects-under-this-team',
          ),
        };
        break;
      case 3:
        dialogConfig = {
          title: t('organization.would-you-like-to-deactivate-this-team'),
          actionType: DialogActionsType.error,
          type: t('organization.deactivate'),
          loaderText: t('organization.deactivating'),
          description: t(
            'organization.upon-deactivating-the-projects-under-this-team-will-be-tagged-as-Done-and-its-members-can-no-longer-register-time',
          ),
        };
        break;
      default:
        break;
    }
    dialogBox(dialogConfig, t('common.cancel'));
  };
  const [activeDialog, setActiveDialog] = useState(false);
  const discardHandler = async () => {
    setActionButton(5);
    setActiveDialog(1);
    if (onChangeUpdate) {
      setIsLoading(false);
      var dialogConfig = {
        title: 'Oops, you have unsaved changes.',
        description: 'Discarding these changes will be removed permanently.',
        type: 'DISCARD',
        actionType: DialogActionsType.error,
        leaveType: LeaveTypeAll.approveALL,
      };
      dialogBox(dialogConfig, t('common.close'));
    } else {
      navigateBack();
    }
  };

  const [disabledBtn, setDisabledBtn] = useState(true);

  const onCreateMembersHandler = () => {
    var item = {
      id: Math.random(),
      memberEmploymentType: null,
      memberFirstName: '',
      memberId: null,
      memberLastName: '',
      memberTeamEmployment: [],
      name: null,
    };
    var nMemberList = memberList.filter((emp) => emp.name !== null);
    nMemberList.unshift(item);
    setMemberList(nMemberList);
    var nMember = [];
    memberListSelection.forEach((member) => {
      const exist = memberList.find((mem) => mem.memberId === member.memberId);
      if (!exist) {
        nMember.push(member);
      }
    });
    setMemberListSelectionState(nMember);
  };
  const onRemoveHandler = () => {
    setSelectedEmployee(null);
    var nMemberList = memberList.filter((emp) => emp.name !== null);
    setMemberList(nMemberList);
  };

  return (
    <>
      <ErrorSnackbar ref={errorSnackbarRef} />
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={onSubmit}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isLoading={isLoading}
      />

      <div className={classes.backButton}>
        {' '}
        <Button
          color="secondary"
          size="medium"
          className={`capitalize ${classes.btnBack}`}
          onClick={() => discardHandler()}
          startIcon={<ArrowBackIcon />}
        >
          {t('common.back')}
        </Button>
      </div>
      <div container className={classes.invite_card}>
        <div className={classes.header_con}>
          <div className={classes.flexContainer}>
            <div>
              <Typography className={classes.name}>
                {isEdit
                  ? `${t('organization.manage-team')} ${selectedItem?.label}`
                  : t('organization.create-a-new-team')}
              </Typography>
              <Typography className={classes.desc}>
                {isEdit
                  ? t('organization.update-details-of-this-team')
                  : t('organization.add-a-new-team-for-your-organization')}
              </Typography>
            </div>
            <div className={classes.invite_button}>
              <div className="padR10">
                <Button
                  color="secondary"
                  size="medium"
                  className={classes.button}
                  onClick={() => discardHandler()}
                >
                  {t('organization.discard')}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => confirmSubmissionHandler(0)}
                  disabled={disabledBtn}
                >
                  {isEdit ? t('common.save-changes') : t('common.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.tabs} PanelCustom`}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                key="2"
                label={t('organization.info')}
                className={classes.tab}
              />
              <Tab
                key="3"
                label={t('organization.members')}
                className={classes.tab}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <>
              <InfoTab
                usersList={memberListSelectionState}
                selectedTeamManager={selectedTeamManager}
                onChangeTeamManager={onChangeTeamManager}
                teamName={teamName}
                onChangeTeamName={onChangeTeamName}
                selectedItem={selectedItem}
                isEdit={isEdit}
              />
            </>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <MemberList
              usersList={memberListSelectionState}
              list={memberList}
              loader={memberListLoader}
              teamName={teamName}
              onCreateMembersHandler={onCreateMembersHandler}
              onAddEmployeeHandler={onAddEmployeeHandler}
              onChangeEmployee={onChangeEmployee}
              onRemoveHandler={onRemoveHandler}
              onDeleteHandler={onDeleteHandler}
            />
          </CustomTabPanel>
        </div>
        <div className={classes.manage_details_wrapper}>
          {isEdit ? (
            <>
              <div className={classes.manage_details}>
                <div className={classes.flex}>
                  <div className={classes.manage_sub}>
                    <Typography className={classes.sub_title}>
                      {t('organization.modified-by')}
                    </Typography>
                    <Typography className={classes.sub_title}>
                      {selectedItem?.updatedAt && (
                        <>
                          {selectedItem?.updatedBy?.name}{' '}
                          {moment(selectedItem?.updatedAt).format('DD-MM-YYYY')}
                        </>
                      )}
                    </Typography>
                  </div>
                  <div className={classes.manage_sub}>
                    <Typography className={classes.sub_title}>
                      {t('organization.created-by')}
                    </Typography>
                    <Typography className={classes.sub_title}>
                      {selectedItem?.createdAt && (
                        <>
                          {selectedItem?.createdBy?.name}{' '}
                          {moment(selectedItem?.createdAt).format('DD-MM-YYYY')}
                        </>
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {selectedItem && (
        <>
          {selectedItem?.isActive ? (
            <div className={classes.deactivateActivateBtn}>
              <Button
                color="secondary"
                size="medium"
                className={`capitalize ${classes.deactivate}`}
                onClick={() => confirmSubmissionHandler(3)}
              >
                <div className={classes.flexContainer}>
                  <div className="padR5">
                    <DoDisturbAltIcon fontSize="medium" />{' '}
                  </div>
                  <div>{t('organization.deactivate-team').toUpperCase()}</div>
                </div>
              </Button>
            </div>
          ) : (
            <div className={classes.deactivateActivateBtn}>
              <Button
                color="secondary"
                size="medium"
                className={`capitalize ${classes.reactivate}`}
                onClick={() => confirmSubmissionHandler(4)}
              >
                <div className={classes.flexContainer}>
                  <div className="padR5">
                    <CheckIcon fontSize="medium" />{' '}
                  </div>
                  <div>{t('organization.reactivate-team')}</div>
                </div>
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrgTeam;
