/* eslint-disable default-case */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TextBox from '../../../../ui/formcontrols/textBox/TextBox';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { FormHelperText, IconButton } from '@mui/material';
import {
  cancelTeamInvitation,
  cleanUpOrgMembersData,
  getListOfActiveMembers,
  getListOfPendingInvitations,
  getListOfPendingRequests,
  removeMember,
  reviewAllLeaveRequests,
  reviewLeaveRequest,
  sendTeamInvitationService,
  validateEmailAddress,
} from '../../../service/orgmanagementService';
import useInputValidation from '../../../../../hooks/use-input-validation';
import FormButtonLoading from '../../../../ui/formcontrols/formButtonLoading/FormButtonLoading';
import FormButton from '../../../../ui/formcontrols/formButton/FormButton';
import ErrorAlert from '../../../../ui/alerts/errorAlert/ErrorAlert';
import {
  authTypes,
  organizationComponentPermissions,
  userRoles,
} from '../../../../../utilities/roleUtils';
import useAuthorization from '../../../../../hooks/use-authorization';
import classes from './OrgMember.module.scss';
import { getUserOrgTeamsList } from '../../../../home/service/homeService';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const TabStyled = styled(Tabs)(({ theme }) => ({
  '& .Mui-selected': {
    color: '#00B8D4 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00B8D4 !important',
  },
}));

const PendingInvitationMembersMenu = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let memberName =
    props.row.invitationUser.userFname +
    ' ' +
    props.row.invitationUser.userLname;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlecCancelInvite = () => {
    dispatch(cancelTeamInvitation(props.row.invitationId)).then((data) => {
      if (data.result === 1) {
        props.showSuccessSnackBarFn(
          t('organization.team-invitation-was-successfully-cancelled'),
        );
      } else {
        props.showErrorSnackBarFn(data.errorMessage);
      }
    });
  };
  return (
    <React.Fragment>
      <Avatar
        className={classes.member_avatar}
        alt={memberName}
        src="./broken-image.jpg"
      />
      <div>{memberName}</div>
      <IconButton className={classes.menu_member_icon} onClick={handleClick}>
        <MoreVertIcon
          className={`${!open && classes.task_menu_button}`}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          className={classes.menu_cell_icon}
          key={1}
          onClick={handlecCancelInvite}
        >
          <CloseIcon />
          {t('organization.cancel-invitation')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const PendingInvitationContent = (props) => {
  const { t } = useTranslation();
  const listOfPendingRequests = useSelector(
    (state) => state.orgContext.pendingInvitationsList,
  );

  return (
    <div className={classes.filter_tab} style={{ width: '100%' }}>
      <DataGridContainer
        autoHeight
        columns={[
          {
            field: t('organization.members'),
            renderCell: (params) => {
              return (
                <PendingInvitationMembersMenu
                  row={params.row}
                  showSuccessSnackBarFn={props.showSuccessSnackBarFn}
                  showErrorSnackBarFn={props.showErrorSnackBarFn}
                />
              );
            },
            width: 300,
          },
          {
            field: t('organization.teams'),
            valueGetter: (params) => {
              return params.row.invitationTeam.teamName;
            },
            width: 200,
          },
          {
            field: t('organization.invited-by'),
            valueGetter: (params) => {
              return (
                params.row.invitationCreatedByUser.userName +
                ` ${t('organization.on')} ` +
                params.row.invitationCreatedAt
              );
            },
            width: 230,
          },
        ]}
        rows={listOfPendingRequests}
        getRowId={(row) => row.invitationId}
      />
    </div>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

const ActiveMembersMenu = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let memberName = props.row.memberFirstName + ' ' + props.row.memberLastName;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveFromOrgClick = () => {
    dispatch(
      removeMember(props.row.memberId, null, props.selectedOrg.emp),
    ).then((data) => {
      if (data.result === 1) {
        props.showSuccessSnackBarFn(
          t(
            'organization.member-was-successfully-removed-from-the-organization',
          ),
        );
      } else {
        props.showErrorSnackBarFn(data.errorMessage);
      }
    });
    handleClose();
  };

  const handleRemoveFromTeamClick = (teamId) => {
    dispatch(
      removeMember(props.row.memberId, teamId, props.selectedOrg.emp),
    ).then((data) => {
      if (data.result === 1) {
        props.showSuccessSnackBarFn(
          t('organization.member-was-successfully-removed-from-the-team'),
        );
      } else {
        // props.showErrorSnackBarFn(removeMemberFromTeamFailureMessage);
        props.showErrorSnackBarFn(data.errorMessage);
      }
    });
    handleClose();
  };

  return (
    <React.Fragment>
      <Avatar
        className={classes.member_avatar}
        alt={memberName}
        src="./broken-image.jpg"
      />
      <div>{memberName}</div>
      <IconButton className={classes.menu_member_icon} onClick={handleClick}>
        <MoreVertIcon
          className={`${!open && classes.task_menu_button}`}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          className={classes.menu_cell_icon}
          key={1}
          onClick={handleRemoveFromOrgClick}
        >
          <PersonOffIcon />
          {props.row.memberEmploymentType === 'outside-collaborator'
            ? t('organization.remove')
            : t('organization.suspend')}{' '}
          from {props.selectedOrg?.organizations?.name} (org)
        </MenuItem>
        {props?.row?.memberTeamEmployment
          ?.filter((mem) => mem.teamName !== undefined)
          ?.map((team, index) => (
            <MenuItem
              className={classes.menu_cell_icon}
              key={index}
              onClick={(e) => {
                handleRemoveFromTeamClick(team?.teamId);
              }}
            >
              <ExitToAppIcon />
              {t('organization.remove-from')} {team?.teamName} (team)
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
};

const ActiveMembersContent = (props) => {
  const { t } = useTranslation();
  const activeMembers = useSelector(
    (state) => state.orgContext.activeMemberList,
  );
  const teamList = useSelector((state) => state.orgContext.teams);
  const [activeMembersState, setActiveMembersState] = useState([]);

  useEffect(() => {
    const list = activeMembers.map((mem) => {
      var item = { ...mem };
      const teamsList = [];
      item.memberTeamEmployment.forEach((team) => {
        var exist = teamList.find((f) => f.teamId === team.teamId);
        if (exist) {
          teamsList.push(team);
        }
      });
      item.memberTeamEmployment = teamsList;
      return item;
    });
    setActiveMembersState(list);
  }, [activeMembers, teamList]);

  return (
    <div className={classes.filter_tab} style={{ height: 400, width: '100%' }}>
      <div className={classes.active_members_number}>
        <Typography>
          {activeMembers.length} {t('organization.members')}
        </Typography>
      </div>
      <DataGridContainer
        autoHeight
        columns={[
          {
            field: t('organization.members'),
            renderCell: (params) => {
              return (
                <ActiveMembersMenu
                  row={params.row}
                  selectedOrg={props.selectedOrg}
                  showSuccessSnackBarFn={props.showSuccessSnackBarFn}
                  showErrorSnackBarFn={props.showErrorSnackBarFn}
                />
              );
            },
            valueGetter: (params) => {
              return (
                params.row.memberFirstName + ' ' + params.row.memberLastName
              );
            },
            width: 300,
          },
          {
            field: t('organization.teams'),
            valueGetter: (params) => {
              if (params.row.memberTeamEmployment) {
                return params.row.memberTeamEmployment
                  .map((team) => team.teamName)
                  .join(', ');
              } else {
                return '';
              }
            },
            width: 300,
          },
          {
            field: t('organization.membership'),
            valueGetter: (params) => {
              let membershipType = '';

              switch (params.row.memberEmploymentType) {
                case null: //if value is null then it is a direct hire/employee of an org
                  membershipType = userRoles.employee;
                  break;
                case 'employee':
                  membershipType = userRoles.employee;
                  break;
                case 'outside-collaborator':
                  membershipType = userRoles.outsideCollaborator;
                  break;
              }

              return membershipType;
            },
            width: 100,
          },
        ]}
        getRowId={(row) => row.memberId}
        rows={activeMembersState}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

const PendingReqActions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pendingRequests = useSelector(
    (state) => state.orgContext.pendingRequestsList,
  );

  const approveAllRequest = useCallback(() => {
    dispatch(reviewAllLeaveRequests(pendingRequests, true)).then((data) => {
      if (data.result === 1) {
        props.showSuccessSnackBarFn(
          t('organization.all-requests-successfully-approved'),
        );
      } else {
        // props.showErrorSnackBarFn(approveAllRequestFailureMessage);
        props.showErrorSnackBarFn(data.errorMessage);
      }
    });
  }, []);

  const declineAllRequest = useCallback(() => {
    dispatch(reviewAllLeaveRequests(pendingRequests, false)).then((data) => {
      if (data.result === 1) {
        props.showSuccessSnackBarFn(
          t('organization.all-requests-successfully-declined'),
        );
      } else {
        // props.showErrorSnackBarFn(declineAllRequestFailureMessage);
        props.showErrorSnackBarFn(data.errorMessage);
      }
    });
  }, []);

  return (
    <GridToolbarContainer>
      <Grid container className={classes.request_action_container}>
        <GridToolbarFilterButton />
        <Grid className={classes.request_action}>
          <Button
            className={`${classes.request_button_action} ${classes.red}`}
            onClick={declineAllRequest}
          >
            <CloseIcon />
            <Typography fontWeight="fontWeightMedium">
              {t('organization.decline-all')}
            </Typography>
          </Button>
          <Button
            className={`${classes.request_button_action} ${classes.green}`}
            onClick={approveAllRequest}
          >
            <CheckIcon />
            <Typography fontWeight="fontWeightMedium">
              {t('organization.approve-all')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

const PendingRequestContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pendingRequests = useSelector(
    (state) => state.orgContext.pendingRequestsList,
  );

  const approveRequest = useCallback(
    (requestId, requestUserId, requestTeamId) => {
      dispatch(
        reviewLeaveRequest({
          id: requestId,
          'is-approve': true,
          requestUserId: requestUserId,
          requestTeamId: requestTeamId,
        }),
      ).then((data) => {
        if (data.result === 1) {
          props.showSuccessSnackBarFn(
            t('organization.request-successfully-approved'),
          );
        } else {
          // props.showErrorSnackBarFn(approveRequestFailureMessage);
          props.showErrorSnackBarFn(data.errorMessage);
        }
      });
    },
    [],
  );

  const declineRequest = useCallback(
    (requestId, requestUserId, requestTeamId) => {
      dispatch(
        reviewLeaveRequest({
          id: requestId,
          'is-approve': false,
          requestUserId: requestUserId,
          requestTeamId: requestTeamId,
        }),
      ).then((data) => {
        if (data.result === 1) {
          props.showSuccessSnackBarFn(
            t('organization.request-successfully-declined'),
          );
        } else {
          props.showErrorSnackBarFn(data.errorMessage);
        }
      });
    },
    [],
  );

  return (
    <div className={classes.filter_tab} style={{ height: 400, width: '100%' }}>
      <DataGridContainer
        autoHeight
        columns={[
          {
            field: t('organization.members'),
            renderCell: (params) => {
              let memberName =
                params.row.requestUser.requestUserFname +
                ' ' +
                params.row.requestUser.requestUserLname;
              return (
                <React.Fragment>
                  <Avatar
                    className={classes.member_avatar}
                    alt={memberName}
                    src="./broken-image.jpg"
                  />
                  <div>{memberName}</div>
                </React.Fragment>
              );
            },
            width: 200,
          },
          {
            field: t('organization.team'),
            valueGetter: (params) => {
              return params.row.requestTeam.requestTeamName;
            },
            width: 150,
          },
          {
            field: t('organization.request'),
            valueGetter: (params) => {
              let reqType = '';

              switch (params.row.requestType) {
                case 'leave-team':
                  reqType = 'Wants to leave the team';
                  break;
              }
              return reqType;
            },
            width: 250,
          },
          {
            field: 'RequestAction',
            type: 'actions',
            getActions: (params) => [
              <GridActionsCellItem
                icon={<CloseIcon />}
                label="decline"
                className={classes.request_dec_icon}
                onClick={() => {
                  declineRequest(
                    params.row.requestId,
                    params.row.requestUser.requestUserId,
                    params.row.requestTeam.requestTeamId,
                  );
                }}
              />,
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="decline"
                className={classes.request_approve_icon}
                onClick={() => {
                  approveRequest(
                    params.row.requestId,
                    params.row.requestUser.requestUserId,
                    params.row.requestTeam.requestTeamId,
                  );
                }}
              />,
            ],
            width: 100,
          },
        ]}
        getRowId={(row) => row.requestId}
        rows={pendingRequests}
        components={{
          Toolbar: PendingReqActions,
        }}
        componentsProps={{
          toolbar: {
            showSuccessSnackBarFn: props.showSuccessSnackBarFn,
            showErrorSnackBarFn: props.showErrorSnackBarFn,
          },
        }}
      />
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="orgtabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.org_tab_panel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const checkKeywordsExist = (array, keywords) => {
  const lowerCaseArray = array?.map((item) => item.toLowerCase());
  const lowerCaseKeywords = keywords?.map((keyword) => keyword.toLowerCase());
  return lowerCaseKeywords?.every((keyword) =>
    lowerCaseArray?.includes(keyword),
  );
};

const OrgMemberInvitationForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orgList = useSelector((state) => state.userContext.userOrganizations);
  const userId = useSelector((state) => state.userContext.userId);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const [selectedOrgHolder, setSelectedOrgHolder] = useState(
    props?.selectedOrg,
  );

  const handleOrgSelectionChange = (orgId) => {
    setSelectedOrgHolder(orgList.filter((org) => org.id === orgId)[0]);
  };

  const getTeamsByEmploymentId = () => {
    if (selectedOrgHolder?.emp && userId) {
      dispatch(getUserOrgTeamsList(userId, selectedOrgHolder?.emp));
    }
  };

  useEffect(() => {
    getTeamsByEmploymentId();
  }, [selectedOrgHolder]);

  const resetFormError = () => {
    if (formErrorMessage) {
      setFormErrorMessage('');
    }
  };

  //Org Selection
  const { checkAuthHandler } = useAuthorization();
  const userOrgList = useSelector((state) =>
    state.userContext.userOrganizations.filter(
      (org) =>
        checkAuthHandler(
          authTypes.ACTION,
          organizationComponentPermissions.ADD_MEMBERS,
          org?.userAuth,
          state.userContext.userTeamRoles.filter(
            (team) => team.teamOrg === selectedOrgHolder?.id,
          ),
          'org',
        ) && org.id !== state.userContext.userId,
    ),
  );

  const [org, setOrg] = useState(
    userOrgList.filter((org) => org.id === selectedOrgHolder?.id)[0]?.id,
  );
  const handleOrgChange = (event) => {
    let orgId = event.target.value;
    setOrg(orgId);
    resetFields();
    resetFormError();
    handleOrgSelectionChange(orgId);
    const isAdmin = checkKeywordsExist(selectedOrgHolder?.userAuth, [
      'Administrator',
    ]);
    dispatch(getListOfPendingRequests(selectedOrgHolder?.emp, isAdmin));
    dispatch(getListOfPendingInvitations(selectedOrgHolder?.emp, isAdmin));
  };

  //Team Selection
  const userTeamList = useSelector((state) =>
    state.orgContext.teams.filter((team) => {
      const isUserOrgOwnerOrAdmin = checkKeywordsExist(
        selectedOrgHolder?.userAuth,
        ['Administrator'],
      );
      const isTeamOrgIdSameWithSelectedOrdId =
        team.orgId === selectedOrgHolder?.id;
      const isUserAManagerInTeam = isUserOrgOwnerOrAdmin
        ? true
        : team?.teamManager?.userId === userId;

      return (
        isUserAManagerInTeam &&
        isTeamOrgIdSameWithSelectedOrdId &&
        checkAuthHandler(
          authTypes.ACTION,
          organizationComponentPermissions.ADD_MEMBERS,
          selectedOrgHolder?.userAuth,
          state.userContext.userTeamRoles.filter(
            (teamInfoRole) =>
              teamInfoRole.teamOrg === selectedOrgHolder?.id &&
              teamInfoRole.teamId === team.teamId,
          ),
          'teams',
        )
      );
    }),
  );

  const [team, setTeam] = useState('');
  const [teamHasError, setTeamlHasError] = useState(false);
  const handleTeamChange = (event) => {
    if (event.target.value) {
      setTeamlHasError(false);
      setTeam(event.target.value);
    }
  };

  const resetFields = () => {
    setTeam('');
    setTeamlHasError(false);
  };

  const onBTeamlurHandler = (event) => {
    const selectedInsideDropdown = event.target.value === 0;
    setTeamlHasError(selectedInsideDropdown || team ? false : true);
  };

  const handleInvitationFormClose = () => {
    props.onClose();
  };

  //Email Input
  const [inputEmail, setInputEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);

  const onEmailChangeHandler = (event) => {
    setInputEmail(event.target.value);
    if (emailErrorMessage) {
      setEmailErrorMessage('');
      setEmailHasError(false);
    }

    resetFormError();
  };

  const onEmailBlurHandler = () => {
    if (!inputEmail) {
      setEmailErrorMessage(t('organization.invalid-email-address'));
      setEmailHasError(true);
    }
  };

  useEffect(() => {
    if (inputEmail) {
      const timeOut = setTimeout(() => {
        validateEmailAddress({ email: inputEmail, field: 'invite-email' }).then(
          (data) => {
            if (data.result === 1) {
            } else {
              setEmailErrorMessage(data.errorMessage);
              setEmailHasError(true);
            }
          },
        );
      }, 800);

      return () => clearTimeout(timeOut);
    } else {
    }
  }, [inputEmail]);

  //first name input
  const {
    value: fname,
    isValid: fnameIsValid,
    hasError: fnameHasError,
    valueChangeHandler: onFnameChangeHandler,
    inputBlurHandler: onFnameBlurHandler,
  } = useInputValidation((value) => value.length > 0, resetFormError);

  //last name input
  const {
    value: lname,
    isValid: lnameIsValid,
    hasError: lnameHasError,
    valueChangeHandler: onLnameChangeHandler,
    inputBlurHandler: onLnameBlurHandler,
  } = useInputValidation((value) => value.length > 0, resetFormError);

  const sendInviteHandler = async () => {
    setIsLoading(true);
    let inviteRequestParam = {
      team: team,
      fname: fname,
      lname: lname,
      email: inputEmail,
      message: '',
    };

    try {
      const inviteRequestResponse = await sendTeamInvitationService(
        inviteRequestParam,
      );

      if (inviteRequestResponse.result === 1) {
        const isAdmin = checkKeywordsExist(selectedOrgHolder?.userAuth, [
          'Administrator',
        ]);
        dispatch(
          getListOfPendingInvitations(selectedOrgHolder?.emp, isAdmin),
        ).then((data) => {
          setIsLoading(false);
          props.showSuccessSnackBarFn(
            t('organization.team-invitation-successfully-sent'),
          );
          handleInvitationFormClose();
          if (data.result !== 1) {
            props.showErrorSnackBarFn(data.errorMessage);
          }
        });
      } else {
        setIsLoading(false);
        setFormErrorMessage(inviteRequestResponse.errorMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setFormErrorMessage(
        error ? error : t('organization.error-on-service-request'),
      );
    }
  };

  let formIsValid = fnameIsValid && lnameIsValid && !emailHasError && team;

  return (
    <Box sx={{ width: 400 }}>
      <Stack className={classes.invite_toggle}>
        <IconButton
          className={classes.invite_panel_close}
          onClick={handleInvitationFormClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography className={classes.invite_toggle_title}>
          {t('organization.invite-members')}
        </Typography>

        <Typography className={classes.invite_toggle_desc}>
          {t(
            'organization.you-can-send-an-invitation-to-anyone-who-has-a-valid-email-address',
          )}
          {t('organization.all-fields-are-required')}
        </Typography>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120 }}
          className="marginB28"
        >
          <InputLabel
            className={classes.org_select_form}
            id="organization-selector-label"
          >
            {t('organization.select-organization')}
          </InputLabel>
          <Select
            labelId="organization-selector-label"
            id="organization-selector"
            value={org}
            onChange={handleOrgChange}
            label={t('organization.select-organization')}
            defaultValue=""
          >
            {userOrgList?.map((org, index) => (
              <MenuItem value={org.id} key={index}>
                {org?.organizations?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120 }}
          className="marginB28"
          error={teamHasError}
        >
          <InputLabel
            className={classes.org_select_form}
            id="team-selector-label"
          >
            {t('organization.select-team')}
          </InputLabel>
          <Select
            labelId="team-selector-label"
            id="team-selector"
            value={team}
            onChange={handleTeamChange}
            onClose={onBTeamlurHandler}
            defaultValue=""
            label={t('organization.select-team')}
          >
            {userTeamList?.map((team) => (
              <MenuItem key={team.teamId} value={team.teamId}>
                {team.teamName}
              </MenuItem>
            ))}
          </Select>
          {teamHasError && (
            <FormHelperText>{t('organization.invalid-team')}</FormHelperText>
          )}
        </FormControl>

        <TextBox
          isError={emailHasError}
          id="email"
          label={t('organization.enter-email-address')}
          type="text"
          onChange={onEmailChangeHandler}
          onBlur={onEmailBlurHandler}
          errorText={emailErrorMessage}
          ariaLabel="Email"
        />

        <TextBox
          isError={fnameHasError}
          id="fname"
          label={t('organization.enter-first-name')}
          type="text"
          onChange={onFnameChangeHandler}
          onBlur={onFnameBlurHandler}
          errorText={t('organization.invalid-first-name')}
          ariaLabel="First name"
        />

        <TextBox
          isError={lnameHasError}
          id="lname"
          label={t('organization.enter-last-name')}
          type="text"
          onChange={onLnameChangeHandler}
          onBlur={onLnameBlurHandler}
          errorText={t('organization.invalid-last-name')}
          ariaLabel="Last name"
        />

        <Grid container className={classes.org_panel_button}>
          <Grid className={classes.org_send}>
            <FormButtonLoading
              id="send-button"
              label={t('organization.send-invite')}
              loadingIndicator={t('organization.sending')}
              loading={isLoading}
              onClick={sendInviteHandler}
              fullWidth={false}
              loadingPosition="start"
              color="primary"
              disabled={!formIsValid}
            />
          </Grid>
          <Grid className={classes.org_cancel}>
            <FormButton
              label={t('common.cancel')}
              fullWidth={false}
              onClick={handleInvitationFormClose}
              id="cancel-button"
              disabled={isLoading}
              variant="text"
            />
          </Grid>
        </Grid>
        {formErrorMessage && (
          <div className={classes.invite_error_alert}>
            <ErrorAlert id="invite-error" message={formErrorMessage} />
          </div>
        )}
      </Stack>
    </Box>
  );
};

const OrgMembers = () => {
  const { selectedOrg, cbShowSuccessSnackBarFn, cbShowErrorSnackBarFn } =
    useOutletContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const orgContext = useSelector((state) => state.orgContext);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    //To set active organization sub menu on toggle of invite form drawer. If drawer is close then don't change active sub menu. If drawer is open keep the active sub menu which is the "all members".
    //cbOnInvitationFormStateChangeFn(newOpen);
  };

  useEffect(() => {
    if (selectedOrg) {
      dispatch(getListOfActiveMembers(selectedOrg?.emp));
      const isAdmin = checkKeywordsExist(selectedOrg?.userAuth, [
        'Administrator',
      ]);
      dispatch(getListOfPendingRequests(selectedOrg?.emp, isAdmin));
      dispatch(getListOfPendingInvitations(selectedOrg?.emp, isAdmin));
      return () => {
        dispatch(cleanUpOrgMembersData());
      };
    }
  }, [selectedOrg]);

  return (
    <div container className={classes.org_invite_card}>
      <div className={classes.org_header_con}>
        <div>
          <Typography className={classes.org_name}>
            {selectedOrg?.name} {t('organization.members')}
          </Typography>
          <Typography className={classes.org_desc}>
            {t(
              'organization.manage-and-view-members-of-the-organization-you-are-an-admin',
            )}
          </Typography>
        </div>

        <div className={classes.org_invite_button}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleDrawer(true)}
          >
            {t('organization.invite')}
          </Button>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <OrgMemberInvitationForm
              selectedOrg={selectedOrg}
              onClose={toggleDrawer(false)}
              showSuccessSnackBarFn={cbShowSuccessSnackBarFn}
              showErrorSnackBarFn={cbShowErrorSnackBarFn}
            />
          </SwipeableDrawer>
        </div>
      </div>

      <Box className={classes.org_tab_box} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabStyled
            aria-label="basic tabs example"
            value={activeTab}
            className={classes.org_tab_name}
            onChange={handleChange}
            columnseparator="false"
          >
            <Tab
              label={t('organization.pending-active')}
              className={classes.org_tab_active}
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Badge
                  className={classes.org_badge}
                  badgeContent={orgContext.pendingInvitationsList.length}
                  color="primary"
                >
                  {t('organization.pending-invitation')}
                </Badge>
              }
              {...a11yProps(1)}
            ></Tab>
            <Tab
              label={
                <Badge
                  className={classes.org_badge}
                  badgeContent={orgContext.pendingRequestsList.length}
                  color="primary"
                >
                  {t('organization.pending-request')}
                </Badge>
              }
              {...a11yProps(2)}
            ></Tab>
          </TabStyled>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <ActiveMembersContent
            selectedOrg={selectedOrg}
            showSuccessSnackBarFn={cbShowSuccessSnackBarFn}
            showErrorSnackBarFn={cbShowErrorSnackBarFn}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <PendingInvitationContent
            showSuccessSnackBarFn={cbShowSuccessSnackBarFn}
            showErrorSnackBarFn={cbShowErrorSnackBarFn}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <PendingRequestContent
            selectedOrg={selectedOrg}
            showSuccessSnackBarFn={cbShowSuccessSnackBarFn}
            showErrorSnackBarFn={cbShowErrorSnackBarFn}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default OrgMembers;
