import React from "react";
import Typography from "@mui/material/Typography";
import classes from "./Typography.module.scss";

const typographyList = [
  { name: "h1", style: "Inter Light 96px" },
  { name: "h2", style: "Inter Light 60px" },
  { name: "h3", style: "Inter Regular 48px" },
  { name: "h4", style: "Inter Regular 34px" },
  { name: "h5", style: "Inter Regular 24px" },
  { name: "h6", style: "Inter Medium 20px" },
  { name: "subtitle1", style: "Inter Regular 16px" },
  { name: "subtitle2", style: "Inter Medium 14px" },
  { name: "body1", style: "Inter Regular 16px" },
  { name: "body2", style: "Inter Regular 14px" },
  { name: "caption", style: "Inter Regular 12px" },
  { name: "overline", style: "INTER BOLD 12PX" },
];
const UITypography = () => {
  return (
    <>
      <Typography variant="h6">Character Styles</Typography>
      <div className={classes.divTable}>
        <div className={classes.divTableBody}>
          {typographyList.map((typo) => (
            <div key={typo.name} className={classes.divTableContainer}>
              <div className={classes.divTableRow}>
                <div className={classes.divTableCell}>
                  <Typography variant="subtitle2">{typo.name}</Typography>
                </div>
                <div className={classes.divTableCell}>
                  <Typography variant="subtitle2">Primary</Typography>
                </div>
                <Typography variant={typo.name} color="text.primary">
                  {typo.style}
                </Typography>
              </div>
              <div className={classes.divTableRow}>
                <div className={classes.divTableCell}></div>
                <div className={classes.divTableCell}>
                  <Typography variant="subtitle2">Secondary</Typography>
                </div>
                <Typography variant={typo.name} color="text.secondary">
                  {typo.style}
                </Typography>
              </div>
              <div className={classes.divTableRow}>
                <div className={classes.divTableCell}></div>
                <div className={classes.divTableCell}>
                  <Typography variant="subtitle2">Disable</Typography>
                </div>
                <Typography variant={typo.name} color="text.disabled">
                  {typo.style}
                </Typography>
              </div>
            </div>
          ))}
          <div className={classes.divTableContainer}>
            <div className={classes.divTableRow}>
              <div className={classes.divTableCell}>
                <Typography variant="subtitle2">button</Typography>
              </div>
              <div className={classes.divTableCell}>
                <Typography variant="subtitle2">Small</Typography>
              </div>
              <Typography variant="buttonsmall">INTER MEDIUM 13PX</Typography>
            </div>
            <div className={classes.divTableRow}>
              <div className={classes.divTableCell}></div>
              <div className={classes.divTableCell}>
                <Typography variant="subtitle2">Medium</Typography>
              </div>
              <Typography variant="buttonmedium">INTER MEDIUM 14PX</Typography>
            </div>
            <div className={classes.divTableRow}>
              <div className={classes.divTableCell}></div>
              <div className={classes.divTableCell}>
                <Typography variant="subtitle2">Large</Typography>
              </div>
              <Typography variant="buttonlarge">INTER MEDIUM 15PX</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UITypography;
