import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useState, useRef } from "react";
const options = ["Option 1", "Option 2", "Option 3"];

const ButtonUI = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6">Button</Typography>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">Contained</Typography>

            <Typography variant="subtitle2">Text Only</Typography>
            <Typography variant="subtitle2">Icon Left</Typography>
            <Typography variant="subtitle2">Icon Right</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <Button variant="contained" color="primary" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <Button variant="contained" color="secondary" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Error</Typography>
            <Button variant="contained" color="error" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="error"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="error"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Warning</Typography>
            <Button variant="contained" color="warning" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="warning"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="warning"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Info</Typography>
            <Button variant="contained" color="info" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="info"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="info"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Success</Typography>
            <Button variant="contained" color="success" size="medium">
              Medium
            </Button>
            <Button
              variant="contained"
              color="success"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="contained"
              color="success"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">Outlined</Typography>

            <Typography variant="subtitle2">Text Only</Typography>
            <Typography variant="subtitle2">Icon Left</Typography>
            <Typography variant="subtitle2">Icon Right</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <Button variant="outlined" color="primary" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <Button variant="outlined" color="secondary" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Error</Typography>
            <Button variant="outlined" color="error" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Warning</Typography>
            <Button variant="outlined" color="warning" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Info</Typography>
            <Button variant="outlined" color="info" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="info"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="info"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Success</Typography>
            <Button variant="outlined" color="success" size="medium">
              Medium
            </Button>
            <Button
              variant="outlined"
              color="success"
              size="medium"
              startIcon={<AddIcon />}
            >
              Medium
            </Button>
            <Button
              variant="outlined"
              color="success"
              size="medium"
              endIcon={<AddIcon />}
            >
              Medium
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Typography variant="h6">Text</Typography>

            <Typography variant="subtitle2">Text Only</Typography>
            <Typography variant="subtitle2">Icon Left</Typography>
            <Typography variant="subtitle2">Icon Right</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <Button color="primary" size="medium">
              Medium
            </Button>
            <Button color="primary" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="primary" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <Button color="secondary" size="medium">
              Medium
            </Button>
            <Button color="secondary" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="secondary" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Error</Typography>
            <Button color="error" size="medium">
              Medium
            </Button>
            <Button color="error" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="error" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Warning</Typography>
            <Button color="warning" size="medium">
              Medium
            </Button>
            <Button color="warning" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="warning" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Info</Typography>
            <Button color="info" size="medium">
              Medium
            </Button>
            <Button color="info" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="info" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Success</Typography>
            <Button color="success" size="medium">
              Medium
            </Button>
            <Button color="success" size="medium" startIcon={<AddIcon />}>
              Medium
            </Button>
            <Button color="success" size="medium" endIcon={<AddIcon />}>
              Medium
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Typography variant="h6">Link</Typography>
            <Typography variant="subtitle2">Text Only</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <Button color="primary" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <Button color="secondary" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Error</Typography>
            <Button color="error" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Warning</Typography>
            <Button color="warning" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Info</Typography>
            <Button color="info" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Success</Typography>
            <Button color="success" size="medium" href="#outlined-buttons">
              Medium
            </Button>
          </Stack>
        </Box>
      </Box>
      <Typography variant="h6">Button Group</Typography>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="h6">Basic</Typography>
            <Typography variant="subtitle2">Outlined</Typography>
            <Typography variant="subtitle2">Contained</Typography>
            <Typography variant="subtitle2">Text</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              color="primary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
            <ButtonGroup
              variant="contained"
              aria-label="contained primary button group"
              color="primary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
            <ButtonGroup
              variant="text"
              aria-label="text primary button group"
              color="primary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined secondary button group"
              color="secondary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
            <ButtonGroup
              variant="contained"
              aria-label="contained secondary button group"
              color="secondary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
            <ButtonGroup
              variant="text"
              aria-label="text secondary button group"
              color="secondary"
            >
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="h6">Split</Typography>
            <Typography variant="subtitle2">Outlined</Typography>
            <Typography variant="subtitle2">Contained</Typography>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Primary</Typography>
            <ButtonGroup
              variant="outlined"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </Box>
        <Box sx={{ m: 5 }}>
          <Stack sx={{ width: "100%" }} spacing={3}>
            <Typography variant="subtitle2">Secondary</Typography>
            <ButtonGroup
              variant="outlined"
              ref={anchorRef}
              aria-label="split button"
              color="secondary"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              color="secondary"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ButtonUI;
