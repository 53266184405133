import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import classes from './ApproveLeave.module.scss';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import PendingRequest from './pendingrequest/PendingRequest';
import History from './history/History';
import CancelledRequest from './cancelledRequest/CancelledRequest';
import { useNavigate } from 'react-router-dom';
import { getLeaves } from '../service/leaveService';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { LeaveStatusType } from '../../../../utilities/constantUtils';
import { format } from 'date-fns';
import { dateFormats } from '../../../../utilities/dateUtils';
import LinearProgress from '@mui/material/LinearProgress';
import i18n from '../../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const TabStyled = styled(Tabs)(({ theme }) => ({
  '& .Mui-selected': {
    color: '#00B8D4 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00B8D4 !important',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="orgtabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.org_tab_panel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ApproveLeave = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const dispatch = useDispatch();
  const pendingLeaveList = useSelector(
    (state) => state.leaveContext.pendingLeaveList,
  );
  const historyLeaveList = useSelector(
    (state) => state.leaveContext.historyLeaveList,
  );
  const cancelledLeaveList = useSelector(
    (state) => state.leaveContext.cancelledLeaveList,
  );
  const [dateFrom, setDateFrom] = useState(
    new Date(`${format(new Date(), dateFormats.year)}-01-01`),
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [loader, setLoader] = useState(true);

  const getLeaveList = async (value, from, to) => {
    setLoader(true);
    await dispatch(
      getLeaves(value === null ? LeaveStatusType.pending : value, from, to),
    );
    setLoader(false);
  };

  useEffect(() => {
    getLeaveList(LeaveStatusType.pending, null, null);
    getLeaveList(LeaveStatusType.cancel, null, null);
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${i18n.language}/me/leave`);
  };

  const getHistoryLeave = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
    if (from > to) {
      to = from;
      setDateTo(from);
    }
    getLeaveList(LeaveStatusType.history, from, to);
  };

  return (
    <div>
      {loader && (
        <Box sx={{ width: '100%' }} className="LinearProgress">
          <LinearProgress />
        </Box>
      )}

      <div className={classes.approveLeave_card}>
        <div className={classes.approveLeave_header_con}>
          <div className={classes.titleWidth}>
            <Typography className={classes.approveLeave_name}>
              {t('we-leave.team-leave-management')}
            </Typography>
            <Typography className={classes.approveLeave_desc}>
              {t(
                'we-leave.approve-decline-and-view-members-leave-request-and-sickness-report',
              )}
            </Typography>
          </div>
          <div className={classes.approveLeave_invite_button}>
            <Button variant="outlined" onClick={handleClick}>
              {t('we-leave.go-to-my-leaves')}
            </Button>
          </div>
        </div>
        <div>
          <Box className={classes.approveLeave_tab_box} sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabStyled
                aria-label="basic tabs example"
                value={activeTab}
                className={classes.approveLeave_tab_name}
                onChange={handleChange}
                columnseparator="false"
              >
                <Tab
                  label={
                    <Badge
                      className={classes.approveLeave_badge}
                      badgeContent={pendingLeaveList.length}
                      color="primary"
                    >
                      {t('we-leave.pending-requests')}
                    </Badge>
                  }
                  {...a11yProps(1)}
                  onClick={() =>
                    getLeaveList(LeaveStatusType.pending, null, null)
                  }
                ></Tab>
                <Tab
                  label={
                    <Badge
                      className={classes.approveLeave_badge}
                      badgeContent={cancelledLeaveList.length}
                      color="primary"
                    >
                      {t('we-leave.cancellation-requests')}
                    </Badge>
                  }
                  {...a11yProps(1)}
                  onClick={() =>
                    getLeaveList(LeaveStatusType.cancel, null, null)
                  }
                ></Tab>
                <Tab
                  label={<Badge color="primary">{t('we-leave.history')}</Badge>}
                  {...a11yProps(2)}
                  onClick={() => getHistoryLeave(dateFrom, dateTo)}
                ></Tab>
              </TabStyled>
            </Box>

            <TabPanel value={activeTab} index={0} className="leaveTable">
              <PendingRequest
                leaveList={pendingLeaveList}
                getPendingLeaves={getLeaveList}
                loader={loader}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1} className="leaveTable">
              <CancelledRequest
                leaveList={cancelledLeaveList}
                getCancelledLeaves={getLeaveList}
                loader={loader}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2} className="leaveTable">
              <History
                leaveList={historyLeaveList}
                getHistoryLeave={getHistoryLeave}
                dateFrom={dateFrom}
                dateTo={dateTo}
                loader={loader}
              />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ApproveLeave;
