import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { format, isWeekend } from 'date-fns';
import {
  addRegistrationService,
  editRegistrationService,
  deleteRegistrationService,
  submitRegistrations,
  bulkDeleteRegistrationsService,
  getTotalRegistrationsPerDate,
  getTotalRegistration,
} from '../../../../../../service/timeregistrationService';
import {
  removeProject,
  bulkDeleteRegistration,
} from '../../../../../../../../store/registrationContextSlice';
import ConfirmationDialogBox from '../../../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import classes from './ProjectTable.module.scss';
import { transformMinsToHoursMinutesString } from '../../../../../../../../utilities/timeConversion';
import TotalCell from '../totalCell/TotalCell';
import { dateFormats } from '../../../../../../../../utilities/dateUtils';
import {
  failedRegDeletionMessage,
  failedRegSaveMessage,
  failedRegSubmissionMessage,
  successRegDeletionMessage,
  successRegSubmissionMessage,
  failedReg24HrLimitMessage,
} from '../../../../../../../../utilities/contentMessagesUtils';
import { LeaveStatusType } from '../../../../../../../../utilities/constantUtils';
import { getProjectTableContentTitleConfig } from './utils/configs';
import {
  isTotalHoursInTheDayLessThanADay,
  isRegDurationValid,
} from './utils/functions';
import RegistrationModal from './registrationModal/RegistrationModal';
import {
  ConfirmationDialogContent,
  ConfirmationDialogContentTitle,
} from './confirmationDialogContentAndTitle/ConfirmationDialogContentAndTitle';
import { submitType, deleteType } from './utils/configs';
import TaskAndRegistrationRow from './taskAndRegistrationRow/TaskAndRegistrationRow';
import TaskAndRegistrationRowGrid from './taskAndRegistrationRowGrid/TaskAndRegistrationRowGrid';

import { useTranslation } from 'react-i18next';

const ProjectTable = (props) => {
  const { t } = useTranslation();
  const [shouldOpenProjectRegTable, setShouldOpenProjectRegTable] =
    useState(true);
  const dispatch = useDispatch();
  const [projectMenuAnchorEl, setprojectMenuAnchorEl] = React.useState(null);
  const openProjectMenu = Boolean(projectMenuAnchorEl);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: '',
    dialogContent: '',
    dialogContentRegListForDeletion: null,
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: '',
    actionLabelText: '',
    actionType: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalCellTaskRegDetail, setModalCellTaskRegDetail] = useState({
    taskReg: {},
    taskId: -1,
    taskProjectId: -1,
  });
  const [isCreatingRegistration, setIsCreatingRegistration] = useState(false);
  const [disableSubmitButtonInRegModal, setDisableSubmitButtonInRegModal] =
    useState(false);
  const [inputDuration, setInputDuration] = useState(null);
  const [shouldCallAddEditTaskReg, setShouldCallAddEditTaskReg] =
    useState(false);
  const [selectedInputDescription, setSelectedInputDescription] =
    useState(null);
  const [regModalDialogContentTitle, setRegModalDialogContentTitle] =
    useState('');
  const [openConfirmationBox, setOpenConfirmationBox] = useState({
    open: false,
    type: undefined,
  });
  const [resultDescChanged, setResultlDescChanged] = useState(false);
  const [selectedTaskTitle, setSelectedTaskTitle] = useState();
  const [registration, setRegistration] = useState();
  const [didDurationInModalChanged, setDidDurationInModalChanged] =
    useState(false);
  const [previousCellNumber, setPreviousCellNumber] = useState();
  const totalHoursPerDayOfTheWeekObject = useSelector(
    (state) => state.registrationContext.totalRegHoursPerDate,
  );

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? 'simple-popover' : undefined;

  useEffect(() => {
    const hasTaskDescriptionChanged =
      modalCellTaskRegDetail?.taskHasReg?.regDesc !== selectedInputDescription;
    const isTimeRegistrationSavable =
      (inputDuration !== null || hasTaskDescriptionChanged) &&
      (modalCellTaskRegDetail.taskReg.regStatus === LeaveStatusType.draft ||
        modalCellTaskRegDetail.taskReg.regStatus === LeaveStatusType.declined);

    if (isTimeRegistrationSavable && shouldCallAddEditTaskReg) {
      const timeOut = setTimeout(() => {
        callAddEditTimeRegistration();
        setShouldCallAddEditTaskReg(false);
        setDidDurationInModalChanged(false);
        setResultlDescChanged(false);
      }, 200);
      return () => clearTimeout(timeOut);
    }
  }, [shouldCallAddEditTaskReg]);

  useEffect(() => {
    !shouldOpenProjectRegTable && setShouldOpenProjectRegTable(true);
  }, [props?.selectedOrg?.id, props?.dateList]);

  useEffect(() => {
    if (
      anchorEl === null &&
      resultDescChanged &&
      modalCellTaskRegDetail?.taskReg?.regDuration !== 0
    ) {
      setShouldCallAddEditTaskReg(true);
    }
  }, [anchorEl]);

  const closeAndResetConfirmationBoxInfo = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: '',
      dialogContent: '',
      dialogContentRegListForDeletion: null,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: '',
      actionLabelText: '',
      actionType: '',
    }));
  };

  const anchorElHandler = (value) => {
    setDisableSubmitButtonInRegModal(false);
    setAnchorEl(value);
  };

  const registrationHandler = (value) => {
    setRegistration(value);
  };

  const disableSubmitHandler = (value) => {
    setDisableSubmitButtonInRegModal(value);
  };

  const cellDetailHandler = (value) => {
    setPreviousCellNumber(value.taskReg.regDuration);
    setModalCellTaskRegDetail(value);
  };

  const closeMoreDetailsPopUp = () => {
    setAnchorEl(null);
  };

  const handleProjectMenuClick = (event) => {
    setprojectMenuAnchorEl(event.currentTarget);
  };
  const handleProjectMenuClose = () => {
    setprojectMenuAnchorEl(null);
  };

  const popOverhandleCloseDialogBox = () => {
    setOpenConfirmationBox((prevState) => ({
      ...prevState,
      open: false,
      type: undefined,
    }));
  };

  const addEditTimeSingleRegistration = ({
    totalHoursPerDayOfTheWeekObject,
    timeRegistration,
    timeOffset,
    selectedOrgEmpId,
    cbOnSuccessAddofTimeReg,
    cbOnFailedAddofTimeReg,
    cbOnSuccessEditofTimeReg,
    cbOnFailedEditofTimeReg,
  }) => {
    const isTimeRegistrationNew = timeRegistration.taskReg.regId === -1;
    if (
      isTotalHoursInTheDayLessThanADay(
        totalHoursPerDayOfTheWeekObject,
        timeRegistration,
        timeOffset,
      ) &&
      isRegDurationValid(timeRegistration)
    ) {
      if (isTimeRegistrationNew) {
        setIsCreatingRegistration(true);
        dispatch(
          addRegistrationService(timeRegistration, selectedOrgEmpId),
        ).then((data) => {
          setIsCreatingRegistration(false);
          data.result === 1
            ? cbOnSuccessAddofTimeReg()
            : cbOnFailedAddofTimeReg();
        });
      } else {
        dispatch(
          editRegistrationService(timeRegistration, selectedOrgEmpId),
        ).then((data) => {
          data.result === 1
            ? cbOnSuccessEditofTimeReg()
            : cbOnFailedEditofTimeReg(data);
        });
      }
    } else {
      props.showErrorSnackBarFn(failedReg24HrLimitMessage);
    }
  };

  const callAddEditTimeRegistration = () => {
    addEditTimeSingleRegistration({
      totalHoursPerDayOfTheWeekObject,
      timeRegistration: modalCellTaskRegDetail,
      timeOffset: Number(previousCellNumber),
      selectedOrgEmpId: props?.selectedOrg?.emp,
      cbOnSuccessAddofTimeReg: () => {
        setPreviousCellNumber(modalCellTaskRegDetail.taskReg.regDuration);
        setIsCreatingRegistration(false);
      },
      cbOnFailedAddofTimeReg: () =>
        props.showErrorSnackBarFn(failedRegSaveMessage),
      cbOnSuccessEditofTimeReg: () =>
        setPreviousCellNumber(modalCellTaskRegDetail.taskReg.regDuration),
      cbOnFailedEditofTimeReg: () =>
        props.showErrorSnackBarFn(failedRegSaveMessage),
    });
  };

  const submitForApprovalInModal = (totalHoursPerDayOfTheWeekObject) => {
    const timeRegistration = Object.assign({}, modalCellTaskRegDetail);
    timeRegistration.taskReg = Object.assign(
      {},
      modalCellTaskRegDetail.taskReg,
    );
    timeRegistration.taskReg.regStatus = LeaveStatusType.approved;
    const timeOffsetForModal =
      Number(modalCellTaskRegDetail?.taskReg?.regDuration) || 0;

    addEditTimeSingleRegistration({
      totalHoursPerDayOfTheWeekObject,
      timeRegistration: timeRegistration,
      timeOffset: timeOffsetForModal,
      selectedOrgEmpId: props?.selectedOrg?.emp,
      cbOnSuccessAddofTimeReg: () => undefined,
      cbOnFailedAddofTimeReg: () => undefined,
      cbOnSuccessEditofTimeReg: () => {
        closeMoreDetailsPopUp();
        setModalCellTaskRegDetail((prevState) => ({
          ...prevState,
          taskReg: {
            ...prevState.taskReg,
            regStatus: timeRegistration.taskReg.regStatus,
            regCreatedBy: timeRegistration.taskReg.regCreatedBy,
            regUpdatedBy: timeRegistration.taskReg.regUpdatedBy,
          },
        }));
        setDisableSubmitButtonInRegModal(true);
        popOverhandleCloseDialogBox();
        props.showSuccessSnackBarFn(successRegSubmissionMessage);
      },
      cbOnFailedEditofTimeReg: () => {
        popOverhandleCloseDialogBox();
        props.showErrorSnackBarFn(failedRegSubmissionMessage);
      },
    });
  };

  const popOverHandleConfirmDeletion = () => {
    if (modalCellTaskRegDetail.taskReg.regId !== -1) {
      dispatch(deleteRegistrationService(modalCellTaskRegDetail)).then(
        (data) => {
          if (data.result === 1) {
            popOverhandleCloseDialogBox();
            closeMoreDetailsPopUp();
            if (disableSubmitButtonInRegModal) {
              // reset disable submit button flag
              setDisableSubmitButtonInRegModal(false);
            }
            if (inputDuration) {
              //reset input duration
              setInputDuration(null);
            }
            props.showSuccessSnackBarFn(successRegDeletionMessage);
          } else {
            popOverhandleCloseDialogBox();
            props.showErrorSnackBarFn(failedRegDeletionMessage);
          }
        },
      );
    }
  };

  const handleAction = () => {
    switch (openConfirmationBox.type) {
      case submitType:
        submitForApprovalInModal(
          totalHoursPerDayOfTheWeekObject,
          modalCellTaskRegDetail,
        );
        break;
      case deleteType:
        popOverHandleConfirmDeletion();
        break;
      default:
        break;
    }
  };

  const onSubmitForApprovalClick = () => {
    let { taskReg } = modalCellTaskRegDetail;
    let time = transformMinsToHoursMinutesString(taskReg.regDuration);
    if (parseFloat(taskReg.regDuration)) {
      setRegModalDialogContentTitle(
        `${t('me.would-you-like-to-submit')} ${time} ${t(
          'me.of',
        )} "${selectedTaskTitle}" ${t('me.for-approval')}?`,
      );
      setOpenConfirmationBox((prevState) => ({
        ...prevState,
        open: true,
        type: submitType,
      }));
    }
  };

  const onDeleteButtonClick = () => {
    let { taskReg } = modalCellTaskRegDetail;
    let time = transformMinsToHoursMinutesString(taskReg.regDuration);
    setRegModalDialogContentTitle(
      `${t('me.would-you-like-to-delete')} ${time} ${t(
        'me.of',
      )} "${selectedTaskTitle}" ${t('me.from-your-time-registration')}`,
    );
    setOpenConfirmationBox((prevState) => ({
      ...prevState,
      open: true,
      type: deleteType,
    }));
  };

  const onDescriptionChangeHandler = (e, prevValue) => {
    loadDescriptionEvent(e, prevValue);
  };

  const onBlurOrKeyPressDescriptionInModal = (e, prevValue) => {
    if (e) {
      const isRegDescChanged = e.target.value !== prevValue;
      setResultlDescChanged(isRegDescChanged);
    }
  };

  const loadDescriptionEvent = (e, prevValue) => {
    setModalCellTaskRegDetail((prevState) => ({
      ...prevState,
      taskReg: {
        ...prevState.taskReg,
        regDesc: e.target.value,
      },
    }));
    setSelectedInputDescription(e.target.value);

    //enable submit for approval on change of data
    if (
      registration?.regStatus === LeaveStatusType.pending &&
      disableSubmitButtonInRegModal
    ) {
      setDisableSubmitButtonInRegModal(false);
    }
  };

  const onDurationInModalChangeHandler = (modalDuration, prevValue) => {
    const isTimeInputtedDifferentThanPrevValueInCell =
      modalDuration !== prevValue;
    setDidDurationInModalChanged(isTimeInputtedDifferentThanPrevValueInCell);
    setModalCellTaskRegDetail((prevState) => ({
      ...prevState,
      taskReg: {
        ...prevState.taskReg,
        regDuration: modalDuration,
      },
    }));
    setInputDuration(modalDuration);

    //enable submit for approval on change of data
    if (
      registration?.regStatus === LeaveStatusType.pending &&
      disableSubmitButtonInRegModal
    ) {
      setDisableSubmitButtonInRegModal(false);
    }
  };

  const onBlurHrModal = (e) => {
    if (e.target.value.length > 0 && didDurationInModalChanged) {
      setShouldCallAddEditTaskReg(true);
    }
  };

  const onKeyPressOfTimeInputInModal = (event) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      if (event.target.value.length > 0 && didDurationInModalChanged) {
        setShouldCallAddEditTaskReg(true);
      }
    } else {
      const regex = /[0-9.]/;
      const notANumber = !regex.test(event.key);
      if (notANumber && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  };

  const onSubmitProjectForApprovalClick = () => {
    //get registrations that are in draft
    const taskWithReg = props.proj.projectTasks.filter(
      (task) => Object.keys(task.taskRegistrations).length > 0,
    );

    const regListObject = [];

    taskWithReg.forEach((task) => {
      const buildTaskObj = {
        taskId: task.taskId,
        taskProjectId: task.taskProjectId,
        taskReg: {},
      };

      for (const [date, value] of Object.entries(task.taskRegistrations)) {
        if (value.length > 0) {
          const draftRegistrations = value
            .filter((reg) => reg.regStatus === LeaveStatusType.draft)
            .map((reg) => {
              let newReg = Object.assign({}, reg);
              newReg.regStatus = LeaveStatusType.pending;
              return newReg;
            });

          if (draftRegistrations.length > 0) {
            buildTaskObj.taskReg[date] = draftRegistrations;
          }
        }
      }

      if (Object.keys(buildTaskObj.taskReg).length > 0) {
        regListObject.push(buildTaskObj);
      }
    });

    if (regListObject.length > 0) {
      setconfirmationBoxInfo((prevState) => ({
        ...prevState,
        open: true,
        dialogContentTitle: getProjectTableContentTitleConfig(
          'submitAllReg',
          props,
        ),
        dialogContent: t(
          'me.this-will-notify-your-manager-approver-to-approve-all-saved-registrations',
        ),
        dialogContentRegListForDeletion: null,
        selectedTasks: regListObject,
        showActionButton: true,
        closeLabelText: t('common.cancel'),
        actionLabelText: t('common.submit'),
        actionType: submitType,
      }));
    } else {
      setconfirmationBoxInfo((prevState) => ({
        ...prevState,
        open: true,
        dialogContentTitle: getProjectTableContentTitleConfig(
          'cantSubmitAllReg',
          props,
        ),
        dialogContent: t(
          'me.the-registrations-are-already-either-submitted-or-approved',
        ),

        dialogContentRegListForDeletion: null,
        selectedTasks: regListObject,
        showActionButton: false,
        closeLabelText: t('me.ok-got-it'),
        actionLabelText: '',
        actionType: '',
      }));
    }
  };

  const handleConfirmSubmission = () => {
    dispatch(submitRegistrations(confirmationBoxInfo.selectedTasks)).then(
      (data) => {
        if (data.result === 1) {
          closeAndResetConfirmationBoxInfo();
          handleProjectMenuClose();
          props.showSuccessSnackBarFn(successRegSubmissionMessage);
        } else {
          closeAndResetConfirmationBoxInfo();
          props.showErrorSnackBarFn(failedRegSubmissionMessage);
        }
      },
    );
  };

  const onRemoveProjectClick = () => {
    const taskWithReg = props.proj.projectTasks.filter(
      (task) => Object.keys(task.taskRegistrations).length > 0,
    );
    const taskWithoutReg = props.proj.projectTasks.filter(
      (task) =>
        Object.keys(task.taskRegistrations).length === 0 && task.taskId !== -1,
    );

    const regListObject = [];
    taskWithReg.forEach((task) => {
      const buildTaskObj = {
        taskId: task.taskId,
        taskTitle: task.taskTitle,
        taskProjectId: task.taskProjectId,
        taskReg: {},
      };

      for (const [date, taskRegistration] of Object.entries(
        task.taskRegistrations,
      )) {
        if (taskRegistration.length > 0) {
          const filteredReg = taskRegistration.filter(
            (reg) =>
              reg.regStatus === LeaveStatusType.draft ||
              reg.regStatus === LeaveStatusType.pending ||
              reg.regStatus === LeaveStatusType.declined,
          );
          if (filteredReg.length > 0) {
            buildTaskObj.taskReg[date] = filteredReg;
          }
        }
      }

      if (Object.keys(buildTaskObj.taskReg).length > 0) {
        regListObject.push(buildTaskObj);
      }
    });

    if (regListObject.length > 0) {
      //there are draft and pending tasks to be removed

      if (taskWithoutReg.length > 0) {
        //there are tasks without reg on registration table so add them on the list to be removed (regListObject)
        taskWithoutReg.forEach((task) => {
          const buildTaskObj = {
            taskId: task.taskId,
            taskTitle: task.taskTitle,
            taskProjectId: task.taskProjectId,
            taskReg: {},
          };
          regListObject.push(buildTaskObj);
        });
      }

      setconfirmationBoxInfo((prevState) => ({
        ...prevState,
        open: true,
        dialogContentTitle: getProjectTableContentTitleConfig(
          'deleteAllReg',
          props,
        ),
        dialogContent: t(
          'me.note-all-approved-registrations-are-not-allowed-to-be-deleted',
        ),

        dialogContentRegListForDeletion: regListObject.filter(
          (task) => Object.keys(task.taskReg).length > 0,
        ),
        selectedTasks: regListObject,
        showActionButton: true,
        closeLabelText: t('common.cancel'),
        actionLabelText: deleteType,
        actionType: deleteType,
      }));
    } else {
      // there are no draft and pending tasks

      if (taskWithReg.length > 0) {
        if (taskWithoutReg.length > 0) {
          //there are approved tasks and tasks without registration so show oops all registrations are approved box
          //Delete button would only remove tasks without registration

          const tasksForRemoval = [];

          taskWithoutReg.forEach((task) => {
            const buildTaskObj = {
              taskId: task.taskId,
              taskTitle: task.taskTitle,
              taskProjectId: task.taskProjectId,
              taskReg: {},
            };

            tasksForRemoval.push(buildTaskObj);
          });

          setconfirmationBoxInfo((prevState) => ({
            ...prevState,
            open: true,
            dialogContentTitle: getProjectTableContentTitleConfig(
              'cantDeleteAllReg',
              props,
            ),
            dialogContent: t(
              'me.the-registrations-are-all-approved-and-cannot-be-deleted-added-tasks-without-registration-would-be-removed',
            ),
            dialogContentRegListForDeletion: null,
            selectedTasks: tasksForRemoval,
            showActionButton: true,
            closeLabelText: t('common.cancel'),
            actionLabelText: deleteType,
            actionType: deleteType,
          }));
        } else {
          //there are only approve tasks so, show oops all registrations are approved box and OK GOT IT shold only close the dialog box
          setconfirmationBoxInfo((prevState) => ({
            ...prevState,
            open: true,
            dialogContentTitle: getProjectTableContentTitleConfig(
              'cantDeleteAllReg',
              props,
            ),
            dialogContent: t(
              'me.the-registrations-are-all-approved-and-cannot-be-deleted',
            ),
            dialogContentRegListForDeletion: null,
            selectedTasks: regListObject,
            showActionButton: false,
            closeLabelText: t('me.ok-got-it'),
            actionLabelText: '',
            actionType: '',
          }));
        }
      } else {
        //there are no approved tasks
        if (taskWithoutReg.length > 0) {
          // there are task without registration, so just remove tasks
          const tasksForRemoval = [];
          taskWithoutReg.forEach((task) => {
            const buildTaskObj = {
              taskId: task.taskId,
              taskTitle: task.taskTitle,
              taskProjectId: task.taskProjectId,
              taskReg: {},
            };
            tasksForRemoval.push(buildTaskObj);
          });
          dispatch(
            bulkDeleteRegistration({
              registrations: tasksForRemoval,
              type: 'project',
            }),
          );
          handleProjectMenuClose();
        } else {
          //there are no tasks in a project, so just remove the project
          dispatch(removeProject({ projectId: props.proj.projectId }));
          handleProjectMenuClose();
        }
      }
    }
  };

  const handleConfirmDeletion = () => {
    bulkDeleteRegistrationsService(confirmationBoxInfo.selectedTasks).then(
      (data) => {
        if (data.result === 1 || data.result === 2) {
          closeAndResetConfirmationBoxInfo();
          handleProjectMenuClose();
          dispatch(
            bulkDeleteRegistration({
              registrations: confirmationBoxInfo.selectedTasks,
              type: 'project',
            }),
          );

          if (data.result === 1) {
            props.showSuccessSnackBarFn(successRegDeletionMessage);
          }
        } else {
          closeAndResetConfirmationBoxInfo();
          props.showErrorSnackBarFn(failedRegDeletionMessage);
        }
      },
    );
  };

  const confirmActionHandler = () => {
    switch (confirmationBoxInfo.actionType) {
      case submitType:
        handleConfirmSubmission();
        break;
      case deleteType:
        handleConfirmDeletion();
        break;
      default:
        break;
    }
  };

  const ArrowIcon = shouldOpenProjectRegTable
    ? KeyboardArrowUpIcon
    : KeyboardArrowDownIcon;

  const [selectedCell, setSelectedCell] = useState({ row: null, col: null });

  const handleCellClick = (row, col) => {
    setSelectedCell({ row, col });
  };

  const projectTasks = props?.proj?.projectTasks;

  return (
    <React.Fragment>
      <TableRow className={classes.project_row}>
        <TableCell
          className={`${classes.project_container} projectCellContainer`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setShouldOpenProjectRegTable(!shouldOpenProjectRegTable)
            }
          >
            <ArrowIcon />
          </IconButton>
          <span>{props.proj.projectName}</span>
          <IconButton
            className={classes.menu_icon}
            aria-label="task menu"
            aria-controls={openProjectMenu ? 'task-menu' : undefined}
            aria-expanded={openProjectMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleProjectMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={projectMenuAnchorEl}
            open={openProjectMenu}
            onClose={handleProjectMenuClose}
            className={classes.menu}
          >
            <MenuItem
              key={'submitAllForApprovalInProject'}
              onClick={onSubmitProjectForApprovalClick}
            >
              <CheckCircleOutlineIcon />
              {t('me.submit-for-approval')}
            </MenuItem>
            <MenuItem
              key={'removeAllRegInProject'}
              onClick={onRemoveProjectClick}
            >
              <ClearIcon />
              {t('common.remove')}
            </MenuItem>
            <ConfirmationDialogBox
              open={confirmationBoxInfo.open}
              title={ConfirmationDialogContentTitle(
                confirmationBoxInfo.dialogContentTitle,
              )}
              content={ConfirmationDialogContent(
                confirmationBoxInfo.dialogContent,
                confirmationBoxInfo.dialogContentRegListForDeletion,
              )}
              handleClose={closeAndResetConfirmationBoxInfo}
              handleAction={confirmActionHandler}
              closeLabelText={confirmationBoxInfo.closeLabelText}
              actionLabelText={confirmationBoxInfo.actionLabelText}
              showActionButton={confirmationBoxInfo.showActionButton}
            />
          </Menu>
        </TableCell>
        {props.dateList.map((date, dateIndex) => (
          <TableCell
            align="center"
            key={dateIndex}
            className={`${isWeekend(date) && classes.weekend_column} ${
              classes.project_row_totals
            }`}
          >
            <TotalCell
              color="text.secondary"
              sumOfReg={getTotalRegistrationsPerDate(
                [props.proj],
                format(date, dateFormats.default),
              )}
            />
          </TableCell>
        ))}
        <TableCell align="center" className={classes.project_row_totals}>
          <TotalCell
            color="text.secondary"
            sumOfReg={getTotalRegistration([props.proj])}
          />
        </TableCell>
      </TableRow>
      {/* {shouldOpenProjectRegTable &&
        projectTasks?.map((projectTask, rowIndex) => (
          <TaskAndRegistrationRow
            selectedCell={selectedCell}
            rowIndex={rowIndex}
            handleCellClick={handleCellClick}
            projectTask={projectTask}
            dateList={props?.dateList}
            selectedOrg={props?.selectedOrg}
            showSuccessSnackBarFn={props?.showSuccessSnackBarFn}
            showErrorSnackBarFn={props?.showErrorSnackBarFn}
            anchorElHandler={anchorElHandler}
            disableSubmitHandler={disableSubmitHandler}
            cellDetailHandler={cellDetailHandler}
            cbSetSelectedTaskTitle={setSelectedTaskTitle}
            registrationHandler={registrationHandler}
            cbAddEditTimeSingleRegistration={addEditTimeSingleRegistration}
          />
        ))} */}
      {shouldOpenProjectRegTable && (
        <TaskAndRegistrationRowGrid
          selectedCell={selectedCell}
          rowIndex={null}
          projectTasks={projectTasks}
          dateList={props?.dateList}
          selectedOrg={props?.selectedOrg}
          showSuccessSnackBarFn={props?.showSuccessSnackBarFn}
          showErrorSnackBarFn={props?.showErrorSnackBarFn}
          anchorElHandler={anchorElHandler}
          disableSubmitHandler={disableSubmitHandler}
          cellDetailHandler={cellDetailHandler}
          cbSetSelectedTaskTitle={setSelectedTaskTitle}
          registrationHandler={registrationHandler}
          cbAddEditTimeSingleRegistration={addEditTimeSingleRegistration}
        />
      )}

      <RegistrationModal
        cellDetail={modalCellTaskRegDetail}
        closeMoreDetailsPopUp={closeMoreDetailsPopUp}
        id={id}
        openPopOver={openPopOver}
        isCreatingRegistration={isCreatingRegistration}
        anchorEl={anchorEl}
        onDurationChangeHandler={onDurationInModalChangeHandler}
        onBlurHrModal={onBlurHrModal}
        onKeyPressHrModal={onKeyPressOfTimeInputInModal}
        onDescriptionChangeHandler={onDescriptionChangeHandler}
        onBlurDescriptionModal={onBlurOrKeyPressDescriptionInModal}
        onKeyPressDescriptionModal={onBlurOrKeyPressDescriptionInModal}
        onDeleteButtonClick={onDeleteButtonClick}
        onSubmitForApprovalClick={onSubmitForApprovalClick}
        disableSubmitButton={disableSubmitButtonInRegModal}
        openConfirmationBox={openConfirmationBox}
        dialogContentTitle={regModalDialogContentTitle}
        popOverhandleCloseDialogBox={popOverhandleCloseDialogBox}
        handleAction={handleAction}
      />
    </React.Fragment>
  );
};

export default ProjectTable;
