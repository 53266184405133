import React from 'react';
import { Button, Typography, Box, Modal, IconButton } from '@mui/material';
import classes from './CustomModal.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {
  dateFormats,
  getBusinessDays,
  handleDateFormatRangeYear,
} from '../../../utilities/dateUtils';
import CustomTooltip from '../tooltips/Tooltips';
import ConfirmModal from '../confirmModal/ConfirmModal';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import {
  LeaveType,
  LeaveStatusType,
  ActionLabel,
} from '../../../utilities/constantUtils';
import { util } from '../../../utilities/utilFunc';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 14px -3px rgba(0, 0, 0, 0.78)',
    fontSize: 11,
  },
}));

const CustomModal = ({
  openConfirmModal,
  setOpenConfirmModal,
  width,
  height,
  data,
  cancelRequest,
  resubmitRequestLeave,
  editRequestLeave,
  cancelApproveRequest,
}) => {
  const { t } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = React.useState(false);

  const uf = util();
  const totalDays = data?.calculate?.length
    ? data.calculate.length
    : getBusinessDays(data.start, data.end);
  const pending = data.status === LeaveStatusType.pending;
  const approved = data.status === LeaveStatusType.approved;
  const declined = data.status === LeaveStatusType.declined;
  const cancelled = data.status === LeaveStatusType.cancelled;
  const sickness = data.type === LeaveType.sickness;
  const isCancelledLeave = data.type === LeaveType.sickness && declined;
  const shouldButtonsBeEnabled =
    data.type === LeaveType.leave || isCancelledLeave || LeaveType.sickness;

  const hasDesc = uf.isNullOrUndefined(data.desc) ? '-' : data.desc;
  const hasTypeName = uf.isNullOrUndefined(data.typeName && data.typeName.type)
    ? '-'
    : `${data.typeName && data.typeName.type} Leave`;

  const hasUpdatedBy = uf.isNullOrUndefined(
    data.updatedBy && data.updatedBy.name,
  )
    ? '-'
    : data.updatedBy && data.updatedBy.name;

  const hasCreatedBy = uf.isNullOrUndefined(
    data.createdBy && data.createdBy.name,
  )
    ? '-'
    : data.createdBy && data.createdBy.name;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width,
    // height: data.type === 'sickness' || approved ? 260 : height,
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px',
  };

  const showStatus = () => {
    let headerStyling;
    let currentStatus;
    const statusType = data.status;
    const leaveType = data.type;

    if (statusType === LeaveStatusType.draft) {
      headerStyling = classes.draft;
    } else if (statusType === LeaveStatusType.pending) {
      headerStyling = classes.pending;
      currentStatus = t('me-leave.pending-for-approval');
    } else if (
      statusType === LeaveStatusType.approved &&
      leaveType === LeaveType.sickness
    ) {
      headerStyling = classes.approved;
      currentStatus = `${t('me-leave.filed-by')} ${hasCreatedBy}`;
    } else if (statusType === LeaveStatusType.cancelled) {
      headerStyling = classes.declined;
      currentStatus = `${t('me-leave.cancelled-by')} ${hasUpdatedBy}`;
    } else if (statusType === LeaveStatusType.declined) {
      headerStyling = classes.declined;
      currentStatus = `${t('me-leave.declined-by')} ${hasUpdatedBy}`;
    } else {
      headerStyling = classes.approved;
      let approvedStatus = '';
      if (hasUpdatedBy) {
        if (hasUpdatedBy.length > 3) {
          approvedStatus = `${t('me-leave.approved-by')} ${hasUpdatedBy}`;
        } else {
          approvedStatus = `${t('me-leave.approved-by')} ${hasCreatedBy}`;
        }
      } else {
        approvedStatus = `${t('me-leave.approved-by')} ${hasCreatedBy}`;
      }
      currentStatus = approvedStatus;
    }

    let message = (
      <>
        <LightTooltip
          title={
            <>
              <div className={classes.status_tooltip}>{hasTypeName}</div>
            </>
          }
          placement="top"
        >
          <Typography className={classes.title}>
            <EmojiTransportationIcon className={classes.icon_style} />
            {hasTypeName}
          </Typography>
        </LightTooltip>
      </>
    );

    return (
      <>
        <div className={`${classes.popover_header} ${headerStyling}`}>
          <IconButton
            aria-label="close form registration"
            className={classes.close_icon}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <div className={classes.popover_text}>
            <Typography variant="caption" color="white">
              {message}
            </Typography>
          </div>
        </div>
        <div className={classes.badge_container}>
          <div className={classes.flexContainer}>
            <div
              className={`${classes[data.status]} ${classes.badge} ${
                classes.marginbadge
              }`}
            ></div>
            <Typography className={classes.leave_status}>
              {currentStatus}
            </Typography>
          </div>
          {(pending || approved) && (
            <>
              {data.vacation && (
                <div className={classes.flexContainer}>
                  <div
                    className={`${classes.cancelled} ${classes.badge} ${classes.pad}`}
                  ></div>
                  <div className={classes.vacation}>
                    <Typography
                      className={`${classes.leave_status} ${classes.colorRed}`}
                    >
                      {t('me-leave.holiday-conflict-with-your')}{' '}
                      {approved ? 'approved' : 'pending'} $
                      {t(
                        'me-leave.vacation-leave-this-will-no-longer-reflect-on-your-vacation-entitlements-kindly-cancel-this-entry',
                      )}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <>
          {data.forCancellation && (
            <div className={classes.cancelled_status_container}>
              <div className={`${classes['cancelled']} ${classes.badge}`}></div>
              <Typography className={classes.cancelled_status}>
                {t('me-leave.awaiting-approver-to-cancel-your-leave')}
              </Typography>
            </div>
          )}
        </>
      </>
    );
  };
  const handleClose = () => setOpenConfirmModal(false);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
    setOpenConfirmModal(false);
  };

  const submittedDate = moment(data.createdAt).format(dateFormats.dateTimeHH);

  return (
    <>
      <Modal
        open={openConfirmModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <React.Fragment>{showStatus()}</React.Fragment>

          <Typography className={classes.leave_date}>
            {handleDateFormatRangeYear(data.start, data.end)}
          </Typography>
          <Typography className={classes.leave_desc}>{hasDesc}</Typography>
          <Typography className={classes.leave_days}>
            <div className={classes.div_block}>
              {data.halfDay === 'half' ? (
                t('me-leave.half-day-leave')
              ) : (
                <>
                  {isNaN(totalDays)
                    ? t('me-leave.no-data-available')
                    : `${totalDays} ${
                        totalDays > 1 ? t('me-leave.days') : t('me-leave.day')
                      } ${t('me-leave.on-leave')}`}
                </>
              )}
            </div>
            <div className={classes.info_icon}>
              <CustomTooltip
                title={
                  <div className={classes.tooltip}>
                    {t('me-leave.total-working-days')} <br />
                    {t('me-leave.excluding-weekends-and-holiday')}
                  </div>
                }
                placement={'top'}
                fontSize={11}
                color={'rgba(0, 0, 0, 0.87)'}
                child={<InfoOutlineIcon sx={{ fontSize: 14 }} />}
              />
            </div>
          </Typography>

          <Typography className={classes.leave_status}>
            {`${t('me-leave.submitted-on')} ${submittedDate}`}
          </Typography>
          {shouldButtonsBeEnabled ? (
            <div className={classes.leave_btn}>
              {declined && (
                <Button
                  color="error"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.delete_btn}
                  onClick={() => cancelRequest(data.id, 'deleted')}
                >
                  {t('common.delete').toUpperCase()}
                </Button>
              )}
              {pending && (
                <Button
                  color="error"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.delete_btn}
                  // onClick={handleOpenCancelModal}
                  onClick={handleOpenCancelModal}
                >
                  {t('me-leave.cancel-leave')}
                </Button>
              )}
              {approved && !data.forCancellation && (
                <Button
                  color="error"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.delete_btn}
                  // onClick={handleOpenCancelModal}
                  onClick={() => cancelApproveRequest(data.id, true)}
                >
                  {t('me-leave.cancel-leave')}
                </Button>
              )}
              {approved && data.forCancellation && (
                <Button
                  color="error"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.delete_btn}
                  // onClick={handleOpenCancelModal}
                  onClick={() => cancelApproveRequest(data.id, false)}
                >
                  {t('me-leave.undo')}
                </Button>
              )}
              {declined && (
                <Button
                  color="secondary"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.edit_btn}
                  onClick={() => resubmitRequestLeave()}
                >
                  {t('me-leave.re-submit')}
                </Button>
              )}
              {(pending || declined) && (
                <Button
                  color="secondary"
                  variant="outlined"
                  labelVariant="buttonsmall"
                  className={classes.edit_btn}
                  onClick={editRequestLeave(true, data.type, data, true)}
                >
                  {t('common.edit')}
                </Button>
              )}
            </div>
          ) : null}
        </Box>
      </Modal>
      <ConfirmModal
        title={`${t(
          'me-leave.would-you-like-to-cancel-your',
        )} ${handleDateFormatRangeYear(data.start, data.end)} ${hasTypeName} ?`}
        description={t(
          'me-leave.this-will-be-removed-permanently-and-cannot-be-undone',
        )}
        openConfirmModal={openCancelModal}
        setOpenConfirmModal={handleCloseCancelModal}
        submitConfirmation={() => cancelRequest(data.id, 'cancelled')}
        width={430}
        height={'auto'}
        submitLabel={t('me-leave.cancel-leave')}
      />
    </>
  );
};

export default CustomModal;
