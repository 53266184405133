import React, { useEffect, useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import classes from './ManageInformation.module.scss';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import { putMeOganization } from '../../../../service/orgmanagementService';
import SuccessSnackbar from '../../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import ConfirmationDialogBox from '../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import { DialogActionsType } from '../../../../../../utilities/constantUtils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateFormats } from '../../../../../../utilities/dateUtils';
import { datePickerLanguage } from '../../../../../../i18n/i18nUtil';
import {
  ListDay,
  getDayLabel,
  ListDayAbb,
} from '../../../../../../utilities/constantUtils';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogUploadImage from './DialogUploadImage/DialogUploadImage';
import OwnerAdmins from './OwnerAdmins/OwnerAdmins';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ManageInformation = () => {
  const { t } = useTranslation();
  const { selectedOrg, organization, cbShowSuccessSnackBar, countryList } =
    useOutletContext();
  const navigate = useNavigate();
  const [bEdit, setBedit] = useState(true);
  const [country, setCountry] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const successSnackbarRef = useRef(null);
  const [severity, setSeverity] = useState('info');
  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: '',
    dialogContent: '',
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    submitLabelText: t('common.save'),
  });
  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: '',
      dialogContent: '',
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };
  const navigateBack = () => navigate(-1);
  useEffect(() => {
    if (countryList) {
      setCountry(countryList);
      var newValue = assignValue(
        countryList,
        organization,
        organization?.country?.code,
      );

      setInitialValue(newValue);
      setFormValue(newValue);
    }
  }, [countryList]);

  const onHandleFormChange = (event, fieldName) => {
    var value = '';
    if (fieldName === 'workWeekStart') {
      value = ListDay.findIndex((f) => f === event) + 1;
    } else if (event.target === undefined) {
      value = event;
    } else if (fieldName === 'workHours') {
      if (event) {
        value = Number(event.target.value);
      }
    } else {
      if (event) {
        value = event.target.value;
      }
    }
    var newValue = assignValue(countryList, formValue, formValue.country.code);
    newValue[fieldName] = value;
    validateChanged(newValue);
    setFormValue(newValue);
  };

  const onChangeWorkDays = (event, fieldName, item, row) => {
    var value = [...item];
    var nWorkDays = item.find((f) => f === row);
    if (event) {
      if (!nWorkDays) {
        value.push(row);
      }
    } else {
      if (nWorkDays) {
        value = item.filter((f) => f !== row);
      }
    }
    var newValue = assignValue(countryList, formValue, formValue.country.code);
    newValue[fieldName] = value.sort();
    validateChanged(newValue);
    setFormValue(newValue);
  };

  const changeAdmins = useRef(false);
  const onChangeAdmins = (check, value) => {
    changeAdmins.current = check;
    var newValue = assignValue(countryList, formValue, formValue.country.code);
    newValue.admins = value;
    setFormValue(newValue);
    if (!check) {
      validateChanged(formValue);
    } else {
      setBedit(false);
    }
  };

  const onChangeRemoveAdmins = (check, value) => {
    changeAdmins.current = check;
    var newValue = assignValue(countryList, formValue, formValue.country.code);
    newValue.admins = value;
    setFormValue(newValue);
    onPostHandler(newValue, true);
  };

  const onChangeOwner = (item) => {
    var newValue = assignValue(countryList, formValue, formValue.country.code);
    newValue.owner = [item];
    setFormValue(newValue);
    onPostHandler(newValue);
  };

  const validateChanged = (newValue) => {
    setBedit(true);
    var error = {};
    Object.keys(initialValue).forEach((f) => {
      if (f !== 'admins' && f !== 'owner') {
        if (initialValue[f] !== newValue[f]) {
          setBedit(false);
        }
        if ('id' === f) {
          if (newValue[f].toString().length === 0) {
            error[f] = true;
          }
        } else {
          if (newValue[f]) {
            if (newValue[f].length === 0) {
              error[f] = true;
            }
          }
        }
      }
    });
    Object.keys(error).forEach((f) => {
      setBedit(true);
    });
    setErrors(error);
    if (changeAdmins.current) {
      setBedit(false);
    }
  };

  const assignValue = (countryValue, item, countryCode) => {
    var country = countryValue.find(
      (f) => f.code.toLowerCase() === countryCode.toLowerCase(),
    );
    var target = {};
    var newValue = Object.assign(target, item);
    newValue.country = country;
    return newValue;
  };

  const saveChanges = async (bRemoveAdmin) => {
    onPostHandler(formValue, bRemoveAdmin);
  };

  const onPostHandler = async (value, bRemoveAdmin) => {
    const postData = { ...value };
    const nAdmins = [...postData.admins];
    postData.admins = nAdmins.concat(postData.owner);
    var response = await putMeOganization(postData);
    if (response.status === 204) {
      if (bRemoveAdmin) {
        cbShowSuccessSnackBar(t('organization.admin-has-been-removed'), value);
      } else {
        cbShowSuccessSnackBar(
          t('organization.organization-information-has-been-updated'),
          value,
        );
        navigateBack();
      }
    } else {
      setSeverity('error');
      showSuccessSnackBar(response.errorMessage);
    }
  };

  const onSubmit = async () => {
    navigateBack();
  };

  const discardHandler = async () => {
    if (!bEdit) {
      var value = {
        title: t(
          'organization."oops-you-have-unsaved-changes":"Oops, you have unsaved changes."',
        ),
        description: t(
          'organization.discarding-these-changes-will-be-removed-permanently',
        ),
        type: t('organization.discard'),
        actionType: DialogActionsType.error,
        leaveType: t('organization.approve-all').toUpperCase(),
      };
      dialogBox(value, t('common.close'));
    } else {
      navigateBack();
    }
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: value.title,
      dialogContent: value.description,
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
      leaveType: value.leaveType,
    }));
  };

  const workDays = () => {
    var result = [];
    ListDayAbb(t).forEach((f, index) => {
      var checked = false;
      var row = index + 1;
      if (formValue.workDays) {
        var value = formValue.workDays.find((i) => i === row);
        if (value) {
          checked = true;
        }
      }
      result.push(
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(_, value) =>
                onChangeWorkDays(value, 'workDays', formValue.workDays, row)
              }
            />
          }
          label={f}
        />,
      );
    });
    return result;
  };

  const uploadHandler = (value) => {
    setSeverity('info');
    showSuccessSnackBar(t('organization.image-has-been-uploaded'));
  };

  return (
    <>
      <div className={classes.backButton}>
        {' '}
        <Button
          color="secondary"
          size="medium"
          className={`capitalize ${classes.btnBack}`}
          onClick={() => discardHandler()}
          startIcon={<ArrowBackIcon />}
        >
          {t('common.back')}
        </Button>
      </div>
      <div container className={classes.invite_card}>
        <div className={`${classes.flexContainer} padB50`}>
          <div>
            <Typography className={classes.name}>
              {t('organization.manage-organization-information')}
            </Typography>
            <Typography className={classes.desc}>
              {t(
                'organization.you-can-update-the-information-of-the-organization-here',
              )}
            </Typography>
          </div>
          <div className={classes._button}>
            <div className="padR10">
              <Button
                color="secondary"
                size="medium"
                className={classes.button}
                onClick={() => discardHandler()}
              >
                {t('organization.discard')}
              </Button>
            </div>
            <div className="padR10">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => saveChanges(false)}
                disabled={bEdit}
              >
                {t('organization.save-changes')}
              </Button>
            </div>
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.titleLabel}>
              {t('organization.information')}
            </Typography>
          </div>
        </div>
        <div className="padB20">
          <Divider />
        </div>
        <div className={`padB20`}>
          <DialogUploadImage
            uploadHandler={uploadHandler}
            handle={formValue?.handle || ''}
          />
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`${classes.titleWidth}`}>
            <TextField
              className={classes.w100}
              label={t('organization.organization-name')}
              variant="standard"
              value={formValue?.name || ''}
              onChange={(e) => onHandleFormChange(e, 'name')}
              error={errors.name ? (errors.name ? true : false) : false}
            />
          </div>
          <div className={` ${classes.titleWidth}`}>
            <TextField
              className={classes.w100}
              label={t('organization.registration-no')}
              variant="standard"
              value={formValue?.regNo || ''}
              onChange={(e) => onHandleFormChange(e, 'regNo')}
              error={errors.regNo ? (errors.regNo ? true : false) : false}
            />
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`${classes.titleWidth}`}>
            <TextField
              className={classes.w100}
              label={t('organization.handle')}
              variant="standard"
              value={formValue?.handle || ''}
              disabled={true}
            />
          </div>
          <div className={` ${classes.titleWidth}`}>
            <TextField
              className={classes.w100}
              label={t('organization.vat-no')}
              variant="standard"
              value={formValue?.vatNo || ''}
              onChange={(e) => onHandleFormChange(e, 'vatNo')}
              error={errors.vatNo ? (errors.vatNo ? true : false) : false}
            />
          </div>
        </div>
        <div className={`${classes.flexContainer} padB50`}>
          <div className={`${classes.titleWidth}`}>
            <TextField
              className={classes.w100}
              label={t('organization.website')}
              variant="standard"
              value={formValue?.url || ''}
              onChange={(e) => onHandleFormChange(e, 'url')}
              error={errors.url ? (errors.url ? true : false) : false}
            />
          </div>
          <div className={` ${classes.titleWidth}`}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={country}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => onHandleFormChange(value, 'country')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('organization.country')}
                  variant="standard"
                />
              )}
              value={formValue?.country ? formValue?.country : null}
            />
          </div>
        </div>

        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.titleLabel}>
              {t('organization.calendar-settings')}
            </Typography>
          </div>
        </div>
        <div className="padB20">
          <Divider />
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div>
            <Typography className={classes.orgTitle}>
              {t('organization.work-days')}
            </Typography>
            {workDays()}
          </div>
        </div>
        <div className={`${classes.flexContainer} padB20`}>
          <div className={`${classes.titleWidth}`}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={datePickerLanguage()}
            >
              <DatePicker
                label={t('organization.vacation-year-start')}
                value={new Date(formValue?.vacationYearStart)}
                onChange={(value) => {
                  onHandleFormChange(value, 'vacationYearStart');
                }}
                renderInput={(params) => <TextField {...params} />}
                className={classes.date_picker}
                format={dateFormats.short_date_picker}
                slotProps={{ textField: { variant: 'standard' } }}
              />
            </LocalizationProvider>
          </div>
          <div className={` ${classes.titleWidth}`}>
            <TextField
              className={classes.w50}
              label={t('organization.work-hours')}
              variant="standard"
              value={formValue?.workHours || ''}
              onChange={(e) => onHandleFormChange(e, 'workHours')}
              error={
                errors.workHours ? (errors.workHours ? true : false) : false
              }
            />
          </div>
        </div>
        <div className={`${classes.flexContainer} padB50`}>
          <div className={` ${classes.titleWidth}`}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={ListDay(t)}
              getOptionLabel={(option) => option}
              onChange={(_, value) =>
                onHandleFormChange(value, 'workWeekStart')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('organization.work-week-start')}
                  variant="standard"
                />
              )}
              value={getDayLabel(formValue?.workWeekStart, t)}
            />
          </div>
          <div className={` ${classes.titleWidth300}`}>
            <Typography className={classes.orgTitle}>
              {t('organization.gdpr-privacy')}
            </Typography>
            <Switch
              checked={formValue?.registrationNotificationDetails}
              onChange={(e, value) =>
                onHandleFormChange(value, 'registrationNotificationDetails')
              }
            />

            <Typography className={classes.orgTitle}>
              {t('organization.personal-details-are-included-in-notifications')}
            </Typography>
            <Typography className={classes.orgTitle}>
              {t('organization.such-as-emails-push-notification-etc')}
            </Typography>
          </div>
        </div>
        <OwnerAdmins
          organization={organization}
          onChangeAdmins={onChangeAdmins}
          onChangeOwner={onChangeOwner}
          saveChanges={saveChanges}
          onChangeRemoveAdmins={onChangeRemoveAdmins}
          employmentId={selectedOrg?.emp}
        />
      </div>
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={
          <Typography
            variant="h6"
            fontWeight="fontWeightSemiBold"
            color="text.primary"
          >
            {confirmationBoxInfo.dialogContentTitle}
          </Typography>
        }
        content={
          <Typography variant="subtitle1" color="text.secondary">
            {confirmationBoxInfo.dialogContent}
          </Typography>
        }
        handleClose={handleCloseDialogBox}
        handleAction={onSubmit}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
      />
    </>
  );
};
export default ManageInformation;
