import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classes from './DurationCellView.module.scss';
import { LeaveStatusType } from '../../../../../../../../../../utilities/constantUtils';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { LightTooltip } from '../../utils/configs';
import { transformMinsToHoursMinutesString } from '../../../../../../../../../../utilities/timeConversion';

const DurationCellView = (props) => {
  const {
    projectTask,
    regDate,
    disAbleSubmitHandler,
    cellDetailHandler,
    anchorElHandler,
    cbSetSelectedTaskTitle,
    registrationHandler,
    cbsetIsUserTryingToEditCell,
  } = props;
  const registration = projectTask?.taskRegistrations[regDate]?.[0] || null;
  const openTimeRegModal = (event) => {
    const regDetail =
      registration !== null
        ? {
            taskReg: Object.assign({}, registration),
            taskId: projectTask?.taskId,
            taskProjectId: projectTask?.taskProjectId,
            regDate: regDate,
          }
        : {
            taskReg: Object.assign(
              {},
              {
                regId: -1,
                regDesc: '',
                regStatus: LeaveStatusType.draft,
                regDuration: 0,
              },
            ),
            taskId: projectTask?.taskId,
            taskProjectId: projectTask?.taskProjectId,
            regDate: regDate,
          };
    toggleDisableSubmitHandler(disAbleSubmitHandler, registration);
    cellDetailHandler(regDetail);
    anchorElHandler(event.currentTarget);
    cbSetSelectedTaskTitle(projectTask.taskTitle);
    registrationHandler(registration);
  };

  const padClass = registration?.regDuration ? '' : classes.padTopAdjust;

  return (
    <div
      onKeyDown={() => {
        registration?.regStatus !== LeaveStatusType.approved &&
          cbsetIsUserTryingToEditCell(true);
      }}
    >
      <LightTooltip title={mainlightToolTipTitle()}>
        <Button
          disableElevation
          disableRipple
          className={`tolowercase ${classes.btnTimesheet}`}
        >
          <div className={classes.duration_cell_container + ' ' + padClass}>
            {registration?.regStatus === LeaveStatusType.approved && (
              <LightTooltip title="Approved" placement="top">
                <CheckCircleIcon className={classes.badgeIcon} />
              </LightTooltip>
            )}
            {registration?.regStatus === LeaveStatusType.pending && (
              <LightTooltip title="Submitted for approval" placement="top">
                <CheckCircleIcon className={classes.badgeIcondraft} />
              </LightTooltip>
            )}

            <div
              className={`${classes.duration_text} ${
                registration?.regStatus === LeaveStatusType.draft
                  ? classes.duration_text_draft
                  : registration?.regStatus === LeaveStatusType.declined &&
                    classes.duration_text_draft
              }`}
            >
              <Typography variant="body2" className={classes.text}>
                {registration?.regDuration ? (
                  <span className={classes.undeline}>
                    {transformMinsToHoursMinutesString(
                      registration?.regDuration,
                    )}
                  </span>
                ) : (
                  <span className={classes.undelinehr}>0h 00m</span>
                )}
              </Typography>
              <ArrowDropDownCircleIcon
                className={classes.expand_icon}
                sx={{ fontSize: 18 }}
                onClick={openTimeRegModal}
              />
            </div>
          </div>
        </Button>
      </LightTooltip>
    </div>
  );
};

export default DurationCellView;

const mainlightToolTipTitle = () => (
  <div className={classes.pad + ' ' + classes.tooltip}>
    <>
      <div className={`textCenter ${classes.width100}`}>
        <Typography variant="subtitle2" className={classes.fsize12}>
          Enter hours
        </Typography>
      </div>
      <div className={classes.flex}>
        <div className={`padR5 padT2 textCenter `}>
          <Typography variant="subtitle2" className={classes.fsize12}>
            or click
          </Typography>
        </div>
        <div className={`padR5`}>
          <ArrowDropDownCircleIcon sx={{ fontSize: 18 }} />
        </div>
        <div className="textCenter padT2">
          <Typography variant="subtitle2" className={classes.fsize12}>
            for more options
          </Typography>
        </div>
      </div>
    </>
  </div>
);

const toggleDisableSubmitHandler = (disAbleSubmitHandler, registration) => {
  if (registration !== null) {
    if (registration.regStatus === LeaveStatusType.draft)
      disAbleSubmitHandler(false);

    if (registration.regStatus === LeaveStatusType.pending) {
      disAbleSubmitHandler(true);
    }
  }
};
