import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";

const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};

const CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const REDIRECT_URI_SIGNIN = process.env.REACT_APP_LINKEDIN_REDIRECT_SIGNIN;
const REDIRECT_URI_SIGNUP = process.env.REACT_APP_LINKEDIN_REDIRECT_SIGNUP;
const STATE = process.env.REACT_APP_LINKEDIN_STATE;
const SCOPE = process.env.REACT_APP_LINKEDIN_SCOPE;

const LinkedinButton = (props) => {
  //TODO: Details here should be moved for security but for now it is placed on .env file
  const LINKEDIN_URL = getURLWithQueryParams(
    "https://www.linkedin.com/oauth/v2/authorization",
    {
      response_type: "code",
      client_id: CLIENT_ID,
      redirect_uri: props.signin ? REDIRECT_URI_SIGNIN : REDIRECT_URI_SIGNUP,
      state: STATE,
      scope: SCOPE,
    }
  );

  const onClickHandler = (event) => {
    if (props.onClick) {
      props.onClick(LINKEDIN_URL);
    }
  };

  return (
    <IconButton
      id={props.id}
      size="large"
      sx={{
        color: "#0073B1",
        borderColor: "primary.main",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 2,
      }}
      onClick={onClickHandler}
    >
      <LinkedInIcon fontSize="inherit" />
    </IconButton>
  );
};

export default LinkedinButton;
