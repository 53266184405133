import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgressLoader from '../../../../../../../../../ui/circularProgressLoader/CircularProgressLoader';
import classes from './DurationCellEdit.module.scss';
import { failedRegSaveMessage } from '../../../../../../../../../../utilities/contentMessagesUtils';
import { LeaveStatusType } from '../../../../../../../../../../utilities/constantUtils';
import { transformMinsToHoursMinutesString } from '../../../../../../../../../../utilities/timeConversion';
import TimeInput from '../../timeInput/TimeInput';

const DurationEditCell = (props) => {
  const {
    projectTask,
    regDate,
    cbAddEditTimeSingleRegistration,
    selectedOrg,
    showErrorSnackBarFn,
    cbsetIsUserTryingToEditCell,
  } = props;
  const originalRegDuration =
    projectTask?.taskRegistrations?.[regDate]?.[0]?.regDuration || null;
  const [isCreatingRegistration, setIsCreatingRegistration] = useState(false);
  const [minutesValue, setMinutesValue] = useState(originalRegDuration);

  const totalHoursPerDayOfTheWeekObject = useSelector(
    (state) => state.registrationContext.totalRegHoursPerDate,
  );
  const REG_ID_OF_NEW_TASK = -1;

  const onTimeInputBlurChange = (event) => {
    if (event) {
      const numberValue = transformMinsToHoursMinutesString(
        event.target.value,
        true,
      );
      if (event.target.value.length > 0 && numberValue !== '00') {
        buildThenAddOrEditTimeRegistration();
      }
    }
  };

  const buildThenAddOrEditTimeRegistration = () => {
    try {
      cbsetIsUserTryingToEditCell(false);
      const { newRegistration, originalRegistration } = buildRegistration(
        minutesValue,
        projectTask,
        regDate,
        REG_ID_OF_NEW_TASK,
      );
      cbAddEditTimeSingleRegistration({
        totalHoursPerDayOfTheWeekObject,
        timeRegistration: newRegistration,
        timeOffset: originalRegistration
          ? originalRegistration[0].regDuration
          : 0,
        selectedOrgEmpId: selectedOrg?.emp,
        cbOnSuccessAddofTimeReg: () => undefined,
        cbOnFailedAddofTimeReg: () => showErrorSnackBarFn(failedRegSaveMessage),
        cbOnSuccessEditofTimeReg: () => setIsCreatingRegistration(false),
        cbOnFailedEditofTimeReg: (data) => {
          const rawErrorMessage = data.errorMessage;
          const errorMessage = rawErrorMessage.substring(
            rawErrorMessage.indexOf('[') + 1,
            rawErrorMessage.lastIndexOf(']'),
          );
          const errorMsgWithoutQuotes = errorMessage.replace(/["']/g, '');
          showErrorSnackBarFn(errorMsgWithoutQuotes);
        },
      });
    } catch {}
  };

  const onKeyPressHandler = (event) => {
    if (event.key === 'Tab') {
      if (event) {
        if (event.target.value.length > 0 && event.target.value !== '00') {
          buildThenAddOrEditTimeRegistration();
        }
      }
    } else if (
      event.key === 'Enter' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowLeft'
    ) {
      if (event) {
        if (event.target.value.length > 0 && event.target.value !== '00') {
          buildThenAddOrEditTimeRegistration();
          event.preventDefault();
        }
      }
    } else {
      const regex = /[0-9.]/;
      const notANumber = !regex.test(event.key);
      if (notANumber && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.edit_cell_container}>
        <Box
          className={`${classes.input_container}${
            isCreatingRegistration && classes.loading
          }`}
        >
          <TimeInput
            className={classes.duration_input_field}
            InputLabelProps={{
              shrink: true,
            }}
            minutesValue={minutesValue}
            setMinutesValue={setMinutesValue}
            onBlur={onTimeInputBlurChange}
            onKeyDown={onKeyPressHandler}
            isDisabled={isCreatingRegistration}
            isAutoFocus
          />
        </Box>
        {isCreatingRegistration && (
          <div className={classes.small_loading_spinner}>
            <CircularProgressLoader size={20} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DurationEditCell;

const buildRegistration = (
  minutesValue,
  projectTask,
  regDate,
  REG_ID_OF_NEW_TASK,
) => {
  const originalRegistration = projectTask?.taskRegistrations?.[regDate];
  const compareValue = originalRegistration?.[0]
    ? originalRegistration?.[0]?.regDuration?.toString()
    : '';
  const wasTimeRegDurationChanged = compareValue !== minutesValue?.toString();

  if (wasTimeRegDurationChanged) {
    const regPlaceHolderOfNewTask = {
      regId: REG_ID_OF_NEW_TASK,
      regDesc: '',
      regStatus: LeaveStatusType.draft,
      regDuration: 0,
    };
    const defaultRegDetails = {
      taskId: projectTask?.taskId,
      taskProjectId: projectTask?.taskProjectId,
      regDate: regDate,
    };

    const newRegistration = originalRegistration
      ? {
          ...defaultRegDetails,
          taskReg: {
            ...originalRegistration?.[0],
            regDuration: minutesValue,
          },
        }
      : {
          ...defaultRegDetails,
          taskReg: { ...regPlaceHolderOfNewTask, regDuration: minutesValue },
        };
    return { newRegistration, originalRegistration };
  }
};
