import React, { useEffect, useState } from 'react';
import classes from './MemberList.module.scss';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { Grid, CircularProgress } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const MemberList = (props) => {
  const { t } = useTranslation();
  const [listState, setListState] = useState([]);
  useEffect(() => {
    setListState(props.list);
  }, [props.list]);

  const columns = [
    {
      field: 'name',
      headerName: t('organization.members'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.flex}>
              <div>
                <Avatar
                  className={classes.member_avatar}
                  alt={item.row.name}
                  src="./broken-image.jpg"
                />
              </div>
              <div className="padT2">{item.row.name}</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'membership',
      headerName: t('organization.membership'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            {' '}
            <div className={classes.pad}>Employee</div>
          </React.Fragment>
        );
      },
    },
  ];

  const Actions = () => {
    return (
      <GridToolbarContainer className={classes.toolBarContainer}>
        <Grid container>
          <div>
            <GridToolbarFilterButton />
          </div>
        </Grid>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.subtitle1}>
        {props.teamName} ({listState.length}{' '}
        {t('organization.members').toUpperCase()})
      </Typography>
      <Typography variant="subtitle2" className={`padB10 ${classes.subtitle2}`}>
        {t(
          'organization.list-below-are-the-team-members-of-your-chosen-team-for-this-project',
        )}
      </Typography>

      <div className={`${classes.container} leaveTable`}>
        <div
          className={classes.filter_tab}
          style={{ height: 400, width: '100%' }}
        >
          <DataGridContainer
            autoHeight={true}
            columns={columns}
            rows={listState}
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: Actions,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 100]}
            sx={{
              '.MuiTablePagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            localeText={{
              toolbarFilters: t('organization.filters'),
            }}
          />

          {props.loader && (
            <div
              className={
                classes.box + ' ' + classes.stackTop + ' ' + classes.box2
              }
            >
              <div className={classes.center}>
                <CircularProgress />
                <Typography>{t('organization.getting-member-list')}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberList;
