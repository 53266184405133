import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import classes from './CardSkeleton.module.scss';
import { Grid } from '@mui/material';

const CardSkeleton = (props) => {
  return (
    <Stack className={classes.card_skeleton} spacing={1}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Skeleton variant="h1" />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.card_skeleton_details}>
        <Grid item xs={1}>
          <Skeleton variant="circular" width={60} height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            className={classes.card_skeleton_variant}
          />
          <Skeleton
            variant="body1"
            width={70}
            height={15}
            className={classes.card_skeleton_variant}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            className={classes.card_skeleton_variant}
          />
          <Skeleton
            variant="body1"
            width={70}
            height={15}
            className={classes.card_skeleton_variant}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            className={classes.card_skeleton_variant}
          />
          <Skeleton
            variant="body1"
            width={70}
            height={15}
            className={classes.card_skeleton_variant}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            className={classes.card_skeleton_variant}
          />
          <Skeleton
            variant="body1"
            width={70}
            height={15}
            className={classes.card_skeleton_variant}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="h1"
            width={130}
            height={30}
            className={classes.card_skeleton_middle}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CardSkeleton;
