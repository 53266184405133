import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authContextSlice from './authContextSlice';
import userContextSlice from './userContextSlice';
import { getItemFromLocalStorage } from '../utilities/localStorageUtlis';
import projectContextSlice from './projectContextSlice';
import registrationContextSlice from './registrationContextSlice';
import orgContextSlice from './orgContextSlice';
import leaveContextSlice from './leaveContextSlice';
import teamTimesheetContextSlice from './teamTimesheetContextSlice';
import toolbarContextSlice from './toolbarContextSlice';
import orgTeamContextSlice from './orgTeamContextSlice';

const persistedSlice = ['authContext'];

const loadFromLocalStorage = () => {
  try {
    let stateStorage = {};
    persistedSlice.forEach((slice) => {
      const item = getItemFromLocalStorage(slice);
      if (item !== null) {
        stateStorage[slice] = JSON.parse(item);
      }
    });

    return stateStorage ? stateStorage : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();

const combinedReducer = combineReducers({
  authContext: authContextSlice,
  userContext: userContextSlice,
  projectContext: projectContextSlice,
  registrationContext: registrationContextSlice,
  orgContext: orgContextSlice,
  leaveContext: leaveContextSlice,
  teamTimesheetContext: teamTimesheetContextSlice,
  toolBarContext: toolbarContextSlice,
  orgTeamContext: orgTeamContextSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'authContext/reset') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedStore,
});

export default store;
