import React, { useState, useEffect, useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import { format, addWeeks, subWeeks } from 'date-fns';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import classes from './WeeklyDatePicker.module.scss';
import { useTranslation } from 'react-i18next';
import { datePickerLanguage } from '../../../../../../../i18n/i18nUtil';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const renderWeekPicker = (
  date,
  pickersDayProps,
  selectedDate,
  startDate,
  endDate,
) => {
  if (!selectedDate) {
    return <PickersDay {...pickersDayProps} />;
  }
  const start = startDate;
  const end = endDate;

  const dayIsBetween = isWithinInterval(date, { start, end });
  const isFirstDay = isSameDay(date, start);
  const isLastDay = isSameDay(date, end);

  return (
    <CustomPickersDay
      {...pickersDayProps}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
};

const WeeklyDatePicker = (props) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRangeDisplay, setDateRangeDisplay] = useState('');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    let startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
    let endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });
    setStartDate(startDate);
    setEndDate(endDate);
    let startmonth = format(startDate, dateFormats.month, {
      locale: datePickerLanguage(),
    });
    let endmonth = format(endDate, dateFormats.month, {
      locale: datePickerLanguage(),
    });
    let year = format(startDate, dateFormats.year);
    let startDay = format(startDate, dateFormats.day);
    let endDay = format(endDate, dateFormats.day);
    let weekNumber = format(startDate, dateFormats.week);

    setDateRangeDisplay(
      `${startmonth} ${startDay} - ${
        startmonth != endmonth ? endmonth : ''
      } ${endDay}, ${year} (${t('me.week')} ${weekNumber})`,
    );
    props.onChange(startDate, endDate, selectedDate);
  }, [selectedDate]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onRightArrowClickHandler = () => {
    setSelectedDate((prev) => addWeeks(prev, 1));
  };

  const onLeftArrowClickHandler = () => {
    setSelectedDate((prev) => subWeeks(prev, 1));
  };

  const renderWeekPickerDayHandler = (date, selectedDates, pickersDayProps) => {
    return renderWeekPicker(
      date,
      pickersDayProps,
      selectedDate,
      startDate,
      endDate,
    );
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        color="secondary"
        ref={anchorRef}
        disabled={props.loader}
      >
        <Button onClick={handleClick}>
          <div className={classes.date_range_display}> {dateRangeDisplay}</div>
          <ArrowDropDownIcon />
        </Button>
        <Button onClick={onLeftArrowClickHandler}>
          <KeyboardArrowLeftIcon />
        </Button>
        <Button onClick={onRightArrowClickHandler}>
          <KeyboardArrowRightIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Typography
                    component="div"
                    color="text.primary"
                    variant="subtitle2"
                    fontWeight="fontWeightSemiBold"
                    className={classes.calendar_text}
                  >
                    {t('me.please-select-a-week')}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={datePickerLanguage()}
                  >
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      label="Week picker"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      renderDay={renderWeekPickerDayHandler}
                      renderInput={(params) => {}}
                      inputFormat="'Week of' MMM d"
                    />
                  </LocalizationProvider>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default WeeklyDatePicker;
