import { format } from 'date-fns/esm';
import {
  removeActiveMember,
  removeActiveMemberTeam,
  removePendingRequest,
  removePendingTeamInvitation,
  removeTeam,
  setActiveMembers,
  setPendingInvitations,
  setPendingRequests,
  setTeams,
  setUsersApprover,
  setProjectList,
  setMyProjectList,
  setProjectListALL,
  setMyTeams,
  setOrganization,
  setMembersPerTeam,
} from '../../../store/orgContextSlice';
import store from '../../../store/store';
import { dateFormats } from '../../../utilities/dateUtils';
import httpRequestHandler from '../../../utilities/httpRequestHandler';
import {
  createTransitData,
  getTransitDataCollection,
} from '../../../utilities/transitJsUtils';
import { _copyAndSort } from '../../../utilities/utilFunc';
import { userRoles } from '../../../utilities/roleUtils';
import { setTeamList } from '../../../store/orgTeamContextSlice';
import moment from 'moment';

export const validateEmailAddress = async (emailInfo) => {
  try {
    let requestParamObj = {
      email: { value: emailInfo.email, isKeyword: false },
      field: {
        value: emailInfo.field,
        isKeyword: false,
      },
    };

    let mappedrequestParamObj = createTransitData(requestParamObj);
    const response = await httpRequestHandler(
      validateCredentialsInfo,
      mappedrequestParamObj,
    );

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const sendTeamInvitationService = async (inviteObj) => {
  let requestParamObj = {
    team: { value: inviteObj.team, isKeyword: false },
    'first-name': {
      value: inviteObj.fname,
      isKeyword: false,
    },
    'last-name': {
      value: inviteObj.lname,
      isKeyword: false,
    },
    email: { value: inviteObj.email, isKeyword: false },
    'custom-msg': { value: inviteObj.message, isKeyword: false },
  };

  let mappedrequestParamObj = createTransitData(requestParamObj);
  const response = await httpRequestHandler(
    sendTeamInvitationInfo,
    mappedrequestParamObj,
  );

  return response;
};

export const leaveTeamService = async (team) => {
  try {
    let requestParamObj = {
      id: { value: team, isKeyword: false },
    };

    let mappedrequestParamObj = createTransitData(requestParamObj);

    const response = await httpRequestHandler(
      leaveTeamInfo,
      mappedrequestParamObj,
    );

    return response;
  } catch {
    throw new Error('Error on Service Request');
  }
};

export const getListofMembersPerTeam = (teamId) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getTeamMembers, {
        id: teamId,
      });
      let memberList = [];
      let mappedResponseDataObj = {
        memberId: 'db/id',
        memberAuth: 'auth',
        memberFirstName: 'firstname',
        memberLastName: 'lastname',
        memberEmploymentType: 'type',
      };
      if (response.result === 1) {
        response.data.forEach((member) => {
          let mappedData = getTransitDataCollection(
            member,
            mappedResponseDataObj,
          );
          mappedData.memberAuth = getAuthMembers(mappedData.memberAuth);
          mappedData.memberEmploymentType = mappedData.memberEmploymentType
            ? mappedData.memberEmploymentType
            : userRoles.employee;
          mappedData.name = `${mappedData.memberFirstName} ${mappedData.memberLastName}`;
          mappedData.id = mappedData.memberId;
          memberList.push(mappedData);
        });
        response.data = memberList;
        dispatch(setMembersPerTeam(response.data));
      } else {
        dispatch(setMembersPerTeam([]));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const getAuthMembers = (auth) => {
  let result = [];
  auth?.keySet()?.forEach((key) => {
    switch (key.name()) {
      case 'orgadmin':
        result.push(userRoles.orgAdmin);
        break;
      case 'manager':
        result.push(userRoles.teamManager);
        break;
      case 'employee':
        result.push(userRoles.employee);
        break;
      case 'user':
        result.push(userRoles.user);
        break;
      default:
        break;
    }
  });
  return result;
};

export const getListOfActiveMembers = (employmentId) => {
  return async (dispatch) => {
    try {
      let response = await httpRequestHandler(getListOfActiveMembersInfo, {
        id: employmentId,
        // note: in API documentation id should be "employment" but in papi tester its "id"
      });
      let memberList = [];
      let mappedResponseDataObj = {
        memberId: 'db/id',
        memberTeamEmployment: 'employment/teams',
        memberFirstName: 'user/firstname',
        memberLastName: 'user/lastname',
        memberEmploymentType: 'employment/type',
      };

      let mappedMemberTeamDataObj = {
        teamId: 'db/id',
        teamName: 'team/name',
      };
      if (response.result === 1) {
        response.data.forEach((member) => {
          let mappedData = getTransitDataCollection(
            member,
            mappedResponseDataObj,
          );

          let listOfTeams = [];
          mappedData.memberTeamEmployment.forEach((team) => {
            let mappedTeam = getTransitDataCollection(
              team,
              mappedMemberTeamDataObj,
            );
            listOfTeams.push(mappedTeam);
          });

          mappedData.memberTeamEmployment = listOfTeams;
          mappedData.name = `${mappedData.memberFirstName} ${mappedData.memberLastName}`;
          mappedData.id = mappedData.memberId;
          memberList.push(mappedData);
        });
        response.data = memberList;
        dispatch(setActiveMembers({ activeMemberList: response.data }));
      } else {
        dispatch(setActiveMembers({ activeMemberList: [] }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getListOfPendingRequests = (employmentId, isAdmin) => {
  return async (dispatch) => {
    try {
      let response = await httpRequestHandler(getListOfPendingRequestsInfo, {
        employment: employmentId,
        'admin-only': isAdmin,
      });
      let requestList = [];
      let mappedResponseDataObj = {
        requestId: 'db/id',
        // requestCreatedByDate: "meta/created-date",
        requestUser: 'request/user',
        requestTeam: 'request/team',
        requestStatus: 'request/status',
        requestType: 'request/type',
      };

      let mappedRequestUserObj = {
        requestUserId: 'db/id',
        requestUserFname: 'user/firstname',
        requestUserLname: 'user/lastname',
        requestUserEmail: 'user/email',
      };

      let mappedRequestTeamObj = {
        requestTeamId: 'db/id',
        requestTeamName: 'team/name',
      };

      if (response.result === 1) {
        response.data.forEach((request) => {
          let mappedData = getTransitDataCollection(
            request,
            mappedResponseDataObj,
          );
          mappedData.requestTeam = mappedData.requestTeam
            ? getTransitDataCollection(
                mappedData.requestTeam,
                mappedRequestTeamObj,
              )
            : {};
          mappedData.requestUser = getTransitDataCollection(
            mappedData.requestUser,
            mappedRequestUserObj,
          );
          requestList.push(mappedData);
        });
        response.data = requestList;
        dispatch(setPendingRequests({ pendingRequestsList: response.data }));
      } else {
        dispatch(setPendingRequests({ pendingRequestsList: [] }));
      }

      return response;
    } catch (error) {
      console.log({ error });
      throw new Error('Error on Service Request');
    }
  };
};

export const reviewLeaveRequest = (requestInfo) => {
  return async (dispatch) => {
    try {
      const userId = store.getState().userContext.userId;
      let requestParamObj = {
        id: { value: requestInfo.id, isKeyword: false },
        'is-approve': { value: requestInfo['is-approve'], isKeyword: false },
      };

      let mappedrequestParamObj = createTransitData(requestParamObj);
      let response = await httpRequestHandler(
        reviewLeaveRequestInfo,
        mappedrequestParamObj,
      );

      if (response.result === 1) {
        dispatch(removePendingRequest({ requestId: requestInfo.id }));

        //if userid is equal to request user id and is-approve is true remove team to teamlist of user
        //and remove user roles associated with team on userTeamRoles
        if (userId === requestInfo.requestUserId && requestInfo['is-approve']) {
          dispatch(removeTeam({ teamId: requestInfo.requestTeamId }));
        }
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const reviewAllLeaveRequests = (requestInfo, isApproved) => {
  return async (dispatch) => {
    try {
      const userId = store.getState().userContext.userId;

      let requestParamList = [];

      requestInfo.forEach((pendingRequest) => {
        let requestParamObj = {
          id: { value: pendingRequest.requestId, isKeyword: false },
          'is-approve': { value: isApproved, isKeyword: false },
        };

        let mappedrequestParamObj = createTransitData(requestParamObj);
        requestParamList.push(JSON.parse(mappedrequestParamObj));
      });

      let response = await httpRequestHandler(
        reviewLeaveRequestInfo,
        requestParamList,
      );

      if (response.result === 1) {
        dispatch(setPendingRequests({ pendingRequestsList: [] }));
        //if userid is equal to request user id and is-approve is true remove team to teamlist of user
        //and remove user roles associated with team on userTeamRoles
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getListOfPendingInvitations = (employmentId, isAdmin) => {
  return async (dispatch) => {
    const userId = store.getState().userContext.userId;
    try {
      let response = await httpRequestHandler(getListOfPendingInvitationsInfo, {
        employment: employmentId,
        'admin-only': isAdmin,
      });

      if (response.result === 1) {
        let invitationList = [];
        let mappedResponseDataObj = {
          invitationId: 'db/id',
          invitationCreatedByUser: 'meta/created-by',
          invitationUser: 'request/user',
          invitationTeam: 'request/team',
          invitationStatus: 'request/status',
          invitationType: 'request/type',
          invitationCreatedAt: 'meta/created-at',
        };

        let mappedCreatedByUserDataObj = {
          userId: 'id',
          userName: 'name',
        };

        let mappedInvitationTeamObj = {
          teamId: 'db/id',
          teamName: 'team/name',
        };

        let mappedInvitationUserObj = {
          userId: 'db/id',
          userFname: 'user/firstname',
          userLname: 'user/lastname',
          userEmail: 'user/email',
        };

        response.data.forEach((invitation) => {
          let mappedInvitationData = getTransitDataCollection(
            invitation,
            mappedResponseDataObj,
          );

          mappedInvitationData.invitationCreatedAt = format(
            mappedInvitationData.invitationCreatedAt,
            dateFormats.date_slashes,
          );

          mappedInvitationData.invitationCreatedByUser =
            getTransitDataCollection(
              mappedInvitationData.invitationCreatedByUser,
              mappedCreatedByUserDataObj,
            );

          mappedInvitationData.invitationTeam = getTransitDataCollection(
            mappedInvitationData.invitationTeam,
            mappedInvitationTeamObj,
          );

          mappedInvitationData.invitationUser = getTransitDataCollection(
            mappedInvitationData.invitationUser,
            mappedInvitationUserObj,
          );

          invitationList.push(mappedInvitationData);
        });

        response.data = invitationList;

        dispatch(
          setPendingInvitations({
            pendingInvitationsList: response.data,
            userId,
          }),
        );
      } else {
        dispatch(setPendingInvitations({ pendingInvitationsList: [], userId }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const cleanUpOrgMembersData = () => {
  return async (dispatch) => {
    dispatch(setActiveMembers({ activeMemberList: [] }));
    dispatch(setPendingRequests({ pendingRequestsList: [] }));
    dispatch(setPendingInvitations({ pendingInvitationsList: [] }));
  };
};

export const removeMember = (id, team, employmentId) => {
  return async (dispatch) => {
    try {
      let requestParamObj = {
        id: { value: id, isKeyword: false },
        team: { value: team, isKeyword: false },
        employment: { value: employmentId, isKeyword: false },
      };

      let mappedrequestParamObj = createTransitData(requestParamObj);

      let response = await httpRequestHandler(
        removeMemberInfo,
        mappedrequestParamObj,
      );

      if (response.result === 1) {
        if (team) {
          //remove to just a team
          dispatch(removeActiveMemberTeam({ id: id, team: team }));
        } else {
          // remove to an org
          dispatch(removeActiveMember({ id: id }));
        }
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const cancelTeamInvitation = (id) => {
  return async (dispatch) => {
    try {
      let requestParamObj = {
        id: { value: id, isKeyword: false },
      };

      let mappedrequestParamObj = createTransitData(requestParamObj);

      let response = await httpRequestHandler(
        cancelTeamInvitationInfo,
        mappedrequestParamObj,
      );

      if (response.result === 1) {
        dispatch(removePendingTeamInvitation({ inviteId: id }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getMyTeamList = (employmentId) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getTeams, {
        employment: employmentId,
      });
      if (response.result === 1) {
        var data = response.data;
        let list = [];
        let mapToObject = {
          id: 'id',
          label: 'label',
          manager: 'manager',
          isActive: 'is-active',
        };
        data.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          // Transit data to object
          const buildManagerData = getTransitDataCollection(
            mappedData.manager,
            {
              fName: 'user/firstname',
              lName: 'user/lastname',
              status: 'user/status',
              status2: 'user.status/completed',
              id: 'db/id',
            },
          );
          const nMappedData = {
            id: mappedData.id,
            label: mappedData.label,
            manager: buildManagerData,
            isActive: mappedData.isActive,
          };
          list.push(nMappedData);
        });

        list = _copyAndSort(list, 'label', false);
        response.data = list;
        dispatch(setMyTeams({ myTeamList: response.data }));
      } else {
        dispatch(setMyTeams({ myTeamList: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getTeamList = (employmentId) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getTeams, {
        employment: employmentId,
      });
      if (response.result === 1) {
        var data = response.data;
        let list = [];
        let mapToObject = {
          id: 'id',
          label: 'label',
          manager: 'manager',
          isActive: 'is-active',
        };
        // list.push({ id: 'orgWide', label: 'Org-wide' });

        data.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          // Transit data to object
          const buildManagerData = getTransitDataCollection(
            mappedData.manager,
            {
              fName: 'user/firstname',
              lName: 'user/lastname',
              status: 'user/status',
              status2: 'user.status/completed',
              id: 'db/id',
            },
          );

          const nMappedData = {
            id: mappedData.id,
            label: mappedData.label,
            manager: buildManagerData,
            isActive: mappedData.isActive,
          };

          mappedData.manager = buildManagerData;
          list.push(nMappedData);
        });
        list = _copyAndSort(list, 'label', false);
        list.unshift({ id: 'orgWide', label: 'Org-wide', isActive: true });

        response.data = list;
        // const managers = getTransitDataCollection(response.data.manager, {
        //   fname: 'user/firstname',
        //   // label: mappedData.label,
        //   // manager: mappedData.manager,
        // });

        dispatch(setTeams({ teamList: response.data }));
      } else {
        dispatch(setTeams({ teamList: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getUsersApproverList = (empId) => {
  return async (dispatch) => {
    try {
      const requestParam = {
        employment: empId,
      };
      const response = await httpRequestHandler(getUsersApprover, requestParam);
      if (response.result === 1) {
        var data = response.data;
        let list = [];
        let mapToObject = {
          id: 'db/id',
          firstName: 'user/firstname',
          lastName: 'user/lastname',
          teams: 'employment/teams',
          employementLegalAccept: 'employment/legal-accept',
        };

        data.rep.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          const nMappedData = {
            id: mappedData.id,
            firstName: mappedData.firstName,
            lastName: mappedData.lastName,
            teams: getEmployeeTeams(mappedData.teams),
            roles: getRoles(mappedData.employementLegalAccept),
            name: `${mappedData.firstName} ${mappedData.lastName}`,
          };
          list.push(nMappedData);
        });
        list = _copyAndSort(list, 'firstName', false);
        response.data = list;
        dispatch(setUsersApprover({ usersList: response.data }));
      } else {
        dispatch(setUsersApprover({ usersList: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const getMeProjectList = (employmentId, myProject) => {
  return async (dispatch) => {
    try {
      const requestParam = {
        from: format(new Date(), dateFormats.yyyymmdd),
        to: format(new Date(), dateFormats.yyyymmdd),
        employment: employmentId,
      };
      const response = await httpRequestHandler(getMeProject, requestParam);
      if (response.result === 1) {
        var data = response.data;
        let list = mapProjectList(data);
        response.data = list;
        if (myProject) {
          dispatch(setMyProjectList(response.data));
        } else {
          dispatch(setProjectList({ projectList: response.data }));
        }
      } else {
        dispatch(setMyProjectList([]));
        dispatch(setProjectList({ projectList: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const mapProjectList = (data) => {
  let list = [];
  let mapToObject = {
    id: 'db/id',
    name: 'project/name',
    organization: 'project/organization',
    approver: 'project/approver',
    start: 'project/start',
    end: 'project/end',
    createdby: 'meta/created-by',
    createdat: 'meta/created-at',
    modifiedat: 'meta/updated-at',
    status: 'project/status',
    task: 'project/tasks',
    ownerDetails: 'project/owner',
  };
  data.forEach((leaveData, index) => {
    let mappedData = getTransitDataCollection(leaveData, mapToObject);
    var checkExist = list.find((f) => f.id === mappedData.id);
    if (!checkExist) {
      const nMappedData = {
        id: mappedData.id,
        name: mappedData.name,
        organization: mappedData.organization,
        approver: getApproverDetails(mappedData.approver),
        start: mappedData.start,
        end: mappedData.end,
        createdat: mappedData.createdat,
        createdby: getName(mappedData.createdby),
        modifiedat: mappedData.modifiedat,
        status: mappedData.status,
        task: mappedData.task,
        ownerDetails: getOwnerDetails(mappedData.ownerDetails),
        ownerName: getOwnerDetails(mappedData.ownerDetails).name,
      };
      list.push(nMappedData);
    }
  });

  return list;
};

export const getProjectList = (employmentId) => {
  return async (dispatch) => {
    try {
      const requestParam = {
        from: format(new Date(), dateFormats.yyyymmdd),
        to: format(new Date(), dateFormats.yyyymmdd),
        employment: employmentId,
      };

      const response = await httpRequestHandler(getProject, requestParam);
      if (response.result === 1) {
        var data = response.data;
        let list = [];
        let mapToObject = {
          id: 'db/id',
          name: 'project/name',
          organization: 'project/organization',
          approver: 'project/approver',
          start: 'project/start',
          end: 'project/end',
          createdby: 'meta/created-by',
          createdat: 'meta/created-at',
          updatedat: 'meta/updated-at',
          status: 'project/status',
          task: 'project/tasks',
          ownerDetails: 'project/owner',
          updatedby: 'meta/updated-by',
        };

        data.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          var checkExist = list.find((f) => f.id === mappedData.id);
          if (!checkExist) {
            const nMappedData = {
              id: mappedData.id,
              name: mappedData.name,
              organization: mappedData.organization,
              approver: getApproverDetails(mappedData.approver),
              start: mappedData.start,
              end: mappedData.end,
              createdat: mappedData.createdat,
              updatedat: mappedData.updatedat,
              createdby: getName(mappedData.createdby),
              status: mappedData.status,
              task: mappedData.task,
              ownerDetails: getOwnerDetails(mappedData.ownerDetails),
              ownerName: getOwnerDetails(mappedData.ownerDetails).name,
              updatedby: getName(mappedData.updatedby),
            };
            list.push(nMappedData);
          }
        });
        response.data = list;
        dispatch(setProjectListALL({ projectListAll: response.data }));
      } else {
        dispatch(setProjectListALL({ projectListAll: [] }));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const createMeProject = async (value) => {
  try {
    const userContext = store.getState().userContext;
    let requestParamObj = {
      'project/start': {
        value: value.startDate,
        isKeyword: false,
      },
      'project/end': {
        value: value.endDate,
        isKeyword: false,
      },
      'project/name': {
        value: value.title,
        isKeyword: false,
      },
      'project/status': {
        value: ':' + value.status,
        isKeyword: false,
      },
      'project/owner': {
        value: userContext.userId,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postRequestProject,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const updateMeProject = async (value) => {
  try {
    const userContext = store.getState().userContext;
    let requestParamObj = {
      'db/id': {
        value: value.id,
        isKeyword: false,
      },
      'project/start': {
        value: value.startDate,
        isKeyword: false,
      },
      'project/end': {
        value: value.endDate,
        isKeyword: false,
      },
      'project/name': {
        value: value.title,
        isKeyword: false,
      },
      'project/status': {
        value: ':' + value.status,
        isKeyword: false,
      },
      'project/owner': {
        value: userContext.userId,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestProject,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const createProject = async (value) => {
  try {
    let requestParamObj = {
      'project/start': {
        value: value.startDate,
        isKeyword: false,
      },
      'project/end': {
        value: value.endDate,
        isKeyword: false,
      },
      'project/name': {
        value: value.title,
        isKeyword: false,
      },
      'project/status': {
        value: ':' + value.status,
        isKeyword: false,
      },
      'project/owner': {
        value: value.teams,
        isKeyword: false,
      },
      'project/approver': {
        value: value.approver,
        isKeyword: false,
      },
      employment: {
        value: value.employment,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postRequestProject,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const updateALLProject = async (value) => {
  try {
    let requestParamObj = {
      'db/id': {
        value: value.id,
        isKeyword: false,
      },
      'project/start': {
        value: value.startDate,
        isKeyword: false,
      },
      'project/end': {
        value: value.endDate,
        isKeyword: false,
      },
      'project/name': {
        value: value.title,
        isKeyword: false,
      },
      'project/status': {
        value: ':' + value.status,
        isKeyword: false,
      },
      'project/owner': {
        value: value.teams,
        isKeyword: false,
      },
      'project/approver': {
        value: value.approver,
        isKeyword: false,
      },
      employment: {
        value: value.employment,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putRequestProject,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const deleteProject = async (value, employmentId) => {
  try {
    let requestParamObj = {
      'db/id': {
        value: value.id,
        isKeyword: false,
      },
      'project/name': {
        value: value.name,
        isKeyword: false,
      },
      'project/owner': {
        value: value.ownerDetails.id,
        isKeyword: false,
      },
      employment: {
        value: employmentId,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      deleteRequestProject,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const getMeOrganization = (orgId) => {
  let requestParamObj = {
    id: orgId,
  };

  return async (dispatch) => {
    try {
      let response = await httpRequestHandler(
        meOrganizationRequest,
        requestParamObj,
      );
      let mappedResponseDataObj = {
        id: 'db/id',
        name: 'organization/name',
        handle: 'organization/handle',
        url: 'organization/url',
        betaFeatures: 'organization/beta-features',
        regNo: 'organization/reg-no',
        vatNo: 'organization/vat-no',
        country: 'organization/country',
        workDays: 'organization/work-week-days',
        vacationYearStart: 'organization/vacation-year-start',
        workWeekStart: 'organization/work-week-start',
        workHours: 'organization/work-day-hours',
        registrationNotificationDetails:
          'organization/registration-notification-details',
        owner: 'organization/owner',
        admins: 'admins',
      };
      if (response.result === 1) {
        let mappedData = getTransitDataCollection(
          response.data,
          mappedResponseDataObj,
        );
        mappedData.country = getCountry(mappedData.country);
        mappedData.owner = getOwners(mappedData.owner);
        mappedData.admins = getOwners(mappedData.admins);
        response.data = mappedData;
        dispatch(
          setOrganization({
            organization: mappedData,
          }),
        );
      } else {
        dispatch(setOrganization({ organization: null }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const putMeOganization = async (value) => {
  try {
    var vacationYearStart = moment(new Date(value.vacationYearStart)).format(
      dateFormats.mmdd,
    );
    let requestParamObj = {
      'db/id': {
        value: value.id,
        isKeyword: false,
      },
      'organization/handle': {
        value: value.handle,
        isKeyword: false,
      },
      'organization/name': {
        value: value.name,
        isKeyword: false,
      },
      'organization/url': {
        value: value.url,
        isKeyword: false,
      },
      'organization/beta-features': {
        value: value.betaFeatures,
        isKeyword: false,
      },
      'organization/reg-no': {
        value: value.regNo,
        isKeyword: false,
      },
      'organization/vat-no': {
        value: value.vatNo,
        isKeyword: false,
      },
      'organization/country': {
        value: 'country/' + value.country.code.toLowerCase(),
        isKeyword: true,
      },
      'organization/work-week-days': {
        value: value.workDays,
        isKeyword: false,
      },
      'organization/vacation-year-start': {
        value: vacationYearStart,
        isKeyword: false,
      },
      'organization/work-week-start': {
        value: value.workWeekStart,
        isKeyword: false,
      },
      'organization/work-day-hours': {
        value: value.workHours,
        isKeyword: false,
      },
      'organization/registration-notification-details': {
        value: value.registrationNotificationDetails
          ? value.registrationNotificationDetails
          : false,
        isKeyword: false,
      },
      adminsfields: {
        value: '',
        isKeyword: false,
      },
      ownersFields: {
        value: '',
        isKeyword: false,
      },
    };
    var adminsList = convertAdmins(value.admins);
    var ownersList = convertAdmins(value.owner);

    let mapObjToTransit = createTransitData(requestParamObj);
    mapObjToTransit = mapObjToTransit.replace(
      'adminsfields",""',
      `admins",[${adminsList}]`,
    );
    mapObjToTransit = mapObjToTransit.replace(
      'ownersFields",""',
      `organization/owner",[${ownersList}]`,
    );
    let registrationsData = await httpRequestHandler(
      putMeOrganizationRequest,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

const convertAdmins = (value) => {
  var result = [];
  value.forEach((f) => {
    let requestParamObj = {
      'db/id': {
        value: f.id,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    result.push(mapObjToTransit);
  });
  return result;
};

const postRequestProject = {
  url: 'me/projects',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const postTeam = {
  url: 'me/teams',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const postDeactivateTeam = {
  url: 'deactivate-team',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const postActivateTeam = {
  url: 'activate-team',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const postTeamMembers = {
  url: 'team/members',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const putTeam = {
  url: 'me/teams',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const putRequestProject = {
  url: 'me/projects',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const deleteRequestProject = {
  url: 'me/projects',
  method: 'DELETE',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getEmployeeTeams = (value) => {
  var result = [];
  let mapToObject = {
    id: 'db/id',
    name: 'team/name',
  };
  value.forEach((rowData) => {
    let mappedData = getTransitDataCollection(rowData, mapToObject);
    result.push(mappedData);
  });
  return result;
};

// const getTeamMetaData = (value) => {
//   let mapToObject = {
//     createdby: 'meta/created-by',
//     createdat: 'meta/created-at',
//     updatedat: 'meta/updated-at',
//   };
//   let mappedData = getTransitDataCollection(value, mapToObject);
//   var result = {
//     date: mappedData.date,
//     createdBy: getName(mappedData.createdBy),
//   };
//   return result;
// };

const getApproverDetails = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'db/id',
      firstName: 'user/firstname',
      lastName: 'user/lastname',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    result = {
      id: mappedData.id,
      name: `${mappedData.firstName ? mappedData.firstName : ''} ${
        mappedData.lastName ? mappedData.lastName : ''
      }`,
    };
  }
  return result;
};

const getName = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'id',
      name: 'name',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    result = mappedData;
  }

  return result;
};

const getOwnerDetails = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'db/id',
      team: 'team/name',
      organization: 'organization/name',
      firstName: 'user/firstname',
      lastName: 'user/lastname',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    var name = '';
    if (mappedData.team) name = mappedData.team;
    if (mappedData.organization) name = mappedData.organization;
    if (mappedData.firstName)
      name = `${mappedData.firstName} ${mappedData.lastName}`;
    result = {
      id: mappedData.id,
      name: name,
    };
  }

  return result;
};

export const getOrganizationTeamList = (employmentId) => {
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(getTeams, {
        employment: employmentId,
      });
      if (response.result === 1) {
        var data = response.data;
        let list = [];
        let mapToObject = {
          id: 'id',
          label: 'label',
          manager: 'manager',
          meta: 'meta',
          isActive: 'is-active',
        };

        data.forEach((leaveData, index) => {
          let mappedData = getTransitDataCollection(leaveData, mapToObject);
          // Transit data to object
          const buildManagerData = getTransitDataCollection(
            mappedData.manager,
            {
              fName: 'user/firstname',
              lName: 'user/lastname',
              status: 'user/status',
              status2: 'user.status/completed',
              id: 'db/id',
            },
          );
          const buildMetaData = getTransitDataCollection(mappedData.meta, {
            createdBy: 'meta/created-by',
            createdAt: 'meta/created-at',
            updatedAt: 'meta/updated-at',
            updatedBy: 'meta/updated-by',
          });

          const nMappedData = {
            id: mappedData.id,
            label: mappedData.label,
            manager: buildManagerData,
            managerName: `${buildManagerData.fName} ${buildManagerData.lName}`,
            createdBy: getName(buildMetaData.createdBy),
            createdAt: buildMetaData.createdAt,
            updatedAt: buildMetaData.updatedAt,
            updatedBy: getName(buildMetaData.updatedBy),
            isActive: mappedData.isActive,
          };

          mappedData.manager = buildManagerData;

          list.push(nMappedData);
        });
        list = _copyAndSort(list, 'label', false);

        response.data = list;
        dispatch(setTeamList(response.data));
      } else {
        dispatch(setTeamList([]));
      }
      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const createTeam = async (team) => {
  try {
    let requestParamObj = {
      'team/name': {
        value: team.name,
        isKeyword: false,
      },
      'team/manager': {
        value: team.manager,
        isKeyword: false,
      },
      'team/owner': {
        value: team.owner,
        isKeyword: false,
      },
      members: {
        value: team.members,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(postTeam, mapObjToTransit);

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const deactivateTeam = async (team) => {
  try {
    let requestParamObj = {
      'db/id': {
        value: team.id,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postDeactivateTeam,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const activateTeam = async (team) => {
  try {
    let requestParamObj = {
      'db/id': {
        value: team.id,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      postActivateTeam,
      mapObjToTransit,
    );

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const ManageTeamMembers = async (team, isAddingMembersFromNewTeam) => {
  try {
    let teamObj = team;
    if (isAddingMembersFromNewTeam) {
      const mappedTeamIdAndMeeweeSession = {
        id: 'id',
        meeweeSession: 'meewee-session',
      };
      const mappedDataTeamIdAndMeeweeSession = getTransitDataCollection(
        team.id,
        mappedTeamIdAndMeeweeSession,
      );
      teamObj = mappedDataTeamIdAndMeeweeSession;
    }

    let requestParamObj = {
      'db/id': {
        value: teamObj.id,
        isKeyword: false,
      },
      members: {
        value: team.members,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let response = await httpRequestHandler(postTeamMembers, mapObjToTransit);
    return response;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

export const updateAllTeam = async (team) => {
  try {
    let requestParamObj = {
      'db/id': {
        value: team.id,
        isKeyword: false,
      },
      'team/name': {
        value: team.name,
        isKeyword: false,
      },
      'team/manager': {
        value: team.manager,
        isKeyword: false,
      },
      'team/owner': {
        value: team.owner,
        isKeyword: false,
      },
      members: {
        value: team.members,
        isKeyword: false,
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(putTeam, mapObjToTransit);

    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

const sendTeamInvitationInfo = {
  url: 'send-invitation',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const validateCredentialsInfo = {
  url: 'signup/validate',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getListOfActiveMembersInfo = {
  url: 'organization/members',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
};

const getListOfPendingRequestsInfo = {
  url: 'leave-team/requests',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
};

const reviewLeaveRequestInfo = {
  url: 'review-leave-team',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  hasAuth: true,
};

const removeMemberInfo = {
  url: 'remove-3rd-party',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  hasAuth: true,
};

const getListOfPendingInvitationsInfo = {
  url: 'me/team-invitations',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
};

const cancelTeamInvitationInfo = {
  url: 'cancel-invitation',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  hasAuth: true,
};

const getTeams = {
  url: 'me/teams',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const getTeamMembers = {
  url: 'team/members',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};
const getMeProject = {
  url: 'me/projects',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const getProject = {
  url: 'projects',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const getUsersApprover = {
  url: 'users-approver',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
};

const meOrganizationRequest = {
  url: 'me/organization',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
};

const getOwners = (value) => {
  var result = [];
  if (value) {
    value.forEach((f) => {
      result.push(getOwner(f));
    });
  }

  return result;
};

const getOwner = (value) => {
  let mapToObject = {
    id: 'db/id',
    firstName: 'user/firstname',
    lastName: 'user/lastname',
    createdDate: 'db/txInstant',
    createdBy: 'source/user',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  mappedData = {
    ...mappedData,
    name: `${mappedData.firstName} ${mappedData.lastName}`,
  };
  mappedData.createdBy =
    mappedData.createdBy && getOwnerSource(mappedData.createdBy)?.name;
  return mappedData;
};

const getOwnerSource = (value) => {
  let mapToObject = {
    firstName: 'user/firstname',
    lastName: 'user/lastname',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  mappedData = {
    ...mappedData,
    name: `${mappedData.firstName} ${mappedData.lastName}`,
  };
  return mappedData;
};

const getCountry = (value) => {
  let mapToObject = {
    ident: 'db/ident',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);

  return mappedData;
};

const putMeOrganizationRequest = {
  url: 'me/organization',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const leaveTeamInfo = {
  url: 'leave-team',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getRoles = (value) => {
  let result = [];
  value?.keySet()?.forEach((key) => {
    switch (key.name()) {
      case 'orgadmin':
        result.push(userRoles.orgAdmin);
        break;
      case 'manager':
        result.push(userRoles.teamManager);
        break;
      case 'employee':
        result.push(userRoles.employee);
        break;
      case 'user':
        result.push(userRoles.user);
        break;
      default:
        break;
    }
  });

  return result;
};
