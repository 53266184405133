import React, { useEffect, useState, useRef, useCallback } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { format } from 'date-fns';
import classes from './TaskAndRegistrationRowGrid.module.scss';
import TotalCell from '../../totalCell/TotalCell';
import { dateFormats } from '../../../../../../../../../utilities/dateUtils';
import TaskMenu from './taskMenu/TaskMenu';
import TaskPicker from '../../../../../../timeRegistrationPickers/taskPicker/TaskPicker';
import DurationCellView from './durationCellView/DurationCellView';
import DurationCellEdit from './durationCellEdit/DurationCellEdit';
import { getTotalRegistrationPerTask } from '../../../../../../../service/timeregistrationService';
import { isWeekend } from 'date-fns';
import { LeaveStatusType } from '../../../../../../../../../utilities/constantUtils';
import { DataGrid as MuiDataGrid, GridCellModes } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
    overflow: 'visible',
  },

  '&.MuiDataGrid-row': {
    maxHeight: 'fit-content !important',
  },

  '&.MuiDataGrid-root.MuiDataGrid-autoHeight': { border: 'none!important' },
  '& .MuiDataGrid-cell': {
    borderLeft: '1px solid #E3E3E3',
    padding: '16px 4px',
    maxHeight: 'unset',
  },
  '& .MuiDataGrid-cell--editing': {
    display: 'block !important',
  },
  '& .MuiDataGrid-cell:nth-of-type(9)': {
    borderRight: '1px solid #E3E3E3',
  },
  '& .MuiDataGrid-row:hover .MuiDataGrid-cell:first-of-type > div > div > svg':
    {
      visibility: 'visible !important',
      cursor: 'pointer',
    },
  '& .weekend_column': {
    backgroundColor: 'rgba(230, 232, 232, 0.4)',
  },
  '& .total_column_text': {
    justifyContent: 'center',
  },
  '& .MuiDataGrid-cell:first-of-type': {
    padding: '16px',
    maxHeight: 'fit-content !important',

    [theme.breakpoints.down('xl')]: {
      minWidth: '250px !important',
      maxWidth: '250px !important',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: '200px !important',
      maxWidth: '200px !important',
    },
  },
}));

const TaskAndRegistrationRowGrid = ({
  selectedCell,
  rowIndex,
  projectTask,
  projectTasks,
  dateList,
  selectedOrg,
  showSuccessSnackBarFn,
  showErrorSnackBarFn,
  anchorElHandler,
  disableSubmitHandler,
  cellDetailHandler,
  cbSetSelectedTaskTitle,
  registrationHandler,
  cbAddEditTimeSingleRegistration,
}) => {
  const startDate = format(dateList?.[0], dateFormats.short_date);
  const endDate = format(dateList?.[6], dateFormats.short_date);
  const [isHovered, setIsHovered] = useState(false);
  const [isUserTryingToEditCell, setIsUserTryingToEditCell] = useState(false);
  const selectedBorderStyle = '1px solid #66E0F0';

  useEffect(() => {
    setIsUserTryingToEditCell(false);
  }, [selectedCell]);

  const TaskComponent = (task) => {
    const isPlaceHolderForAddTask = task?.taskId === -1;
    return isPlaceHolderForAddTask ? (
      <></>
    ) : (
      <TaskMenu
        task={task}
        startDate={startDate}
        endDate={endDate}
        isRowHovered={isHovered}
        showSuccessSnackBarFn={showSuccessSnackBarFn}
        showErrorSnackBarFn={showErrorSnackBarFn}
      />
    );
  };

  const anchorEl = useRef(false);
  const anchorElHandlerGrid = (e) => {
    anchorEl.current = true;
    anchorElHandler(e);
  };

  const generateColumn = () => {
    let columns = [];
    const taskColumn = {
      field: 'taskName1',
      resizable: false,
      sortable: false,
      width: 400,
      minWidth: 400,
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return TaskComponent(params.row);
      },
    };
    columns.push(taskColumn);

    dateList?.map((date, dateIndex) => {
      const formattedDate = format(date, dateFormats.default);
      let RegistrationContainer = null;
      const registration = projectTask?.taskRegistrations[formattedDate]?.[0];
      const registrationStatus = registration?.regStatus;
      const isCellSelected =
        selectedCell.row === rowIndex && selectedCell.col === dateIndex;
      const isCellEditable =
        registrationStatus !== LeaveStatusType.pending &&
        registrationStatus !== LeaveStatusType.approved &&
        registrationStatus !== LeaveStatusType.declined &&
        projectTask?.taskId !== -1;
      const isSetToDurationCellEdit =
        isCellSelected && isUserTryingToEditCell && isCellEditable;

      const regCellStyle = {
        border: isCellSelected && selectedBorderStyle,
        backgroundColor: isSetToDurationCellEdit
          ? '#FFFFFF'
          : isWeekend(date) && '#E6E8E866',
        marginTop: '1px',
        marginBottom: '1px',
      };

      let dateColumn = {
        field: 'date' + _ + moment(date).format(dateFormats.moment_MMddyyyy),
        resizable: false,
        sortable: false,
        flex: 0.5,
        minWidth: 90,
        editable: true,
        renderCell: (params) => {
          if (params.row?.taskId !== -1) {
            RegistrationContainer = isSetToDurationCellEdit ? (
              <></>
            ) : (
              <DurationCellView
                cbsetIsUserTryingToEditCell={setIsUserTryingToEditCell}
                projectTask={params.row}
                regDate={formattedDate}
                anchorElHandler={anchorElHandlerGrid}
                disAbleSubmitHandler={disableSubmitHandler}
                cellDetailHandler={cellDetailHandler}
                cbSetSelectedTaskTitle={cbSetSelectedTaskTitle}
                registrationHandler={registrationHandler}
              />
            );
          } else {
            RegistrationContainer = <div className={classes.cellPlaceHolder} />;
          }
          return (
            <div className={classes.weekEnd} style={regCellStyle}>
              {RegistrationContainer}
            </div>
          );
        },
        renderEditCell: (params) => {
          return (
            <div className={classes.weekEnd} style={regCellStyle}>
              {
                <DurationCellEdit
                  cbsetIsUserTryingToEditCell={setIsUserTryingToEditCell}
                  projectTask={params.row}
                  regDate={formattedDate}
                  showSuccessSnackBarFn={showSuccessSnackBarFn}
                  showErrorSnackBarFn={showErrorSnackBarFn}
                  selectedOrg={selectedOrg}
                  cbAddEditTimeSingleRegistration={
                    cbAddEditTimeSingleRegistration
                  }
                />
              }
            </div>
          );
        },
      };

      columns.push(dateColumn);
    });

    const totalCell = {
      field: 'total',
      resizable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 90,
      align: 'center',

      renderCell: (params) => {
        return (
          params.row?.taskId !== -1 && (
            <TotalCell
              color="text.secondary"
              sumOfReg={getTotalRegistrationPerTask(
                params.row?.taskRegistrations,
              )}
            />
          )
        );
      },
    };
    columns.push(totalCell);

    return columns;
  };
  const columns = generateColumn();

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = React.useCallback((params) => {
    if (
      params.field !== 'total' &&
      params.field !== 'taskName1' &&
      !anchorEl.current
    ) {
      if (isEnabledCell(params.field, params.row?.taskRegistrations)) {
        setCellModesModel((prevModel) => {
          return {
            // Revert the mode of the other cells from other rows
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {},
                ),
              }),
              {},
            ),
            [params.id]: {
              // Revert the mode of other cells in the same row
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      }
    }
    anchorEl.current = false;
  }, []);

  const onCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const isEnabledCell = (field, taskRegistrations) => {
    try {
      const nField = field.replace('date', '');
      const regDay = taskRegistrations[nField];
      if (regDay) {
        const regDayApproved = regDay.find(
          (reg) => reg.regStatus === 'approved',
        );
        if (regDayApproved) {
          return false;
        }
      }
      return true;
    } catch {
      return false;
    }
  };

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const onCellKeyDownHandler = (params, event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      setCellModesModel((prevModel) => ({
        ...prevModel,
        [params.id]: {
          ...prevModel[params.id],
          [params.field]: { mode: 'view' },
        },
      }));
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      if (!isEnabledCell(params.field, params.row?.taskRegistrations)) {
        event.defaultMuiPrevented = true;
      }
    }
  };

  return (
    <>
      {projectTasks.filter((task) => task.taskId > 0).length > 0 && (
        <TableRow className={classes.tablePadding}>
          <TableCell
            colSpan={9}
            sx={{ width: '100%' }}
            className={`${classes.tablePadding} dataGridTimesheet`}
          >
            <DataGrid
              disableSelectionOnClick
              disableColumnMenu
              rows={projectTasks.filter((task) => task.taskId > 0)}
              columns={columns}
              getRowId={(row) => row.taskId}
              hideFooter
              autoHeight
              onCellClick={handleCellClick}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellKeyDown={onCellKeyDownHandler}
              onCellDoubleClick={onCellDoubleClick}
            />
          </TableCell>
        </TableRow>
      )}
      <TableRow className={classes.tableBorder}>
        <TableCell
          colSpan={9}
          className={classes.addTask}
          sx={{ width: '100%' }}
        >
          <TaskPicker
            org={selectedOrg}
            projectId={
              projectTasks.find((task) => task.taskId <= -1)?.taskProjectId
            }
            showSuccessSnackBarFn={showSuccessSnackBarFn}
            showErrorSnackBarFn={showErrorSnackBarFn}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TaskAndRegistrationRowGrid;
