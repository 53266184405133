import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import classes from './SideNavBar.module.scss';
import meeweelogo from '../../../assets/meewee-main-logo.svg';
import SideNavBarListItems from './sidenavbarListItems/SideNavBarListItems';
import FormButton from '../../ui/formcontrols/formButton/FormButton';
import { getRolesAccess } from '../../../utilities/roleUtils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const drawerWidth = 226;

const openedMixin = (theme) => ({
  backgroundColor: '#04181C',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  backgroundColor: '#04181C',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideNavBar = (props) => {
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState(null);
  const userOrgList = useSelector(
    (state) => state.userContext.userOrganizations,
  );
  const userId = useSelector((state) => state.userContext.userId);
  const userLastName = useSelector((state) => state.userContext.userLastName);
  const userFirstName = useSelector((state) => state.userContext.userFirstName);

  // useEffect(() => {
  //   debugger;
  //   if (userOrgList.length > 0) {
  //     getRoleList();
  //   }
  // }, [userOrgList]);

  useEffect(() => {
    var newList = [];
    var orgItem = {
      id: userId,
      emp: userId,
      name: `${userFirstName} ${userLastName}`,
      organizations: { id: userId, name: `${userFirstName} ${userLastName}` },
      owner: { id: userId, fname: userFirstName, lname: userLastName },
      userAuth: ['Personal'],
    };
    newList.push(orgItem);
    if (userOrgList) {
      newList = newList.concat(userOrgList);
    }
    getRoleList(newList);
  }, [userOrgList]);

  const getRoleList = (orgList) => {
    var roles = [];
    orgList.forEach((element) => {
      roles = roles.concat(element.userAuth);
    });

    var temp = getRolesAccess(roles);
    setRoleList(temp);
  };

  const { window } = props;

  const onDrawerToggleHandler = () => {
    props.onDrawerToggle();
  };

  const handleDrawerToggle = () => {
    props.onMobileDrawerToggle();
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const mobileDrawer = (
    <React.Fragment>
      <List
        sx={{
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgb(255,255,255, 0.06)',
          },
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#132629',
          },
          ' .Mui-selected > div path': {
            fill: '#00B8D4',
          },
          '.Mui-selected > div, .Mui-selected > div > p': {
            color: 'primary.main',
          },
        }}
        className={classes.list_item_container}
      >
        <SideNavBarListItems
          roleList={roleList}
          handleDrawerToggle={handleDrawerToggle}
          open={props.mobileOpen}
        />
      </List>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <div className={classes.drawer_header}>
        <img src={meeweelogo} alt="meewee logo"></img>
        <Typography variant="text" sx={{ opacity: props.open ? 1 : 0 }}>
          meewee
        </Typography>
        <Typography
          variant="caption"
          className={classes.drawer_header_subcontent}
          sx={{ opacity: props.open ? 1 : 0 }}
        >
          PRO
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <List
        sx={{
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgb(255,255,255, 0.06)',
          },
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#132629',
          },
          ' .Mui-selected > div path': {
            fill: '#00B8D4',
          },
          '.Mui-selected > div, .Mui-selected > div > p': {
            color: 'primary.main',
            fontFamily: 'Inter Semibold',
          },
        }}
        className={classes.list_item_container}
      >
        <SideNavBarListItems open={props.open} roleList={roleList} />
      </List>
      <div className={classes.drawer_footer}>
        <Divider className={classes.divider} />
        <div className={`${classes.arrow} ${props.open && classes.collapse}`}>
          <IconButton onClick={onDrawerToggleHandler}>
            {props.open ? (
              <>
                <Typography component="div" variant="caption">
                  {t('dashboard.collapse')}
                </Typography>
                <ExitToAppIcon className={classes.collapse} />
              </>
            ) : (
              <ExitToAppIcon />
            )}
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Drawer
        className={`${classes.mobile_drawer} mobile_bg`}
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {mobileDrawer}
      </Drawer>
      <div className={classes.desktop_drawer}>
        <DesktopDrawer
          variant="permanent"
          open={props.open}
          className={classes.drawer_container}
        >
          {drawer}
        </DesktopDrawer>
      </div>
    </React.Fragment>
  );
};

export default SideNavBar;
