import React, { useState, useRef, useEffect } from 'react';
import classes from './OrgInfo.module.scss';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import countryList from 'country-list';
import { getMeOrganization } from '../../../service/orgmanagementService';
import { Outlet, useOutletContext } from 'react-router-dom';

const OrgInfo = () => {
  const { selectedOrg } = useOutletContext();
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState('info');
  const successSnackbarRef = useRef(null);
  const organization = useSelector((state) => state.orgContext.organization);
  const [country, setCountry] = useState([]);
  const [updateOrganization, setUpdatedOrganization] = useState(null);

  const showSuccessSnackBar = (message, value) => {
    dispatch(getMeOrganization(organization.id));
    successSnackbarRef.current.snackBarContentHandler(message);
    setUpdatedOrganization(value);
  };

  useEffect(() => {
    if (organization) {
      var countryValue = countryList.getData();
      setCountry(countryValue);
      var newValue = assignValue(
        countryValue,
        organization,
        organization.country.ident,
      );

      setUpdatedOrganization(newValue);
    }
  }, [organization]);

  const assignValue = (countryValue, item, countryCode) => {
    var country = countryValue.find(
      (f) => f.code.toLowerCase() === countryCode.toLowerCase(),
    );
    var year = moment(new Date()).format('YYYY');
    var target = {};
    var newValue = Object.assign(target, item);
    newValue.registrationNotificationDetails =
      item.registrationNotificationDetails
        ? item.registrationNotificationDetails
        : false;
    newValue.vacationYearStart = `${item.vacationYearStart}/${year}`;
    newValue.country = country;
    newValue.betaFeatures = item.betaFeatures ? item.betaFeatures : false;
    newValue.workDays = [...new Set(newValue.workDays)];
    return newValue;
  };

  return (
    <div className={classes.fullwidth}>
      <Outlet
        context={{
          selectedOrg,
          organization: updateOrganization,
          cbShowSuccessSnackBar: showSuccessSnackBar,
          countryList: country,
        }}
      />
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>
    </div>
  );
};

export default OrgInfo;
