import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';
import { bulkDeleteRegistrationsService } from '../../../../../../../../service/timeregistrationService';
import { bulkDeleteRegistration } from '../../../../../../../../../../store/registrationContextSlice';
import ConfirmationDialogBox from '../../../../../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import classes from './TaskMenu.module.scss';
import {
  failedRegDeletionMessage,
  successRegDeletionMessage,
} from '../../../../../../../../../../utilities/contentMessagesUtils';
import { LeaveStatusType } from '../../../../../../../../../../utilities/constantUtils';
import Tooltip from '@mui/material/Tooltip';
import {
  ConfirmationDialogContent,
  ConfirmationDialogContentTitle,
} from './confirmationDialogContentAndTitle/ConfirmationDialogContentAndTitle';

const TaskMenu = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const [confirmationBoxInfo, setConfirmationBoxInfo] = useState({
    open: false,
    taskHasReg: false,
    buildTaskObj: {},
    selectedTasks: [],
    closeLabelText: 'CANCEL',
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialogBox = () => {
    setConfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      taskHasReg: false,
      buildTaskObj: {},
      selectedTasks: [],
      closeLabelText: 'CANCEL',
    }));
  };

  const confirmRemovalHandler = () => {
    bulkDeleteRegistrationsService(confirmationBoxInfo.selectedTasks).then(
      (data) => {
        if (data.result === 1) {
          handleCloseDialogBox();
          handleClose();
          dispatch(
            bulkDeleteRegistration({
              registrations: confirmationBoxInfo.selectedTasks,
              type: 'task',
            }),
          );
          props.showSuccessSnackBarFn(successRegDeletionMessage);
        } else {
          handleCloseDialogBox();
          props.showErrorSnackBarFn(failedRegDeletionMessage);
        }
      },
    );
  };

  const deleteBulkRegistration = (regListObject) => {
    dispatch(
      bulkDeleteRegistration({ registrations: regListObject, type: 'task' }),
    );
  };

  const shouldHideButton = !Boolean(anchorEl) && !props?.isRowHovered;
  return (
    <div className={`${classes.task_container}`}>
      <Tooltip title={props.task.taskTitle} arrow>
        <span className={`${classes.task_title} taskTitle`}>
          {props.task.taskTitle}
        </span>
      </Tooltip>
      <div className={classes.task_menu_button_container}>
        <MoreVertIcon
          className={`${
            shouldHideButton
              ? classes.task_menu_button_hidden
              : classes.task_menu_button_show
          }`}
          aria-label="task menu"
          aria-controls={Boolean(anchorEl) ? 'task-menu' : undefined}
          aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem
          key={1}
          onClick={() =>
            bulkRemoveRegistrations(
              props.task,
              setConfirmationBoxInfo,
              deleteBulkRegistration,
            )
          }
        >
          <ClearIcon />
          Remove
        </MenuItem>
        <ConfirmationDialogBox
          open={confirmationBoxInfo.open}
          title={ConfirmationDialogContentTitle(
            confirmationBoxInfo.taskHasReg,
            props.task,
            props.startDate,
            props.endDate,
          )}
          content={ConfirmationDialogContent(
            confirmationBoxInfo.buildTaskObj,
            confirmationBoxInfo.taskHasReg,
          )}
          handleClose={handleCloseDialogBox}
          handleAction={confirmRemovalHandler}
          closeLabelText={confirmationBoxInfo.closeLabelText}
          actionLabelText="DELETE"
          showActionButton={confirmationBoxInfo.taskHasReg}
        />
      </Menu>
    </div>
  );
};

export default TaskMenu;

const bulkRemoveRegistrations = (
  task,
  setConfirmationBoxInfo,
  deleteBulkRegistration,
) => {
  //get draft and pending registrations
  const regListObject = [];
  const regList = task?.taskRegistrations;
  const buildTaskObj = {
    taskId: task?.taskId,
    taskProjectId: task?.taskProjectId,
    taskTitle: task?.taskTitle,
    taskReg: {},
  };
  if (regList && Object.keys(regList)?.length > 0) {
    for (const [date, registration] of Object.entries(regList)) {
      if (registration.length > 0) {
        const filteredReg = registration.filter(
          (reg) =>
            reg?.regStatus === LeaveStatusType?.draft ||
            reg?.regStatus === LeaveStatusType?.pending ||
            reg?.regStatus === LeaveStatusType?.declined,
        );
        if (filteredReg.length > 0) {
          buildTaskObj.taskReg[date] = filteredReg;
        }
      }
    }
    const taskHasReg = Object.keys(buildTaskObj.taskReg).length > 0;
    regListObject.push(buildTaskObj);
    setConfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      taskHasReg: taskHasReg,
      buildTaskObj: buildTaskObj,
      selectedTasks: regListObject,
      closeLabelText: taskHasReg ? 'CANCEL' : 'OK, GOT IT',
    }));
  } else {
    //SCENARIO: user adds a task then clicks for bulk remove
    //SCENARIO: delete registration in a task individually then user clicks for bulk remove
    regListObject.push(buildTaskObj);
    deleteBulkRegistration(regListObject);
  }
};
