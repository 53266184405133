import classes from './PrivacyAccountTab.module.scss';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PasswordValidation = ({
  passwordValidationType,
  textPasswordValidations,
}) => {
  return (
    <li className={passwordValidationType ? classes.validated : null}>
      {passwordValidationType ? (
        <CheckIcon className={classes.iconGreen} />
      ) : (
        <CloseIcon className={classes.iconRed} />
      )}
      {textPasswordValidations}
    </li>
  );
};

export default PasswordValidation;
