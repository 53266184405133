export const saveStateToLocalStorage = (stateObject, state) => {
  try {
    localStorage.setItem(stateObject, JSON.stringify(state));
  } catch (error) {
    console.log(error);
  }
};

export const getItemFromLocalStorage = (itemObject) => {
  try {
    const item = localStorage.getItem(itemObject);
    return item;
  } catch (error) {
    console.log(error);
  }
};

export const removeItemsFromLocalStorage = (itemOject) => {
  try {
    itemOject.forEach((item) => {
      localStorage.removeItem(item);
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeAllItemsFromLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log(error);
  }
};
