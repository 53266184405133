import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teamRegistrations: [],
  approvedRegistrations: [],
};

const teamTimesheetContextSlice = createSlice({
  name: 'teamTimesheetContext',
  initialState: initialState,
  reducers: {
    setTeamRegistrations: (state, action) => {
      state.teamRegistrations = action.payload.teamRegistrations;
    },
    setApprovedRegistrations: (state, action) => {
      state.approvedRegistrations = action.payload.approvedRegistrations;
    },
  },
});

export const { setTeamRegistrations, setApprovedRegistrations } =
  teamTimesheetContextSlice.actions;

export default teamTimesheetContextSlice.reducer;
