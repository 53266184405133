import { createSlice, current } from '@reduxjs/toolkit';
import { addProjectTask } from './projectContextSlice';

const initialState = {
  registrations: [],
  totalRegHoursPerDate: {},
};

const registrationContextSlice = createSlice({
  name: 'registrationContext',
  initialState: initialState,
  reducers: {
    setRegistration: (state, action) => {
      var newList = [];
      action.payload.registrations.forEach((f) => {
        var checkExist = state.registrations.find(
          (ff) => f.projectId === ff.projectId,
        );
        if (checkExist === undefined) {
          newList.push(f);
        }
      });

      var temp = [...state.registrations];
      state.registrations = temp.concat(newList);
    },
    setEmptyRegistration: (state, action) => {
      state.registrations = [];
    },
    setTotalRegHoursPerDate: (state, action) => {
      const { date, totalHours } = action.payload;

      state.totalRegHoursPerDate = {
        ...state.totalRegHoursPerDate,
        ...{ [`${date}`]: totalHours },
      };
    },
    editRegistration: (state, action) => {
      //find proj Index
      let projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      );

      let proj = state.registrations.filter(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let copyTask = Object.assign([], copyProj.projectTasks);

      //find task
      let registrationTask = copyTask.find(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      let taskIndex = copyTask.findIndex(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      //copy Registration
      //right now we assume that although the value of the key is an array,
      // it would always have one registration on a single date so we only get item on the first index
      let copyRegistration = Object.assign(
        {},
        registrationTask.taskRegistrations[action.payload.rowDetail.regDate][0],
      );

      copyRegistration.regDesc = action.payload.rowDetail.taskReg.regDesc;
      copyRegistration.regStatus = action.payload.rowDetail.taskReg.regStatus;
      copyRegistration.regDuration =
        action.payload.rowDetail.taskReg.regDuration;
      copyRegistration.regUpdatedBy =
        action.payload.rowDetail.taskReg.regUpdatedBy;
      copyRegistration.regCreatedBy =
        action.payload.rowDetail.taskReg.regCreatedBy;

      registrationTask.taskRegistrations[action.payload.rowDetail.regDate][0] =
        copyRegistration;
      copyTask[taskIndex] = registrationTask;

      copyProj = { ...copyProj, projectTasks: copyTask };

      state.registrations[projIndex] = copyProj;
    },
    addRegistration: (state, action) => {
      //find proj Index
      let projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      );

      let proj = state.registrations.filter(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let copyTask = Object.assign([], copyProj.projectTasks);

      //find task
      let registrationTask = copyTask.find(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      let taskIndex = copyTask.findIndex(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      //copy Registration
      let copyRegistration = Object.assign(
        {},
        registrationTask.taskRegistrations,
      );

      copyRegistration[action.payload.rowDetail.regDate] = [];
      copyRegistration[action.payload.rowDetail.regDate].push(
        action.payload.rowDetail.taskReg,
      );

      registrationTask.taskRegistrations = copyRegistration;
      copyTask[taskIndex] = registrationTask;

      copyProj = { ...copyProj, projectTasks: copyTask };

      state.registrations[projIndex] = copyProj;
    },
    deleteRegistration: (state, action) => {
      //find proj Index
      let projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      );

      let proj = state.registrations.filter(
        (proj) => proj.projectId == action.payload.rowDetail.taskProjectId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let copyTask = Object.assign([], copyProj.projectTasks);

      //find task
      let registrationTask = copyTask.find(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      let taskIndex = copyTask.findIndex(
        (task) => task.taskId == action.payload.rowDetail.taskId,
      );

      //copy Registration
      let copyRegistration = Object.assign(
        {},
        registrationTask.taskRegistrations,
      );

      // right now we assume that although the value of the key is an array,
      // it would always have one registration on a single date which means we can just remove the date object
      delete copyRegistration[action.payload.rowDetail.regDate];

      registrationTask.taskRegistrations = copyRegistration;
      copyTask[taskIndex] = registrationTask;

      copyProj = { ...copyProj, projectTasks: copyTask };

      state.registrations[projIndex] = copyProj;
    },
    addProject: (state, action) => {
      state.registrations.push(action.payload.project);
    },
    addTask: (state, action) => {
      const projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.taskProjId,
      );

      let proj = state.registrations.filter(
        (proj) => proj.projectId == action.payload.taskProjId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let taskCopy = Object.assign([], copyProj.projectTasks);

      //check first if task already exists on the registration
      let isTaskExisting =
        taskCopy.findIndex(
          (task) => task.taskId === action.payload.newTask.taskId,
        ) !== -1;

      if (!isTaskExisting) {
        let taskInsertIndex = taskCopy.length - 1;
        taskCopy.splice(taskInsertIndex, 0, action.payload.newTask);
        copyProj.projectTasks = taskCopy;

        state.registrations[projIndex] = copyProj;
      }
    },
    bulkEditRegistration: (state, action) => {
      action.payload.registrations.forEach((reg) => {
        //find projIndex
        let projIndex = state.registrations.findIndex(
          (proj) => proj.projectId == reg.taskProjectId,
        );

        let proj = state.registrations.filter(
          (proj) => proj.projectId == reg.taskProjectId,
        )[0];

        let copyProj = Object.assign({}, proj);
        let copyTask = Object.assign([], copyProj.projectTasks);

        //find task
        let registrationTask = copyTask.find(
          (task) => task.taskId == reg.taskId,
        );

        let taskIndex = copyTask.findIndex((task) => task.taskId == reg.taskId);

        //copy Registration
        let copyRegistration = Object.assign(
          {},
          registrationTask.taskRegistrations,
        );

        //iterate thru payload registrations taskReg
        for (const [date, value] of Object.entries(reg.taskReg)) {
          //right now we assume that although the value is an array,
          // it would always have one registration on a single date which means we can just re-assign the value
          copyRegistration[date] = value;
          copyRegistration[date][0].regUpdatedBy =
            action.payload.regLastModifiedDate;
        }

        registrationTask.taskRegistrations = copyRegistration;
        copyTask[taskIndex] = registrationTask;

        copyProj = { ...copyProj, projectTasks: copyTask };

        state.registrations[projIndex] = copyProj;
      });
    },
    bulkDeleteRegistration: (state, action) => {
      action.payload.registrations.forEach((reg) => {
        //find proj Index
        let projIndex = state.registrations.findIndex(
          (proj) => proj.projectId == reg.taskProjectId,
        );

        let proj = state.registrations.filter(
          (proj) => proj.projectId == reg.taskProjectId,
        )[0];

        let copyProj = Object.assign({}, proj);
        let copyTask = Object.assign([], copyProj.projectTasks);

        //find task
        let registrationTask = copyTask.find(
          (task) => task.taskId == reg.taskId,
        );

        let taskIndex = copyTask.findIndex((task) => task.taskId == reg.taskId);

        //copy Registration
        let copyRegistration = Object.assign(
          {},
          registrationTask.taskRegistrations,
        );

        //iterate thru payload registrations taskReg
        for (const [date, value] of Object.entries(reg.taskReg)) {
          //right now we assume that although the value is an array,
          // it would always have one registration on a single date which means we can just remove the date object
          delete copyRegistration[date];
        }

        if (Object.keys(copyRegistration).length > 0) {
          registrationTask.taskRegistrations = copyRegistration;
          copyTask[taskIndex] = registrationTask;
        } else {
          copyTask.splice(taskIndex, 1);
        }

        copyProj = { ...copyProj, projectTasks: copyTask };

        if (
          copyProj.projectTasks.length == 1 &&
          action.payload.type === 'project'
        ) {
          state.registrations.splice(projIndex, 1);
        } else {
          state.registrations[projIndex] = copyProj;
        }
      });
    },
    removeProject: (state, action) => {
      let projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.projectId,
      );
      state.registrations.splice(projIndex, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProjectTask, (state, action) => {
      const projIndex = state.registrations.findIndex(
        (proj) => proj.projectId == action.payload.taskProjId,
      );

      let proj = state.registrations.filter(
        (proj) => proj.projectId == action.payload.taskProjId,
      )[0];

      let copyProj = Object.assign({}, proj);
      let taskCopy = Object.assign([], copyProj.projectTasks);

      //check first if task already exists on the registration
      let isTaskExisting =
        taskCopy.findIndex(
          (task) => task.taskId === action.payload.newTask.taskId,
        ) !== -1;

      if (!isTaskExisting) {
        let taskInsertIndex = taskCopy.length - 1;
        taskCopy.splice(taskInsertIndex, 0, action.payload.newTask);
        copyProj.projectTasks = taskCopy;

        state.registrations[projIndex] = copyProj;
      }
    });
  },
});

export const {
  setTotalRegHoursPerDate,
  setRegistration,
  editRegistration,
  addRegistration,
  addProject,
  addTask,
  deleteRegistration,
  bulkEditRegistration,
  bulkDeleteRegistration,
  removeProject,
  setEmptyRegistration,
} = registrationContextSlice.actions;

export default registrationContextSlice.reducer;
