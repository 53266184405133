import { createTheme } from '@mui/material/styles';

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;
const fontWeightBold = 700;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 640,
      lg: 1024,
      xl: 1366,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontWeightLight: fontWeightLight,
    fontWeightRegular: fontWeightRegular,
    fontWeightMedium: fontWeightMedium,
    fontWeightBold: fontWeightBold,
    fontWeightSemiBold: fontWeightSemiBold,
    h1: {
      fontSize: 96,
      fontWeight: fontWeightLight,
    },
    h2: {
      fontSize: 60,
      fontWeight: fontWeightLight,
    },
    h3: {
      fontSize: 48,
      fontWeight: fontWeightRegular,
    },
    h4: {
      fontSize: 34,
      fontWeight: fontWeightRegular,
    },
    h5: {
      fontSize: 24,
      fontWeight: fontWeightRegular,
    },
    h6: {
      fontSize: 20,
      fontWeight: fontWeightMedium,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: fontWeightRegular,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: fontWeightMedium,
    },
    body1: {
      fontSize: 16,
      fontWeight: fontWeightRegular,
    },
    body2: {
      fontSize: 14,
      fontWeight: fontWeightRegular,
    },
    caption: {
      fontSize: 12,
      fontWeight: fontWeightRegular,
    },
    overline: {
      fontSize: 12,
      fontWeight: fontWeightBold,
    },
    buttonsmall: {
      fontWeight: fontWeightMedium,
      fontSize: 13,
      fontFamily: ['Inter', 'sans-serif'].join(','),
    },
    buttonmedium: {
      fontWeight: fontWeightMedium,
      fontSize: 14,
      fontFamily: ['Inter', 'sans-serif'].join(','),
    },
    buttonlarge: {
      fontWeight: fontWeightMedium,
      fontSize: 15,
      fontFamily: ['Inter', 'sans-serif'].join(','),
    },
    heading: {
      fontFamily: 'Causten Bold',
      fontSize: 32,
    },
    text: {
      fontFamily: 'Causten Bold',
      fontSize: 20,
    },
    text1: {
      fontSize: 20,
      fontWeight: fontWeightRegular,
      fontFamily: ['Inter', 'sans-serif'].join(','),
    },
    semiboldfont: {
      fontFamily: 'Inter Semibold',
    },
  },
  palette: {
    primary: {
      main: '#00B8D4',
      light: '#62EBFF',
      dark: '#0088A3',
      background: '#EBF9FC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#072E36',
      light: '#34575F',
      dark: '#00040F',
      background: '#ECEFEF',
      contrastText: '#fff',
    },
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#9E9E9E',
      success: '#59C65D',
      pending: '#0091EA',
    },
    error: {
      main: '#F44336',
      light: '#FF7961',
      dark: '#B71C1C',
      background: '#FDECEB',
      content: '#621B16',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFCA28',
      dark: '#FF8F00',
      background: '#FFF5E6',
      content: '#663D00',
      contrastText: '#fff',
    },
    info: {
      main: '#0091EA',
      light: '#64C1FF',
      dark: '#004B89',
      background: '#E9F5FE',
      content: '#0D3C61',
      contrastText: '#fff',
    },
    action: {
      disabled: '#BDBDBD',
      hover: '#F5F5F5',
      selected: '#EBEBEB',
    },
    success: {
      main: '#67E36C',
      light: '#8AEF8E',
      dark: '#59C65D',
      background: '#EDF7EE',
      content: '#1E4620',
      contrastText: '#fff',
    },
    neutral: {
      main: '#666666',
    },
    backdrop: {
      main: '#7F7F7F',
    },
    counter: {
      main: '#ffeceb',
    },
    // divider: {
    //   main: "#E0E0E0",
    // },
    snackbar: {
      main: '#323232',
    },
    outline: {
      main: '#FFFFFF',
    },
    gray: {
      light: '#00000099',
    },
  },
});

export default theme;
