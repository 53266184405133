import httpRequestHandler from '../../../../utilities/httpRequestHandler';
import {
  createTransitData,
  getTransitDataCollection,
} from '../../../../utilities/transitJsUtils';
import { setTeamRegistrations } from '../../../../store/teamTimesheetContextSlice';
import {
  setProjects,
  setProjectTeam,
} from '../../../../store/projectContextSlice';
var _ = require('lodash');

export const getTeamRegistrations = (params) => {
  const employee = params.employee === 'All employees' ? '' : params.employee;
  const project = params.project === 'All projects' ? '' : params.project;
  const team = params.team === 'All teams' ? '' : params.team;
  const requestParam = {
    from: params.from,
    to: params.to,
    employee: employee,
    project: project,
    team: team,
    // status: status,
  };
  return async (dispatch) => {
    try {
      const response = await httpRequestHandler(
        getTeamRegistrationsList,
        requestParam,
      );
      if (response.result === 1) {
        var data = response.data;
        let teamRegistrationList = [];
        let mapTeamRegistrationToObject = {
          id: 'db/id',
          owner: 'registration/owner',
          task: 'registration/task',
          regDuration: 'registration/quantity',
          start: 'registration/start',
          status: 'registration/status',
          description: 'task/project',
          regDesc: 'registration/description',
          createdBy: 'meta/created-by',
          approvedBy: 'meta/updated-by',
          updatedDate: 'meta/updated-at',
          note: 'registration/comments',
          createdDate: 'meta/created-at',
          teams: 'employment/teams',
        };

        data.rep.forEach((teamRegData, index) => {
          let mappedData = getTransitDataCollection(
            teamRegData,
            mapTeamRegistrationToObject,
          );

          const nMappedData = {
            id: mappedData.id,
            owner: getOwner(mappedData.owner),
            ownerName: getOwner(mappedData.owner).name,
            task: getTask(mappedData.task),
            regDuration: mappedData.regDuration,
            startDate: mappedData.start,
            status: mappedData.status,
            description: getDesc(mappedData.description),
            regDesc: mappedData.regDesc,
            createdByName: getUser(mappedData.createdBy)?.name,
            approvedByName: getUser(mappedData.approvedBy)?.name,
            note: getNote(mappedData.note),
            createdDate: mappedData.createdDate,
            updatedDate: mappedData.updatedDate,
            teams: getTeams(mappedData.teams),
          };
          teamRegistrationList.push(nMappedData);
        });

        dispatch(
          setTeamRegistrations({
            teamRegistrations: teamRegistrationList,
          }),
        );
      } else {
        dispatch(setTeamRegistrations({ teamRegistrations: [] }));
      }

      return response;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const updateApproveNote = async (value, note, status) => {
  try {
    let requestParamObj = {
      'db/id': { value: value.id, isKeyword: false },
      'registration/owner': {
        value: value.owner.id,
        isKeyword: false,
      },
      'registration/task': {
        value: value.task.id,
        isKeyword: false,
      },
      'registration/description': {
        value: value.regDesc,
        isKeyword: false,
      },
      'registration/comments': {
        value: note,
        isKeyword: false,
      },
      'registration/status': {
        value: status,
        isKeyword: true,
      },
      'registration/quantity': {
        value: Number(value.regDuration),
      },
    };
    let mapObjToTransit = createTransitData(requestParamObj);
    let registrationsData = await httpRequestHandler(
      putApproveNote,
      mapObjToTransit,
    );
    return registrationsData;
  } catch (error) {
    return { errorMessage: 'Error on Service Request' };
  }
};

const getNote = (value) => {
  var noteList = [];
  if (value !== undefined) {
    if (value.length > 0) {
      value.forEach((f) => {
        let mapToObject = {
          id: 'db/id',
          note: 'comment',
          firstName: 'user/firstname',
          lastName: 'user/lastname',
          email: 'user/email',
          timestamp: 'timestamp',
        };
        let mappedData = getTransitDataCollection(f, mapToObject);
        mappedData = {
          ...mappedData,
          name: `${mappedData.firstName} ${mappedData.lastName}`,
        };
        if (mappedData.note.length > 0) {
          noteList.push(mappedData);
        }
      });
    }
  }
  return noteList;
};

export const getProjectByTeam = (regObj) => {
  return async (dispatch) => {
    try {
      const registrationsData = await httpRequestHandler(
        getMeProjectsURL,
        regObj,
      );
      const mappedData = projectsDataMapper(registrationsData.data);

      dispatch(setProjectTeam({ projects: mappedData.projects }));
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const getUser = (value) => {
  if (value !== undefined) {
    let mapToObject = {
      id: 'db/id',
      name: 'name',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);

    return mappedData;
  }
  return null;
};

const getTeams = (value) => {
  var list = [];
  if (value !== undefined) {
    if (value.length > 0) {
      value.forEach((f) => {
        let mapToObject = {
          id: 'db/id',
          name: 'team/name',
        };
        let mappedData = getTransitDataCollection(f, mapToObject);
        list.push(mappedData);
      });
    }
  }
  return list;
};

const getDesc = (value) => {
  let mapToObject = {
    id: 'db/id',
    description: 'registration-type/description',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  return mappedData;
};

const getTask = (value) => {
  let mapToObject = {
    id: 'db/id',
    task: 'task/title',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  return mappedData;
};

const getOwner = (value) => {
  let mapToObject = {
    id: 'db/id',
    firstName: 'user/firstname',
    lastName: 'user/lastname',
  };
  let mappedData = getTransitDataCollection(value, mapToObject);
  mappedData = {
    ...mappedData,
    name: `${mappedData.firstName} ${mappedData.lastName}`,
  };
  return mappedData;
};

const getTeamRegistrationsList = {
  url: 'registrations',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'query-string',
  hasAuth: true,
  'x-registration-unit': 'minutes',
};

const putApproveNote = {
  url: 'me/registrations',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  'x-registration-unit': 'minutes',
};

const getMeProjectsURL = {
  url: 'me/projects',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
};

const projectsDataMapper = (data) => {
  let mappedProjectsList = [];
  let mapProjectToObject = {
    projectId: 'db/id',
    projectName: 'project/name',
    projectOwnerId: 'project/owner',
    projectOrganizationId: 'project/organization',
    projectTasks: 'project/tasks',
    projectStatus: 'project/status',
  };
  data.rep.forEach((project) => {
    var mappedProject = getTransitDataCollection(project, mapProjectToObject);
    mappedProject.ownerId = getOwnerDetails(mappedProject.projectOwnerId)?.id;

    mappedProjectsList.push(mappedProject);
  });
  return {
    projects: mappedProjectsList,
  };
};

const getOwnerDetails = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'db/id',
      team: 'team/name',
      organization: 'organization/name',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    var name = '';
    if (mappedData.team) name = mappedData.team;
    if (mappedData.organization) name = mappedData.organization;

    result = {
      id: mappedData.id,
      name: name,
    };
  }

  return result;
};
