import React from 'react';
import classes from './Sidebar.module.scss';
import Avatar from '@mui/material/Avatar';
import { ListItemButton, Typography, List, styled } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useTranslation } from 'react-i18next';

const ListMenu = styled(List)(({ theme }) => ({
  '& .Mui-selected p': {
    fontWeight: '600',
  },
}));

const Sidebar = (props) => {
  const { t } = useTranslation();
  const onListItemHandler = (value) => {
    props.onMenuHandler(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer + ' ' + classes.profileName}>
        <div className={classes.appBar_profile}>
          <Avatar alt={props.user.userFirstName} src="/broken-image.jpg">
            {props.user.userFirstName.charAt(0) +
              props.user.userLastName.charAt(0)}
          </Avatar>
        </div>
        <div>
          <Typography variant="body2">
            {props.user.userFirstName} {props.user.userLastName}
          </Typography>
          <Typography variant="caption">{props.user.userEmail}</Typography>
        </div>
      </div>
      <div className="SidebarBackgroundColor">
        <ListMenu className={classes.org_list}>
          <ListItemButton
            key={2}
            selected={props.selectedMenu === 2}
            className={{ selected: classes.active }}
            onClick={(_) => onListItemHandler(2)}
          >
            <VpnKeyIcon fontSize="small" />
            <Typography variant="body2" className={'padT3 ' + classes.palbl}>
              {t('privacy-account.privacy-account')}
            </Typography>
          </ListItemButton>
          <ListItemButton
            key={1}
            selected={props.selectedMenu === 1}
            className={{ selected: classes.active }}
            onClick={(_) => onListItemHandler(1)}
          >
            <Typography variant="body2" className={'padT3 ' + classes.palbl}>
              {t('privacy-account.security')}
            </Typography>
          </ListItemButton>
        </ListMenu>
      </div>
    </div>
  );
};

export default Sidebar;
